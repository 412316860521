import { showErrorToast, showSuccessToast } from "components/WbToast";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import callApi from "utils/callApi";
import Form from "../components/Form";

type FormValues = {
  senderName: string;
  senderEmail: string;
  fromName: string;
  replyToEmail: string;
  replyToName: string;
  accessToken: string;
};

const fields = [
  { label: "Sender name", name: "senderName", type: "text" },
  { label: "From address", name: "senderEmail", type: "email" },
  { label: "From name", name: "fromName", type: "text" },
  { label: "Reply to address", name: "replyToEmail", type: "email" },
  { label: "Reply to name", name: "replyToName", type: "text" },
  { label: "Apikey", name: "accessToken", type: "text" },
];
const CreateSender = () => {
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const res = await callApi({
      url: `/builtInTools/dataStudio/saveSender`,
      body: data,
    });
    if (res.success === true) {
      navigate(-1);
      showSuccessToast(`${res.message}`);
    } else {
      showErrorToast(`${res.message}`);
    }
  };
  return (
    <>
      <Form
        fields={fields}
        className="w-[90%] md:w-[50%] xl:w-[30%]"
        onSubmit={onSubmit}
        button="Save"
      />
    </>
  );
};

export default CreateSender;
