import React from "react";
import { useSelector } from "react-redux";

import SelectPlan from "components/SelectPlan";
import { isDataPresent } from "utils/object_util";
import WbModal, { WbModalTitle } from "components/WbModal";

export default function SelectPlanOrUpdatePlan({ closeModal, planOps }: any) {
  const defaultCardDetails = useSelector(
    (state: any) => state.ManageSubscription
  );

  return (
    <WbModal toggle={closeModal} isOpen={true}>
      <WbModalTitle toggle={closeModal} title="Upgrade Plan" />
      <SelectPlan
        planButtonText={
          isDataPresent(defaultCardDetails)
            ? "Select Plan"
            : "Try FREE for 7 days"
        }
        closeModal={closeModal}
        planOps={planOps}
        shouldLoadEnterprisePlan={false}
        shouldLoadFaqs={false}
      />
    </WbModal>
  );
}
