import WbLoader from "components/WbLoader";

const LoadSpinner = (props: any) => {
  const { padding, message } = props;

  return (
    <div
      className="mt-4 flex w-auto flex-col flex-wrap items-center justify-around"
      style={{ padding }}
    >
      <WbLoader className="mt-4" />
      <h6 className="mt-2">{message}</h6>
    </div>
  );
};

export default LoadSpinner;
