import { call, put, takeEvery, all } from "redux-saga/effects";
import _ from "lodash";

import { asyncPost } from "utils/async_util";
import { logoutUser } from "modules/Auth/AuthActions";
import {
  loggedInUserSubscriptionFetchFail,
  loggedInUserSubscriptionFetchSuccess,
  refreshSubscriptionSuccess,
  loadDataFromUrlFail,
  loadDataFromUrlSuccess,
} from "./LoggedInLayoutActions";
import {
  FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER,
  REFRESH_SUBSCRIPTION,
  LOAD_DATA_FROM_URL,
} from "./LoggedInLayoutActionTypes";
import { showErrorToast } from "components/WbToast";

function* getSubscriptionForLoggedInUserSaga({ payload }) {
  const { loggedInUserSubscriptionId } = payload;

  try {
    // TODO: What to project?
    const [err, res] = yield call(asyncPost, `/customer/getSubscription`, {
      subscriptionId: loggedInUserSubscriptionId,
    });
    if (err) {
      return yield all([
        put(loggedInUserSubscriptionFetchFail()),
        put(logoutUser()),
      ]);
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield all([
        put(loggedInUserSubscriptionFetchFail()),
        put(logoutUser()),
      ]);
    }

    const { subscription } = res.data;
    if (window.smartlook) {
      window.smartlook("identify", subscription._id.toString(), {
        email: subscription.email,
        name: subscription.name,
        subscriptionId: subscription._id.toString(),
        source: "v3",
      });
    }

    return yield put(loggedInUserSubscriptionFetchSuccess(subscription));
  } catch (err) {
    showErrorToast();
    return yield all([put(loggedInUserSubscriptionFetchFail())]);
  }
}

function* refreshSubscriptionSaga({ payload }) {
  const { subscriptionId } = payload;

  try {
    // TODO: What to project?
    const [err, res] = yield call(asyncPost, `/customer/getSubscription`, {
      subscriptionId,
    });
    if (err) {
      return;
    }

    if (!res.data.success) {
      return;
    }

    return yield put(refreshSubscriptionSuccess(res.data.subscription));
  } catch (err) {
    return;
  }
}

function* loadDataFromUrlSaga({ payload }) {
  const { url, body, sideEffectFns } = payload;

  try {
    const [dataLoadErr, dataLoadRes] = yield call(asyncPost, url, body);
    if (dataLoadErr) {
      return yield put(loadDataFromUrlFail());
    }

    if (!dataLoadRes.data.success) {
      return yield put(loadDataFromUrlFail());
    }

    delete dataLoadRes.data.success;
    delete dataLoadRes.data.message;
    _.each(sideEffectFns, (eachSideEffectFn) =>
      eachSideEffectFn(dataLoadRes.data.uiData)
    );
    return yield put(loadDataFromUrlSuccess());
  } catch (err) {
    return yield put(loadDataFromUrlFail());
  }
}

export function* watchLoggedInLayout() {
  yield takeEvery(
    FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER,
    getSubscriptionForLoggedInUserSaga
  );
  yield takeEvery(REFRESH_SUBSCRIPTION, refreshSubscriptionSaga);
  yield takeEvery(LOAD_DATA_FROM_URL, loadDataFromUrlSaga);
}
