import {
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	VERIFY_RESET_PASSWORD_TOKEN,
	VERIFY_RESET_PASSWORD_TOKEN_SUCCESS,
	VERIFY_RESET_PASSWORD_TOKEN_FAIL,
} from "./ResetPasswordActionTypes";

const INIT_STATE = {
	isResettingPassword: false,
	isResetPasswordSuccess: false,
	isVerifyingResetPasswordToken: false,
	isResetPasswordTokenVerificationSuccess: false,
	verifyResetPasswordErrMessage: "",
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case VERIFY_RESET_PASSWORD_TOKEN:
			return {
				...state,
				isVerifyingResetPasswordToken: true,
			};
		case VERIFY_RESET_PASSWORD_TOKEN_SUCCESS:
			return {
				...state,
				isVerifyingResetPasswordToken: false,
				isResetPasswordTokenVerificationSuccess: true,
			};
		case VERIFY_RESET_PASSWORD_TOKEN_FAIL:
			return {
				...state,
				isVerifyingResetPasswordToken: false,
				isResetPasswordTokenVerificationSuccess: false,
				verifyResetPasswordErrMessage: payload.errMessage,
			};
		case RESET_PASSWORD:
			return {
				...state,
				isResettingPassword: true,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				isResettingPassword: false,
				isResetPasswordSuccess: true,
			};
		case RESET_PASSWORD_FAIL:
			return {
				...state,
				isResettingPassword: false,
			};
		default:
			return { ...state };
	}
};
