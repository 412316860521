import axios from "axios";

const axiosWithoutErrHandler = axios.create({
    baseURL: window.wyzebulbApiBaseUrl,
});

const axiosWithoutErrHandlerForFileUploads = axios.create({
    baseURL: window.wyzebulbApiBaseUrl,
});

axiosWithoutErrHandler.defaults.headers.post["Content-Type"] = "application/json";

axiosWithoutErrHandlerForFileUploads.defaults.headers.post["Content-Type"] = "multipart/form-data";

export { axiosWithoutErrHandler, axiosWithoutErrHandlerForFileUploads };
