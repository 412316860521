import { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FaFlask } from "react-icons/fa";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import {
  isDataPresent,
  getHelperTextHtmlJsxFromParts,
} from "utils/object_util";

import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbLoader from "components/WbLoader";
import WbIntegrationImage from "components/WbIntegrationImage";

import {
  getTestEventSampleData,
  setSelectedTestEventSampleData,
} from "../../FlowBuilderActions";

class TestEventModal extends PureComponent {
  state = {
    shouldExpandTestEventData: {},
  };

  componentDidMount() {
    const { stageIndex, testEventPollUrlDetails, commonData, flowObj } =
      this.props;

    const reducerTestEventSampleData =
      commonData[stageIndex].testEventSampleData || {};
    if (!isDataPresent(reducerTestEventSampleData)) {
      const { url, bodyParamsRequired } = testEventPollUrlDetails;
      const bodyParams = {};

      if (isDataPresent(bodyParamsRequired)) {
        _.each(bodyParamsRequired, (eachBodyParamRequired) => {
          bodyParams[eachBodyParamRequired] =
            commonData[stageIndex][eachBodyParamRequired];
        });
      }
      const incomingData =
        flowObj.stages[stageIndex].stageItems.setupEventOptions.incomingData;
      this.props.getTestEventSampleData(
        stageIndex,
        url,
        bodyParams,
        incomingData
      );
    }
  }

  toggleCollapseOfTestEventData = (indexOfTestEventData) => {
    this.setState((prevState) => {
      return {
        shouldExpandTestEventData: {
          ...prevState.shouldExpandTestEventData,
          [indexOfTestEventData]:
            !prevState.shouldExpandTestEventData[indexOfTestEventData],
        },
      };
    });
  };

  changeEventHandler = (testEventSampleData) => {
    const { stageIndex } = this.props;

    this.props.setSelectedTestEventSampleData(stageIndex, testEventSampleData);
  };

  saveClickHandler = () => {
    this.props.closeModal();
  };

  pullMoreTestSamplesHandler = () => {
    const { stageIndex, testEventPollUrlDetails, commonData, flowObj } =
      this.props;
    const { url, bodyParamsRequired } = testEventPollUrlDetails;
    const bodyParams = {};

    if (isDataPresent(bodyParamsRequired)) {
      _.each(bodyParamsRequired, (eachBodyParamRequired) => {
        bodyParams[eachBodyParamRequired] =
          commonData[stageIndex][eachBodyParamRequired];
      });
    }
    const incomingData =
      flowObj.stages[stageIndex].stageItems.setupEventOptions.incomingData;
    this.props.getTestEventSampleData(
      stageIndex,
      url,
      bodyParams,
      incomingData
    );
  };

  skipStageItemHandler = () => {
    this.props.skipStageItem();
    this.props.closeModal();
  };

  render() {
    const {
      stageIndex,
      integrationDisplayText,
      commonData,
      userHelper,
      isLoadingTestEventSampleData,
      slug,
    } = this.props;
    const { helperTextHtmlParts } = userHelper;
    const helperTextHtmlJsx =
      getHelperTextHtmlJsxFromParts(helperTextHtmlParts);

    const { testEventSampleData, selectedTestEventSampleData } =
      commonData[stageIndex] || {};
    let testSampleDataUi = null;
    const isWebhookTriggerUrlPresent = isDataPresent(
      _.get(commonData[stageIndex], "wyzebulbWebhookTriggerUrl", "")
    );

    if (!isLoadingTestEventSampleData && !isDataPresent(testEventSampleData)) {
      testSampleDataUi = (
        <div className="flex flex-col items-center justify-center">
          <FaFlask size={44} />
          <h1 className="text-2xl">No sample data found</h1>
        </div>
      );
    } else {
      testSampleDataUi = _.map(testEventSampleData, (eachTestSample, index) => {
        const isCurrentTestEventSampleDataSelected = _.isEqual(
          eachTestSample,
          selectedTestEventSampleData
        );

        return (
          <div
            key={index}
            className="flex min-w-[100%] flex-col dark:text-gray-700"
          >
            <div
              className="flex cursor-pointer flex-row items-center space-x-2 bg-gray-100 p-4 dark:bg-gray-700  dark:text-gray-100 "
              onClick={() => this.changeEventHandler(eachTestSample)}
            >
              <input
                type="radio"
                id={`test-event-sample-${index}`}
                name="select-event-radio"
                checked={isCurrentTestEventSampleDataSelected}
                onChange={() => this.changeEventHandler(eachTestSample)}
              />
              <h3 className="text-lg">{`Test Sample #${index + 1}`}</h3>
              <div
                className="cursor-pointer"
                onClick={() => this.toggleCollapseOfTestEventData(index)}
              >
                {!this.state.shouldExpandTestEventData[index] ? (
                  <RiArrowDownSLine size={22} />
                ) : (
                  <RiArrowUpSLine size={22} />
                )}
              </div>
            </div>

            {this.state.shouldExpandTestEventData[index] && (
              <div className="border p-4 dark:border-gray-400">
                {_.map(eachTestSample, (eachSampleObj, sampleIndex) => {
                  return (
                    <div key={sampleIndex} className="grid grid-cols-4">
                      <div className="border p-2 font-bold  dark:border-gray-400 dark:text-white">
                        {eachSampleObj.displayText}:
                      </div>
                      <div className="break-words col-span-3 border p-2 dark:border-gray-400 dark:text-white">
                        {_.includes(eachSampleObj.testValue, "https") ? (
                          <a
                            href={`${eachSampleObj.testValue}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-400"
                          >
                            {eachSampleObj.testValue}
                          </a>
                        ) : (
                          eachSampleObj.testValue
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    }

    return (
      <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
        <WbModalTitle toggle={this.props.closeModal} title="Test Event" />
        <div className="flex flex-col items-center space-y-5 p-4">
          <WbIntegrationImage
            slug={slug}
            altText={integrationDisplayText}
            avtarSize="80"
          />
          <h1
            className="text-2xl"
            dangerouslySetInnerHTML={{
              __html: helperTextHtmlJsx,
            }}
          ></h1>
          {testSampleDataUi}
          {isLoadingTestEventSampleData ? (
            <div className="flex flex-col items-center">
              <WbLoader />
              <h6 className="mt-2">
                Please wait. We are fetching the Test sample(s).
              </h6>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-center justify-between space-x-4 p-10">
          <WbButton outline={true} onClick={this.pullMoreTestSamplesHandler}>
            Pull more samples
          </WbButton>
          {!isWebhookTriggerUrlPresent ? (
            <WbButton outline={true} onClick={this.skipStageItemHandler}>
              Skip this step
            </WbButton>
          ) : null}
          <WbButton
            disabled={
              isLoadingTestEventSampleData ||
              !isDataPresent(selectedTestEventSampleData)
            }
            onClick={this.saveClickHandler}
          >
            Save + continue
          </WbButton>
        </div>
      </WbModal>
    );
  }
}

const mapStateToProps = ({ FlowBuilder }) => {
  const {
    selectedIntegrations,
    flowObj,
    commonData,
    isLoadingTestEventSampleData,
  } = FlowBuilder;

  return {
    selectedIntegrations,
    flowObj,
    commonData,
    isLoadingTestEventSampleData,
  };
};

export default connect(mapStateToProps, {
  getTestEventSampleData,
  setSelectedTestEventSampleData,
})(TestEventModal);
