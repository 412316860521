function WbYoutube(props: any) {
  const { videoUrl, title, subTitle, thumbnailImg } = props;

  return (
    <div className="dark: h[80%] m-2 mt-8 overflow-hidden rounded border px-2 pt-2 pr-4 pb-4 pl-4 dark:border-slate-600">
      <span className="text-normal font-medium dark:font-light dark:text-white">
        {title}
      </span>
      <p className="mb-2 text-sm dark:text-gray-400">{subTitle}</p>
      <div className="over:border-orange-300 relative max-w-xs overflow-hidden rounded border shadow-lg">
        <a href={videoUrl} target="_blank" rel="noreferrer">
          <img
            src={thumbnailImg}
            alt="Video thumbnail"
            className="h-[80%] w-[100%]"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className=" h absolute inset-0 bg-black opacity-20"></div>
            <img
              src="/assets/img/video/play.png"
              alt="Video thumbnail"
              className="h-[40%] w-[20%]"
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default WbYoutube;
