import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useKBar,
  useMatches,
} from "kbar";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { isMac } from "utils/isMac";
import { Tooltip } from "components/Tooltip";
import {
  FiSearch,
  FiArrowUp,
  FiArrowDown,
  FiCornerDownLeft,
  FiCommand,
} from "components/Icon";

type shortcutArrayType = {
  shortcuts?: string[];
};

export const KBarRoot = ({ children }: { children: React.ReactNode }) => {
  const router = useNavigate();

  // grab link to events
  // quick nested actions would be extremely useful

  const actions = useMemo(() => {
    return [
      {
        id: "dashboard",
        name: "Dashboard",
        section: "dashboard",
        shortcut: ["d"],
        keywords: "dashboard flow",
        perform: () => router("/dashboard"),
      },
      {
        id: "flows",
        name: "Flows",
        section: "flows",
        shortcut: ["f"],
        keywords: "flows flow createflow",
        perform: () => router("/flows"),
      },
      {
        id: "apps",
        name: "Apps",
        section: "apps",
        shortcut: ["a"],
        keywords: "app store",
        perform: () => router("/apps"),
      },
      {
        id: "profile",
        name: "Profile",
        section: "profile",
        shortcut: ["p"],
        keywords: "setting profile",
        perform: () => router("/account"),
      },
      {
        id: "avatar",
        name: "Change Avatar",
        section: "profile",
        shortcut: ["c", "a"],
        keywords: "remove change modify avatar",
        perform: () => router("/account/profile"),
      },
      {
        id: "password",
        name: "Change Password",
        section: "security",
        shortcut: ["c", "p"],
        keywords: "change modify password",
        perform: () => router("/account/password"),
      },
      {
        id: "billing",
        name: "Manage Billing",
        section: "billing",
        shortcut: ["m", "b"],
        keywords: "billing view manage",
        perform: () => router("/account/billing"),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <KBarProvider actions={actions}>{children}</KBarProvider>;
};

export const KBarContent = () => {
  return (
    <KBarPortal>
      <KBarPositioner>
        <KBarAnimator className="z-10 w-full max-w-screen-sm overflow-hidden rounded-md bg-white shadow-lg">
          <div className="flex items-center justify-center border-b">
            <FiSearch className="mx-3 h-4 w-4 text-gray-500" />
            <KBarSearch
              defaultPlaceholder="Type a command or search..."
              className="w-full rounded-sm py-2.5 focus-visible:outline-none"
            />
          </div>
          <RenderResults />
          <div className="hidden items-center space-x-1 border-t px-2 py-1.5 text-xs text-gray-500 sm:flex">
            <FiArrowUp className="h-4 w-4" />
            <FiArrowDown className="h-4 w-4" />{" "}
            <span className="pr-2">Navigate</span>
            <FiCornerDownLeft className="h-4 w-4" />
            <span className="pr-2">Open</span>
            {isMac ? <FiCommand className="h-3 w-3" /> : "CTRL"}
            <span className="pr-1">+ K </span>
            <span className="pr-2">Close</span>
          </div>
        </KBarAnimator>
        <div className="z-1 fixed inset-0 bg-gray-600 bg-opacity-75" />
      </KBarPositioner>
    </KBarPortal>
  );
};

export const KBarTrigger = () => {
  const { query } = useKBar();
  return query ? (
    <>
      <Tooltip side="right" content={isMac ? "⌘ + K" : "CTRL + K"}>
        <button
          color="minimal"
          onClick={query.toggle}
          className="dark:hover-none group flex rounded-md py-2 px-2 text-sm font-medium hover:bg-gray-100 dark:bg-none dark:text-white lg:p-2 lg:hover:bg-gray-200 lg:hover:text-gray-900"
        >
          <FiSearch className="h-4 w-4 flex-shrink-0 text-inherit" />
        </button>
      </Tooltip>
    </>
  ) : null;
};

const DisplayShortcuts = (item: shortcutArrayType) => {
  const shortcuts = item.shortcuts;

  return (
    <span className="space-x-1">
      {shortcuts?.map((shortcut) => {
        return (
          <kbd
            key={shortcut}
            className="rounded-sm border bg-white px-2 py-1 text-black hover:bg-gray-100"
          >
            {shortcut}
          </kbd>
        );
      })}
    </span>
  );
};

function RenderResults() {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div className="bg-white p-4 text-xs uppercase text-gray-500">
            {item}
          </div>
        ) : (
          <div
            // For seeing keyboard up & down navigation in action, we need visual feedback based on "active" prop
            style={{
              background: active ? "rgb(245,245,245)" : "#fff",
              borderLeft: active ? "2px solid black" : "2px solid transparent",
            }}
            className="flex items-center justify-between px-4 py-2.5 text-sm hover:cursor-pointer"
            onClick={() => item.command?.perform()}
          >
            <span>{item.name}</span>
            <DisplayShortcuts shortcuts={item.shortcut} />
          </div>
        )
      }
    />
  );
}
