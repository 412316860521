export const INIT_STAGES_TO_BE_SHOWN = "INIT_STAGES_TO_BE_SHOWN";
export const ADD_STAGE = "ADD_STAGE";
export const DELETE_STAGE = "DELETE_STAGE";
export const SET_SELECTED_INTEGRATION = "SET_SELECTED_INTEGRATION";
export const SET_SUBSCRIPTION_ID = "SET_SUBSCRIPTION_ID";
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";
export const SET_SELECTED_ACTION = "SET_SELECTED_ACTION";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SELECT_DYNAMIC_INCOMING_DATA_PREREQUISITE = "SELECT_DYNAMIC_INCOMING_DATA_PREREQUISITE";
export const SELECT_DYNAMIC_INCOMING_DATA = "SELECT_DYNAMIC_INCOMING_DATA";
export const SETUP_DATA_FROM_PREVIOUS_STAGES = "SETUP_DATA_FROM_PREVIOUS_STAGES";
export const INIT_API_DATA_IN_REDUCER = "INIT_API_DATA_IN_REDUCER";
export const SELECT_API_DATA = "SELECT_API_DATA";
export const SETUP_FILTER = "SETUP_FILTER";
export const SET_FLOW_NAME = "SET_FLOW_NAME";
export const SAVE_FLOW = "SAVE_FLOW";
export const SAVE_FLOW_SUCCESS = "SAVE_FLOW_SUCCESS";
export const SAVE_FLOW_FAIL = "SAVE_FLOW_FAIL";
export const SEED_FLOW_BUILDER_FOR_VIEW = "SEED_FLOW_BUILDER_FOR_VIEW";
export const GET_INTEGRATION_FOR_FLOW_BUILDER = "GET_INTEGRATION_FOR_FLOW_BUILDER";
export const GET_TEST_EVENT_SAMPLE_DATA = "GET_TEST_EVENT_SAMPLE_DATA";
export const GET_TEST_EVENT_SAMPLE_DATA_SUCCESS = "GET_TEST_EVENT_SAMPLE_DATA_SUCCESS";
export const GET_TEST_EVENT_SAMPLE_DATA_ERROR = "GET_TEST_EVENT_SAMPLE_DATA_ERROR";
export const SET_SELECTED_TEST_EVENT_SAMPLE_DATA = "SET_SELECTED_TEST_EVENT_SAMPLE_DATA";
export const GET_GENERATED_WEBHOOK_URL = "GET_GENERATED_WEBHOOK_URL";
export const GET_GENERATED_WEBHOOK_URL_SUCCESS = "GET_GENERATED_WEBHOOK_URL_SUCCESS";
export const GET_GENERATED_WEBHOOK_URL_ERROR = "GET_GENERATED_WEBHOOK_URL_ERROR";
export const SEND_TEST_ACTION_DATA = "SEND_TEST_ACTION_DATA";
export const SEND_TEST_ACTION_DATA_SUCCESS = "SEND_TEST_ACTION_DATA_SUCCESS";
export const SEND_TEST_ACTION_DATA_ERROR = "SEND_TEST_ACTION_DATA_ERROR";
export const FETCH_SINGLE_FLOW = "FETCH_SINGLE_FLOW";
export const FETCH_SINGLE_FLOW_SUCCESS = "FETCH_SINGLE_FLOW_SUCCESS";
export const FETCH_SINGLE_FLOW_FAIL = "FETCH_SINGLE_FLOW_FAIL";
export const RESET_FLOW_BUILDER_REDUCER = "RESET_FLOW_BUILDER_REDUCER";
export const SET_DATA_FORMATTER_OPTION = "SET_DATA_FORMATTER_OPTION";
export const SET_DEFAULT_DYNAMIC_INCOMING_DATA = "SET_DEFAULT_DYNAMIC_INCOMING_DATA";
export const GET_TRIGGER_AND_ACTION_INTEGRATION = "GET_TRIGGER_AND_ACTION_INTEGRATION";
export const GET_TRIGGER_AND_ACTION_INTEGRATION_SUCCESS = "GET_TRIGGER_AND_ACTION_INTEGRATION_SUCCESS";
export const FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES = "FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES";
export const FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES_SUCCESS = "FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES_SUCCESS";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";
