import { memo } from "react";
import PropTypes from "prop-types";

import WbButton from "components/WbButton";
import { WbCard } from "components/WbCard";
import { FaInfoCircle } from "react-icons/fa";

const EachPlanDetails = (props: any) => {
	const { indexOfEachPlan, eachPlanDetailsToShow, eachPlanButtonText } =
		props;
	const { displayText, price, features } = eachPlanDetailsToShow;
	const bestValuePlan = indexOfEachPlan === 1;

	return (
		<WbCard className="m-2">
			<div className="space-y-4 text-center">
				<h5 className="text-lg font-semibold uppercase text-orange-600">
					{displayText}
				</h5>
				<div className="space-x-1 text-base">
					<span>{features[0]}</span>
					<span>Tasks / month</span>
					<span id={`tooltipId-${indexOfEachPlan}`}>
						<FaInfoCircle className="inline" />
					</span>
				</div>
				<div className="text-base">
					<span className="text-lg">${price}</span>
					<span>/ month</span>
				</div>
			</div>
			<div className="mx-auto mt-5">
				<WbButton
					onClick={() =>
						props.initSelectPlanModal(eachPlanDetailsToShow)
					}
				>
					{eachPlanButtonText}
				</WbButton>
			</div>
		</WbCard>
	);
};

EachPlanDetails.propTypes = {
	openContactUsModal: PropTypes.func,
	shouldShowSpinner: PropTypes.bool,
	initSelectPlanModal: PropTypes.func,
	indexOfEachPlan: PropTypes.number,
	eachPlanDetailsToShow: PropTypes.object,
	eachPlanButtonText: PropTypes.string,
};

export default memo(EachPlanDetails);
