import AuthCover from "./AuthCover";

export default function AuthLayout(props: any) {
  return (
    <div className="flex h-screen w-screen justify-between overflow-y-scroll bg-gray-50 dark:bg-slate-900">
      <AuthCover />
      <div className="flex h-full w-full grow items-center justify-center p-4">
        <div className="flex w-full max-w-xl flex-col justify-between rounded-lg ">
          {props.children}
        </div>
      </div>
    </div>
  );
}
