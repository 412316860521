import React, { Fragment } from "react";

import OptionalRequiredLabel from "../OptionalRequiredLabel";
import { isDataPresent } from "utils/object_util";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";

const DefaultInput = (props) => {
    const { selectedValueData } = props;
    let selectedValue = "";
    if (isDataPresent(selectedValueData)) {
        const { jsonDetails: selectedJsonDetails } = selectedValueData;
        selectedValue = selectedJsonDetails[0].valueDetails[0].value;
    }

    return (
        <Fragment>
            <OptionalRequiredLabel {...props} />
            {props.userHelperContent}
            <div>
                {props.preInputText ? <div>{props.preInputText}</div> : null}
                <input
                    className="rounded mt-4 border-gray-300 border text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-100"
                    style={{ height: props.inputHeight || "50px", width: "100%" }}
                    type={props.inputType}
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder || `Enter ${props.labelText}`}
                    required={props.isRequired}
                    {...(props.shouldHandleChange
                        ? {
                              onChange: (event) =>
                                  props.userInputHandler({
                                      jsonPairIndex: 0,
                                      userInput: {
                                          value: event.target.value,
                                          valueFrom: "userCustomValue",
                                          id: props.id,
                                      },
                                      jsonPairType: JsonPairTypesEnum.VALUE,
                                      jsonPairValueIndex: 0,
                                  }),
                          }
                        : {
                              onBlur: (event) =>
                                  props.userInputHandler({
                                      jsonPairIndex: 0,
                                      userInput: {
                                          value: event.target.value,
                                          valueFrom: "userCustomValue",
                                          id: props.id,
                                      },
                                      jsonPairType: JsonPairTypesEnum.VALUE,
                                      jsonPairValueIndex: 0,
                                  }),
                          })}
                    value={selectedValue}
                />
                {props.postInputText ? <div>{props.postInputText}</div> : null}
                <span className="hidden">Please enter {props.labelText}</span>
            </div>
        </Fragment>
    );
};

export default DefaultInput;
