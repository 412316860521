import classNames from "classnames";

const theme = {
    base: "min-h-screen dark:bg-gray-800 dark:border-gray-700",
};

export default function WbPage(props) {
    const { children, className, ...restProps } = props;

    return (
        <div className={classNames(theme.base, className)} {...restProps}>
            {children}
        </div>
    );
}
