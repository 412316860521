import { WbCard } from "components/WbCard";
import { get } from "lodash";
import { useState, useCallback, useEffect } from "react";
import callApi from "utils/callApi";
import { MdArrowForwardIos } from "react-icons/md";
import SegmentNameAndDateSection from "./SegmentNameAndDateSection";
import { useNavigate } from "react-router-dom";
import WbLoader from "components/WbLoader";

export default function Segments(props: any) {
  const [segments, setSegments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: any = await callApi({
        url: `/builtInTools/dataStudio/listSegments`,
        method: "POST",
        body: {
          findQuery: {},
          projection: {
            segmentName: 1,
            email: 1,
            createdAt: 1,
            updatedAt: 1,
          },
          sort: { updatedAt: -1 },
          limit: 5,
        },
      });
      setSegments(get(response, "data.docs", []));
      setIsLoading(false);
    } catch (error: any) {}
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {isLoading ? (
        <WbLoader />
      ) : (
        <>
          {segments?.map((segment: any) => (
            <div key={segment._id}>
              <WbCard
                className="mx-auto mb-2 w-full hover:cursor-pointer hover:bg-orange-50 dark:bg-gray-600 dark:hover:bg-slate-500 md:w-[60%]"
                title={segment.segmentName}
                onClick={() => navigate(`${segment._id}`)}
              >
                <div className="flex w-full items-center">
                  <div className="flex w-[98%] items-center">
                    <SegmentNameAndDateSection
                      name={segment.segmentName}
                      date={segment.updatedAt}
                      segmentId={segment._id}
                    />
                  </div>
                  <div className="flex">
                    <div className="mr-4 text-gray-500">
                      {segment.senderEmail}
                    </div>
                    <MdArrowForwardIos
                      size={25}
                      className="mb-1 text-orange-600"
                    />
                  </div>
                </div>
              </WbCard>
            </div>
          ))}
        </>
      )}
    </>
  );
}
