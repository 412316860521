import { call, put, takeEvery } from "redux-saga/effects";

import { asyncPost } from "utils/async_util";
import { FETCH_INTEGRATION_CATEGORIES } from "./IntegrationCategoriesActionTypes";
import {
	integrationCategoriesFetchSuccess,
	integrationCategoriesFetchFail,
} from "./IntegrationCategoriesActions";

function* getIntegrationCategoriesSaga() {
	try {
		const [err, res] = yield call(
			asyncPost,
			"/apps/listCategories"
		);
		if (err) {
			return yield put(integrationCategoriesFetchFail());
		}

		if (!res.data.success) {
			return yield put(integrationCategoriesFetchFail());
		}

		return yield put(
			integrationCategoriesFetchSuccess(res.data.integrationCategories)
		);
	} catch (err) {
		return yield put(integrationCategoriesFetchFail());
	}
}

export function* watchIntegrationCategories() {
	yield takeEvery(FETCH_INTEGRATION_CATEGORIES, getIntegrationCategoriesSaga);
}
