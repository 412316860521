import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { useForm } from "react-hook-form";
import { RiLockPasswordLine } from "react-icons/ri";
import queryString from "query-string";

import { Form, ShowPasswordField } from "components/Fields";
import WbButton from "components/WbButton";
import { showErrorToast } from "components/WbToast";
import { isDataPresent } from "utils/object_util";

import {
  resetPassword,
  verifyResetPasswordToken,
} from "./ResetPasswordActions";
import AuthLayout from "../AuthLayout";

export default function ResetPassword() {
  const {
    isResettingPassword,
    isResetPasswordSuccess,
    isVerifyingResetPasswordToken,
    isResetPasswordTokenVerificationSuccess,
    verifyResetPasswordErrMessage,
  } = useSelector((state) => state.ResetPassword);

  const formMethods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryObj = queryString.parse(search);
  const { token } = queryObj;

  useEffect(() => {
    if (isDataPresent(token)) {
      dispatch(verifyResetPasswordToken(token));
    }
  }, [dispatch, token]);

  const submitResetPassword = (formValues) => {
    const { newPassword, confirmPassword } = formValues;

    if (newPassword === "" || confirmPassword === "") {
      return showErrorToast("Please fill in all the details.");
    }
    if (newPassword !== confirmPassword) {
      return showErrorToast("Passwords do not match.");
    }
    if (isDataPresent(token)) {
      dispatch(resetPassword(newPassword, token));
    }
  };

  if (!isResettingPassword && isResetPasswordSuccess) {
    return <Navigate to="/login" />;
  }

  let resetPasswordDiv = null;
  if (isVerifyingResetPasswordToken) {
    resetPasswordDiv = (
      <Fragment>
        <ContentLoader height={2} width={50} speed={2}>
          <rect x="0" y="0" rx="0.5" ry="0.5" width="100%" height="1" />
        </ContentLoader>
        <ContentLoader height={4} width={50} speed={2}>
          <rect x="0" y="0" rx="0.5" ry="0.5" width="100%" height="1" />
        </ContentLoader>
        <ContentLoader height={5} width={50} speed={2}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="4" />
        </ContentLoader>
        <ContentLoader height={7} width={50} speed={2}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="4" />
        </ContentLoader>
        <ContentLoader height={5} width={50} speed={2}>
          <rect x="0" y="0" rx="0" ry="0" width="25%" height="4" />
        </ContentLoader>
      </Fragment>
    );
  } else if (isResetPasswordTokenVerificationSuccess) {
    resetPasswordDiv = (
      <>
        <div className="h-screen">
          <div className="flex min-h-full">
            <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <div>
                  <div className="flex flex-row items-center justify-center">
                    <img
                      className="h-12 w-auto"
                      src="/assets/img/logos/wyzebulb-1x.png"
                      alt="Wyzebulb"
                    />
                  </div>
                  <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white/60">
                    Reset your Password
                  </h2>
                  <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                    Forgot Your Password? Enter your new Password{" "}
                  </p>
                </div>

                <div className="mt-4">
                  <div className="mt-6">
                    <Form
                      form={formMethods}
                      handleSubmit={submitResetPassword}
                      className="space-y-4"
                    >
                      <div>
                        <ShowPasswordField
                          label={"New Password"}
                          autoComplete="current-password"
                          AddOnIcon={RiLockPasswordLine}
                          required
                          {...formMethods.register("newPassword")}
                        />
                      </div>

                      <div className="space-y-1">
                        <ShowPasswordField
                          label={"Confirm Password"}
                          autoComplete="confirm-password"
                          AddOnIcon={RiLockPasswordLine}
                          required
                          {...formMethods.register("confirmPassword")}
                        />
                      </div>

                      <div>
                        <WbButton type="submit" className="w-full md:w-[400px]">
                          Reset Password
                        </WbButton>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    resetPasswordDiv = (
      <div className="h-screen">
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="flex flex-row items-center justify-center">
                  <img
                    className="h-12 w-auto"
                    src="/assets/img/logos/wyzebulb-1x.png"
                    alt="Wyzebulb"
                  />
                </div>
                <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white/60">
                  Reset your Password
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                  {verifyResetPasswordErrMessage
                    ? verifyResetPasswordErrMessage
                    : "Failed to change password! Try again"}
                </p>
              </div>
              <WbButton
                onClick={() => {
                  navigate("/login");
                }}
                className="mt-6 w-full md:w-[400px]"
              >
                Go to Login
              </WbButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <AuthLayout>{resetPasswordDiv}</AuthLayout>;
}
