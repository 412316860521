import { Menu } from "@headlessui/react";
import classNames from "classnames";

const theme = {
    base: "inline-flex w-full items-center justify-center rounded-md",
    colors: {
        primary: "bg-orange-600 text-white px-6 py-3 rounded-lg cursor-pointer disabled:opacity-75 hover:bg-orange-700",
        dark: "bg-gray-800 text-white px-3 py-3 border border-transparent hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 disabled:hover:bg-gray-800 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700 dark:disabled:hover:bg-gray-800",
        gray: "bg-white text-gray-900 px-4 py-2 border border-gray-200 hover:bg-gray-100 disabled:hover:bg-white focus:ring-orange-50 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-2 dark:disabled:hover:bg-gray-800",
    },
    outline: {
        color: {
            gray: "border border-gray-900 dark:border-white",
            default: "border-0",
            light: "",
        },
        off: "",
        on: "bg-white text-gray-900 transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit dark:bg-gray-900 dark:text-white",
        pill: {
            off: "rounded-md",
            on: "rounded-full",
        },
    },
};

export default function WbMenuButton(props) {
    const { color, className, outline = false, ...restProps } = props;

    return (
        <Menu.Button
            className={classNames(theme.base, theme.colors[color], outline && (theme.outline.color[color] ?? theme.outline.color.default), className)}
            {...restProps}
        >
            {props.children}
        </Menu.Button>
    );
}
