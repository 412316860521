import {
  FETCH_FLOWS,
  FETCH_FLOWS_SUCCESS,
  FETCH_FLOWS_FAIL,
  CHANGE_FLOW_NAME,
  CHANGE_FLOW_NAME_SUCCESS,
  CHANGE_FLOW_NAME_FAIL,
  SHOW_CHANGE_FLOW_NAME_MODAL,
  HIDE_CHANGE_FLOW_NAME_MODAL,
  CHANGE_FLOWS_STATE,
  FETCH_FLOWS_COUNT,
  FETCH_FLOWS_COUNT_SUCCESS,
} from "./FlowsActionTypes";

export const fetchFlows = ({
  sortQuery = { updatedAt: -1 },
  searchQuery = {},
  skip = 0,
  limit = 10,
  page = 1,
}) => {
  return {
    type: FETCH_FLOWS,
    payload: {
      sortQuery,
      searchQuery,
      skip,
      limit,
      page,
    },
  };
};

export const fetchFlowsSuccess = (flows: object) => {
  return {
    type: FETCH_FLOWS_SUCCESS,
    payload: {
      flows,
    },
  };
};

export const fetchFlowsFail = () => {
  return {
    type: FETCH_FLOWS_FAIL,
  };
};

export const changeFlowName = (
  flowId: string,
  flowName: string,
  searchQuery: object,
  sortQuery: object,
  skip: number,
  limit: number
) => {
  return {
    type: CHANGE_FLOW_NAME,
    payload: {
      flowId,
      flowName,
      searchQuery,
      sortQuery,
      skip,
      limit,
    },
  };
};

export const changeFlowNameSuccess = () => {
  return {
    type: CHANGE_FLOW_NAME_SUCCESS,
  };
};

export const changeFlowNameFail = () => {
  return {
    type: CHANGE_FLOW_NAME_FAIL,
  };
};

export const showChangeFlowNameModal = (flowId: string) => {
  return {
    type: SHOW_CHANGE_FLOW_NAME_MODAL,
    payload: {
      flowId,
    },
  };
};

export const hideChangeFlowNameModal = () => {
  return {
    type: HIDE_CHANGE_FLOW_NAME_MODAL,
  };
};

export const changeFlowsState = (
  selectedFlowIds: string[],
  newState: string,
  searchQuery: object,
  sortQuery: object,
  skip: number,
  limit: number
) => {
  return {
    type: CHANGE_FLOWS_STATE,
    payload: {
      selectedFlowIds,
      newState,
      searchQuery,
      sortQuery,
      skip,
      limit,
    },
  };
};

export const fetchFlowsCount = () => {
  return {
    type: FETCH_FLOWS_COUNT,
  };
};

export const fetchFlowsCountSuccess = (flowsCount: number) => {
  return {
    type: FETCH_FLOWS_COUNT_SUCCESS,
    payload: {
      flowsCount,
    },
  };
};
