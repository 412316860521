/**
 * Contains object related utils.
 */
import _ from "lodash";

const getClassName = (obj: any) => {
    const objString = Object.prototype.toString.call(obj);
    return objString.split("[object ").join("").split("]").join("");
};

export const isArray = (obj: any) => {
    return getClassName(obj) === "Array";
};

export const isString = (obj: any) => {
    return getClassName(obj) === "String";
};

export const isNullOrUndefined = (obj: any) => {
    return getClassName(obj) === "Null" || getClassName(obj) === "Undefined";
};

export const isJsonObject = (obj: any) => {
    return getClassName(obj) === "Object";
};

export const isEmpty = (obj: any) => {
    if (isArray(obj)) {
        return obj.length === 0;
    }

    if (isJsonObject(obj)) {
        return Object.keys(obj).length === 0;
    }

    if (isString(obj)) {
        return obj.length === 0;
    }

    return true;
};

export const isNaN = (obj: any) => {
    return Number.isNaN(obj);
};

export const isDataPresent = (obj: any) => {
    if (isNullOrUndefined(obj) || isEmpty(obj)) {
        return false;
    }

    return true;
};

export const convertToJson = (dataToConvert: any) => {
    if (isJsonObject(dataToConvert)) {
        return dataToConvert;
    }

    if (!isDataPresent(dataToConvert)) {
        return {};
    }

    if (isString(dataToConvert)) {
        if (dataToConvert === "undefined" || dataToConvert === "null") {
            return {};
        }

        return JSON.parse(dataToConvert);
    }

    return dataToConvert;
};

export const getHelperTextHtmlJsxFromParts = (helperTextHtmlParts: any, nospan: boolean = false) => {
    return _.map(helperTextHtmlParts, (eachPart: any) => {
        const { partValue } = eachPart;
        return nospan ? partValue : `<span>${partValue}</span>`;
    });
};
