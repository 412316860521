import {
    GET_CURRENT_SUBSCRIBED_PLAN_DETAILS,
    GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_ERROR,
    GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_SUCCESS,
    CANCEL_STRIPE_SUBSCRIPTION,
    CANCEL_STRIPE_SUBSCRIPTION_ERROR,
    CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
    UPDATE_PLAN,
    UPDATE_PLAN_SUCCESS,
    UPDATE_PLAN_ERROR,
    GET_DEFAULT_CARD_DETAILS,
    GET_DEFAULT_CARD_DETAILS_ERROR,
    GET_DEFAULT_CARD_DETAILS_SUCCESS,
    SEND_FEEDBACK,
} from "./ManageSubscriptionActionTypes";

export const getPlanDetails = (stripeSubscriptionId, subscriptionId) => {
    return {
        type: GET_CURRENT_SUBSCRIBED_PLAN_DETAILS,
        payload: {
            stripeSubscriptionId,
            subscriptionId,
        },
    };
};

export const getPlanDetailsSuccess = (planDetails) => {
    return {
        type: GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_SUCCESS,
        payload: {
            planDetails,
        },
    };
};

export const getPlanDetailsError = () => {
    return {
        type: GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_ERROR,
    };
};

export const cancelSubscription = (data) => {
    const {
        shouldCancelSubscriptionImmediately ,
        stripeSubscriptionId,
        subscriptionId ,
        sideEffectFunctions,
        reasonForCancel,
        description
  } = data;
    return {
        type: CANCEL_STRIPE_SUBSCRIPTION,
        payload: {
            shouldCancelSubscriptionImmediately,
            stripeSubscriptionId,
            subscriptionId,
            reasonForCancel,
            description,
            sideEffectFunctions,
        },
    };
};

export const cancelSubscriptionError = () => {
    return {
        type: CANCEL_STRIPE_SUBSCRIPTION_ERROR,
    };
};

export const cancelSubscriptionSuccess = (sideEffectFunctions) => {
    return {
        type: CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
        payload: {
            sideEffectFunctions,
        },
    };
};

export const updatePlan = (stripeSubscriptionId, planId, tasksCount, sideEffectFunctions) => {
    return {
        type: UPDATE_PLAN,
        payload: {
            stripeSubscriptionId,
            planId,
            tasksCount,
            sideEffectFunctions,
        },
    };
};

export const updatePlanSuccess = (sideEffectFunctions) => {
    return {
        type: UPDATE_PLAN_SUCCESS,
        payload: {
            sideEffectFunctions,
        },
    };
};

export const updatePlanError = () => {
    return {
        type: UPDATE_PLAN_ERROR,
    };
};

export const getDefaultCardDetails = (stripeCustomerId) => {
    return {
        type: GET_DEFAULT_CARD_DETAILS,
        payload: {
            stripeCustomerId,
        },
    };
};

export const getDefaultCardDetailsSuccess = (cardDetails) => {
    return {
        type: GET_DEFAULT_CARD_DETAILS_SUCCESS,
        payload: {
            cardDetails,
        },
    };
};

export const getDefaultCardDetailsError = () => {
    return {
        type: GET_DEFAULT_CARD_DETAILS_ERROR,
    };
};

export const sendFeedBack = (reason, description) => {
    return {
        type: SEND_FEEDBACK,
        payload: {
            reason,
            description,
        },
    };
};
