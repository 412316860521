import WbNotFound from "components/WbNotFound";

function NoTaskFound() {
	return (
		<WbNotFound
			title={`No Tasks found in the selected duration!!`}
			message="Create a new Flow and automate the task execution!"
			path="/flow-builder"
			linkLabel="Create Flow"
		></WbNotFound>
	);
}

export default NoTaskFound;
