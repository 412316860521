import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";

type Field = {
  label: string;
  name: string;
  type: string;
  options?: { value: string; label: string }[];
  onChange?: any;
};

type Props = {
  fields: Field[];
  onSubmit: SubmitHandler<any>;
  className?: string;
  button?: string;
  onChange?: any;
};

function Form(props: Props) {
  const { register, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <div className={`m-4 flex ${props.className} flex-col`}>
        {props.fields.map((field) => (
          <React.Fragment key={field.name}>
            <label className="mb-2" htmlFor={field.name}>
              {field.label}:
            </label>
            {field.type === "textarea" ? (
              <>
                <p className="mb-2 text-sm text-slate-500">
                  You can add any JSON object as custom data to your leads.
                </p>
                <textarea
                  {...register(field.name)}
                  id={field.name}
                  name={field.name}
                  className="mb-4  rounded-md border-slate-300"
                />
              </>
            ) : field.type === "select" ? (
              <select
                {...register(field.name)}
                id={field.name}
                name={field.name}
                className="mb-4 rounded-md border-slate-300"
                onChange={field.onChange}
              >
                {field.options?.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : (
              <input
                {...register(field.name)}
                type={field.type}
                id={field.name}
                name={field.name}
                className="mb-4 rounded-md border-slate-300"
              />
            )}
          </React.Fragment>
        ))}
        <button
          className="rounded-md bg-orange-600 p-2 text-white focus:outline-none focus:ring focus:ring-orange-300"
          type="submit"
        >
          {props.button || `Submit`}
        </button>
      </div>
    </form>
  );
}

export default Form;
