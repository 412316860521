import { useEffect, useState } from "react";
import {
	BoltIcon,
	CheckCircleIcon,
	ArrowRightIcon,
} from "@heroicons/react/24/solid";
import { get, isEmpty } from "lodash";

import callApi from "utils/callApi";
import WbIntegrationImage from "components/WbIntegrationImage";

interface flowTemplateType {
	slug: string;
	name: string;
	triggerEvents: string[];
	triggerSlug: string;
	actionSlug: string;
	actionEvents: string[];
}

function FlowTemplateDetails(props: any) {
	const [flowTemplateDetails, setFlowTemplateDetails] =
		useState<flowTemplateType>({
			slug: "",
			name: "",
			triggerEvents: [],
			triggerSlug: "",
			actionSlug: "",
			actionEvents: [],
		});

	useEffect(() => {
		getFlowTemplateDetails();
	}, []);

	const getFlowTemplateDetails = async () => {
		const initialPathName = decodeURIComponent(
			localStorage.getItem("initialPathname") || ""
		);
		try {
			const splittedPath = initialPathName.split("?");
			const stages = splittedPath[1] ? splittedPath[1]?.split("=") : [];
			const queryData = stages[1] ? JSON.parse(stages[1]) : null;

			if (queryData) {
				const triggerIntegrationId = get(
					queryData,
					"0.triggerStageItems.integrationId"
				);
				const actionIntegrationId = get(
					queryData,
					"1.actionStageItems.integrationId"
				);

				if (
					!isEmpty(triggerIntegrationId) &&
					!isEmpty(actionIntegrationId)
				) {
					const details = await callApi({
						url: "/flowTemplate/getFlowTemplate",
						method: "POST",
						body: {
							findQuery: {
								"triggerStageItems.integrationId":
									triggerIntegrationId,
								"actionStageItems.0.integrationId":
									actionIntegrationId,
							},
						},
					});
					const { success, data } = details;

					if (success && !isEmpty(data)) {
						setFlowTemplateDetails(data);
					}
				}
			}
		} catch (error) {
		}
	};

	const { name, triggerEvents, triggerSlug, actionSlug, actionEvents } =
		flowTemplateDetails || {};

	return !isEmpty(flowTemplateDetails) &&
		!isEmpty(triggerEvents) &&
		!isEmpty(actionEvents) ? (
		<>
			<div className="flex items-center justify-center  p-4">
				<WbIntegrationImage slug={triggerSlug} />
				<ArrowRightIcon className="ml-4 mr-4 h-5 w-5" />
				<WbIntegrationImage slug={actionSlug} />
			</div>
			<h2 className="mb-2 text-center text-2xl  font-semibold text-orange-500">
				{name}
			</h2>
			<div className="flex justify-center gap-8 pb-4 text-black">
				<div>
					<p className="font-bold text-gray-600 dark:text-gray-200">
						When any of this happen :{" "}
					</p>
					<ul>
						{triggerEvents.map((evt: any) => {
							return (
								<li className="">
									<BoltIcon className="mr-1 inline h-5 w-5 text-orange-500"></BoltIcon>
									{evt?.displayText}
								</li>
							);
						})}
					</ul>
				</div>

				<div>
					<p className="font-bold text-gray-600 dark:text-gray-200">
						Do any of this :
					</p>
					<ul>
						{actionEvents.map((evt: any) => {
							return (
								<li>
									<CheckCircleIcon className="mr-1 inline h-5 w-5 text-orange-500"></CheckCircleIcon>
									{evt?.displayText}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<hr />
		</>
	) : (
		<></>
	);
}

export default FlowTemplateDetails;
