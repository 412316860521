import { useEffect, useState } from "react";

export default function Credits() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <small className="mx-3 mt-1 mb-1 hidden text-center text-[0.5rem] opacity-50 lg:block">
      &copy; {new Date().getFullYear()}{" "}
      <a
        href="https://wyzebulb.com"
        target="_blank"
        className="hover:underline"
        rel="noreferrer"
      >
        Wyzebulb
      </a>{" "}
      {hasMounted && (
        <a
          href="https://wyzebulb.com/flows"
          target="_blank"
          className="hover:underline"
          rel="noreferrer"
        >
          1.0.0
        </a>
      )}
    </small>
  );
}
