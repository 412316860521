"use client";
import { useState } from "react";
import { chunk, startCase, snakeCase } from "lodash";
import { HiCheck, HiChevronDown, HiMagnifyingGlass } from "react-icons/hi2";
import { Combobox } from "@headlessui/react";

import integrationData from "../../utils/integrationData";

const getInitialData = (showTrigger: boolean, showAction: boolean) => {
    const filteredData = integrationData.filter((d) => {
        if (showTrigger) {
            return d.isActive && d.isTrigger;
        } else if (showAction) {
            return d.isActive && d.isAction;
        } else {
            return d.isActive;
        }
    });
    return chunk(filteredData, 50)[0];
};

const IntegrationDropdown = (props: any) => {
    const { shouldRedirect = true, showTrigger, showAction } = props;
    const [query, setQuery] = useState("");
    const [selectedData, setSelectedData] = useState("");
    let data = getInitialData(showTrigger, showAction);

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(" ");
    }

    const filteredData =
        query === ""
            ? data
            : chunk(
                  integrationData.filter((d: any) => {
                      if (showTrigger) {
                          return d.isTrigger && d.slug.toLowerCase().includes(query.toLowerCase());
                      } else if (showAction) {
                          return d.isAction && d.slug.toLowerCase().includes(query.toLowerCase());
                      } else {
                          return d.slug.toLowerCase().includes(query.toLowerCase());
                      }
                  }),
                  50,
              )[0];

    const handleChange = (event: any) => {
        setQuery(event.target.value);
    };

    const handleSelect = (value = "") => {
        //event.preventDefault();
        const formattedValue = snakeCase(value?.split("-integration")[0]);

        setSelectedData(value);
        if (value) {
            props.handleSelect && props.handleSelect(formattedValue);
        }
    };

    const getItem = (d: any, slugName: any, selected: boolean) => {
        return (
            <>
                {d?.isActive ? (
                    <img src={`/assets/img/integrations/hex_${slugName}.png`} alt={slugName} className="h-6 w-6 flex-shrink-0 rounded-full" />
                ) : (
                    <div className="h-6 w-6  rounded-full bg-gray-400 text-center text-white">{d?.displayText[0]}</div>
                )}
                <span className={classNames("ml-3 truncate", selected && "font-semibold")}>{d?.displayText}</span>
            </>
        );
    };

    return (
        <Combobox as="div" value={selectedData} onChange={handleSelect} className="">
            <div className="relative mt-1">
                <Combobox.Input
                    className="sm:text-md w-full rounded-md border-gray-300 py-2 pl-8 pr-10
         shadow-sm outline-none focus:border-orange-500 focus:bg-white text-gray-900 dark:bg-gray-200
               focus:ring-orange-500"
                    placeholder="Search for Apps"
                    onChange={handleChange}
                    //onSelectCapture={handleSelect}
                    displayValue={(d: string) => {
                        const slugName = d?.replace("-integration", "");
                        return startCase(slugName);
                    }}
                />
                <Combobox.Button className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 text-gray-600 focus:text-gray-300 focus:outline-none">
                    {selectedData ? (
                        <img
                            src={`/assets/img/integrations/hex_${selectedData?.replaceAll("-integration", "")?.replaceAll("-", "_")}.png`}
                            alt={selectedData}
                            className="mr-6 h-6 w-6 flex-shrink-0 rounded-full"
                        />
                    ) : (
                        <HiMagnifyingGlass className="mr-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                    )}
                </Combobox.Button>

                <Combobox.Button
                    id="search-combo-box-btn"
                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                    <HiChevronDown className="h-5 w-5 text-gray-600" aria-hidden="true" />
                </Combobox.Button>

                {filteredData?.length > 0 && (
                    <Combobox.Options
                        className="absolute z-10 mt-1 max-h-56 w-full overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black
 ring-opacity-5 scrollbar-thin scrollbar-track-orange-50 scrollbar-thumb-orange-400 scrollbar-track-rounded-full scrollbar-thumb-rounded-md scrollbar-w-1
 focus:outline-none sm:text-sm"
                    >
                        {filteredData.map((d: any) => {
                            let slugName = d?.slug?.replace("-integration", "");
                            slugName = slugName?.replaceAll("-", "_");

                            return (
                                <Combobox.Option
                                    key={d._id}
                                    value={d.slug}
                                    className={({ active }) =>
                                        classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            active ? "bg-gray-200 text-gray-900" : "text-gray-900",
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <div className="flex items-center">{getItem(d, slugName, selected)}</div>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                                        active ? "text-gray-900" : "text-orange-600",
                                                    )}
                                                >
                                                    <HiCheck className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            );
                        })}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

export default IntegrationDropdown;
