import { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { SetupIntent, loadStripe } from "@stripe/stripe-js";

import StripeCard from "./index";

const StripeElement = (props:any) => {
  const { vendorProductId, vendorPlanId, tasksCount, shouldUpdateCard } = props;

  return (
      <StripeCard
        shouldUpdateCard={shouldUpdateCard}
        closeModal={props.closeModal}
        vendorPlanId={vendorPlanId}
        tasksCount={tasksCount}
        vendorProductId={vendorProductId}
      />
  );
};

StripeElement.propTypes = {
  vendorProductId: PropTypes.string,
  vendorPlanId: PropTypes.string,
};

export default StripeElement;
