import { useState } from "react";
import { connect } from "react-redux";
import { FaCheckCircle, FaTimesCircle, FaUsers } from "react-icons/fa";

import { isDataPresent } from "utils/object_util";
import SelectPlanOrUpdatePlan from "modules/Account/LegacyBilling/SelectPlanOrUpdatePlan";
import UpdateCardModal from "modules/Account/LegacyBilling/CurrentSubscriptionDetails/UpdateCardModal";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import { withRouter } from "withRouter";

function PaymentFailureModal({
  closeModal,
  subscription,
  isPaymentPending,
}: any) {
  const [shouldLoadSelectOrUpdatePlan, setShouldLoadSelectOrUpdatePlan] =
    useState(false);
  const [
    shouldShowUpdatePaymentInfoModal,
    setShouldShowUpdatePaymentInfoModal,
  ] = useState(false);

  const initSelectOrUpdatePlan = () => {
    setShouldLoadSelectOrUpdatePlan(true);
  };

  const stopSelectOrUpdatePlan = () => {
    setShouldLoadSelectOrUpdatePlan(false);
    closeModal();
  };

  const stopUpdatePaymentInfoModal = () => {
    setShouldShowUpdatePaymentInfoModal(false);
    closeModal();
  };

  const ctaClickHandler = (isPaymentFailed: any) => {
    if (isPaymentFailed) {
      setShouldShowUpdatePaymentInfoModal(true);
      return;
    }
    initSelectOrUpdatePlan();
  };

  let paymentWarningTextHeader = "";
  let paymentWarningTextBody = "";
  let buttonText = "";
  let isPaymentFailed = false;

  if (isDataPresent(subscription)) {
    const hasUserSelectedAPlan = isDataPresent(
      subscription.billingInfo.stripeSubscriptionIds
    );
    if (hasUserSelectedAPlan && isPaymentPending) {
      paymentWarningTextHeader = `Please update payment info to continue with your workflow.`;
      buttonText = `Update Payment Info`;
      paymentWarningTextBody = `Update payment info`;
      isPaymentFailed = true;
    } else {
      paymentWarningTextHeader = `Select any plan now to continue using our platform.`;
      buttonText = `Select Plan`;
      paymentWarningTextBody = `Selected plan`;
    }
  }

  return (
    <>
      <WbModal isOpen={true} size="sm" toggle={closeModal}>
        <WbModalTitle toggle={closeModal} title="Payment Pending" />
        <div className="space-y-4 p-4 text-center">
          <h3 className="text-red-700">{paymentWarningTextHeader}</h3>
          <div className="space-x-2">
            <FaUsers className="inline" />
            <span>You're only one step away from reaching new customers</span>
          </div>
          <div className="space-x-4">
            <span className="space-x-2">
              <FaCheckCircle className="inline text-green-500" />
              <span>Registered</span>
            </span>
            <span className="space-x-2">
              <FaTimesCircle className="inline text-red-500" />
              <span>{paymentWarningTextBody}</span>
            </span>
          </div>
          <div className="float-right mt-4 p-4">
            <WbButton onClick={() => ctaClickHandler(isPaymentFailed)}>
              {buttonText}
            </WbButton>
          </div>
        </div>
      </WbModal>
      {shouldLoadSelectOrUpdatePlan && (
        <SelectPlanOrUpdatePlan closeModal={stopSelectOrUpdatePlan} />
      )}
      {shouldShowUpdatePaymentInfoModal && (
        <UpdateCardModal closeModal={stopUpdatePaymentInfoModal} />
      )}
    </>
  );
}

const mapStateToProps = ({ LoggedInLayout }: any) => {
  const { subscription, isPaymentPending } = LoggedInLayout;

  return {
    subscription,
    isPaymentPending,
  };
};

export default withRouter(connect(mapStateToProps, null)(PaymentFailureModal));
