import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckDouble } from "react-icons/fa";

import SetupFilterModal from "./SetupFilterModal";
import { isDataPresent } from "utils/object_util";
import { FILTER } from "enums/StageTypesEnum";
import { SETUP_FILTER } from "enums/StageItemSlugsEnum";
import { setupDataFromPreviousStages } from "../../FlowBuilderActions";

export default function SetupFilter(props) {
    const dispatch = useDispatch();
    const { flowObj } = useSelector((state) => state.FlowBuilder);
    const { stageIndex, finishStageItem } = props;
    const selectedFilter = flowObj.stages[stageIndex].stageItems.setupFilter.selectedFilter;
    const [showModal, setShowModal] = useState(false);
    const [isStageSetupComplete, setIsStageSetupComplete] = useState(isDataPresent(selectedFilter));

    useEffect(() => {
        if (isStageSetupComplete) finishStageItem(SETUP_FILTER);
    }, [isStageSetupComplete, finishStageItem]);

    const initAndShowModal = () => {
        dispatch(setupDataFromPreviousStages(stageIndex, FILTER));
        setShowModal(true);
    };

    const finishSetup = () => {
        if (isStageSetupComplete) {
            setIsStageSetupComplete(true);
        }
        setShowModal(false);
    };

    return (
        <>
            <div className="mb-0 cursor-pointer" onClick={initAndShowModal}>
                {!isStageSetupComplete ? (
                    <>
                        <span>1. Setup filter options - Click here add filters</span>
                        <span className="text-red-600 mx-1">(required)</span>
                    </>
                ) : (
                    <>
                        <span>1. Click here add filters</span>
                        <FaCheckDouble size={12} className="inline-block" />
                    </>
                )}
            </div>
            {showModal && (
                <SetupFilterModal stageIndex={stageIndex} finishSetup={finishSetup} closeModal={() => setShowModal(false)} flowObj={flowObj} />
            )}
        </>
    );
}
