import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { deleteMe } from "./DeleteSubscriptionActions";
import { useLocation } from "react-router-dom";

export default function DeleteSubscription() {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { token } = queryString.parse(search);

    useEffect(() => {
        dispatch(deleteMe(token));
    }, []);

    return <div></div>;
}
