import { SubmitHandler } from "react-hook-form";
import Form from "../components/Form";
import { showErrorToast, showSuccessToast } from "components/WbToast";
import callApi from "utils/callApi";
import { get } from "lodash";
import { useEffect, useState } from "react";
import WbLoader from "components/Loader";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  rawData: string;
};

const CreateCampaign = () => {
  const [fields, setFields] = useState<any[]>([]);
  const [templates, setTemplates] = useState<any[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);

  const handleSenderChange = (selectedOption: any) => {
    // Get the selected sender ID
    const senderId = selectedOption.target.value;

    // Fetch the templates for the selected sender
    getTemplates(senderId);
  };

  const getTemplates = async (senderId: any) => {
    const res = await callApi({
      url: `/builtInTools/dataStudio/getTemplates`,
      body: {
        senderId,
      },
    });
    const docs = get(res, "templates", "");
    setTemplates(
      docs?.map((doc: any) => ({
        value: doc.id,
        label: doc.name,
      }))
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const segments = await getSegments();
      const senders = await getSenders();
      setFields([
        {
          label: "Senders",
          name: "senderId",
          type: "select",
          options: senders,
          onChange: handleSenderChange,
        },
        { label: "Subject", name: "subject", type: "text" },
        {
          label: "Segment",
          name: "segment",
          type: "select",
          options: segments,
        },
        {
          label: "Template",
          name: "template",
          type: "select",
          options: templates,
        },
      ]);
    };

    fetchData();
  }, [templates]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formData = {
      ...data,
    };
    const res = await callApi({
      url: `/builtInTools/dataStudio/sendBulkEmails`,
      body: formData,
    });
    if (res.success === true) {
      showSuccessToast(`${res.message}`);
    } else {
      showErrorToast(`${res.message}`);
    }
  };

  const getSegments = async () => {
    setIsloading(true);
    const response: any = await callApi({
      url: `/builtInTools/dataStudio/listSegments`,
      method: "POST",
      body: {
        findQuery: {},
        sort: { updatedAt: -1 },
      },
    });
    const docs = get(response, "data.docs", "");
    setIsloading(false);
    return docs.map((doc: any) => ({
      value: doc.query,
      label: doc.segmentName,
    }));
  };

  const getSenders = async () => {
    const response: any = await callApi({
      url: `/builtInTools/dataStudio/listSenders`,
      method: "POST",
      body: {
        findQuery: {},
        sort: { updatedAt: -1 },
      },
    });
    const docs = get(response, "data.docs", "");
    return docs.map((doc: any) => ({
      value: doc._id,
      label: doc.senderName,
    }));
  };

  return (
    <>
      {isloading ? (
        <div className="w-[30%]">
          <WbLoader />
        </div>
      ) : (
        <>
          <div className="mx-8 w-[30%]"></div>
          <div>
            <Form
              fields={fields}
              className="mx-8 w-[90%] md:w-[50%] xl:w-[30%]"
              onSubmit={onSubmit}
              button="Send Emails"
            />
          </div>
        </>
      )}
    </>
  );
};

export default CreateCampaign;
