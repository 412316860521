import { Outlet } from "react-router-dom";

const NonLoggedInLayout = () => {
	return (
		<main className="font-sans dark:bg-gray-800">
			<Outlet />
		</main>
	);
};

export default NonLoggedInLayout;
