const ElementTypesEnum = {
  INPUT: "input",
  RICH_INPUT: "rich-input",
  TEXTAREA: "textarea",
  RICH_TEXTAREA: "rich-textarea",
  SELECT: "select",
  RICH_SELECT: "rich-select",
  JSON_INPUT: "json-input",
};

export default ElementTypesEnum;
