import React, { useState } from "react";
import {
  CardElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
// import { injectStripe } from "react-stripe-elements";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { createSubscriptionInStripe, updateCard } from "../SelectPlanActions";
import { getDefaultCardDetails } from "modules/Account/LegacyBilling/ManageSubscriptionActions";
import { refreshSubscription } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import WbButton from "components/WbButton";
import { showErrorToast } from "components/WbToast";

const StripeCard = (props: any) => {
  const {
    vendorPlanId,
    shouldUpdateCard,
    tasksCount,
    addNewCard,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { stripe, elements } = props;
  const { subscription } = useSelector((state: any) => state.LoggedInLayout);
  const { billingInfo } = subscription;
  const stripeCustomerId = get(billingInfo, "stripeCustomerId");
  const subscriptionId = get(subscription, "_id");
  const dispatch = useDispatch();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const element = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(element);

      debugger
      /**Stripe token creation error */
      if (error) {
        showErrorToast(error?.message || error);
        setIsLoading(false);
      } else if (shouldUpdateCard) {
      /**Stripe Add a new card */
        const sideEffectFunctions = () => {
          props.closeModal();
          dispatch(getDefaultCardDetails(stripeCustomerId));
        };
        dispatch(updateCard(token.id, stripeCustomerId, null, [sideEffectFunctions]));
      } else if (addNewCard) {
        /**Stripe Update a new card */
        const sideEffectFunctions = () => {
          props.closeModal();
          dispatch(getDefaultCardDetails(stripeCustomerId));
        };
        dispatch(
          updateCard(
            token.id,
            stripeCustomerId,
            addNewCard,[sideEffectFunctions]
          )
        );
      } else {
        /**Create a new subscription */
        const sideEffectFunctions = () => {
          setIsLoading(false);
          props.closeModal();
        };

        const isDefaultCardExist = false;

        dispatch(
          createSubscriptionInStripe(
            stripeCustomerId,
            token.id,
            vendorPlanId,
            subscriptionId,
            isDefaultCardExist,
            tasksCount,
            [sideEffectFunctions]
          )
        );
      }
      dispatch(refreshSubscription(subscriptionId));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showErrorToast(`Error in payment: ${error?.message}`);
    }
  };

  return (
    <div className="space-y-2 bg-white p-4">
      <p className=" text-base font-medium">Card Details</p>
      <form onSubmit={handleSubmit} className="space-y-10">
        <CardElement
          //hidePostalCode={true}
          className="border-2 p-2"
        />
        <WbButton onClick={handleSubmit} fullSized={true} disabled={isLoading}>
          {!isLoading ? "Continue" : "Loading"}
        </WbButton>
      </form>
    </div>
  );
};

const InjectedStripeCard = (props: any) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <StripeCard stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedStripeCard;
