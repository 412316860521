import { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FaTrash } from "react-icons/fa";

import CustomInput from "components/CustomInput";
import { setupFilter } from "../../FlowBuilderActions";
import { FILTER } from "enums/StageTypesEnum";
import { isDataPresent } from "utils/object_util";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";

class SetupFilterModal extends Component {
  newFilterObj = {
    ifValue: {},
    operatorValue: {},
    secondOperandValue: {
      jsonDetails: [
        {
          valueDetails: [
            {
              value: "",
              valueFrom: "userCustomValue",
            },
          ],
        },
      ],
    },
  };

  operators = [
    {
      value: "equals",
      valueFrom: "predefinedValues",
      displayText: "equals",
    },
    {
      value: "notEquals",
      valueFrom: "predefinedValues",
      displayText: "is not",
    },
    {
      value: "contains",
      valueFrom: "predefinedValues",
      displayText: "contains",
    },
    {
      value: "notContains",
      valueFrom: "predefinedValues",
      displayText: "does not contain",
    },
    {
      value: "startsWith",
      valueFrom: "predefinedValues",
      displayText: "starts with",
    },
    {
      value: "endsWith",
      valueFrom: "predefinedValues",
      displayText: "ends with",
    },
    {
      value: "greaterThan",
      valueFrom: "predefinedValues",
      displayText: "greater than",
    },
    {
      value: "greaterThanOrEqualTo",
      valueFrom: "predefinedValues",
      displayText: "greater than or equal to",
    },
    {
      value: "lesserThan",
      valueFrom: "predefinedValues",
      displayText: "lesser than",
    },
    {
      value: "lesserThanOrEqualTo",
      valueFrom: "predefinedValues",
      displayText: "lesser than or equal to",
    },
    {
      value: "matches",
      valueFrom: "predefinedValues",
      displayText: "matches regex",
    },
  ];

  state = {
    filters: [{ ...this.newFilterObj }],
  };

  componentDidMount() {
    const { flowObj, stageIndex } = this.props;
    const setupFilterStageItem =
      flowObj.stages[stageIndex].stageItems.setupFilter;

    if (
      isDataPresent(setupFilterStageItem) &&
      isDataPresent(setupFilterStageItem.selectedFilter)
    ) {
      this.setState({
        filters: setupFilterStageItem.selectedFilterForUi,
      });
    }
  }

  handleIfSelect = (jsonDetails, filterIndex) => {
    this.setState((prevState) => {
      const { filters } = prevState;
      filters[filterIndex].ifValue = {
        jsonDetails: [
          {
            valueDetails: [
              {
                valueFrom: jsonDetails.userInput.valueFrom,
                value: jsonDetails.userInput.value,
                displayText: jsonDetails.userInput.displayText,
              },
            ],
          },
        ],
      };

      return {
        filters,
      };
    });
  };

  handleOperatorSelect = (jsonDetails, filterIndex) => {
    this.setState((prevState) => {
      const { filters } = prevState;
      filters[filterIndex].operatorValue = {
        jsonDetails: [
          {
            valueDetails: [
              {
                valueFrom: jsonDetails.userInput.valueFrom,
                value: jsonDetails.userInput.value,
                displayText: jsonDetails.userInput.displayText,
              },
            ],
          },
        ],
      };

      return {
        filters,
      };
    });
  };

  handleValueChange = (jsonDetails, filterIndex) => {
    this.setState((prevState) => {
      const { filters } = prevState;
      filters[filterIndex].secondOperandValue = {
        jsonDetails: [
          {
            valueDetails: [
              {
                valueFrom: jsonDetails.userInput.valueFrom,
                value: jsonDetails.userInput.value,
                displayText: jsonDetails.userInput.displayText,
              },
            ],
          },
        ],
      };

      return {
        filters,
      };
    });
  };

  addFilter = () => {
    this.setState((prevState) => ({
      filters: [...prevState.filters, { ...this.newFilterObj }],
    }));
  };

  removeFilter = (index) => {
    this.setState((prevState) => {
      const { filters } = prevState;
      filters.splice(index, 1);

      return {
        filters,
      };
    });
  };

  handleSubmit = () => {
    const { stageIndex } = this.props;
    const { filters } = this.state;

    const filterQuery = {
      $or: [],
    };

    _.each(filters, (eachFilter) => {
      const { ifValue, operatorValue, secondOperandValue } = eachFilter;

      if (!isDataPresent(ifValue) || !isDataPresent(operatorValue)) {
        return;
      }

      let siftQuery = {};

      const selectedIfValue = ifValue.jsonDetails[0].valueDetails[0].value;
      const selectedOperatorValue =
        operatorValue.jsonDetails[0].valueDetails[0].value;
      let selectedSecondOperandValue =
        secondOperandValue.jsonDetails[0].valueDetails[0].value;

      switch (selectedOperatorValue) {
        case "equals":
          siftQuery = {
            $eq: selectedSecondOperandValue,
          };
          break;
        case "notEquals":
          siftQuery = {
            $ne: selectedSecondOperandValue,
          };
          break;
        case "contains":
          siftQuery = {
            $regex: selectedSecondOperandValue,
            $options: "i",
          };
          break;
        case "notContains":
          siftQuery = {
            $not: {
              $regex: selectedSecondOperandValue,
              $options: "i",
            },
          };
          break;
        case "startsWith":
          siftQuery = {
            $regex: `^${selectedSecondOperandValue}`,
            $options: "i",
          };
          break;
        case "endsWith":
          siftQuery = {
            $regex: `${selectedSecondOperandValue}$`,
            $options: "i",
          };
          break;
        case "greaterThan":
          siftQuery = {
            $gt: selectedSecondOperandValue,
          };
          break;
        case "greaterThanOrEqualTo":
          siftQuery = {
            $gte: selectedSecondOperandValue,
          };
          break;
        case "lesserThan":
          siftQuery = {
            $lt: selectedSecondOperandValue,
          };
          break;
        case "lesserThanOrEqualTo":
          siftQuery = {
            $lte: selectedSecondOperandValue,
          };
          break;
        case "matches":
          siftQuery = {
            $regex: selectedSecondOperandValue,
            $options: "i",
          };
          break;
        default:
          siftQuery = {};
      }

      filterQuery.$or.push({
        [selectedIfValue]: siftQuery,
      });
    });

    this.props.setupFilter(FILTER, stageIndex, filterQuery, filters);
    setTimeout(this.props.finishSetup, 100);
  };

  render() {
    const { flowObj, stageIndex } = this.props;

    let allFilters = null;

    const { dataFromPreviousStages } =
      flowObj.stages[stageIndex].stageItems.setupFilter;
    const formattedDataFromPreviousStages = _.reduce(
      dataFromPreviousStages,
      (accumulator, eachDataFromPreviousStage) => {
        const { stageIndex: previousStageIndex, stageData: previousStageData } =
          eachDataFromPreviousStage;

        const formattedPreviousStageData = _.map(
          previousStageData,
          (eachData) => ({
            ...eachData,
            displayText: `From Step ${previousStageIndex + 1} - ${
              eachData.displayText
            }`,
            value: eachData.slug,
            valueFrom: "previousStageDataSlug",
          })
        );

        return [...accumulator, ...formattedPreviousStageData];
      },
      []
    );

    allFilters = _.map(this.state.filters, (eachFilter, index) => {
      return (
        <Fragment key={index}>
          <div className="mt-3 grid grid-cols-4 gap-4">
            <div>
              <CustomInput
                elementType="rich-select"
                labelText="If"
                selectedValueData={eachFilter.ifValue}
                staticData={formattedDataFromPreviousStages}
                userInputHandler={(jsonDetails) =>
                  this.handleIfSelect(jsonDetails, index)
                }
              />
            </div>
            <div>
              <CustomInput
                elementType="rich-select"
                labelText="Condition"
                selectedValueData={eachFilter.operatorValue}
                staticData={this.operators}
                userInputHandler={(jsonDetails) =>
                  this.handleOperatorSelect(jsonDetails, index)
                }
              />
            </div>
            <div>
              <CustomInput
                elementType="input"
                labelText="Value"
                inputHeight="initial"
                name={`second-operand-value-${index}`}
                id={`second-operand-value-${index}`}
                isRequired={false}
                inputType="text"
                selectedValueData={eachFilter.secondOperandValue}
                shouldHandleChange={true}
                userInputHandler={(jsonDetails) =>
                  this.handleValueChange(jsonDetails, index)
                }
              />
            </div>
            {this.state.filters.length > 1 ? (
              <div>
                <FaTrash className="text-red-500" onClick={this.removeFilter} />
              </div>
            ) : null}
          </div>
          {index < this.state.filters.length - 1 ? (
            <div className="my-2 text-gray-600">(OR)</div>
          ) : null}
        </Fragment>
      );
    });
    return (
      <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
        <WbModalTitle title="Setup Filter" toggle={this.props.closeModal} />
        <div className="p-4">
          {allFilters}
          <div className="mt-5">
            <WbButton onClick={this.addFilter} size="sm" outline={true}>
              Add Filter
            </WbButton>
          </div>
          <div className="float-right p-4">
            <WbButton
              onClick={() => this.handleSubmit()}
              className="mt-4 uppercase"
            >
              Save + Continue
            </WbButton>
          </div>
        </div>
      </WbModal>
    );
  }
}

const mapStateToProps = ({ FlowBuilder }) => {
  const { flowObj } = FlowBuilder;
  return {
    flowObj,
  };
};

export default connect(mapStateToProps, {
  setupFilter,
})(SetupFilterModal);
