import {
	FETCH_INTEGRATION_CATEGORIES,
	INTEGRATION_CATEGORIES_FETCH_SUCCESS,
	INTEGRATION_CATEGORIES_FETCH_FAIL,
} from "./IntegrationCategoriesActionTypes";

export const fetchIntegrationCategories = () => {
	return {
		type: FETCH_INTEGRATION_CATEGORIES,
	};
};

export const integrationCategoriesFetchSuccess = (integrationCategories) => {
	return {
		type: INTEGRATION_CATEGORIES_FETCH_SUCCESS,
		payload: {
			integrationCategories,
		},
	};
};

export const integrationCategoriesFetchFail = () => {
	return {
		type: INTEGRATION_CATEGORIES_FETCH_FAIL,
	};
};
