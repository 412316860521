import { HeaderSection } from "components/WbLayout/Header";
import ImportLeads from "./ImportLeads";
import { useState } from "react";
import ActionsHelper from "./ActionsHelper";
import { useLocation } from "react-router-dom";

export default function StudioHeader() {
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  const toggleModal = () => {
    setShowSendEmailModal(!showSendEmailModal);
  };
  const location = useLocation();
  const pathName: any = location.pathname.split("/").at(-1);

  const componentMap: any = {
    leads: <ImportLeads toggleModal={toggleModal} />,
    segments: <ImportLeads toggleModal={toggleModal} />,
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between px-2 md:flex-row">
        <HeaderSection
          title="Data Studio"
          description="Data Studio helps to manage all the sources of Data in one place."
        />
        <ActionsHelper toggleModal={toggleModal} />
        {showSendEmailModal && componentMap[pathName]}
      </div>
    </>
  );
}
