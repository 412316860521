import React, { Fragment } from "react";

import { integrationsToIgnore } from "constants/integrations";
import SingleIntegration from "./SingleIntegration";

const Integrations = (props) => {
    const { integrations = [], initComingSoonNotification, shouldLoadOAuthWindow, onSelectIntegration } = props;

    return (
        <Fragment>
            {integrations.map((eachIntegration) => {
                // Excluding the Integrations base on "integrationsToIgnore" constents from Integration Select Modal.
                if (!integrationsToIgnore.includes(eachIntegration?.slug)) {
                    return (
                        <SingleIntegration
                            initComingSoonNotification={initComingSoonNotification}
                            integrationClicked={eachIntegration}
                            shouldLoadOAuthWindow={shouldLoadOAuthWindow}
                            integration={eachIntegration}
                            onSelectIntegration={onSelectIntegration}
                            key={eachIntegration._id}
                        />
                    );
                } else return null;
            })}
        </Fragment>
    );
};

export default Integrations;
