import { useState } from "react";

import WbButton from "components/WbButton";
import WbLabel from "components/WbLabel";
import WbModal, { WbModalTitle } from "components/WbModal";
import { showErrorToast, showSuccessToast } from "components/WbToast";
import callApi from "utils/callApi";

const emailProviders = ["Gmail", "Sendgrid", "Mailerlite"];

interface sendEmail {
  email: string;
  toggleSendEmailModal: Function;
}

export default function SendEmail({ email, toggleSendEmailModal }: sendEmail) {
  const [mail, setmail] = useState("");
  const [template, setTemplate] = useState("");

  const handleContinue = async () => {
    toggleSendEmailModal();
    const response: any = await callApi({
      url: "/builtInTools/dataStudio/sendEmail",
      method: "POST",
      body: {
        email: email,
        template: mail,
      },
    });
    if (response.status === true) {
      showSuccessToast(`${mail} sent to ${email}`);
    } else showErrorToast("Something Went wrong");
  };

  const handleEmail = (event: any) => {
    setmail(event.target.value);
  };

  const handleTemplate = (event: any) => {
    setTemplate(event.target.value);
  };
  return (
    <WbModal toggle={toggleSendEmailModal} isOpen={true} size="sm">
      <WbModalTitle title="Send Email" toggle={toggleSendEmailModal} />
      <div className="w-[70%] p-4">
        <WbLabel>Select Email Provder</WbLabel>
      </div>
      <div>
        <div className="mx-6">
          <select
            className="w-full rounded border-gray-200"
            id="select"
            value={mail}
            onChange={handleEmail}
          >
            {emailProviders.map((option) => (
              <option
                className="m-4 rounded-2xl p-4"
                key={option}
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="mx-4 mt-4">
          <WbLabel>Select Email Template</WbLabel>
        </div>
        <div className="mx-6 mt-2">
          <select
            className="w-full rounded border-gray-200"
            id="select"
            value={template}
            onChange={handleTemplate}
          >
            {emailProviders.map((option) => (
              <option
                className="m-4 rounded-2xl p-4"
                key={option}
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="float-right flex items-center space-x-3 p-4">
        <WbButton color="failure" onClick={toggleSendEmailModal}>
          Cancel
        </WbButton>
        <WbButton onClick={handleContinue}>Continue</WbButton>
      </div>
    </WbModal>
  );
}
