import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { hideAddAccountModal } from "modules/Apps/AccountsActions";
import { refreshSubscription } from "../LoggedInLayout/LoggedInLayoutActions";
import { showOAuthModal } from "./OAuthActions";


const withOAuthContainer = (WrappedComponent) => {
    class OAuthContainer extends Component {
        state = {
            isOAuthInProgress: false,
        };

        showOAuthWindow = (integration) => {
            this.setState({
                isOAuthInProgress: true,
            });

            const { subscriptionId, oAuthType, reconnectAccountId } = this.props;

            const sideEffectFn = () => {
                this.setState({
                    isOAuthInProgress: false,
                });
                this.props.hideAddAccountModal(hideAddAccountModal());
            };
            const refreshAccounts = () => {
                this.props.refreshSubscription(this.props.subscriptionId);
            };

            this.props.showOAuthModal(integration, subscriptionId, oAuthType, reconnectAccountId, [sideEffectFn], refreshAccounts);
        };

        render() {
            return (
                <Fragment>
                    <div style={{ display: "inline" }} onClick={() => this.showOAuthWindow(this.props.oAuthIntegration)}>
                        <WrappedComponent isOAuthInProgress={this.state.isOAuthInProgress} />
                    </div>
                </Fragment>
            );
        }
    }

    const mapStateToProps = ({ LoggedInLayout }) => {
        const { subscription } = LoggedInLayout;

        return {
            subscriptionId: subscription._id.toString(),
        };
    };

    return connect(mapStateToProps, {
        showOAuthModal,
        hideAddAccountModal,
        refreshSubscription,
    })(OAuthContainer);
};

export default withOAuthContainer;
