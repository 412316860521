export const SELECT_INTEGRATION = "selectIntegration";
export const SELECT_EVENT = "selectEvent";
export const SELECT_ACTION = "selectAction";
export const SELECT_ACCOUNT = "selectAccount";
export const SETUP_EVENT_OPTIONS = "setupEventOptions";
export const SETUP_ACTION_TEMPLATE = "setupActionTemplate";
export const TEST_EVENT = "testEvent";
export const TEST_ACTION = "testAction";
export const SETUP_FILTER = "setupFilter";
export const SETUP_DELAY = "setupDelay";
