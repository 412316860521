import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { FaCaretRight } from "react-icons/fa";

import WbIntegrationImage from "components/WbIntegrationImage";
import WbModal, { WbModalTitle } from "components/WbModal";

import { initStagesToBeShown } from "../../FlowBuilder/FlowBuilderActions";

export default function FlowTemplateDetailsModal({
  flowTemplate,
  toggleModal,
  shouldShowModal,
}) {
  const dispatch = useDispatch();
  const triggerIntegration = flowTemplate.triggerStageItems.integrationId;
  const actionIntegration = flowTemplate.actionStageItems[0].integrationId;
  const stageItems = [
    {
      triggerStageItems: {
        ...flowTemplate.triggerStageItems,
        integrationId: triggerIntegration._id,
      },
    },
    {
      actionStageItems: {
        ...flowTemplate.actionStageItems[0],
        integrationId: actionIntegration._id,
      },
    },
  ];
  const stringifiedStageItems = JSON.stringify(stageItems);

  return (
    <WbModal isOpen={shouldShowModal} toggle={toggleModal}>
      <WbModalTitle toggle={toggleModal} title="Flow Template Details" />
      <div className="space-y-4 p-8 dark:text-white">
        <div className="mx-10 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <WbIntegrationImage
              slug={triggerIntegration.slug}
              altText={triggerIntegration.displayText}
            />
            <FaCaretRight size={16} />
            <WbIntegrationImage
              slug={actionIntegration.slug}
              altText={actionIntegration.displayText}
            />
          </div>
          <Link
            to={{
              pathname: `/flow-builder`,
              search: `?stages=${encodeURIComponent(stringifiedStageItems)}`,
            }}
            className="rounded-lg bg-orange-600 px-6 py-2 text-white disabled:opacity-75"
            onClick={() => dispatch(initStagesToBeShown([]))}
          >
            Use this Flow
          </Link>
        </div>
        <div className="flex flex-col space-y-5">
          <div>
            <h2 className="my-3 text-2xl">{flowTemplate.name}</h2>
            <iframe
              src={flowTemplate.youtubeHowToVideoUrl}
              title="how-to-video"
              allow="autoplay; encrypted-media"
              allowFullScreen
              className="aspect-video w-full"
            ></iframe>
          </div>
          <div>
            <h3 className="my-2 text-2xl">Description</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(flowTemplate.description),
              }}
            ></div>
          </div>
          <div>
            <h3 className="my-2 text-2xl">How It Works</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  flowTemplate.landingPageContent.howItWorksHtml
                ),
              }}
            ></div>
          </div>
        </div>
      </div>
    </WbModal>
  );
}
