import {
	SHOW_OAUTH_MODAL,
	HIDE_OAUTH_BACKDROP,
	SHOW_OAUTH_BACKDROP,
} from "./OAuthActionTypes";

export const showOAuthModal = (
	integration,
	subscriptionId,
	oAuthType,
	reconnectAccountId,
	sideEffectFns,
	refreshAccounts
) => {
	return {
		type: SHOW_OAUTH_MODAL,
		payload: {
			integration,
			subscriptionId,
			oAuthType,
			reconnectAccountId,
			sideEffectFns,
			refreshAccounts,
		},
	};
};

export const showOAuthBackdrop = () => {
	return {
		type: SHOW_OAUTH_BACKDROP,
	};
};

export const hideOAuthBackdrop = () => {
	return {
		type: HIDE_OAUTH_BACKDROP,
	};
};
