import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import camelcase from "camelcase";

import {
  isDataPresent,
  getHelperTextHtmlJsxFromParts,
} from "utils/object_util";
import {
  selectDynamicIncomingDataPrerequisite,
  selectDynamicIncomingData,
  setDefaultDynamicIncomingData,
} from "../../FlowBuilderActions";
import DynamicIncomingDataPrerequisite from "./DynamicIncomingDataPrerequisite";
import { loadDataFromUrl } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";

class SetupEventOptionsModal extends Component {
  state = {
    dynamicIncomingDataPrerequisites: null,
    prerequisitesToDisplay: null,
  };

  dynamicIncomingDataPrerequisiteSelectHandler = (
    stageIndex,
    prerequisite,
    jsonCustomInputDetails
  ) => {
    this.props.selectDynamicIncomingDataPrerequisite(
      stageIndex,
      prerequisite,
      jsonCustomInputDetails
    );
    this.setState((prevState) => {
      const {
        dynamicIncomingDataPrerequisites:
          previousDynamicIncomingDataPrerequisites,
      } = prevState;

      const dynamicIncomingDataPrerequisitesWithParentAsSelectedPrerequisite =
        _.filter(previousDynamicIncomingDataPrerequisites, {
          parentOptionSlug: prerequisite.slug,
        });

      return {
        prerequisitesToDisplay: _.unionBy(
          prevState.prerequisitesToDisplay,
          dynamicIncomingDataPrerequisitesWithParentAsSelectedPrerequisite,
          "slug"
        ),
      };
    });

    const { commonData } = this.props;

    const { dynamicIncomingDataLoadOptions } = prerequisite;
    if (!isDataPresent(dynamicIncomingDataLoadOptions)) return;

    const { userInput } = jsonCustomInputDetails;
    const { value: selectedPrerequisiteOptionValue } = userInput;
    if (selectedPrerequisiteOptionValue === "nil") {
      this.props.setDefaultDynamicIncomingData(stageIndex);
      return;
    }

    const clonedCommonData = _.cloneDeep(commonData);
    const { slug: prerequisiteSlug } = prerequisite;
    const camelCasedPrerequisiteSlug = camelcase(prerequisiteSlug);
    const currentStageCommonData = clonedCommonData[stageIndex];
    currentStageCommonData[camelCasedPrerequisiteSlug] =
      selectedPrerequisiteOptionValue;

    _.each(dynamicIncomingDataLoadOptions, (eachDynamicDataLoadOption) => {
      const { bodyParamsRequired, url } = eachDynamicDataLoadOption;
      const bodyParams = {};
      _.map(bodyParamsRequired, (eachBodyParamRequired) => {
        bodyParams[eachBodyParamRequired] =
          currentStageCommonData[eachBodyParamRequired];
      });

      const dynamicIncomingDataLoadOptionsSideEffect = (urlLoadedData) => {
        this.props.selectDynamicIncomingData(stageIndex, urlLoadedData);
      };
      this.props.loadDataFromUrl(
        `${window.wyzebulbApiBaseUrl}/${url}`,
        bodyParams,
        [dynamicIncomingDataLoadOptionsSideEffect]
      );
    });
  };

  componentDidMount() {
    const { eventOptions, selectedEvent } = this.props;

    const eventOptionsForSelectedEvent = _.find(
      eventOptions,
      (eachEventOption) =>
        selectedEvent.selectedEventSlug === eachEventOption.eventSlug
    );
    const { dynamicIncomingDataPrerequisites } = eventOptionsForSelectedEvent;

    this.setState({
      dynamicIncomingDataPrerequisites,
      prerequisitesToDisplay: _.filter(
        dynamicIncomingDataPrerequisites,
        (eachPrerequisite) => !isDataPresent(eachPrerequisite.parentOptionSlug)
      ),
    });
  }

  render() {
    const {
      stageIndex,
      commonData,
      flowObj,
      integrationImageUrl,
      integrationDisplayText,
      userHelper,
    } = this.props;
    const { helperTextHtmlParts } = userHelper;
    const helperTextHtmlJsx = getHelperTextHtmlJsxFromParts(
      helperTextHtmlParts,
      true
    );
    const {
      selectedDynamicIncomingDataPrerequisites:
        reducerSelectedDynamicIncomingDataPrerequisites,
    } = flowObj.stages[stageIndex].stageItems.setupEventOptions;

    return (
      <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
        <WbModalTitle
          toggle={this.props.closeModal}
          title="Setup Event Options"
        />
        <div className="p-4">
          <div className=" flex flex-col items-center">
            <img
              className="h-28 w-28 object-contain"
              src={`/assets/${integrationImageUrl}`}
              alt={integrationDisplayText}
            />
            <div className="text-2xl text-gray-800">{helperTextHtmlJsx}</div>
          </div>
          {_.map(
            this.state.prerequisitesToDisplay,
            (eachPrerequisite, index) => {
              const reducerSelectedPrerequisite = _.find(
                reducerSelectedDynamicIncomingDataPrerequisites,
                { slug: eachPrerequisite.slug }
              );
              return (
                <div className="mb-4" key={index}>
                  <DynamicIncomingDataPrerequisite
                    onSelectEventOptions={(selectedPrerequisiteOption) =>
                      this.dynamicIncomingDataPrerequisiteSelectHandler(
                        stageIndex,
                        eachPrerequisite,
                        selectedPrerequisiteOption
                      )
                    }
                    commonData={commonData}
                    userHelper={eachPrerequisite.userHelper}
                    dynamicIncomingDataPrerequisite={eachPrerequisite}
                    reducerSelectedPrerequisite={reducerSelectedPrerequisite}
                    stageIndex={stageIndex}
                  />
                </div>
              );
            }
          )}
          <div className="float-right p-4">
            <WbButton onClick={this.props.finishSetup}>
              Save + Continue
            </WbButton>
          </div>
        </div>
      </WbModal>
    );
  }
}

const mapStateToProps = ({ FlowBuilder }) => {
  const { commonData, flowObj } = FlowBuilder;

  return {
    commonData,
    flowObj,
  };
};

export default connect(mapStateToProps, {
  selectDynamicIncomingDataPrerequisite,
  selectDynamicIncomingData,
  loadDataFromUrl,
  setDefaultDynamicIncomingData,
})(SetupEventOptionsModal);
