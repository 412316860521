import { call, put, takeEvery, all } from "redux-saga/effects";
import _ from "lodash";

import { asyncPost, asyncPostWithoutErrHandler } from "utils/async_util";
import { refreshSubscription } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import {
  ADD_NEW_ACCOUNT,
  RECONNECT_ACCOUNT,
  DISCONNECT_ACCOUNT,
  FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN,
  CHANGE_ACCOUNT_NAME,
} from "./AccountsActionTypes";
import {
  addNewAccountFail,
  addNewAccountSuccess,
  reconnectAccountFail,
  reconnectAccountSuccess,
  disconnectAccountFail,
  disconnectAccountSuccess,
  fetchOAuthIntegrationsForAccountsShownSuccess,
  fetchOAuthIntegrationsForAccountsShownFail,
  changeAccountNameFail,
  changeAccountNameSuccess,
} from "./AccountsActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function formatApiKeysObjToArr(apiKeysObj) {
  const apiKeySlugs = Object.keys(apiKeysObj);
  const apiKeysArr = _.map(apiKeySlugs, (eachSlug) => {
    return {
      slug: eachSlug,
      value: apiKeysObj[eachSlug],
    };
  });

  return apiKeysArr;
}

function* addNewAccountSaga({ payload }) {
  const { subscriptionId, accountDetails } = payload;

  try {
    accountDetails.apiKeys = formatApiKeysObjToArr(accountDetails.apiKeys);
    accountDetails.subscriptionId = subscriptionId;
    const [err, res] = yield call(
      asyncPost,
      `/apps/addAccount`,
      accountDetails
    );
    if (err) {
      return yield put(addNewAccountFail());
    }
    showSuccessToast("Account connected Successfully");

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield all([put(addNewAccountFail())]);
    }

    return yield all([
      put(refreshSubscription(subscriptionId)),
      put(addNewAccountSuccess()),
    ]);
  } catch (err) {
    showErrorToast();
    return yield all([put(addNewAccountFail())]);
  }
}

function* reconnectAccountSaga({ payload }) {
  const { subscriptionId, accountId, apiKeys } = payload;

  try {
    const [err, res] = yield call(asyncPost, `/apps/reconnectAccount`, {
      apiKeys: formatApiKeysObjToArr(apiKeys),
      subscriptionId,
      accountId,
    });
    if (err) {
      return yield put(reconnectAccountFail());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield all([put(reconnectAccountFail())]);
    }

    return yield all([
      put(refreshSubscription(subscriptionId)),
      put(reconnectAccountSuccess()),
    ]);
  } catch (err) {
    showErrorToast();
    return yield all([put(reconnectAccountFail())]);
  }
}

function* disconnectAccountSaga({ payload }) {
  const { subscriptionId, accountId, integrationId } = payload;

  try {
    const [err, res] = yield call(asyncPost, `/apps/disconnectAccount`, {
      subscriptionId,
      accountId,
			integrationId
    });
    if (err) {
      return yield put(disconnectAccountFail());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield all([put(disconnectAccountFail())]);
    }

    return yield all([
      put(refreshSubscription(subscriptionId)),
      put(disconnectAccountSuccess()),
    ]);
  } catch (err) {
    showErrorToast();
    return yield all([put(disconnectAccountFail())]);
  }
}

function* changeAccountNameSaga({ payload }) {
  const { subscriptionId, accountId, accountName } = payload;

  try {
    const [err, res] = yield call(
      asyncPost,
      `/apps/changeAccountName`,
      {
        accountId,
        subscriptionId,
        accountName,
      }
    );
    if (err) {
      return yield put(changeAccountNameFail());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield all([put(changeAccountNameFail())]);
    }

    return yield all([
      put(refreshSubscription(subscriptionId)),
      put(changeAccountNameSuccess()),
    ]);
  } catch (err) {
    showErrorToast();
    return yield all([put(changeAccountNameFail())]);
  }
}

function* fetchOAuthIntegrationsForAccountsShownSaga({ payload }) {
  const { accounts } = payload;

  try {
    const accountsGroupedByIntegrationIds = _.groupBy(
      accounts,
      "integration.id"
    );
    const uniqueIntegrationIds = Object.keys(accountsGroupedByIntegrationIds);
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/apps/listApps",
      {
        findQuery: {
          _id: {
            $in: uniqueIntegrationIds,
          },
          oAuthDetails: {
            $exists: true,
          },
        },
      }
    );
    if (err) {
      return yield put(fetchOAuthIntegrationsForAccountsShownFail());
    }

    if (!res.data.success) {
      return yield put(fetchOAuthIntegrationsForAccountsShownFail());
    }

    return yield put(
      fetchOAuthIntegrationsForAccountsShownSuccess(res.data.integrations)
    );
  } catch (err) {
    return yield put(fetchOAuthIntegrationsForAccountsShownFail());
  }
}

export function* watchAccounts() {
  yield takeEvery(ADD_NEW_ACCOUNT, addNewAccountSaga);
  yield takeEvery(RECONNECT_ACCOUNT, reconnectAccountSaga);
  yield takeEvery(DISCONNECT_ACCOUNT, disconnectAccountSaga);
  yield takeEvery(CHANGE_ACCOUNT_NAME, changeAccountNameSaga);
  yield takeEvery(
    FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN,
    fetchOAuthIntegrationsForAccountsShownSaga
  );
}
