import {
	FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER,
	LOGGED_IN_USER_SUBSCRIPTION_FETCH_SUCCESS,
	LOGGED_IN_USER_SUBSCRIPTION_FETCH_FAIL,
	REFRESH_SUBSCRIPTION,
	REFRESH_SUBSCRIPTION_SUCCESS,
	LOAD_DATA_FROM_URL,
	LOAD_DATA_FROM_URL_SUCCESS,
	LOAD_DATA_FROM_URL_FAIL,
	LOAD_PAYMENT_FAILURE_MODAL,
	STOP_PAYMENT_FAILURE_MODAL,
} from "./LoggedInLayoutActionTypes";

const INIT_STATE = {
	fetchingSubscription: false,
	subscription: null,
	isLoadingDataFromUrl: false,
	isPaymentPending: false,
	shouldShowPaymentFailureModal: false,
	isFirstFlowCreated: false,
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;

	switch (action.type) {
		case FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER:
			return {
				...state,
				fetchingSubscription: true,
			};
		case LOGGED_IN_USER_SUBSCRIPTION_FETCH_SUCCESS:
			const { fetchedSubscription } = payload;
			const isPaymentPending =
				fetchedSubscription.billingInfo.isPaymentPending.service
					.wyzebulb;
			return {
				...state,
				subscription: payload.fetchedSubscription,
				isPaymentPending,
				isFirstFlowCreated: fetchedSubscription.isFirstFlowCreated,
				fetchingSubscription: false,
			};
		case LOGGED_IN_USER_SUBSCRIPTION_FETCH_FAIL:
			return {
				...state,
				subscription: null,
				fetchingSubscription: false,
			};
		case REFRESH_SUBSCRIPTION:
			return {
				...state,
				fetchingSubscription: true,
			};
		case REFRESH_SUBSCRIPTION_SUCCESS:
			const refreshedSubscription = payload.fetchedSubscription;

			return {
				...state,
				isPaymentPending:
					refreshedSubscription.billingInfo.isPaymentPending.service
						.wyzebulb,
				subscription: refreshedSubscription,
				isFirstFlowCreated: refreshedSubscription.isFirstFlowCreated,
				fetchingSubscription: false,
			};
		case LOAD_DATA_FROM_URL:
			return {
				...state,
				isLoadingDataFromUrl: true,
			};
		case LOAD_DATA_FROM_URL_SUCCESS:
		case LOAD_DATA_FROM_URL_FAIL:
			return {
				...state,
				isLoadingDataFromUrl: false,
			};
		case LOAD_PAYMENT_FAILURE_MODAL:
			return {
				...state,
				shouldShowPaymentFailureModal: true,
			};
		case STOP_PAYMENT_FAILURE_MODAL:
			return {
				...state,
				shouldShowPaymentFailureModal: false,
			};
		default:
			return {
				...state,
			};
	}
};
