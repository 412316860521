export default function FlowsSearch({ refreshFlowTemplates }) {
  const inputChange = (event) => {
    const { value } = event.target;
    const findQuery = {
      slug: {
        $regex: `.*${value}.*`,
        $options: "i",
      },
    };

    refreshFlowTemplates(findQuery);
  };

  return (
    <div className="w-96">
      <input
        type="text"
        placeholder="Type app name to search flow"
        onChange={(event) => inputChange(event)}
        className="w-[100%] rounded border border-gray-400 px-3 py-2 text-gray-900 dark:bg-gray-700 dark:text-white"
      />
    </div>
  );
}
