export const REGISTER_VIA_WYZEBULB = "REGISTER_VIA_WYZEBULB";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SET_TOKEN_COOKIE = "SET_TOKEN_COOKIE";
export const CHECK_AUTH_STATE = "CHECK_AUTH_STATE";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_NOT_LOGGED_IN = "USER_NOT_LOGGED_IN";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_VIA_WYZEBULB = "LOGIN_VIA_WYZEBULB";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_VIA_FACEBOOK = "LOGIN_VIA_FACEBOOK";
export const LOGIN_VIA_GOOGLE = "LOGIN_VIA_GOOGLE";
