import React, { Component } from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { FaCheckDouble, FaPen } from "react-icons/fa";

import { ACTION } from "enums/StageTypesEnum";
import { SETUP_ACTION_TEMPLATE } from "enums/StageItemSlugsEnum";
import SetUpActionTemplateModal from "./SetUpActionTemplateModal";
import { setupDataFromPreviousStages } from "../../FlowBuilderActions";

class SetupActionTemplate extends Component {
    state = {
        showModal: false,
        isStageSetupComplete: false,
    };

    initActionTemplateOptionsSelection = () => {
        const { stageIndex } = this.props;
        this.props.setupDataFromPreviousStages(stageIndex, ACTION);
        this.setState({
            showModal: true,
        });
    };

    stopActionTemplateOptionsSelection = () => {
        this.setState({
            showModal: false,
        });
    };

    componentDidUpdate() {
        this.finishStageItem();
    }

    componentWillMount() {
        this.finishStageItem();
        const { stageIndex, flowObj } = this.props;
        const setupActionTemplate = get(flowObj, `stages[${stageIndex}].stageItems.setupActionTemplate`, {});

        const isStageSetupComplete = !isEmpty(get(setupActionTemplate, "apiData", []));

        if (isStageSetupComplete) {
            this.setState({
                isStageSetupComplete,
            });
        }
    }

    finishStageItem = () => {
        const { stageIndex, flowObj } = this.props;
        const setupActionTemplate = get(flowObj, `stages[${stageIndex}].stageItems.setupActionTemplate`, {});

        const isStageSetupComplete = !isEmpty(get(setupActionTemplate, "apiData", []));

        if (isStageSetupComplete) this.props.finishStageItem(SETUP_ACTION_TEMPLATE);
    };

    finishSetup = () => {
        const { stageIndex, flowObj } = this.props;
        const setupActionTemplate = get(flowObj, `stages[${stageIndex}].stageItems.setupActionTemplate`, {});

        const isStageSetupComplete = !isEmpty(get(setupActionTemplate, "apiData", []));

        if (isStageSetupComplete) {
            this.setState({
                isStageSetupComplete: true,
            });
        }

        this.stopActionTemplateOptionsSelection();
    };

    render() {
        const { stageIndex, stageType, selectedIntegrations, flowObj, userHelper, orderIndex } = this.props;
        const currentStageSelectedIntegration = selectedIntegrations[stageIndex];
        const selectedAction = flowObj.stages[stageIndex].stageItems.selectAction;

        return (
            <>
                <div>
                    {!this.state.isStageSetupComplete ? (
                        <div className="mb-0 cursor-pointer max-w-fit" onClick={this.initActionTemplateOptionsSelection}>
                            <span>{orderIndex + 1}. Setup action template - Click here to setup action template</span>
                            <span className="text-red-600 mx-1">(required)</span>
                        </div>
                    ) : (
                        <div className="mb-0 flex flex-row cursor-pointer max-w-fit" onClick={this.initActionTemplateOptionsSelection}>
                            <span>
                                {orderIndex + 1}. Setup action template - Click here to view action template &nbsp;
                                <span>
                                    <FaPen size={12} className="inline-block text-blue-600 mx-1" />
                                    <FaCheckDouble size={12} className="inline-block text-green-600" />
                                </span>
                            </span>
                        </div>
                    )}
                </div>
                {this.state.showModal && (
                    <SetUpActionTemplateModal
                        stageIndex={stageIndex}
                        stageType={stageType}
                        selectedAction={selectedAction}
                        actionApiRequiredData={selectedIntegrations[stageIndex].actionStageItems.setupActionTemplate.actionApiRequiredData}
                        userHelper={userHelper}
                        integrationImageUrl={currentStageSelectedIntegration.imageUrl}
                        integrationDisplayText={currentStageSelectedIntegration.displayText}
                        finishSetup={this.finishSetup}
                        closeModal={this.stopActionTemplateOptionsSelection}
                        slug={currentStageSelectedIntegration.slug}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ FlowBuilder }) => {
    const { selectedIntegrations, flowObj, commonData } = FlowBuilder;
    return {
        selectedIntegrations,
        flowObj,
        commonData,
    };
};

export default connect(mapStateToProps, {
    setupDataFromPreviousStages,
})(SetupActionTemplate);
