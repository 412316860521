export const FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER = "FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER";
export const LOGGED_IN_USER_SUBSCRIPTION_FETCH_SUCCESS = "LOGGED_IN_USER_SUBSCRIPTION_FETCH_SUCCESS";
export const LOGGED_IN_USER_SUBSCRIPTION_FETCH_FAIL = "LOGGED_IN_USER_SUBSCRIPTION_FETCH_FAIL";
export const REFRESH_SUBSCRIPTION = "REFRESH_SUBSCRIPTION";
export const REFRESH_SUBSCRIPTION_SUCCESS = "REFRESH_SUBSCRIPTION_SUCCESS";
export const LOAD_DATA_FROM_URL = "LOAD_DATA_FROM_URL";
export const LOAD_DATA_FROM_URL_SUCCESS = "LOAD_DATA_FROM_URL_SUCCESS";
export const LOAD_DATA_FROM_URL_FAIL = "LOAD_DATA_FROM_URL_FAIL";
export const LOAD_PAYMENT_FAILURE_MODAL = "LOAD_PAYMENT_FAILURE_MODAL";
export const STOP_PAYMENT_FAILURE_MODAL = "STOP_PAYMENT_FAILURE_MODAL";
