import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, get } from "lodash";

import SelectPlanOrUpdatePlan from "modules/Account/LegacyBilling/SelectPlanOrUpdatePlan";
import UpdateCardModal from "modules/Account/LegacyBilling/CurrentSubscriptionDetails/UpdateCardModal";
import { FaExclamationCircle } from "react-icons/fa";
import WbButton from "components/WbButton";

class PaymentFailureWarning extends Component {
  state = {
    shouldShowSelectPlanModal: false,
    shouldShowUpdatePaymentInfoModal: false,
  };

  initSelectPlanModal = (
    hadUserSelectedPlanWithoutCardDetails: any,
    hasUserSelectedAPlan: any,
    isPaymentPending: any
  ) => {
    if (
      hadUserSelectedPlanWithoutCardDetails ||
      (hasUserSelectedAPlan && isPaymentPending)
    ) {
      this.setState({ shouldShowUpdatePaymentInfoModal: true });
      return;
    }
    this.setState({ shouldShowSelectPlanModal: true });
  };

  stopSelectPlanModal = () => {
    this.setState({ shouldShowSelectPlanModal: false });
  };

  stopUpdatePaymentInfoModal = () => {
    this.setState({ shouldShowUpdatePaymentInfoModal: false });
  };

  render() {
    const {
      subscription,
      isDefaultCardDetailsExist,
      isPaymentPending,
      isLoadingGetDefaultCardDetails,
    }: any = this.props;

    let paymentFailureWaringMessage = null;
    let hadUserSelectedPlanWithoutCardDetails = false;
    let paymentWarningButtonText = null;
    let hasUserSelectedAPlan = false;
    let isAppsumoUser = false;

    if (!isEmpty(subscription)) {
      hasUserSelectedAPlan = !isEmpty(
        get(subscription, "billingInfo.stripeSubscriptionIds")
      );
      isAppsumoUser = subscription.source === "appsumo";

      if (hasUserSelectedAPlan && isPaymentPending) {
        paymentFailureWaringMessage = `Oops! We are unable process your payment.\
                Please update payment info.`;
        paymentWarningButtonText = `update payment info`;
      } else if (hasUserSelectedAPlan && !isDefaultCardDetailsExist) {
        hadUserSelectedPlanWithoutCardDetails = true;
        paymentWarningButtonText = `update payment info`;
        paymentFailureWaringMessage = `Oops! Your payment details are declined.\
                Please add valid payment info to continue with your workflow.`;
      } else {
        paymentWarningButtonText = `select plan`;
        paymentFailureWaringMessage = `You have not selected any plan.\
                Select any plan now to continue using our platform.`;
      }
    }

    const loadSelectPlanModal = this.state.shouldShowSelectPlanModal ? (
      <SelectPlanOrUpdatePlan closeModal={this.stopSelectPlanModal} />
    ) : null;

    const loadUpdatePaymentModal = this.state
      .shouldShowUpdatePaymentInfoModal ? (
      <UpdateCardModal closeModal={this.stopUpdatePaymentInfoModal} />
    ) : null;

    return (
      <>
        {!isAppsumoUser && !isLoadingGetDefaultCardDetails && (
          <div className="">
            <div className="flex animate-[bounce_5s_ease-in-out_infinite] flex-row items-center justify-between bg-yellow-50 p-2 text-yellow-700">
              <div className="flex flex-row items-center space-x-2">
                <FaExclamationCircle />
                <span>{paymentFailureWaringMessage} </span>
              </div>
              <WbButton
                size="sm"
                color="warning"
                className="capitalize"
                onClick={() =>
                  this.initSelectPlanModal(
                    hadUserSelectedPlanWithoutCardDetails,
                    hasUserSelectedAPlan,
                    isPaymentPending
                  )
                }
              >
                {paymentWarningButtonText}
              </WbButton>
            </div>
          </div>
        )}
        {loadSelectPlanModal}
        {loadUpdatePaymentModal}
      </>
    );
  }
}

const mapStateToProps = ({ LoggedInLayout, ManageSubscription }: any) => {
  const { subscription, isPaymentPending } = LoggedInLayout;
  const { isLoadingGetDefaultCardDetails } = ManageSubscription;
  return {
    subscription,
    isPaymentPending,
    isLoadingGetDefaultCardDetails,
  };
};

export default connect(mapStateToProps, null)(PaymentFailureWarning);
