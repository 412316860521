import { flexRender } from "@tanstack/react-table";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

export default function Table({ table }: any) {
	
	return (
		<table
			className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
			
		>
			{/*Table Head*/}
			<thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-800 dark:text-gray-400">
				{table.getHeaderGroups().map((headerGroup: any) => (
					<>
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header: any) => (
							<th
								scope="col"
								className="py-2 px-3"
								key={header.id}
								colSpan={header.colSpan}
								style={{
									minWidth: header.getSize() || "200px",
								}}
							>
								{header.isPlaceholder ? null : (
									<div
										{...{
											className:
												header.column.getCanSort()
													? "cursor-pointer select-none flex items-center"
													: "flex items-center",
											onClick:
												header.column.getToggleSortingHandler(),
										}}
									>
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
										{{
											asc: <ChevronDownIcon className="w-4 m-1"/>,
											desc: <ChevronUpIcon className="w-4 m-1"/>,
										}[
											header.column.getIsSorted() as string
										] ?? null}
									</div>
								)}
							</th>
						))}
					</tr>
					</>
				))}
			</thead>
			{/*Table Body*/}
			<tbody>
				{/*Table Rows*/}
				{table.getRowModel().rows.map((row: any) => (
					<tr
						key={row.id}
						className="bg-white border-b hover:bg-gray-50 break-normal"
					>
						{row.getVisibleCells().map((cell: any) => (
							<td
								key={cell.id}
								className="py-2 px-2"
								style={{
									width: cell.column.getSize(),
								}}
							>
								{flexRender(
									cell.column.columnDef.cell,
									cell.getContext()
								)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}
