import { call, put, takeEvery, all } from "redux-saga/effects";
import cookie from "react-cookies";

import {
  REGISTER_VIA_WYZEBULB,
  SET_TOKEN_COOKIE,
  CHECK_AUTH_STATE,
  LOGIN_VIA_WYZEBULB,
  LOGOUT_USER,
  LOGIN_VIA_FACEBOOK,
  LOGIN_VIA_GOOGLE,
} from "./AuthActionTypes";
import {
  registerSuccess,
  registerFail,
  setTokenCookie,
  checkAuthState,
  authSuccess,
  authFail,
  loginFail,
  loginSuccess,
  logoutUser,
} from "./AuthActions";
import { asyncPost } from "utils/async_util";
import { isDataPresent } from "utils/object_util";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* registerViaWyzebulbSaga({ payload }) {
  const { user, initialPathname, initialSearchQuery } = payload;
  try {
    const [v3Err, v3Res] = yield call(asyncPost, "/auth/creds/register", user);
    if (v3Err) {
      return yield put(registerFail());
    }
    if (!v3Res.data.success) {
      showErrorToast(v3Res?.data?.message);
      return yield all([put(registerFail())]);
    }

    showSuccessToast(`Welcome ${user?.name || ""} to Wyzebulb.`);
    yield all([put(registerSuccess()), put(setTokenCookie(v3Res.data.token))]);
    window.top.location.href = `${window.location.origin}${initialPathname}${initialSearchQuery}`;
    return;
  } catch (err) {
    showErrorToast(err || err?.message);
    return yield put(registerFail());
  }
}

function* loginViaWyzebulbSaga({ payload }) {
  const { user, initialPathname, initialSearchQuery } = payload;
  try {
    const [v3Err, v3Res] = yield call(asyncPost, "/auth/creds/login", user);
    if (v3Err) {
      return yield put(loginFail());
    }

    if (!v3Res.data.success) {
      showErrorToast(v3Res.data.message);
      return yield put(loginFail());
    }

    yield all([put(setTokenCookie(v3Res.data.token)), put(loginSuccess())]);
    window.top.location.href = `${window.location.origin}${initialPathname}${initialSearchQuery}`;
    return;
  } catch (err) {
    showErrorToast(err || err?.message);
    return yield put(loginFail());
  }
}

function* loginViaFacebookSaga({ payload }) {
  const { user, initialPathname, initialSearchQuery } = payload;
  try {
    const [err, res] = yield call(asyncPost, "/auth/facebook/callback", {
      accessToken: user.accessToken,
      userProfileData: user.profileObj,
    });
    if (err) {
      return yield put(loginFail());
    }

    if (!res.data.success) {
      return yield put(loginFail());
    }

    yield all([put(loginSuccess()), put(setTokenCookie(res.data.token))]);
    window.top.location.href = `${window.location.origin}${initialPathname}${initialSearchQuery}`;
    return;
  } catch (err) {
    return yield put(loginFail());
  }
}

function* loginViaGoogleSaga({ payload }) {
  const { user, initialPathname, initialSearchQuery } = payload;

  try {
    yield all([put(setTokenCookie(user?.accessToken))]);
    const [err, res] = yield call(asyncPost, "/auth/google/callback", {
      accessToken: user.accessToken,
      userProfileData: user.profileObj,
    });
    if (err) {
      return yield put(loginFail());
    }

    if (!res.data.success) {
      return yield put(loginFail());
    }

    yield all([put(loginSuccess()), put(setTokenCookie(res.data.token))]);
    window.top.location.href = `${window.location.origin}${initialPathname}${initialSearchQuery}`;
    return;
  } catch (err) {
    showErrorToast(err || err?.message);
    return yield put(loginFail());
  }
}

function* setTokenCookieSaga({ payload }) {
  const { token } = payload;

  try {
    const noOfSecondsIn1Day = 24 * 60 * 60;
    const noOfSecondsIn7Days = 7 * noOfSecondsIn1Day;

    const { hostname } = window.location;

    const isLocal = hostname === "localhost";
    const domain = isLocal ? "localhost" : "wyzebulb.com";
    const secure = isLocal ? "" : "secure";
    const cookieStr = `token=${token}; domain=${domain}; max-age=${noOfSecondsIn7Days}; path=/; ${secure}`;
    document.cookie = cookieStr;

    return yield put(checkAuthState());
  } catch (err) {
    showErrorToast(err || err?.message);
  }
}

function* checkAuthStateSaga() {
  try {
    const tokenCookie = cookie.load("token");

    if (!isDataPresent(tokenCookie)) {
      return yield put(authFail());
    }

    const [err, res] = yield call(asyncPost, "/auth/verifyToken", {
      token: tokenCookie,
    });
    if (err) {
      return yield put(authFail());
    }

    if (!res.data.success) {
      return yield all([put(authFail()), put(logoutUser())]);
    }

    return yield put(authSuccess(res.data.subscriptionId));
  } catch (err) {
    console.log(err?.message);
    //showErrorToast();
    return yield put(authFail());
  }
}

function* logoutUserSaga() {
  const { hostname } = window.location;

  const isLocal = hostname === "localhost";
  const domain = isLocal ? "localhost" : "wyzebulb.com";
  document.cookie = `token=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  localStorage.clear();

  return yield put(authFail());
}

export function* watchAuth() {
  yield takeEvery(REGISTER_VIA_WYZEBULB, registerViaWyzebulbSaga);
  yield takeEvery(LOGIN_VIA_WYZEBULB, loginViaWyzebulbSaga);
  yield takeEvery(SET_TOKEN_COOKIE, setTokenCookieSaga);
  yield takeEvery(CHECK_AUTH_STATE, checkAuthStateSaga);
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
  yield takeEvery(LOGIN_VIA_FACEBOOK, loginViaFacebookSaga);
  yield takeEvery(LOGIN_VIA_GOOGLE, loginViaGoogleSaga);
}
