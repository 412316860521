import { memo, useEffect, useState } from "react";
import { each, map, isEmpty } from "lodash";

import CustomInput from "components/CustomInput";

const DynamicIncomingDataPrerequisite = (props) => {
    const { dynamicIncomingDataPrerequisite, commonData, stageIndex, userHelper, reducerSelectedPrerequisite } = props;
    const { dataLoadOptions, slug, displayText, isMandatory } = dynamicIncomingDataPrerequisite;
    const { dataLoadUrlDetails, predefinedValues } = dataLoadOptions;
    const currentStageCommonData = commonData[stageIndex];

    const [reload, setReload] = useState(false);

    // Updating the Body Params When Values Changes
    useEffect(() => {
        setReload(true);
    }, [currentStageCommonData]);

    let dataLoadUrlFilledDetails;
    if (!isEmpty(dataLoadUrlDetails)) {
        const { url, bodyParamsRequired } = dataLoadUrlDetails;
        const bodyParams = {};

        each(bodyParamsRequired, (eachBodyParamRequired) => {
            bodyParams[eachBodyParamRequired] = currentStageCommonData[eachBodyParamRequired];
        });

        dataLoadUrlFilledDetails = {
            url: `${window.wyzebulbApiBaseUrl}/${url}`,
            body: bodyParams,
        };
    }

    const staticData = map(predefinedValues, (eachPredefinedValue) => {
        return {
            ...eachPredefinedValue,
            valueFrom: "predefinedValues",
        };
    });

    const formattedReducerSelectedPrerequisite = {
        jsonDetails: [
            {
                valueDetails: [
                    {
                        ...(!isEmpty(reducerSelectedPrerequisite) && {
                            ...reducerSelectedPrerequisite.valueDetails,
                        }),
                    },
                ],
            },
        ],
    };

    return (
        reload && (
            <CustomInput
                dynamicDataLoadOptions={dataLoadUrlFilledDetails}
                staticData={staticData}
                userInputHandler={(selectedOption) => props.onSelectEventOptions(selectedOption)}
                id={slug}
                labelText={displayText}
                showRequiredOrOptional={true}
                isRequired={isMandatory}
                userHelper={userHelper}
                stageIndex={stageIndex}
                selectedValueData={formattedReducerSelectedPrerequisite}
                elementType="rich-select"
            />
        )
    );
};

export default memo(DynamicIncomingDataPrerequisite);
