import { Menu } from "@headlessui/react";

export default function WbMenuItem({ text, icon, onClick, disabled }) {
    return (
        <Menu.Item>
            {({ active }) => (
                <button
                    className={`${active ? "bg-gray-500 text-white" : "text-gray-900 dark:text-white"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm space-x-2 transition ease-in-out duration-200`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {icon}
                    <span>{text}</span>
                </button>
            )}
        </Menu.Item>
    );
}
