import React from "react";
import classNames from "classnames";

const theme = {
    base: "text-sm font-medium",
    colors: {
        default: "text-gray-900 dark:text-gray-300",
        info: "text-blue-500 dark:text-blue-600",
        failure: "text-red-700 dark:text-red-500",
        warning: "text-yellow-500 dark:text-yellow-600",
        success: "text-green-700 dark:text-green-500",
    },
    disabled: "opacity-50",
};

export default function WbLabel(props) {
    const { children, color = "default", disabled = false, value, className } = props;

    return (
        <label className={classNames(theme.base, theme.colors[color], disabled ?? theme.disabled, className)} {...props}>
            {value ?? children ?? ""}
        </label>
    );
}
