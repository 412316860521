import { Link, Outlet, useLocation } from "react-router-dom";
import { useState } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { kebabCase } from "lodash";

interface ITab {
  name: string;
}
interface IWbTabsProps {
  tabs: ITab[];
}

export default function WbTabsV2({ tabs = [] }: IWbTabsProps) {
  const { pathname } = useLocation();
  const [selectedTab] = useState(tabs.findIndex(({ name }) => pathname.includes(kebabCase(name))));

  return (
    <div className="w-full px-2 sm:px-0">
      <Tab.Group defaultIndex={selectedTab}>
        <Tab.List className="ml-2 flex space-x-2 rounded-md border-b">
          {tabs.map(({ name }) => (
            <Link to={kebabCase(name)} state={{ showTab: true }} key={name} className="px-0.5">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "p-2.5 text-sm font-medium leading-5 focus:outline-none",
                    selected ? "border-b-2 border-orange-700 text-orange-700 " : "text-gray-500 hover:border-b-2 dark:text-gray-300",
                  )
                }
              >
                {name}
              </Tab>
            </Link>
          ))}
        </Tab.List>
        <Tab.Panels className={"mt-2"}>
          {tabs.map(({ name }: ITab, idx: number) => (
            <Tab.Panel key={idx}>
              {/* Route Outlet Depending on the pathName Component will be Rendered */}
              <Outlet context={{ tabName: name }} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
