import {
	FETCH_FLOW_TEMPLATES_SUCCESS,
	FETCH_FLOW_TEMPLATES_FAIL,
	FETCH_FLOW_TEMPLATES,
	FLOW_FETCH_INTEGRATIONS,
	FLOW_FETCH_INTEGRATIONS_SUCCESS,
	FLOW_FETCH_INTEGRATIONS_FAIL,
} from "./FlowLibraryActionTypes";

export const fetchFlowTemplates = (
	{ populateQuery = {}, findQuery = {} },
	limit,
	skip
) => {
	return {
		type: FETCH_FLOW_TEMPLATES,
		payload: { populateQuery, findQuery, limit, skip },
	};
};

export const fetchFlowTemplatesSuccess = (flowTemplates) => {
	return {
		type: FETCH_FLOW_TEMPLATES_SUCCESS,
		payload: {
			flowTemplates,
		},
	};
};

export const fetchFlowTemplatesFail = () => {
	return {
		type: FETCH_FLOW_TEMPLATES_FAIL,
	};
};

export const fetchIntegrations = (findQuery) => {
	return {
		type: FLOW_FETCH_INTEGRATIONS,
		payload: { findQuery },
	};
};

export const fetchIntegrationsSuccess = (integrations) => {
	return {
		type: FLOW_FETCH_INTEGRATIONS_SUCCESS,
		payload: {
			integrations,
		},
	};
};
export const fetchIntegrationsFail = () => {
	return {
		type: FLOW_FETCH_INTEGRATIONS_FAIL,
	};
};
