export const contactUsForEnterprise = [
  {
    elementType: "input",
    inputType: "text",
    placeholder: "Name",
    name: "name",
    labelText: "Name",
    isRequired: true,
  },
  {
    elementType: "input",
    inputType: "text",
    placeholder: "Company name",
    name: "companyName",
    labelText: "Company name",
    isRequired: true,
  },
  {
    elementType: "input",
    inputType: "email",
    placeholder: "Work email",
    name: "email",
    labelText: "Work email",
    isRequired: true,
  },
  {
    elementType: "textarea",
    inputType: "text",
    placeholder: "How may we help you?",
    name: "helpDescription",
    labelText: "How may we help you?",
    isRequired: false,
  },
];

export const contactUsForSupport = [
  {
    elementType: "input",
    inputType: "text",
    placeholder: "Name",
    name: "name",
    labelText: "Name",
    isRequired: true,
  },
  {
    elementType: "input",
    inputType: "email",
    placeholder: "Email",
    name: "email",
    labelText: "Email",
    isRequired: true,
  },
  {
    elementType: "textarea",
    inputType: "text",
    placeholder: "How may we help you?",
    name: "helpDescription",
    labelText: "How may we help you?",
    isRequired: false,
  },
];
