import { memo } from "react";

import WbModal, { WbModalTitle } from "components/WbModal";
import { FaEnvelope, FaPhone } from "react-icons/fa";

const MouseOutsideWindowModal = (props: any) => {
  return (
    <WbModal isOpen={true} size="md" toggle={props.closeModal}>
      <WbModalTitle toggle={props.closeModal} title="Contact Us" />
      <div>
        <div className="p-2 text-center">
          <h4 className="text-red-600">
            It seems you didn’t choose our flexi plans, is there something we
            can do for you?
          </h4>
          <div>
            <div>
              <div className="font-semibold text-gray-800">CONTACT US</div>
              <span>
                <FaPhone className=" mr-1 inline text-orange-600" />
                <span onClick={() => props.initContactUsModal(true)}>
                  +1 (302) 261-9743
                </span>
              </span>
            </div>
            <div>
              <div className="font-semibold text-gray-800">EMAIL</div>
              <span>
                <FaEnvelope className=" mr-1 inline text-orange-600" />
                <span onClick={() => props.initContactUsModal(true)}>
                  hello@Wyzebulb.com
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </WbModal>
  );
};

export default memo(MouseOutsideWindowModal);
