import { get } from "lodash";
import { parseMongoDB } from "react-querybuilder";
import callApi from "utils/callApi";

const getInitialQuery = async (pathname: any) => {
  const res = await callApi({
    url: `/builtInTools/dataStudio/editSegment`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: { pathname },
  });

  const parsedQuery = parseMongoDB(get(res, "data.query", ""));
  const segmentId = get(res, "data._id","")

  return {parsedQuery, segmentId};
};

export default getInitialQuery;
