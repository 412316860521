import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requestIntegration } from "../SelectIntegrationActions";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbLabel from "components/WbLabel";
import WbTextarea from "components/WbTextarea";

export default function RequestIntegration({ closeModal }) {
    const dispatch = useDispatch();
    const { subscription } = useSelector((state) => state.LoggedInLayout);
    const [usecase, setUsecase] = useState("");

    const onClickContinue = () => {
        if (usecase.length !== 0) {
            dispatch(requestIntegration(subscription.name, subscription.email, usecase, null));
        } else {
            alert("Please enter required information");
        }
    };

    const onChangeUsecase = (event) => {
        setUsecase(event.target.value);
    };

    return (
        <WbModal toggle={closeModal} isOpen={true} size="sm">
            <WbModalTitle title="Request Integration" toggle={closeModal} />
            <div className="p-4 w-[70%]">
                <WbLabel>Need an app? Request it now!!</WbLabel>
                <WbTextarea id="useCase" placeholder="Please explain your use-case..." name="useCase" onChange={onChangeUsecase} />
            </div>
            <div className="p-4 float-right flex items-center space-x-3">
                <WbButton color="failure" onClick={closeModal}>
                    Cancel
                </WbButton>
                <WbButton onClick={onClickContinue}>Continue</WbButton>
            </div>
        </WbModal>
    );
}
