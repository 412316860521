// import { StripeProvider } from "react-stripe-elements";

import StripeCard from "components/SelectPlan/StripeCard";
import WbModal, { WbModalTitle } from "components/WbModal";

const UpdateCardModal = (props: any) => {
  return (
    <WbModal isOpen={true} size="md" toggle={props.closeModal}>
      <WbModalTitle toggle={props.closeModal} title="Update Card Details" />
      <div className="p-4">
        <StripeCard shouldUpdateCard={true} closeModal={props.closeModal} />
      </div>
    </WbModal>
  );
};

export default UpdateCardModal;
