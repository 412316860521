import {
  REGISTER_VIA_WYZEBULB,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_TOKEN_COOKIE,
  CHECK_AUTH_STATE,
  USER_LOGGED_IN,
  USER_NOT_LOGGED_IN,
  LOGOUT_USER,
  LOGIN_VIA_WYZEBULB,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_VIA_FACEBOOK,
  LOGIN_VIA_GOOGLE,
} from "./AuthActionTypes";

export const registerViaWyzebulb = (
  user,
  initialPathname,
  initialSearchQuery
) => {
  return {
    type: REGISTER_VIA_WYZEBULB,
    payload: {
      user,
      initialPathname,
      initialSearchQuery,
    },
  };
};

export const registerSuccess = () => {
  return {
    type: REGISTER_SUCCESS,
  };
};

export const registerFail = () => {
  return {
    type: REGISTER_FAIL,
  };
};

export const loginViaWyzebulb = (user, initialPathname, initialSearchQuery) => {
  return {
    type: LOGIN_VIA_WYZEBULB,
    payload: {
      user,
      initialPathname,
      initialSearchQuery,
    },
  };
};
export const loginViaFacebook = (user, initialPathname, initialSearchQuery) => {
  return {
    type: LOGIN_VIA_FACEBOOK,
    payload: {
      user,
      initialPathname,
      initialSearchQuery,
    },
  };
};

export const loginViaGoogle = (
  user,
  initialPathname,
  initialSearchQuery,
  history
) => {
  return {
    type: LOGIN_VIA_GOOGLE,
    payload: { user, initialPathname, initialSearchQuery, history },
  };
};

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const loginFail = () => {
  return {
    type: LOGIN_FAIL,
  };
};

export const setTokenCookie = (token) => {
  return {
    type: SET_TOKEN_COOKIE,
    payload: {
      token,
    },
  };
};

export const checkAuthState = () => {
  return {
    type: CHECK_AUTH_STATE,
  };
};

export const authSuccess = (loggedInUserSubscriptionId) => {
  return {
    type: USER_LOGGED_IN,
    payload: {
      loggedInUserSubscriptionId,
    },
  };
};

export const authFail = () => {
  return {
    type: USER_NOT_LOGGED_IN,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
