import {
	GET_BILLING_PLANS,
	GET_BILLING_PLANS_SUCCESS,
	GET_BILLING_PLANS_FAIL,
	CREATE_SUBSCRIPTION_IN_STRIPE,
	CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS,
	CREATE_SUBSCRIPTION_IN_STRIPE_FAIL,
	SEND_MESSAGE_FOR_CONTACT_US,
	UPDATE_STRIPE_CARD_DETAILS,
	UPDATE_STRIPE_CARD_DETAILS_ERROR,
	UPDATE_STRIPE_CARD_DETAILS_SUCCESS,
	LOAD_MODAL_IF_MOUSE_POSITION_IS_OUT,
	STOP_MODAL_IF_MOUSE_POSITION_IS_IN,
} from "./SelectPlanActionTypes";

export const getBillingPlans = () => {
	return {
		type: GET_BILLING_PLANS,
	};
};

export const getBillingPlansSuccess = (billingPlans) => {
	return {
		type: GET_BILLING_PLANS_SUCCESS,
		payload: {
			billingPlans,
		},
	};
};

export const getBillingPlansFail = () => {
	return {
		type: GET_BILLING_PLANS_FAIL,
	};
};

export const createSubscriptionInStripe = (
	stripeCustomerId,
	stripeCardToken,
	stripePlanId,
	subscriptionId,
	isDefaultCardExist,
	tasksCount,
	sideEffectFunctions
) => {
	return {
		type: CREATE_SUBSCRIPTION_IN_STRIPE,
		payload: {
			stripeCustomerId,
			stripeCardToken,
			stripePlanId,
			subscriptionId,
			isDefaultCardExist,
			tasksCount,
			sideEffectFunctions,
		},
	};
};

export const createSubscriptionInStripeSuccess = (sideEffectFunctions) => {
	return {
		type: CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS,
		payload: {
			sideEffectFunctions,
		},
	};
};

export const createSubscriptionInStripeFail = () => {
	return {
		type: CREATE_SUBSCRIPTION_IN_STRIPE_FAIL,
	};
};

export const sendMessageForContactUs = (userDetails) => {
	return {
		type: SEND_MESSAGE_FOR_CONTACT_US,
		payload: {
			userDetails,
		},
	};
};

export const updateCard = (
	stripeCardToken,
	stripeCustomerId,
	addNewCard,
	sideEffectFunctions
) => {
	return {
		type: UPDATE_STRIPE_CARD_DETAILS,
		payload: {
			stripeCardToken,
			stripeCustomerId,
			addNewCard,
			sideEffectFunctions,
		},
	};
};

export const updateCardSuccess = (sideEffectFunctions) => {
	return {
		type: UPDATE_STRIPE_CARD_DETAILS_SUCCESS,
		payload: {
			sideEffectFunctions,
		},
	};
};

export const updateCardError = () => {
	return {
		type: UPDATE_STRIPE_CARD_DETAILS_ERROR,
	};
};

export const loadModalIfMousePositionIsOut = () => {
	return {
		type: LOAD_MODAL_IF_MOUSE_POSITION_IS_OUT,
	};
};

export const stopModalIfMousePositionIsInside = () => {
	return {
		type: STOP_MODAL_IF_MOUSE_POSITION_IS_IN,
	};
};
