import _ from "lodash";
import camelcase from "camelcase";
import { snakeCase } from "lodash";

import { isDataPresent } from "utils/object_util";
import { FILTER, TRIGGER, ACTION } from "enums/StageTypesEnum";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";

export const initStagesToBeShownReducerHandler = (state, stagesToBeShown) => {
	const { flowObj } = state;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);

	clonedFlowObj.stages = _.map(stagesToBeShown, () => ({}));

	return { clonedFlowObj };
};

export const addStageReducerHandler = (state, payload) => {
	const { flowObj, selectedIntegrations, stagesToBeShown, commonData } =
		state;
	const { stageIndex, stageType } = payload;

	// updating stagesToBeShown
	const clonedStagesToBeShown = _.cloneDeep(stagesToBeShown);
	clonedStagesToBeShown.splice(payload.stageIndex, 0, payload.stageType);

	// updating selectedIntegrations
	const clonedSelectedIntegrations = _.cloneDeep(selectedIntegrations);
	clonedSelectedIntegrations.splice(stageIndex, 0, {});

	// updating flowObj
	const addNewStageToFlowObjStages = {
		stageType: stageType,
	};
	if (stageType === FILTER) {
		addNewStageToFlowObjStages.stageItems = {
			setupFilter: {
				dataFromPreviousStages: [],
			},
		};
	}
	const clonedFlowObj = _.cloneDeep(flowObj);
	clonedFlowObj.stages.splice(stageIndex, 0, addNewStageToFlowObjStages);

	// updating commonData
	const clonedCommonData = _.cloneDeep(commonData);
	clonedCommonData.splice(stageIndex, 0, {});

	return {
		flowObj: clonedFlowObj,
		selectedIntegrations: clonedSelectedIntegrations,
		stagesToBeShown: clonedStagesToBeShown,
		commonData: clonedCommonData,
	};
};

export const deleteStageReducerHandler = (state, payload) => {
	const { flowObj, selectedIntegrations, stagesToBeShown, commonData } =
		state;
	const { stageIndex } = payload;

	// updating stagesToBeShown
	const clonedStagesToBeShown = _.cloneDeep(stagesToBeShown);
	clonedStagesToBeShown.splice(payload.stageIndex, 1);

	// updating selectedIntegrations
	const clonedFlowObj = _.cloneDeep(flowObj);
	clonedFlowObj.stages.splice(stageIndex, 1);

	// updating flowObj
	const clonedSelectedIntegrations = _.cloneDeep(selectedIntegrations);
	clonedSelectedIntegrations.splice(stageIndex, 1);

	// updating commonData
	const clonedCommonData = _.cloneDeep(commonData);
	clonedCommonData.splice(stageIndex, 1);

	return {
		clonedFlowObj,
		clonedSelectedIntegrations,
		clonedStagesToBeShown,
		clonedCommonData,
	};
};

export const selectDynamicIncomingDataPrerequisiteReducerHandler = (
	state,
	payload
) => {
	const { flowObj, commonData } = state;
	const { stageIndex, prerequisite, jsonCustomInputDetails } = payload;
	const { slug: prerequisiteSlug } = prerequisite;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);

	const existingSetupEventOptions =
		clonedFlowObj.stages[stageIndex].stageItems.setupEventOptions;
	const { selectedDynamicIncomingDataPrerequisites } =
		existingSetupEventOptions;
	const indexOfCurrentPrerequisiteInSelectedDynamicIncomingDataPrerequisites =
		_.findIndex(
			selectedDynamicIncomingDataPrerequisites,
			(eachSelectedPrerequisite) =>
				prerequisiteSlug === eachSelectedPrerequisite.slug
		);

	const isCurrentPrerequisiteAlreadySelected =
		indexOfCurrentPrerequisiteInSelectedDynamicIncomingDataPrerequisites >
		-1;

	const { userInput } = jsonCustomInputDetails;
	const newSelectedPrerequisite = {
		slug: prerequisiteSlug,
		integrationSpecificSlug: prerequisite.integrationSpecificSlug,
		valueDetails: {
			from: userInput.valueFrom,
			value: userInput.value,
			displayText: userInput.displayText,
		},
		...(isDataPresent(prerequisite.parentOptionSlug) && {
			parentOptionSlug: prerequisite.parentOptionSlug,
		}),
	};

	if (isCurrentPrerequisiteAlreadySelected) {
		selectedDynamicIncomingDataPrerequisites[
			indexOfCurrentPrerequisiteInSelectedDynamicIncomingDataPrerequisites
		] = newSelectedPrerequisite;
	} else {
		selectedDynamicIncomingDataPrerequisites.push(newSelectedPrerequisite);
	}

	clonedFlowObj.stages[
		stageIndex
	].stageItems.setupEventOptions.selectedDynamicIncomingDataPrerequisites = selectedDynamicIncomingDataPrerequisites;

	// updating commonData
	const clonedCommonData = _.cloneDeep(commonData);
	const camelCasedPrerequisiteSlug = camelcase(prerequisiteSlug);
	clonedCommonData[stageIndex] = {
		...clonedCommonData[stageIndex],
		[prerequisiteSlug]: userInput.value,
		[camelCasedPrerequisiteSlug]: userInput.value,
	};
	clonedCommonData[stageIndex].testEventSampleData = [];

	return { clonedFlowObj, clonedCommonData };
};

export const selectDynamicIncomingDataReducerHandler = (state, payload) => {
	const { flowObj, commonData } = state;
	const { stageIndex, dynamicIncomingData } = payload;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);
	const existingIncomingData =
		clonedFlowObj.stages[stageIndex].stageItems.setupEventOptions
			.incomingData;
	const newIncomingData = _.unionBy(
		existingIncomingData,
		dynamicIncomingData,
		"slug"
	);

	clonedFlowObj.stages[stageIndex].stageItems.setupEventOptions.incomingData =
		newIncomingData;

	// updating commonData
	const clonedCommonData = _.cloneDeep(commonData);
	clonedCommonData[stageIndex].incomingData = existingIncomingData;

	return { clonedFlowObj, clonedCommonData };
};

export const setDefaultDynamicIncomingData = (state, payload) => {
	const { flowObj, commonData, selectedIntegrations } = state;
	const clonedCommonData = _.cloneDeep(commonData);
	const clonedFlowObj = _.cloneDeep(flowObj);

	const { stageIndex } = payload;

	const currentSelectedIntegration = selectedIntegrations[stageIndex];

	const currentStageItem = clonedFlowObj.stages[stageIndex];
	const { stageItems } = currentStageItem;
	const currentSelectedEvent = stageItems.selectEvent.selectedEventSlug;
	const { triggerStageItems } = currentSelectedIntegration;
	const { selectEvent } = triggerStageItems;
	const { events } = selectEvent;

	const currentIntegrationStaticIncomingData = _.find(events, (eachEvent) => {
		return eachEvent.slug === currentSelectedEvent;
	});

	const { staticIncomingData } = currentIntegrationStaticIncomingData;
	const { setupEventOptions } = stageItems;

	setupEventOptions.incomingData = staticIncomingData;
	clonedCommonData[stageIndex].incomingData = staticIncomingData;
	clonedCommonData[stageIndex].testEventSampleData = [];

	return {
		clonedCommonData,
		clonedFlowObj,
	};
};

export const setupDataFromPreviousStagesReducerHandler = (state, payload) => {
	const { flowObj } = state;
	const { stageIndex: currentStageIndex, stageType: currentStageType } =
		payload;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);
	const { stages: existingStages } = clonedFlowObj;
	let currentStageDataFromPreviousStages = [];

	// for (let previousStageIndex = 0; previousStageIndex < currentStageIndex; previousStageIndex++) {
	//     const previousStage = existingStages[previousStageIndex];
	//     let previousStageData = [];

	//     const { stageItems: previousStageItems } = previousStage;

	//     if (isDataPresent(previousStageItems)) {
	//         const {
	//             setupEventOptions: previousStageSetupEventOptions,
	//             setupActionTemplate: previousStageSetupActionTemplate,
	//             setupFilter: previousStageSetupFilter,
	//         } = previousStageItems;

	//         if (isDataPresent(previousStageSetupEventOptions)) {
	//             const { incomingData: previousStageIncomingData } = previousStageSetupEventOptions;

	//             if (isDataPresent(previousStageIncomingData)) {
	//                 previousStageData.push({
	//                     stageIndex: previousStageIndex,
	//                     stageData: [
	//                         ...previousStageIncomingData
	//                     ],
	//                 });
	//                 // currentStageDataFromPreviousStages.push({
	//                 //     stageIndex: previousStageIndex,
	//                 //     stageData: [
	//                 //         ...previousStageIncomingData
	//                 //     ],
	//                 // });
	//             }
	//         }

	//         if (isDataPresent(previousStageSetupActionTemplate)) {
	//             const {
	//                 dataFromPreviousStages: previousStageDataFromPreviousStages,
	//                 apiData: previousStageApiData,
	//             } = previousStageSetupActionTemplate;

	//             if (isDataPresent(previousStageDataFromPreviousStages)) {
	//                 previousStageData = [
	//                     ...previousStageData, ...previousStageDataFromPreviousStages
	//                 ];
	//                 // currentStageDataFromPreviousStages = [
	//                 //     ...currentStageDataFromPreviousStages, ...previousStageDataFromPreviousStages
	//                 // ];
	//             }
	//             if (isDataPresent(previousStageApiData)) {
	//                 const previouStageApiDataToStore = _.map(previousStageApiData, eachApiData => ({
	//                     slug: eachApiData.slug,
	//                     integrationSpecificSlug: eachApiData.integrationSpecificSlug,
	//                     displayText: eachApiData.displayText,
	//                 }));

	//                 previousStageData.push({
	//                     stageIndex: previousStageIndex,
	//                     stageData: [
	//                         ...previouStageApiDataToStore
	//                     ],
	//                 });

	//                 // currentStageDataFromPreviousStages.push({
	//                 //     stageIndex: previousStageIndex,
	//                 //     stageData: [
	//                 //         ...previouStageApiDataToStore
	//                 //     ],
	//                 // });
	//             }
	//         }

	//         if (isDataPresent(previousStageSetupFilter)) {
	//             const {
	//                 dataFromPreviousStages: previousStageDataFromPreviousStages,
	//             } = previousStageSetupFilter;

	//             if (isDataPresent(previousStageDataFromPreviousStages)) {
	//                 previousStageData = [
	//                     ...previousStageData, ...previousStageDataFromPreviousStages
	//                 ];
	//                 // currentStageDataFromPreviousStages = [
	//                 //     ...currentStageDataFromPreviousStages, ...previousStageDataFromPreviousStages
	//                 // ];
	//             }
	//         }
	//     }
	//     currentStageDataFromPreviousStages.push({
	//         stageIndex: previousStageIndex,
	//         stageData: previousStageData,
	//     });
	// }

	const previousStageIndex = currentStageIndex - 1;

	const previousStage = existingStages[previousStageIndex];

	const { stageItems: previousStageItems } = previousStage;

	if (isDataPresent(previousStageItems)) {
		const {
			setupEventOptions: previousStageSetupEventOptions,
			setupActionTemplate: previousStageSetupActionTemplate,
			setupFilter: previousStageSetupFilter,
		} = previousStageItems;

		if (isDataPresent(previousStageSetupEventOptions)) {
			const { incomingData: previousStageIncomingData } =
				previousStageSetupEventOptions;

			if (isDataPresent(previousStageIncomingData)) {
				currentStageDataFromPreviousStages.push({
					stageIndex: previousStageIndex,
					stageData: [...previousStageIncomingData],
				});
			}
		}

		if (isDataPresent(previousStageSetupActionTemplate)) {
			const {
				dataFromPreviousStages: previousStageDataFromPreviousStages,
				apiData: previousStageApiData,
			} = previousStageSetupActionTemplate;

			if (isDataPresent(previousStageDataFromPreviousStages)) {
				currentStageDataFromPreviousStages = [
					...currentStageDataFromPreviousStages,
					...previousStageDataFromPreviousStages,
				];
			}
			if (isDataPresent(previousStageApiData)) {
				const previouStageApiDataToStore = _.map(
					previousStageApiData,
					(eachApiData) => ({
						slug: eachApiData.slug,
						integrationSpecificSlug:
							eachApiData.integrationSpecificSlug,
						displayText: eachApiData.displayText,
					})
				);

				currentStageDataFromPreviousStages.push({
					stageIndex: previousStageIndex,
					stageData: [...previouStageApiDataToStore],
				});
			}
		}

		if (isDataPresent(previousStageSetupFilter)) {
			const {
				dataFromPreviousStages: previousStageDataFromPreviousStages,
			} = previousStageSetupFilter;

			if (isDataPresent(previousStageDataFromPreviousStages)) {
				currentStageDataFromPreviousStages = [
					...currentStageDataFromPreviousStages,
					...previousStageDataFromPreviousStages,
				];
			}
		}
	}

	const stageItemToAddDataTo =
		currentStageType === FILTER ? "setupFilter" : "setupActionTemplate";
	clonedFlowObj.stages[currentStageIndex].stageItems[
		stageItemToAddDataTo
	].dataFromPreviousStages = currentStageDataFromPreviousStages;

	return { clonedFlowObj };
};

const jsonCustomInputHandler = (jsonDetailsState, jsonCustomInputDetails) => {
	const {
		jsonPairIndex,
		userInput,
		jsonPairType,
		jsonPairValueIndex,
		jsonPairOp,
	} = jsonCustomInputDetails;
	const clonedJsonDetailsState = _.cloneDeep(jsonDetailsState);

	if (jsonPairOp === "add") {
		clonedJsonDetailsState.splice(jsonPairIndex, 0, {
			keyName: "",
			keySlug: "",
			valueDetails: [
				{
					valueFrom: "",
					value: "",
				},
			],
		});

		return clonedJsonDetailsState;
	} else if (jsonPairOp === "delete") {
		clonedJsonDetailsState.splice(jsonPairIndex, 1);
		return clonedJsonDetailsState;
	}

	const isPairPresentAtCurrentIndex = isDataPresent(
		clonedJsonDetailsState[jsonPairIndex]
	);
	if (!isPairPresentAtCurrentIndex) {
		clonedJsonDetailsState[jsonPairIndex] = {
			keyName: "",
			keySlug: "",
			valueDetails: [
				{
					valueFrom: "",
					value: "",
				},
			],
		};
	}

	if (jsonPairType === JsonPairTypesEnum.KEY) {
		clonedJsonDetailsState[jsonPairIndex] = {
			...clonedJsonDetailsState[jsonPairIndex],
			keyName: userInput.value,
			keySlug: userInput.slug || snakeCase(userInput.value),
		};
	} else {
		const isValuePresentAtCurrentValueIndex = isDataPresent(
			clonedJsonDetailsState[jsonPairIndex].valueDetails[
				jsonPairValueIndex
			]
		);

		if (!isValuePresentAtCurrentValueIndex) {
			clonedJsonDetailsState[jsonPairIndex].valueDetails[
				jsonPairValueIndex
			] = {
				valueFrom: userInput.valueFrom,
				value: userInput.value,
				...(isDataPresent(userInput.displayText) && {
					displayText: userInput.displayText,
				}),
				...(isDataPresent(userInput.mergeFields) && {
					mergeFields: userInput.mergeFields,
				}),
				...(isDataPresent(userInput.extraDetails) && {
					extraDetails: userInput.extraDetails,
				}),
			};
		} else {
			clonedJsonDetailsState[jsonPairIndex].valueDetails[
				jsonPairValueIndex
			] = {
				...clonedJsonDetailsState[jsonPairIndex].valueDetails[
					jsonPairValueIndex
				],
				valueFrom: userInput.valueFrom,
				value: userInput.value,
				...(isDataPresent(userInput.displayText) && {
					displayText: userInput.displayText,
				}),
				...(isDataPresent(userInput.mergeFields) && {
					mergeFields: userInput.mergeFields,
				}),
				...(isDataPresent(userInput.extraDetails) && {
					extraDetails: userInput.extraDetails,
				}),
			};
		}
	}

	return clonedJsonDetailsState;
};

export const selectApiDataReducerHandler = (state, payload) => {
	const { flowObj, commonData } = state;

	const { stageIndex, apiData, jsonCustomInputDetails } = payload;
	const { slug: apiDataSlug } = apiData;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);

	const existingSetupActionTemplate =
		clonedFlowObj.stages[stageIndex].stageItems.setupActionTemplate;
	let { apiData: selectedApiData } = existingSetupActionTemplate;
	const indexOfCurrentApiDataInSelectedApiData = _.findIndex(
		selectedApiData,
		(eachSelectedApiData) => apiDataSlug === eachSelectedApiData.slug
	);

	const isCurrentApiDataAlreadySelected =
		indexOfCurrentApiDataInSelectedApiData > -1;
	let currentJsonDetails = [];
	if (isCurrentApiDataAlreadySelected) {
		currentJsonDetails =
			selectedApiData[indexOfCurrentApiDataInSelectedApiData].jsonDetails;
	}

	const newJsonDetails = jsonCustomInputHandler(
		currentJsonDetails,
		jsonCustomInputDetails
	);
	const newApiData = {
		slug: apiDataSlug,
		integrationSpecificSlug: apiData.integrationSpecificSlug,
		displayText: apiData.displayText,
		dataType: apiData.dataType,
		jsonDetails: newJsonDetails,
	};

	if (isCurrentApiDataAlreadySelected) {
		selectedApiData[indexOfCurrentApiDataInSelectedApiData] = {
			...selectedApiData[indexOfCurrentApiDataInSelectedApiData],
			...newApiData,
		};
	} else {
		selectedApiData.push(newApiData);
	}

	clonedFlowObj.stages[stageIndex].stageItems.setupActionTemplate.apiData =
		selectedApiData;

	// updating commonData
	const { userInput } = jsonCustomInputDetails;
	const clonedCommonData = _.cloneDeep(commonData);
	const camelCasedApiDataSlug = camelcase(apiDataSlug);
	clonedCommonData[stageIndex] = {
		...clonedCommonData[stageIndex],
		[apiDataSlug]: userInput.value,
		[camelCasedApiDataSlug]: userInput.value,
	};
	return { clonedFlowObj, clonedCommonData };
};

export const setupFilterReducerHandler = (state, payload) => {
	const { flowObj } = state;
	const { stageIndex, filter, filterForUi } = payload;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);
	clonedFlowObj.stages[stageIndex].stageItems.setupFilter.selectedFilter =
		JSON.stringify(filter);
	clonedFlowObj.stages[
		stageIndex
	].stageItems.setupFilter.selectedFilterForUi = filterForUi;

	return { clonedFlowObj };
};

export const seedFlowBuilderForViewReducerHandler = (payload) => {
	const { flow, flowIntegrations } = payload;

	const { stages } = flow;
	const stagesToBeShown = [];
	const selectedIntegrations = [];
	const commonData = [];
	_.each(stages, (eachStage) => {
		const { stageType, stageItems } = eachStage;
		const {
			selectIntegration,
			selectAccount,
			selectEvent,
			setupEventOptions,
			setupActionTemplate,
		} = stageItems;
		const currentStageCommonData = {};

		stagesToBeShown.push(stageType);

		if (stageType === FILTER) {
			selectedIntegrations.push({});
		} else {
			const { selectedIntegrationId } = selectIntegration;
			const selectedIntegrationFromFlowIntegrations = _.find(
				flowIntegrations,
				(eachIntegration) =>
					eachIntegration._id.toString() === selectedIntegrationId
			);

			selectedIntegrations.push(selectedIntegrationFromFlowIntegrations);

			if (stageType === TRIGGER) {
				const { selectedDynamicIncomingDataPrerequisites } =
					setupEventOptions;
				_.each(
					selectedDynamicIncomingDataPrerequisites,
					(eachSelectedDynamicIncomingDataPrerequisites) => {
						currentStageCommonData[
							`${camelcase(
								eachSelectedDynamicIncomingDataPrerequisites.slug
							)}`
						] =
							eachSelectedDynamicIncomingDataPrerequisites.valueDetails.value;
						currentStageCommonData[
							`${eachSelectedDynamicIncomingDataPrerequisites.slug}`
						] =
							eachSelectedDynamicIncomingDataPrerequisites.valueDetails.value;
					}
				);
				const { triggerStageItems } =
					selectedIntegrationFromFlowIntegrations;
				const { selectEvent: selectEventForIntegration } =
					triggerStageItems;
				const { events } = selectEventForIntegration;

				const { selectedEventSlug } = selectEvent;
				const selectedEvent = _.find(events, {
					slug: selectedEventSlug,
				});
				const { staticIncomingData } = selectedEvent;
				currentStageCommonData.staticIncomingData = staticIncomingData;
			}

			if (stageType === ACTION) {
				const { apiData } = setupActionTemplate;
				_.each(apiData, (eachApiData) => {
					currentStageCommonData[`${eachApiData.slug}`] =
						eachApiData.jsonDetails[0].valueDetails[0].value;
					currentStageCommonData[`${camelcase(eachApiData.slug)}`] =
						eachApiData.jsonDetails[0].valueDetails[0].value;
				});
			}

			if (isDataPresent(selectAccount)) {
				const { selectedAccountId } = selectAccount;
				currentStageCommonData.accountId = selectedAccountId;
			}
		}

		commonData.push(currentStageCommonData);
	});

	return {
		flowObj: flow,
		stagesToBeShown,
		selectedIntegrations,
		commonData,
	};
};

export const setSelectedTestEventSampleDataReducerHandler = (
	state,
	payload
) => {
	const { flowObj, commonData } = state;

	const { stageIndex, selectedTestEventSampleData } = payload;

	// updating flowObj
	const clonedFlowObj = _.cloneDeep(flowObj);

	clonedFlowObj.stages[stageIndex].stageItems.setupEventOptions.incomingData =
		selectedTestEventSampleData;

	// updating commonData
	const clonedCommonData = _.cloneDeep(commonData);
	clonedCommonData[stageIndex].incomingData = selectedTestEventSampleData;
	clonedCommonData[stageIndex].selectedTestEventSampleData =
		selectedTestEventSampleData;

	return { clonedFlowObj, clonedCommonData };
};

export const setSelectedDataFormatterReducerHandler = (state, payload) => {
	const { flowObj } = state;

	const {
		stageIndex,
		selectedFormatterOption,
		selectedFormatterSlug,
		eachApiDataSlug,
	} = payload;
	const clonedFlowObj = _.cloneDeep(flowObj);

	const existingSetupActionTemplate =
		clonedFlowObj.stages[stageIndex].stageItems.setupActionTemplate;
	let { apiData: selectedApiData } = existingSetupActionTemplate;
	const indexOfCurrentApiDataInSelectedApiData = _.findIndex(
		selectedApiData,
		(eachApiData) => eachApiDataSlug === eachApiData.slug
	);

	const isCurrentApiDataAlreadySelected =
		indexOfCurrentApiDataInSelectedApiData > -1;

	if (isCurrentApiDataAlreadySelected) {
		let { selectedFormatterOptions } =
			selectedApiData[indexOfCurrentApiDataInSelectedApiData];
		selectedFormatterOptions = isDataPresent(selectedFormatterOptions)
			? selectedFormatterOptions
			: [];
		const selectedFormatterOptionSlug = selectedFormatterOption.slug;
		const indexOfSelectedFormatterOption = _.findIndex(
			selectedFormatterOptions,
			(eachSelectedFormatterOption) =>
				selectedFormatterOptionSlug === eachSelectedFormatterOption.slug
		);

		if (indexOfSelectedFormatterOption > -1) {
			selectedFormatterOptions[indexOfSelectedFormatterOption] =
				selectedFormatterOption;
			selectedApiData[
				indexOfCurrentApiDataInSelectedApiData
			].selectedFormatterOptions = selectedFormatterOptions;
		} else {
			selectedApiData[indexOfCurrentApiDataInSelectedApiData] = {
				...selectedApiData[indexOfCurrentApiDataInSelectedApiData],
				selectedFormatterSlug: selectedFormatterSlug,
				selectedFormatterOptions: [
					...selectedFormatterOptions,
					selectedFormatterOption,
				],
			};
		}
	}

	clonedFlowObj.stages[stageIndex].stageItems.setupActionTemplate.apiData =
		selectedApiData;

	return { clonedFlowObj };
};
