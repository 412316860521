import { useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import RequestIntegration from "components/SelectIntegration/RequestIntegration";
import WbButton from "components/WbButton";
import {
  showRequestIntegrationModal,
  hideRequestIntegrationModal,
} from "components/SelectIntegration/SelectIntegrationActions";
import WbLoader from "components/WbLoader";
import FlowBuilder from "components/FlowBuilder";

import { fetchFlowTemplates } from "./FlowLibraryActions";
import FlowsSearch from "./FlowSearch";
import SingleFlowTemplate from "./SingleFlowTemplate";
import WbLayout from "components/WbLayout";

export default function FlowLibraryList() {
  const dispatch = useDispatch();
  const { fetchingFlowTemplates, flowTemplates } = useSelector(
    (state) => state.FlowLibrary
  );
  const { shouldShowRequestIntegrationModal } = useSelector(
    (state) => state.SelectIntegration
  );

  const refreshFlowTemplates = useCallback(
    (findQuery) => {
      const limit = 10;
      const skip = 0;
      const populateQuery = {
        path: "triggerStageItems.integrationId actionStageItems.integrationId",
      };
      dispatch(fetchFlowTemplates({ populateQuery, findQuery }, limit, skip));
    },
    [dispatch]
  );

  useEffect(() => {
    refreshFlowTemplates();
  }, [refreshFlowTemplates]);

  const initRequestIntegration = () => {
    dispatch(showRequestIntegrationModal());
  };

  const stopRequestIntegration = () => {
    dispatch(hideRequestIntegrationModal());
  };

  return (
    <>
      <WbLayout
        title="Dashboard"
        description="Create new Wyzebulb workflows between different apps to automate tasks"
      />
      <div className="flex flex-col items-center space-y-4 dark:bg-gray-700 dark:text-white">
        <div className="w-[100%]  border border-gray-100 shadow dark:border-gray-700 dark:bg-gray-800 dark:text-white">
          {/* <h1 className="text-2xl text-gray-700 dark:text-gray-100 leading-7">Create your own Flow</h1> */}
          {/* <IntegrationSearch requestIntegration={() => dispatch(showRequestIntegrationModal)} /> */}
          <FlowBuilder />
        </div>
        <div className="flex w-[100%] flex-col border border-gray-200 p-4 shadow dark:border-gray-700 dark:bg-gray-800 dark:text-white">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-2xl leading-7 text-gray-700 dark:text-gray-100">
              Choose one of our recommended flows
            </h1>
            <FlowsSearch refreshFlowTemplates={refreshFlowTemplates} />
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Flows - Loader */}
            {fetchingFlowTemplates && (
              <div className="absolute bottom-56 z-[200] ml-[41.5%]">
                <WbLoader className="rounded-full" />
              </div>
            )}
            {/* Flows - Not Found */}
            {isEmpty(flowTemplates) && (
              <div className="relative col-span-3 flex w-full flex-col justify-center text-center">
                <i className="iconsmind-Repair text-xxlarge" />
                <h1>No flows found</h1>
                <h3>
                  Don't worry! We can build it for you.{" "}
                  <span role="img" aria-label="happy">
                    😀
                  </span>
                </h3>
                <h3>Click the button below to request a custom flow.</h3>
                &nbsp;
                <div className="flex w-full justify-center">
                  <WbButton
                    onClick={initRequestIntegration}
                    className="w-[15%]"
                  >
                    Request flow
                  </WbButton>
                </div>
              </div>
            )}
            {/* Flows - Found */}
            {!isEmpty(flowTemplates) &&
              flowTemplates.map(
                (flowTemplate, flowIndex) =>
                  flowTemplate.actionStageItems[0].integrationId && (
                    <div className="m-2" key={flowIndex}>
                      <SingleFlowTemplate
                        singleFlowTemplate={flowTemplate}
                        key={flowTemplate._id}
                        index={flowIndex}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
      {/* {pagination}  */}
      {shouldShowRequestIntegrationModal && (
        <RequestIntegration closeModal={stopRequestIntegration} />
      )}
    </>
  );
}
