import {
  LinkIcon,
  PlusCircleIcon,
  BoltIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import FlowTemplateDetails from "./FlowTemplateDetails";

const steps = [
  {
    icon: <LinkIcon className="h-12 w-12 text-orange-500 " />,
    title: "Connect an App",
    text: "Connect your apps and accounts by authorizing the Wyzebulb with required permissions.",
  },
  {
    icon: <PlusCircleIcon className="h-12 w-12 text-orange-500 " />,
    title: "Create a Flow",
    text: "Create workflows by choosing the app and selecting the connected account. Setup the filter as well to automate specific events.",
  },
  {
    icon: <BoltIcon className="h-12 w-12 text-orange-500 " />,
    title: "Test a Flow",
    text: "Test your workflow by triggering them manually in the flow builder. This ensues the connection is done properly.",
  },
  {
    icon: <RocketLaunchIcon className="h-12 w-12 text-orange-500 " />,
    title: "Publish a Flow",
    text: "Publish your workflow and inspect the tasks realtime in your dashboard.",
  },
];

export default function AuthCover() {
  return (
    <div className="bg-slate-950 hidden h-full min-w-[40%] flex-col justify-center border-r border-black/20 bg-orange-50 p-12 lg:flex">
      <FlowTemplateDetails />
      <ul className="pt-8">
        {steps.map((step, index) => {
          let { icon, title, text } = step;

          return (
            <li className="flex gap-2 text-white" key={index}>
              <div className="mt-1.5 flex flex-col items-center gap-2">
                {icon}
                <div className="bg-toxic/50 h-full w-0.5"></div>
              </div>
              <div className="mb-1">
                <h2 className="mb-2 text-2xl font-semibold  text-orange-500">
                  {title}
                </h2>
                <p className="mb-10 font-semibold text-gray-700 dark:text-gray-700">
                  {text}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
