import { useState, useEffect } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { find } from 'lodash';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WbSelect(props) {
  const { data, onInputChange, onChange, label, width = "20rem" } = props;
  const [query, setQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [selectedData, setSelectedData] = useState("")

  useEffect(() => {
    setFilteredData(data);
  }, [data])

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    onInputChange && onInputChange(value);
  }

  const handleOnChange = (value) => {
    setSelectedData(value);
    onChange && onChange(value);
  }

  return (
    <Combobox
      as="div"
      value={selectedData}
      onChange={handleOnChange}
      className={`w-[${width}]`}
    >
      <Combobox.Label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
        {label || "Search"}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-100 bg-white  py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 dark:bg-gray-300 sm:text-sm"
          onChange={handleInputChange}
          displayValue={(selectedData) => {
            const selectedObj = find(data, (d) => d.value === selectedData);
            return selectedObj?.label;
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredData.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredData.map((d, index) => (
              <Combobox.Option
                key={index}
                value={d.value}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          "inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400"
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {d.label}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
