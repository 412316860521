import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

/* Reducers Imports */
import LoggedInLayout from "./layouts/LoggedInLayout/LoggedInLayoutReducer";
import OAuth from "./layouts/OAuth/OAuthReducer";
import App from "./components/App/AppReducer";
import IntegrationCategories from "./components/SelectIntegration/IntegrationCategories/IntegrationCategoriesReducer";
import SelectIntegration from "./components/SelectIntegration/SelectIntegrationReducer";
import Integrations from "./components/Integrations/IntegrationsReducer";
import SelectPlan from "./components/SelectPlan/SelectPlanReducer";

import Accounts from "./modules/Apps/AccountsReducer";
import FlowBuilder from "./modules/FlowBuilder/FlowBuilderReducer";
import FlowLibrary from "./modules/FlowLibrary/FlowLibraryReducer";
import Flows from "./modules/Flows/FlowsReducer";
import Auth from "modules/Auth/AuthReducer";
import ForgotPassword from "modules/Auth/ForgotPassword/ForgotPasswordReducer";
import ResetPassword from "modules/Auth/ResetPassword/ResetPasswordReducer";
import AccountDetails from "modules/Account/Profile/AccountDetailsReducer";
import ManageSubscription from "modules/Account/LegacyBilling/ManageSubscriptionReducer";

/* Sagas Imports */
import { watchAuth } from "modules/Auth/AuthSaga";
import { watchLoggedInLayout } from "./layouts/LoggedInLayout/LoggedInLayoutSaga";
import { watchIntegrationCategories } from "./components/SelectIntegration/IntegrationCategories/IntegrationCategoriesSaga";
import { watchSelectIntegrations } from "./components/SelectIntegration/SelectIntegrationSaga";
import { watchAccounts } from "./modules/Apps/AccountsSaga";
import { watchIntegrations } from "./components/Integrations/IntegrationsSaga";
import { watchOAuthModal } from "./layouts/OAuth/OAuthSaga";
import { watchChangePassword } from "modules/Account/Profile/AccountDetailsSaga";
import { watchForgotPasswordContainer } from "modules/Auth/ForgotPassword/ForgotPasswordSaga";
import { watchResetPasswordContainer } from "modules/Auth/ResetPassword/ResetPasswordSaga";
import { watchDeleteSubscription } from "modules/User/DeleteSubscription/DeleteSubscriptionSaga";
import { watchFlowBuilderContainer } from "./modules/FlowBuilder/FlowBuilderSaga";
import { watchFlows } from "./modules/Flows/FlowsSaga";
import { watchFlowTemplates } from "./modules/FlowLibrary/FlowLibrarySaga";
import { watchSelectPlan } from "./components/SelectPlan/SelectPlanSaga";
import { watchManageSubscription } from "modules/Account/LegacyBilling/ManageSubscriptionSaga";
import { watchImpersonateUser } from "modules/User/AdminControls/ImpersonateUserSaga";

import { composeWithDevTools } from "remote-redux-devtools";

const composeEnhancers = composeWithDevTools({
  realtime: true,
  name: "Elsa Redux",
  hostname: "localhost",
  port: 3000, // the port your remotedev server is running at
});

export function configureStore(initialState: any) {
  const reducers = combineReducers({
    Auth,
    LoggedInLayout,
    IntegrationCategories,
    SelectIntegration,
    Accounts,
    Integrations,
    FlowBuilder,
    ForgotPassword,
    ResetPassword,
    OAuth,
    Flows,
    FlowLibrary,
    SelectPlan,
    ManageSubscription,
    AccountDetails,
    App,
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(watchAuth);
  sagaMiddleware.run(watchLoggedInLayout);
  sagaMiddleware.run(watchIntegrationCategories);
  sagaMiddleware.run(watchSelectIntegrations);
  sagaMiddleware.run(watchAccounts);
  sagaMiddleware.run(watchIntegrations);
  sagaMiddleware.run(watchOAuthModal);
  sagaMiddleware.run(watchForgotPasswordContainer);
  sagaMiddleware.run(watchResetPasswordContainer);
  sagaMiddleware.run(watchDeleteSubscription);
  sagaMiddleware.run(watchFlowBuilderContainer);
  sagaMiddleware.run(watchFlows);
  sagaMiddleware.run(watchFlowTemplates);
  sagaMiddleware.run(watchSelectPlan);
  sagaMiddleware.run(watchManageSubscription);
  sagaMiddleware.run(watchChangePassword);
  sagaMiddleware.run(watchImpersonateUser);

  return store;
}
