import { Outlet } from "react-router-dom";
import WbHeader from "./Header";

interface ILayoutProps {
	title: string | React.ReactElement;
	description?: string | React.ReactNode;
	children?: React.ReactNode;
}

export default function WbLayout({
	title,
	description,
	children,
}: ILayoutProps) {
	return (
		<>
			<WbHeader title={title} description={description}>
				{children}
			</WbHeader>
			<Outlet context={{ title }} />
		</>
	);
}
