import { each, isEmpty } from "lodash";
import { call, put, takeEvery, all } from "redux-saga/effects";

import { asyncPost } from "utils/async_util";
import { SHOW_OAUTH_MODAL } from "./OAuthActionTypes";
import { hideOAuthBackdrop } from "./OAuthActions";

function* showOAuthModalSaga({ payload }) {
  const {
    integration,
    oAuthType,
    reconnectAccountId,
    sideEffectFns,
    refreshAccounts,
  } = payload;
  const { _id: integrationId } = integration;
  const isIntegrationOAuth1 = !isEmpty(integration.oAuthDetails.oAuth1Key);

  try {
    // TODO: Handle errors
    if (isIntegrationOAuth1) {
      const [, res] = yield call(asyncPost, `/oauth1/getAuthUrl`, {
        oAuthType,
        integrationId,
        ...(!isEmpty(reconnectAccountId) && { reconnectAccountId }),
      });
      const x = window.screen.width / 2 - 600 / 2;
      const y = window.screen.height / 2 - 600 / 2;
      window.open(
        res.data.oAuthUrl,
        "Authentication",
        `height=600,width=600,left=${x},top=${y}`
      );
    } else {
      const [, res] = yield call(asyncPost, `/oauth/getAuthUrl`, {
        oAuthType,
        integrationId,
        ...(!isEmpty(reconnectAccountId) && { reconnectAccountId }),
      });
      const x = window.screen.width / 2 - 600 / 2;
      const y = window.screen.height / 2 - 600 / 2;

      const oAuthWindow = window.open(
        res.data.oAuthUrl,
        "Authentication",
        `height=600,width=600,left=${x},top=${y}`
      );
      const timer = setInterval(() => {
        if (oAuthWindow.closed) {
          clearInterval(timer);
          refreshAccounts();
        }
      }, 1000);

      each(sideEffectFns, (eachSideEffectFn) => eachSideEffectFn());
    }
  } catch (err) {
    return yield all([put(hideOAuthBackdrop())]);
  }
}

export function* watchOAuthModal() {
  yield takeEvery(SHOW_OAUTH_MODAL, showOAuthModalSaga);
}
