import { useState } from "react";
import { Link } from "react-router-dom";
import { startCase, isEmpty } from "lodash";
import moment from "moment";

import BaseGrid from "components/BaseGrid";
import Details from "./Details";

export default function DataList(props: any) {
  const [selectedValue, setSelectedValue] = useState<any>({});
  
  const handleClick = (singleLeadId: any) => {
    setSelectedValue(singleLeadId);
  };

  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MMM-YYYY hh:mm:ss A");
    },
    leadFormatter: (cell: any, row: any) => {
      const singleLeadId = cell.getValue();
      return (
        <Link
          to={`details/${singleLeadId}`}
          onClick={() => handleClick(singleLeadId)}
          className="text-orange-600 underline"
        >
          {" "}
          {singleLeadId}
        </Link>
      );
    },
    nameFormatter: (data: any) => {
      const flowName = data.getValue();
      return startCase(flowName);
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "leadId",
      header: "Lead Id",
      formatter: columnFormatter.leadFormatter,
      isQuickFilter: true,
    },
    {
      dataIndex: "formattedData.leadEmail",
      header: "Email",
      isQuickFilter: true,
    },
    {
      dataIndex: "source",
      header: "Source",
      formatter: columnFormatter.nameFormatter,
      isQuickFilter: true,
    },
  ];

  return (
    <>
      {isEmpty(selectedValue) ? (
        <>
          <BaseGrid
            columns={columns}
            gridUrl={"/builtInTools/dataStudio/dataStudioList"}
            method="POST"
          />
        </>
      ) : (
        <div>
          <Details data={selectedValue} />
        </div>
      )}
    </>
  );
}
