import React, { Fragment, PureComponent } from "react";

import { isDataPresent } from "utils/object_util";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";
import DefaultInput from "../DefaultInput";
import RichInputContainer from "../RichInput";
import OptionalRequiredLabel from "../OptionalRequiredLabel";
import { FaTrash } from "react-icons/fa";

class JsonInput extends PureComponent {
    state = {
        shouldResetJsonDetails: false,
    };

    async componentDidUpdate(prevProps) {
        const { selectedValueData: prevSelectedValueData } = prevProps;
        const { selectedValueData: currSelectedValueData, labelText } = this.props;

        if (isDataPresent(prevSelectedValueData) && isDataPresent(currSelectedValueData)) {
            const { jsonDetails: prevJsonDetails } = prevSelectedValueData;
            const { jsonDetails: currJsonDetails } = currSelectedValueData;

            await this.setState({
                shouldResetJsonDetails: prevJsonDetails.length !== currJsonDetails.length,
            });
        }

        if (isDataPresent(currSelectedValueData)) {
            const { jsonDetails } = currSelectedValueData;
            const firstJsonPairKeyName = jsonDetails[0].keyName;
            if (firstJsonPairKeyName === labelText) {
                this.props.userInputHandler({
                    jsonPairIndex: 0,
                    userInput: {
                        value: "",
                    },
                    jsonPairType: JsonPairTypesEnum.KEY,
                    jsonPairValueIndex: 0,
                });
            }
        }
    }

    render() {
        const { staticData, userHelperContent, selectedValueData } = this.props;

        let jsonDetails = [];
        if (isDataPresent(selectedValueData)) {
            jsonDetails = selectedValueData.jsonDetails;
        }
        const jsonDetailsLength = jsonDetails.length;
        const jsonInputFields = jsonDetails.map((eachJsonDetail, index) => {
            const selectedValueDataForJsonKey = isDataPresent(eachJsonDetail)
                ? {
                      jsonDetails: [
                          {
                              valueDetails: [
                                  {
                                      value: eachJsonDetail.keyName,
                                  },
                              ],
                          },
                      ],
                  }
                : null;
            const selectedValueDataForJsonValue = isDataPresent(eachJsonDetail)
                ? {
                      jsonDetails: [
                          {
                              ...eachJsonDetail,
                          },
                      ],
                  }
                : null;

            const isFirstJsonPair = index === 0;
            const shouldShowRemoveBtn = !isFirstJsonPair || jsonDetailsLength > 1;

            return (
                <Fragment key={index}>
                    <div>
                        <div>
                            <div>
                                <form>
                                    <DefaultInput
                                        selectedValueData={selectedValueDataForJsonKey}
                                        userInputHandler={(jsonInputDetails) =>
                                            this.props.userInputHandler({
                                                jsonPairIndex: index,
                                                userInput: {
                                                    ...jsonInputDetails.userInput,
                                                },
                                                jsonPairType: JsonPairTypesEnum.KEY,
                                                jsonPairValueIndex: 0,
                                            })
                                        }
                                        shouldHandleChange={true}
                                        inputType="text"
                                        name="key"
                                        placeholder="key"
                                        required={true}
                                    />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div>
                                <RichInputContainer
                                    selectedValueData={selectedValueDataForJsonValue}
                                    userInputHandler={(jsonInputDetails) =>
                                        this.props.userInputHandler({
                                            jsonPairIndex: index,
                                            userInput: {
                                                ...jsonInputDetails.userInput,
                                            },
                                            jsonPairType: JsonPairTypesEnum.VALUE,
                                            jsonPairValueIndex: 0,
                                        })
                                    }
                                    placeholder="value"
                                    staticData={staticData}
                                />
                            </div>
                        </div>
                        <div>
                            {shouldShowRemoveBtn ? (
                                <div
                                    onClick={() =>
                                        this.props.userInputHandler({
                                            jsonPairIndex: index,
                                            userInput: {
                                                value: "",
                                            },
                                            jsonPairOp: "delete",
                                        })
                                    }
                                >
                                    <FaTrash size={16} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <div
                            onClick={() =>
                                this.props.userInputHandler({
                                    jsonPairIndex: index + 1,
                                    userInput: {
                                        value: "",
                                    },
                                    jsonPairOp: "add",
                                })
                            }
                        >
                            Add new field
                        </div>
                    </div>
                </Fragment>
            );
        });
        return (
            <Fragment>
                <div>
                    <OptionalRequiredLabel {...this.props} />
                </div>
                <div>{userHelperContent}</div>
                {this.state.shouldResetJsonDetails ? null : jsonInputFields}
                <hr />
            </Fragment>
        );
    }
}

export default JsonInput;
