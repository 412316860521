import React, { useState } from "react";
import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { FaUpload } from "react-icons/fa";

import { asyncPostFileSupport } from "utils/async_util";
import WbButton from "components/WbButton";
import Alert from "components/WbAlert";
import LoadSpinner from "components/Loader";

import { fileUpload } from "../../../FlowBuilderActions";

const WyzebulbUpload = (props) => {
    const { stageIndex, closeModal, fileUpload } = props;

    const [uploadedFile, setUploadedFile] = useState({});
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [responseMsg, setResponseMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const { fileName = "", fileHeaders = "" } = get(props, "wbFileInfo.wyzebulbUploadedFile", {});
    const isFilePresent = !isEmpty(fileHeaders);

    /* () => To Download CSV file based on headers */
    const downloadSampleFile = () => {
        const csvDownloadLink = document.createElement("a");
        csvDownloadLink.href = URL.createObjectURL(new Blob([fileHeaders], { type: "data:text/csv;charset=utf-8," }));
        csvDownloadLink.download = `${fileName?.split(".csv")[0]}_sample.csv`;
        document.body.appendChild(csvDownloadLink);
        csvDownloadLink.click();
        document.body.removeChild(csvDownloadLink);
    };

    const submitForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (uploadedFile) {
            formData.append("file", uploadedFile[0]);
            const wyzebulbUploadedFile = get(uploadedFile, "0.name", "");
            setLoading(true);
            const [, res] = await asyncPostFileSupport(`/wyzebulb_upload/upload-file`, formData);
            const status = get(res, "data.success", false);
            setUploadSuccess(status);
            setResponseMsg(get(res, "data.message", ""));
            fileUpload({ stageIndex, status, wyzebulbUploadedFile });
            setLoading(false);
        }
    };

    const handleFileInput = (e) => {
        setUploadedFile(e.currentTarget.files);
    };

    return (
        <div className="w-full">
            <div className="text-center"> Please Upload a CSV file having Headers & atleast one row data</div>
            <div className="mt-3 flex flex-row items-center justify-center">
                <input type="file" accept=".csv" onChange={handleFileInput} />
                <WbButton className="ml-4" onClick={(e) => submitForm(e)} disabled={isEmpty(uploadedFile)}>
                    <FaUpload />
                </WbButton>
            </div>
            <div className="w-100 text-right pr-4 mt-4 flex justify-evenly">
                {loading && <LoadSpinner message={"Please wait while we process your file!"} />}
                {!isEmpty(responseMsg) ? (
                    <Alert color={responseMsg.includes("Success") ? "success" : "failure"}>{responseMsg}</Alert>
                ) : (
                    <>
                        {isFilePresent && (
                            <div className="mt-3 flex flex-col items-center justify-center">
                                <div className="rounded mb-3">
                                    Uploaded File: <span className="font-bold">{fileName}</span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="flex float-right -mb-[30px] mt-4">
                {isFilePresent && (
                    <WbButton color="info" className="mr-4" onClick={() => downloadSampleFile()}>
                        Download Sample File
                    </WbButton>
                )}
                <WbButton onClick={closeModal} disabled={isFilePresent ? false : !uploadSuccess}>
                    {isFilePresent ? "Close" : "Save + Continue"}
                </WbButton>
            </div>
        </div>
    );
};

const mapStateToProps = ({ FlowBuilder }) => {
    const { commonData, flowObj, wbFileInfo } = FlowBuilder;
    return {
        flowObj,
        commonData,
        wbFileInfo,
    };
};

export default connect(mapStateToProps, { fileUpload })(WyzebulbUpload);
