import { useLocation } from "react-router-dom";

import WbRouter from "components/WbRouter";
import WbTabsV2 from "components/WbTabs/TabsV2";
import { useShowTabs } from "components/WbTabs/useTabs";

import { StudioDashboard, DataList, Details } from "./Tabs";
import NoTaskFound from "../../Tasks/NoTaskFound";
import StudioHeader from "./StudioHeader";
import Segments from "./Segments";
import CreateLead from "./ImportLeads/createLead";
import CreateSegment from "./Segments/CreateSegment";
import EditSegment from "./Segments/EditSegment";
import SelectProvider from "./Campaigns/selectProvider";
import SelectSender from "./Senders/SelectSender";
import Senders from "./Senders";

export default function StudioRouter() {
  const { pathname } = useLocation();

  const studioTabs = [
    { name: `Overview` },
    { name: `Leads` },
    { name: `Senders` },
    { name: `Segments` },
    { name: `Templates` },
    { name: `Campaigns` },
  ];

  let { isDetailsPage } = useShowTabs({
    tabs: studioTabs,
  });
  // Validaing if pathName is having Id
  const createPaths = ["leads/new", "segments/new", "senders/new"];
  const createLead = createPaths.some((path) => pathname.includes(path));

  const isPathWithId = /\d/.test(pathname);

  const getRoutes = () => {
    const routes = [
      { path: "overview", element: <StudioDashboard /> },
      {
        path: "leads",
        element: <DataList />,
      },
      {
        path: "senders",
        element: <Senders />,
      },
      {
        path: "senders/new",
        element: <SelectSender />,
      },
      {
        path: "senders/:senderId",
        element: <SelectSender />,
      },
      { path: "segments", element: <Segments /> },
      { path: "segments/new", element: <CreateSegment /> },
      { path: "segments/:segmentId", element: <EditSegment /> },
      {
        path: "leads/details/:leadId",
        element: <Details />,
      },
      {
        path: "leads/new",
        element: <CreateLead />,
      },
      {
        path: "accounts",
        element: <DataList />,
      },
      {
        path: "campaigns",
        element: <SelectProvider />,
      },
    ];

    return <WbRouter routes={routes} />;
  };

  return (
    <>
      {/**Include Date range component here */}
      {!isPathWithId && !createLead && <StudioHeader />}
      {/* This is Only Required Because of the Date Range Component */}
      {!isPathWithId && !createLead ? (
        <>
          <WbTabsV2 tabs={studioTabs} />
          {getRoutes()}
        </>
      ) : (
        <>{isDetailsPage ? getRoutes() : <NoTaskFound />}</>
      )}
    </>
  );
}
