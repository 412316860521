import classNames from "classnames";

const theme = {
    base: "mx-auto w-full space-y-2",
    children: "flex flex-row flex-wrap",
};

export default function WbPageBody(props) {
    const { children, className, ...restProps } = props;

    return (
        <div className={classNames(theme.base, className)} {...restProps}>
            <div className={theme.children}>{children}</div>
        </div>
    );
}
