import moment from "moment"
const QueriedData = ({ queryResponse, count }: any) => {
  return (
    <>
      <h4 className="m-4 mt-5 ">
        Total Leads Under this Segment{" "}
        <span className="font-bold">({count})</span>
      </h4>
      {queryResponse?.map((lead: any) => (
        <>
          <div className="m-2 rounded-md border border-gray-200 bg-white shadow-md hover:cursor-pointer hover:bg-orange-50 dark:border-gray-600 dark:bg-gray-600 dark:hover:bg-slate-700 lg:w-[50%]">
            <div key={lead.rawData.email} className="p-4">
              <p className="text-lg font-medium">
                {lead.rawData.firstName} {lead.rawData.lastName}
              </p>
              <p className="text-sm flex justify-between text-gray-500">{lead.rawData.email}<span>{moment(lead.createdAt).format("LLL")}</span></p>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default QueriedData;
