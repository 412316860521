import { IMPERSONATE_USER, SET_TOKEN_COOKIE_FOR_SELECTED_USER } from "./ImpersonateUserActionTypes";

export const impersonateUser = (subscriptionId) => {
    return {
        type: IMPERSONATE_USER,
        payload: {
            subscriptionId,
        },
    };
};

export const setTokenCookieForSelectedUser = (accessToken) => {
    return {
        type: SET_TOKEN_COOKIE_FOR_SELECTED_USER,
        payload: {
            accessToken,
        },
    };
};
