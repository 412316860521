import { STORE_QUERY_DETAILS } from "./AppActionTypes";

const INIT_STATE = {
	initialPathname: "",
	initialSearchQuery: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
	const { payload } = action;

	switch (action.type) {
		case STORE_QUERY_DETAILS:
			return {
				...state,
				initialPathname: payload.pathname || "",
				initialSearchQuery: payload.search || "",
			};
		default:
			return {
				...state,
			};
	}
};
