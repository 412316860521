import {
    SET_SELECTED_INTEGRATION,
    SET_SUBSCRIPTION_ID,
    SET_SELECTED_EVENT,
    SET_SELECTED_ACTION,
    SET_SELECTED_ACCOUNT,
    SELECT_DYNAMIC_INCOMING_DATA_PREREQUISITE,
    SELECT_DYNAMIC_INCOMING_DATA,
    SELECT_API_DATA,
    SETUP_FILTER,
    SET_FLOW_NAME,
    SAVE_FLOW,
    SAVE_FLOW_SUCCESS,
    ADD_STAGE,
    DELETE_STAGE,
    INIT_STAGES_TO_BE_SHOWN,
    SETUP_DATA_FROM_PREVIOUS_STAGES,
    SEED_FLOW_BUILDER_FOR_VIEW,
    SAVE_FLOW_FAIL,
    GET_INTEGRATION_FOR_FLOW_BUILDER,
    GET_TEST_EVENT_SAMPLE_DATA,
    GET_TEST_EVENT_SAMPLE_DATA_SUCCESS,
    GET_TEST_EVENT_SAMPLE_DATA_ERROR,
    SET_SELECTED_TEST_EVENT_SAMPLE_DATA,
    SEND_TEST_ACTION_DATA,
    GET_GENERATED_WEBHOOK_URL,
    GET_GENERATED_WEBHOOK_URL_SUCCESS,
    GET_GENERATED_WEBHOOK_URL_ERROR,
    INIT_API_DATA_IN_REDUCER,
    SEND_TEST_ACTION_DATA_SUCCESS,
    SEND_TEST_ACTION_DATA_ERROR,
    FETCH_SINGLE_FLOW,
    FETCH_SINGLE_FLOW_SUCCESS,
    FETCH_SINGLE_FLOW_FAIL,
    RESET_FLOW_BUILDER_REDUCER,
    SET_DATA_FORMATTER_OPTION,
    SET_DEFAULT_DYNAMIC_INCOMING_DATA,
    GET_TRIGGER_AND_ACTION_INTEGRATION,
    GET_TRIGGER_AND_ACTION_INTEGRATION_SUCCESS,
    FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES,
    FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES_SUCCESS,
    FILE_UPLOAD,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_SUCCESS,
} from "./FlowBuilderActionTypes";

export const initStagesToBeShown = (stagesToBeShown) => {
    return {
        type: INIT_STAGES_TO_BE_SHOWN,
        payload: {
            stagesToBeShown,
        },
    };
};

export const addStage = (stageIndex, stageType) => {
    return {
        type: ADD_STAGE,
        payload: {
            stageIndex,
            stageType,
        },
    };
};

export const deleteStage = (stageIndex) => {
    return {
        type: DELETE_STAGE,
        payload: {
            stageIndex,
        },
    };
};

export const setSelectedIntegration = (stageIndex, sideEffectData, stageType) => {
    return {
        type: SET_SELECTED_INTEGRATION,
        payload: {
            stageIndex,
            stageType,
            sideEffectData,
        },
    };
};

export const setSubscriptionId = (subscriptionId) => {
    return {
        type: SET_SUBSCRIPTION_ID,
        payload: {
            subscriptionId,
        },
    };
};

export const setSelectedEvent = (stageIndex, event) => {
    return {
        type: SET_SELECTED_EVENT,
        payload: {
            stageIndex,
            event,
        },
    };
};

export const setSelectedAction = (stageIndex, action) => {
    return {
        type: SET_SELECTED_ACTION,
        payload: {
            stageIndex,
            action,
        },
    };
};

export const setSelectedAccount = (stageIndex, account) => {
    return {
        type: SET_SELECTED_ACCOUNT,
        payload: {
            stageIndex,
            account,
        },
    };
};

export const selectDynamicIncomingDataPrerequisite = (stageIndex, prerequisite, jsonCustomInputDetails) => {
    return {
        type: SELECT_DYNAMIC_INCOMING_DATA_PREREQUISITE,
        payload: {
            stageIndex,
            prerequisite,
            jsonCustomInputDetails,
        },
    };
};

export const selectDynamicIncomingData = (stageIndex, dynamicIncomingData) => {
    return {
        type: SELECT_DYNAMIC_INCOMING_DATA,
        payload: {
            stageIndex,
            dynamicIncomingData,
        },
    };
};

export const setDefaultDynamicIncomingData = (stageIndex) => {
    return {
        type: SET_DEFAULT_DYNAMIC_INCOMING_DATA,
        payload: {
            stageIndex,
        },
    };
};

export const setupDataFromPreviousStages = (stageIndex, stageType) => {
    return {
        type: SETUP_DATA_FROM_PREVIOUS_STAGES,
        payload: {
            stageIndex,
            stageType,
        },
    };
};

export const initApiDataInReducer = (stageIndex, apiData, reducerApiData) => {
    return {
        type: INIT_API_DATA_IN_REDUCER,
        payload: {
            stageIndex,
            apiData,
            reducerApiData,
        },
    };
};

export const selectApiData = (stageIndex, apiData, jsonCustomInputDetails) => {
    return {
        type: SELECT_API_DATA,
        payload: {
            stageIndex,
            apiData,
            jsonCustomInputDetails,
        },
    };
};

export const setupFilter = (stageType, stageIndex, filter, filterForUi) => {
    return {
        type: SETUP_FILTER,
        payload: {
            stageType,
            stageIndex,
            filter,
            filterForUi,
        },
    };
};

export const setFlowName = (flowName) => {
    return {
        type: SET_FLOW_NAME,
        payload: {
            flowName,
        },
    };
};

export const saveFlow = (flowObj, extraDetails) => {
    return {
        type: SAVE_FLOW,
        payload: {
            flowObj,
            extraDetails,
        },
    };
};

export const saveFlowSuccess = () => {
    return {
        type: SAVE_FLOW_SUCCESS,
    };
};

export const saveFlowFail = () => {
    return {
        type: SAVE_FLOW_FAIL,
    };
};

export const seedFlowBuilderForView = (flow, flowIntegrations) => {
    return {
        type: SEED_FLOW_BUILDER_FOR_VIEW,
        payload: {
            flow,
            flowIntegrations,
        },
    };
};

export const getSingleIntegration = (integrationId, sideEffectFns) => {
    return {
        type: GET_INTEGRATION_FOR_FLOW_BUILDER,
        payload: {
            integrationId,
            sideEffectFns,
        },
    };
};

export const getTestEventSampleData = (stageIndex, url, bodyParams, incomingData) => {
    return {
        type: GET_TEST_EVENT_SAMPLE_DATA,
        payload: {
            url,
            bodyParams,
            stageIndex,
            incomingData,
        },
    };
};

export const getTestEventSampleDataSuccess = (stageIndex, testEventSampleData) => {
    return {
        type: GET_TEST_EVENT_SAMPLE_DATA_SUCCESS,
        payload: {
            testEventSampleData,
            stageIndex,
        },
    };
};

export const getTestEventSampleDataError = () => {
    return {
        type: GET_TEST_EVENT_SAMPLE_DATA_ERROR,
    };
};

export const setSelectedTestEventSampleData = (stageIndex, selectedTestEventSampleData) => {
    return {
        type: SET_SELECTED_TEST_EVENT_SAMPLE_DATA,
        payload: {
            stageIndex,
            selectedTestEventSampleData,
        },
    };
};

export const getGeneratedWebhookUrl = (stageIndex, flowId, slug) => {
    return {
        type: GET_GENERATED_WEBHOOK_URL,
        payload: {
            stageIndex,
            flowId,
            slug,
        },
    };
};

export const getGeneratedWebhookUrlSuccess = (stageIndex, webhookUrl) => {
    return {
        type: GET_GENERATED_WEBHOOK_URL_SUCCESS,
        payload: {
            stageIndex,
            webhookUrl,
        },
    };
};

export const getGeneratedWebhookUrlError = () => {
    return {
        type: GET_GENERATED_WEBHOOK_URL_ERROR,
    };
};

export const sendTestActionData = (stageIndex, url, bodyParams, apiData, dataFromPreviousStages) => {
    return {
        type: SEND_TEST_ACTION_DATA,
        payload: {
            stageIndex,
            url,
            bodyParams,
            apiData,
            dataFromPreviousStages,
        },
    };
};

export const sendTestActionDataSuccess = (stageIndex, message) => {
    return {
        type: SEND_TEST_ACTION_DATA_SUCCESS,
        payload: {
            stageIndex,
            message,
        },
    };
};

export const sendTestActionDataError = (stageIndex, message) => {
    return {
        type: SEND_TEST_ACTION_DATA_ERROR,
        payload: {
            stageIndex,
            message,
        },
    };
};

export const fetchSingleFlow = (flowId) => {
    return {
        type: FETCH_SINGLE_FLOW,
        payload: {
            flowId,
        },
    };
};

export const fetchSingleFlowSuccess = (flowObj) => {
    return {
        type: FETCH_SINGLE_FLOW_SUCCESS,
        payload: {
            flowObj,
        },
    };
};

export const fetchSingleFlowFail = () => {
    return {
        type: FETCH_SINGLE_FLOW_FAIL,
    };
};

export const resetFlowBuilderReducer = () => {
    return {
        type: RESET_FLOW_BUILDER_REDUCER,
    };
};

export const setDataFormatterOption = (stageIndex, selectedFormatterOption, selectedFormatterSlug, eachApiDataSlug) => {
    return {
        type: SET_DATA_FORMATTER_OPTION,
        payload: {
            stageIndex,
            selectedFormatterOption,
            selectedFormatterSlug,
            eachApiDataSlug,
        },
    };
};

export const getTriggerAndActionIntegration = (triggerIntegrationId, actionIntegrationId) => {
    return {
        type: GET_TRIGGER_AND_ACTION_INTEGRATION,
        payload: {
            triggerIntegrationId,
            actionIntegrationId,
        },
    };
};

export const getTriggerAndActionIntegrationSuccess = (triggerAndActionIntegrationData) => {
    return {
        type: GET_TRIGGER_AND_ACTION_INTEGRATION_SUCCESS,
        payload: {
            triggerAndActionIntegrationData,
        },
    };
};

export const fetchActiveIntegrationsWithSimilarCategories = (categories) => {
    return {
        type: FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES,
        payload: {
            categories,
        },
    };
};

export const fetchActiveIntegrationsWithSimilarCategoriesSuccess = (integrationsWithSimilarCategories) => {
    return {
        type: FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES_SUCCESS,
        payload: {
            integrationsWithSimilarCategories,
        },
    };
};

export const fileUploadSuccess = (stageIndex, wyzebulbUploadedFile = "", wbFileInfo = {}) => {
    return {
        type: FILE_UPLOAD_SUCCESS,
        payload: { stageIndex, wyzebulbUploadedFile, wbFileInfo },
    };
};

export const fileUploadError = () => {
    return {
        type: FILE_UPLOAD_ERROR,
    };
};

export const fileUpload = ({ stageIndex, status, wyzebulbUploadedFile = "", flowId = "" }) => {
    return {
        type: FILE_UPLOAD,
        payload: { stageIndex, status, wyzebulbUploadedFile, flowId },
    };
};
