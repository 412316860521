import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { get } from "lodash";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FiLogOut } from "react-icons/fi";
import {
  ArrowRightOnRectangleIcon,
  ChevronUpDownIcon,
  EyeIcon,
  PowerIcon,
  UserIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";

import Switcher from "components/darkModeSwitch/Switcher";
import { logoutUser } from "modules/Auth/AuthActions";
import { Avatar } from "components/Avatar";

export default function UserProfile() {
  const { subscription } = useSelector((state: any) => state.LoggedInLayout);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userName = get(subscription, "name", "Anonymous");
  const userEmail = get(subscription, "email", "anonymous@email.com");
  const userProfile = get(subscription, "profilePicUrl", null);
  const isUserAdmin = get(subscription, "roles", ["defaultUser"]).includes(
    "admin"
  );

  const menuItems: any = [
    {
      /* DarkMode switch botton */
      icon: <Switcher />,
    },
    {
      text: "Sign out",
      icon: <ArrowRightOnRectangleIcon className="h-4 w-4" />,
      onClick: () => dispatch(logoutUser()),
    },
  ];

  /* Note: Push Admin Menu at 1st position of the index with the user is Admin */
  if (isUserAdmin) {
    menuItems.splice(1, 0, {
      text: "Admin",
      icon: <PowerIcon className="h-4 w-4" />,
      onClick: () => navigate("/nirvahane-controls"),
    });
  }

  return (
    <Menu as="div" className="relative mb-1 inline-block text-left">
      <div>
        <Menu.Button className="focus:ring-offset group w-full rounded-md bg-gray-100 px-2 py-2 text-left text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:focus:ring-gray-600 dark:focus:ring-offset-gray-800 dark:hover:bg-black">
          <span className="flex w-52 items-center justify-between">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <Avatar
                alt="Avatar"
                size="md"
                imageSrc={userProfile}
                className="rounded-full object-contain"
              />
              <span className="flex min-w-0 flex-1 flex-col">
                <span className="truncate text-sm text-gray-900 dark:text-gray-300">
                  {userName}
                </span>
                <span className="truncate text-sm text-gray-500">
                  {userEmail}
                </span>
              </span>
            </span>
            <ChevronUpDownIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bottom-12 right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:bg-gray-900">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300"
                      : "text-gray-700 dark:text-gray-300",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <UserCircleIcon
                    className="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Profile
                </Link>
              )}
            </Menu.Item>
            {isUserAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/nirvahane-controls"
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300"
                        : "text-gray-700 dark:text-gray-300",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <UserIcon
                      className="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Admin
                  </Link>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300"
                      : "text-gray-700 dark:text-gray-300",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <Switcher />
                </div>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300"
                      : "text-gray-700 dark:text-gray-300",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                  onClick={() => dispatch(logoutUser())}
                >
                  <FiLogOut
                    className="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Logout
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
