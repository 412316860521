import { call, takeEvery, put } from "redux-saga/effects";
import { isEmpty, each, find } from "lodash";

import { asyncPost } from "utils/async_util";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";

import {
  SAVE_FLOW,
  GET_INTEGRATION_FOR_FLOW_BUILDER,
  GET_TEST_EVENT_SAMPLE_DATA,
  SEND_TEST_ACTION_DATA,
  GET_GENERATED_WEBHOOK_URL,
  INIT_API_DATA_IN_REDUCER,
  FETCH_SINGLE_FLOW,
  GET_TRIGGER_AND_ACTION_INTEGRATION,
  FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES,
  FILE_UPLOAD,
} from "./FlowBuilderActionTypes";
import {
  selectApiData,
  saveFlowSuccess,
  saveFlowFail,
  getTestEventSampleDataError,
  getTestEventSampleDataSuccess,
  sendTestActionDataError,
  sendTestActionDataSuccess,
  getGeneratedWebhookUrlError,
  getGeneratedWebhookUrlSuccess,
  fetchSingleFlowFail,
  fetchSingleFlowSuccess,
  getTriggerAndActionIntegrationSuccess,
  fetchActiveIntegrationsWithSimilarCategoriesSuccess,
  fileUploadError,
  fileUploadSuccess,
} from "./FlowBuilderActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* initApiDataInReducerSaga({ payload }) {
  const { stageIndex, apiData, reducerApiData } = payload;

  for (let i = 0; i < apiData.length; i++) {
    const eachApiData = apiData[i];
    const isCurrentApiDataAlreadyInReducer = find(reducerApiData, {
      slug: eachApiData.slug,
    });

    if (isCurrentApiDataAlreadyInReducer) continue;

    yield put(
      selectApiData(stageIndex, eachApiData, {
        jsonPairIndex: 0,
        userInput: {
          value: eachApiData.displayText,
          slug: eachApiData.slug,
        },
        jsonPairType: JsonPairTypesEnum.KEY,
        jsonPairValueIndex: 0,
      })
    );

    yield put(
      selectApiData(stageIndex, eachApiData, {
        jsonPairIndex: 0,
        userInput: {
          value: "",
          valueFrom: "userCustomValue",
        },
        jsonPairType: JsonPairTypesEnum.VALUE,
        jsonPairValueIndex: 0,
      })
    );
  }

  return;
}

function* saveFlowSaga({ payload }) {
  const { flowObj, extraDetails } = payload;
  const { _id: flowObjId } = flowObj;

  const isEditingFlow = !isEmpty(flowObjId?.toString());
  const saveFlowUrl = isEditingFlow ? `/flows/updateFlow` : "/flows/createFlow";

  const saveFlowObj = isEditingFlow ? { updateQuery: flowObj } : flowObj;

  try {
    const [err, res] = yield call(asyncPost, saveFlowUrl, {
      ...saveFlowObj,
      extraDetails,
      flowId: flowObjId,
    });

    if (err) {
      return yield put(saveFlowFail());
    }

    if (res.data.succcess === false) {
      showErrorToast(res.data.message);
    }

    showSuccessToast(res?.data?.message);
    return yield put(saveFlowSuccess());
  } catch (err) {
    showErrorToast(err?.message || err);
    return yield put(saveFlowFail());
  }
}

function* getSingleIntegrationSaga({ payload }) {
  const { integrationId, sideEffectFns } = payload;

  try {
    const [err, res] = yield call(asyncPost, `/apps/${integrationId}`);
    if (err) {
      return;
    }

    if (!res.data.success) {
      return;
    }

    each(sideEffectFns, (eachFn) => {
      eachFn({
        integration: res.data.integration,
      });
    });
    return;
  } catch (err) {
    return;
  }
}

function* getGeneratedWebhookUrlSaga({ payload }) {
  const { stageIndex, flowId, slug } = payload;

  try {
    const [err, res] = yield call(asyncPost, "/flows/generateWebhookUrl", {
      flowId,
      slug,
    });

    if (err) {
      return yield put(getGeneratedWebhookUrlError());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(getGeneratedWebhookUrlError());
    }

    return yield put(
      getGeneratedWebhookUrlSuccess(stageIndex, res.data.webhookDataUrl)
    );
  } catch (err) {
    showErrorToast();
    return yield put(getGeneratedWebhookUrlError());
  }
}

function* getTestEventSampleDataSaga({ payload }) {
  const { url, bodyParams, stageIndex, incomingData } = payload;

  try {
    const [err, res] = yield call(asyncPost, url, {
      ...bodyParams,
      incomingData,
    });

    if (err) {
      return yield put(getTestEventSampleDataError());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(getTestEventSampleDataError());
    }

    return yield put(
      getTestEventSampleDataSuccess(stageIndex, res.data.uiData)
    );
  } catch (err) {
    showErrorToast();
    return yield put(getTestEventSampleDataError());
  }
}

function* sendTestActionDataSaga({ payload }) {
  const { stageIndex, url, bodyParams, apiData, dataFromPreviousStages } =
    payload;
  try {
    const [, res] = yield call(asyncPost, url, {
      ...bodyParams,
      apiData,
      dataFromPreviousStages,
    });

    if (!res.data.success) {
      return yield put(sendTestActionDataError(stageIndex, res.data.message));
    }

    return yield put(sendTestActionDataSuccess(stageIndex, res.data.message));
  } catch (err) {
    showErrorToast();
    return yield put(sendTestActionDataError());
  }
}

function* fetchSingleFlowSaga({ payload }) {
  const { flowId } = payload;

  try {
    const [err, res] = yield call(asyncPost, `/flows/getFlow`, {
      flowId,
    });
    if (err) {
      yield put(fetchSingleFlowFail());
      return;
    }

    if (!res.data.success) {
      yield put(fetchSingleFlowFail());
      return;
    }

    return yield put(fetchSingleFlowSuccess(res.data.flow));
  } catch (err) {
    showErrorToast();
    return;
  }
}

function* getTriggerAndActionIntegrationSaga({ payload }) {
  const { triggerIntegrationId, actionIntegrationId } = payload;
  const integrationsArray = [triggerIntegrationId, actionIntegrationId];
  const findQuery = {
    _id: {
      $in: integrationsArray,
    },
  };
  const projectionQuery = {
    isActive: 1,
    categories: 1,
    displayText: 1,
    imageUrl: 1,
  };
  try {
    const [err, res] = yield call(asyncPost, "/apps/listApps", {
      findQuery,
      projectionQuery,
    });
    if (err) {
      return;
    }

    if (!res.data.success) {
      return;
    }
    return yield put(
      getTriggerAndActionIntegrationSuccess(res.data.integrations)
    );
  } catch (err) {
    return;
  }
}

function* fetchActiveIntegrationsWithSimilarCategoriesSaga({ payload }) {
  const { categories } = payload;
  const findQuery = {
    isActive: {
      $eq: true,
    },
    categories: {
      $in: categories,
    },
  };
  const projectionQuery = {
    displayText: 1,
    imageUrl: 1,
  };
  try {
    const [err, res] = yield call(asyncPost, "/apps/listApps", {
      findQuery,
      projectionQuery,
    });
    if (err) {
      return;
    }

    if (!res.data.success) {
      return;
    }
    return yield put(
      fetchActiveIntegrationsWithSimilarCategoriesSuccess(res.data.integrations)
    );
  } catch (err) {
    return;
  }
}

function* fileUploadSaga({ payload }) {
  const { stageIndex, status, wyzebulbUploadedFile = "", flowId } = payload;

  try {
    if (!status) {
      return yield put(fileUploadError());
    }

    if (!isEmpty(flowId)) {
      const [, res] = yield call(
        asyncPost,
        `/wyzebulb_upload/upload-file/${flowId}`,
        {}
      );
      return yield put(
        fileUploadSuccess(
          stageIndex,
          res.data.wyzebulbUploadedFile || "",
          res.data || {}
        )
      );
    }

    return yield put(fileUploadSuccess(stageIndex, wyzebulbUploadedFile, {}));
  } catch (err) {
    showErrorToast();
    return yield put(fileUploadError());
  }
}

export function* watchFlowBuilderContainer() {
  yield takeEvery(INIT_API_DATA_IN_REDUCER, initApiDataInReducerSaga);
  yield takeEvery(SAVE_FLOW, saveFlowSaga);
  yield takeEvery(GET_INTEGRATION_FOR_FLOW_BUILDER, getSingleIntegrationSaga);
  yield takeEvery(GET_TEST_EVENT_SAMPLE_DATA, getTestEventSampleDataSaga);
  yield takeEvery(SEND_TEST_ACTION_DATA, sendTestActionDataSaga);
  yield takeEvery(GET_GENERATED_WEBHOOK_URL, getGeneratedWebhookUrlSaga);
  yield takeEvery(FETCH_SINGLE_FLOW, fetchSingleFlowSaga);
  yield takeEvery(
    GET_TRIGGER_AND_ACTION_INTEGRATION,
    getTriggerAndActionIntegrationSaga
  );
  yield takeEvery(
    FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES,
    fetchActiveIntegrationsWithSimilarCategoriesSaga
  );
  yield takeEvery(FILE_UPLOAD, fileUploadSaga);
}
