import React from "react";

const OptionalRequiredLabel = (props) => {
    return (
        <label htmlFor={props.id} className="mb-1">
            <h6 className="font-semibold mb-0">
                {props.labelText}{" "}
                {props.showRequiredOrOptional ? (
                    props.isRequired ? (
                        <span className="text-red-500 text-base">(required)</span>
                    ) : (
                        <span className="text-gray-500 text-base">(optional)</span>
                    )
                ) : null}
            </h6>
        </label>
    );
};

export default OptionalRequiredLabel;
