import {
  Block,
  Color,
  Flex,
  Icon,
  Metric,
  Text,
  Title,
  Toggle,
  ToggleItem,
} from "@tremor/react";
import { startCase } from "lodash";

interface IKpiCard {
  card: {
    title: string;
    metric: string;
    icon: React.ElementType<any>;
    color: Color;
  };
}

interface IChartTitleBlock {
  title: string;
  count?: number;
  type?: string;
  subtitle?: boolean;
}

interface IChartToggleOptions {
  color: Color;
  items: string[];
  defaultIndex: number;
  handleSelect: (s: string) => void;
}

export function KpiCard({ card }: IKpiCard) {
  return (
    <div key={card.title} className="border p-4 rounded-md">
      <Flex justifyContent="justify-start" spaceX="space-x-4" key={card.title}>
        <Icon icon={card.icon} variant="light" size="lg" color={card.color} />
        <Block truncate={true}>
          <Text>{card.title}</Text>
          <Metric truncate={true}>{card.metric}</Metric>
        </Block>
      </Flex>
    </div>
  );
}

export function ChartTitleBlock({ title }: IChartTitleBlock) {
  return (
    <Title>
      <span className="capitalize pl-2">{title}</span> Summary
    </Title>
  );
}

export function ChartToggleOptions({
  color,
  items,
  defaultIndex,
  handleSelect,
}: IChartToggleOptions) {
  return (
    <div className="">
      <Toggle
        color={color}
        defaultValue={items[defaultIndex]}
        handleSelect={(s) => handleSelect(s)}
      >
        {items.map((item, index) => (
          <ToggleItem key={index} value={item} text={startCase(item)} />
        ))}
      </Toggle>
    </div>
  );
}
