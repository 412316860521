import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import uuid from "uuid/v1";

import { isDataPresent } from "utils/object_util";
import { loadDataFromUrl } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import OptionalRequiredLabel from "../OptionalRequiredLabel";
import DraftJsEditorContainer from "../DraftJsEditor";

class RichTextarea extends Component {
    state = {
        shouldDisplayOptions: false,
        optionsData: [],
        selectedOption: null,
        selectedOptionUniqueId: null,
        areOptionsShown: false,
    };

    toggleOptionsDisplay = () => {
        this.setState((prevState) => {
            return {
                shouldDisplayOptions: !prevState.shouldDisplayOptions,
            };
        });
    };

    toggleClickHandler = () => {
        this.setState((prevState) => {
            return {
                areOptionsShown: !prevState.areOptionsShown,
            };
        });
        const { dynamicDataLoadOptions, staticData } = this.props;

        let newOptionsData = [];
        if (isDataPresent(staticData)) {
            newOptionsData = [...newOptionsData, ...staticData];
            this.setState({
                optionsData: newOptionsData,
            });
        }

        if (isDataPresent(dynamicDataLoadOptions)) {
            const { url, body } = dynamicDataLoadOptions;

            const setUrlLoadedData = (urlLoadedData) => {
                const urlLoadedDataWithValueFrom = _.map(urlLoadedData, (eachUrlLoadedData) => {
                    return {
                        ...eachUrlLoadedData,
                        valueFrom: "dataLoadUrl",
                    };
                });
                newOptionsData = [...newOptionsData, ...urlLoadedDataWithValueFrom];
                this.setState({
                    optionsData: newOptionsData,
                });
            };
            this.props.loadDataFromUrl(url, body, [setUrlLoadedData]);
        }
        this.toggleOptionsDisplay();
    };

    optionSelectHandler = async (event, selectedOption) => {
        event.preventDefault();

        this.toggleOptionsDisplay();

        this.setState({
            selectedOption,
            selectedOptionUniqueId: uuid(),
        });
    };

    render() {
        const { isLoadingDataFromUrl, userHelperContent, selectedValueData } = this.props;

        let optionsToShow = null;
        if (isLoadingDataFromUrl) {
            optionsToShow = <div>Loading choices...</div>;
        } else if (!isDataPresent(this.state.optionsData)) {
            optionsToShow = (
                <div className="text-red-600 text-sm">
                    No choices found; either the choices are empty or there was an error. Please reach out to us at support@wyzebulb.com or our
                    support chat if there's an error.
                </div>
            );
        } else {
            optionsToShow = _.map(this.state.optionsData, (eachOption, index) => {
                return (
                    <div
                        className="p-2 hover:bg-orange-600 hover:text-white text-sm border-1 border-gray-500"
                        key={index}
                        onMouseDown={(event) =>
                            this.optionSelectHandler(event, {
                                value: eachOption.value,
                                displayText: eachOption.displayText,
                            })
                        }
                    >
                        {eachOption.displayText}
                    </div>
                );
            });
        }

        let optionsDiv = null;
        if (this.state.shouldDisplayOptions) {
            optionsDiv = <div>{optionsToShow}</div>;
        }

        return (
            <Fragment>
                <div>
                    <OptionalRequiredLabel {...this.props} />
                </div>
                <div>{userHelperContent}</div>
                <div>
                    <DraftJsEditorContainer
                        toggleClickHandler={this.toggleClickHandler}
                        selectedOption={this.state.selectedOption}
                        selectedOptionUniqueId={this.state.selectedOptionUniqueId}
                        shouldStopReturn={false}
                        userInputHandler={this.props.userInputHandler}
                        selectedValueData={selectedValueData}
                        placeholder={`Enter ${this.props.labelText}`}
                    />
                </div>
                {optionsDiv}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ LoggedInLayout }) => {
    const { isLoadingDataFromUrl } = LoggedInLayout;

    return {
        isLoadingDataFromUrl,
    };
};

export default connect(mapStateToProps, {
    loadDataFromUrl,
})(RichTextarea);
