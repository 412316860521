import { Component } from "react";
import { connect } from "react-redux";

import {
  getHelperTextHtmlJsxFromParts,
  isDataPresent,
} from "utils/object_util";

import WbIntegrationImage from "components/WbIntegrationImage";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";

import { setSelectedAction } from "../../FlowBuilderActions";

class SelectActionEventModal extends Component {
  state = {
    selectedAction: null,
    reducerSelectedActionSlug: "",
  };

  componentWillMount() {
    const { actions, reducerSelectedAction } = this.props;
    const [firstAction] = actions;
    const reducerSelectedActionSlug = isDataPresent(reducerSelectedAction)
      ? reducerSelectedAction.selectedActionSlug
      : "";

    this.setState({
      selectedAction: firstAction,
      reducerSelectedActionSlug,
    });
  }

  changeActionHandler = (action) => {
    this.setState({
      selectedAction: action,
      reducerSelectedActionSlug: "",
    });
  };

  finishActionSelect = () => {
    this.props.setSelectedAction(
      this.props.stageIndex,
      this.state.selectedAction
    );
    this.props.closeModal();
  };

  render() {
    const { actions, integrationDisplayText, userHelper, slug } = this.props;
    const { helperTextHtmlParts } = userHelper;
    const helperTextHtmlJsx =
      getHelperTextHtmlJsxFromParts(helperTextHtmlParts);

    return (
      <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
        <WbModalTitle
          title="Select Action Event"
          toggle={this.props.closeModal}
        />
        <div className="flex flex-col items-center space-y-5 p-4">
          <WbIntegrationImage
            slug={slug}
            altText={integrationDisplayText}
            avtarSize="80"
          />
          <h1
            className="text-xl"
            dangerouslySetInnerHTML={{
              __html: helperTextHtmlJsx,
            }}
          ></h1>
          {actions.map((action) => {
            const stringifiedActionId = action._id.toString();
            const actionSlug = action.slug;
            const isCurrentActionSelectedInReducer =
              actionSlug === this.state.reducerSelectedActionSlug;
            const isCurrentActionSelectedInSelf =
              stringifiedActionId === this.state.selectedAction._id.toString();
            const isCurrentActionSelected =
              isCurrentActionSelectedInReducer || isCurrentActionSelectedInSelf;

            return (
              <div
                key={action._id}
                className="flex w-[80%] cursor-pointer flex-row items-center space-x-3 bg-gray-100 p-4 dark:bg-gray-700"
                onClick={() => this.changeActionHandler(action)}
              >
                <input
                  type="radio"
                  id={action.slug}
                  name="select-action-radio"
                  checked={isCurrentActionSelected}
                  onChange={() => this.changeActionHandler(action)}
                />
                <div className="text-lg">{action.displayText}</div>
              </div>
            );
          })}
        </div>
        <div className="float-right p-4">
          <WbButton onClick={this.finishActionSelect}>Save + Continue</WbButton>
        </div>
      </WbModal>
    );
  }
}

export default connect(null, {
  setSelectedAction,
})(SelectActionEventModal);
