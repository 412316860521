import moment from "moment";

export default function SegmentNameAndDateSection({
  name,
  date,
  segmentId,
}: {
  name: string;
  date: string;
  segmentId: string;
}) {
  return (
    <div className="flex flex-col pl-2">
      <span className="text-sm dark:text-slate-100">{name}</span>
      <span className="text-sm text-slate-500 dark:text-slate-400">
        ({segmentId})
      </span>
      <span className="text-sm text-gray-400 dark:text-slate-200">
        <div className="flex items-center py-0.5">
          {moment(date).format("lll") || ""}
        </div>
      </span>
    </div>
  );
}