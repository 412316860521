import classNames from "classnames";

const theme = {
  base: "text-gray-700 flex items-center text-base leading-5 p-4 border-b-[3px] border-gray-100 dark:bg-gray-800 dark:text-white",
  children: "flex w-full items-center justify-between",
};

export default function WbPageHeader(props) {
  const { children, className, path, ...restProps } = props;

  return (
    <div className={classNames(theme.base, className)} {...restProps}>
      <div className={theme.children}>{children}</div>
    </div>
  );
}
