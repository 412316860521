import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isDataPresent } from "utils/object_util";
import { getSingleIntegration } from "components/Integrations/IntegrationsActions";
import { addNewAccount } from "../AccountsActions";
import SetupAccount from "./SetupAccount";
import WbModal, { WbModalTitle } from "components/WbModal";

export default function AddAccountForIntegration({ addIntegrationId, closeModal }:any) {
    const dispatch = useDispatch();
    const { subscription } = useSelector((state:any) => state.LoggedInLayout);
    const { singleIntegration: addIntegration } = useSelector((state:any) => state.Integrations);
    const subscriptionId = subscription?._id?.toString();

    useEffect(() => {
        dispatch(getSingleIntegration(addIntegrationId));
    }, [addIntegrationId]);

    const addAccountHandler = (apiKeysFormData:any, accountName:any) => {
        dispatch(
            addNewAccount(subscriptionId, {
                apiKeys: apiKeysFormData,
                accountName,
                integrationId: addIntegration._id.toString(),
                integrationSlug: addIntegration.slug,
            }),
        );
    };

    return (
        <WbModal toggle={closeModal} isOpen={true} size="lg">
            <WbModalTitle toggle={closeModal} title="Add Account"></WbModalTitle>
            <div>
                {isDataPresent(addIntegration) && (
                    <SetupAccount saveAccountHandler={addAccountHandler} integration={addIntegration} setupType="add" />
                )}
            </div>
        </WbModal>
    );
}
