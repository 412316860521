import { STORE_QUERY_DETAILS } from "./AppActionTypes";

export const storeQueryDetails = (pathname, search) => {
	return {
		type: STORE_QUERY_DETAILS,
		payload: {
			pathname,
			search,
		},
	};
};
