import {
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILURE,
	CHECK_REGISTRATION_SOURCE,
	CHECK_REGISTRATION_SOURCE_SUCCESS,
	CHECK_REGISTRATION_SOURCE_FAILURE,
	ADD_PASSWORD,
	ADD_PASSWORD_SUCCESS,
	ADD_PASSWORD_FAILURE,
} from "./AccountDetailsActionTypes";

export const changePassword = (password, sideEffectFunctions) => {
	return {
		type: CHANGE_PASSWORD,
		payload: {
			password,
			sideEffectFunctions,
		},
	};
};

export const changePasswordSuccess = (sideEffectFunctions) => {
	return {
		type: CHANGE_PASSWORD_SUCCESS,
		payload: {
			sideEffectFunctions,
		},
	};
};

export const changePasswordFailure = () => {
	return {
		type: CHANGE_PASSWORD_FAILURE,
	};
};

export const checkRegistrationSource = () => {
	return {
		type: CHECK_REGISTRATION_SOURCE,
	};
};

export const checkRegistrationSourceSuccess = (registrationSources) => {
	return {
		type: CHECK_REGISTRATION_SOURCE_SUCCESS,
		payload: {
			registrationSources,
		},
	};
};

export const checkRegistrationSourceFailure = () => {
	return {
		type: CHECK_REGISTRATION_SOURCE_FAILURE,
	};
};

export const addPassword = (password, sideEffectFunctions) => {
	return {
		type: ADD_PASSWORD,
		payload: {
			password,
			sideEffectFunctions,
		},
	};
};

export const addPasswordSuccess = (sideEffectFunctions) => {
	return {
		type: ADD_PASSWORD_SUCCESS,
		payload: {
			sideEffectFunctions,
		},
	};
};

export const addPasswordFailure = () => {
	return {
		type: ADD_PASSWORD_FAILURE,
	};
};
