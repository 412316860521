import {
	FETCH_FLOW_TEMPLATES,
	FETCH_FLOW_TEMPLATES_SUCCESS,
	FETCH_FLOW_TEMPLATES_FAIL,
	FLOW_FETCH_INTEGRATIONS,
	FLOW_FETCH_INTEGRATIONS_SUCCESS,
	FLOW_FETCH_INTEGRATIONS_FAIL,
} from "./FlowLibraryActionTypes";

const INIT_STATE = {
	fetchingFlowTemplates: false,
	flowTemplates: null,
	fetchingIntegrations: false,
	integrations: null,
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;

	switch (action.type) {
		case FETCH_FLOW_TEMPLATES:
			return {
				...state,
				fetchingFlowTemplates: true,
			};
		case FETCH_FLOW_TEMPLATES_SUCCESS:
			return {
				...state,
				fetchingFlowTemplates: false,
				flowTemplates: payload.flowTemplates,
			};
		case FETCH_FLOW_TEMPLATES_FAIL:
			return {
				...state,
				fetchingFlowTemplates: false,
				flowTemplates: null,
			};
		case FLOW_FETCH_INTEGRATIONS:
			return {
				...state,
				fetchingIntegrations: true,
			};
		case FLOW_FETCH_INTEGRATIONS_SUCCESS:
			return {
				...state,
				fetchingIntegrations: false,
				integrations: payload.integrations,
			};
		case FLOW_FETCH_INTEGRATIONS_FAIL:
			return {
				...state,
				fetchingIntegrations: false,
				integrations: null,
			};
		default:
			return { ...state };
	}
};
