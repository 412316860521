import { Fragment } from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

export function WbModalTitle(props) {
  const { title, toggle } = props;

  return (
    <>
      <Dialog.Title className="mx-2 flex flex-row items-center justify-between p-4">
        <div className="text-lg font-medium leading-6 text-gray-600 dark:text-white">
          {title}
        </div>
        <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={toggle} />
      </Dialog.Title>
      <div className="w-full border-b-2 dark:border-gray-700"></div>
    </>
  );
}

export default function WbModal(props) {
  const { toggle, isOpen, size = "md", className, title="" } = props;

  const theme = {
    base: "z-0 transform overflow-hidden rounded-2xl bg-gray-50 text-left align-middle shadow-xl transition-all dark:bg-gray-800 dark:text-white",
    size: {
      max: "min-w-[100%]",
      xl: "min-w-[1500px]",
      lg: "min-w-[1000px]",
      md: "min-w-[750px]",
      sm: "min-w-[500px]",
    },
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={toggle}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 dark:bg-gray-100 dark:bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${classNames(
                    theme.base,
                    `${size}:${theme.size[size]}`,
                    "min-h-[200px]",
                    className
                  )}
                  `}
                >
                  {title && <WbModalTitle title={title} toggle={toggle} />}
                  {props.children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
