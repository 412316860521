import { Link } from "react-router-dom";

function WbNotFound(props: INotFoundProps) {
  const { title, message, path, linkLabel, children } = props;

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col items-center space-y-5 rounded-xl bg-white p-10 dark:bg-gray-700 dark:text-white">
        <i className="iconsmind-Coconut text-xxxlarge text-5xl" />
        <h1 className="text-2xl font-medium">{title}</h1>
        <p className="text-md text-gray-500 dark:text-gray-300">{message}</p>
        {children}
        <div>
          <Link
            to={path ?? "/"}
            className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {linkLabel ?? "Get Started"}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WbNotFound;
