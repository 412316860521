import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { kebabCase } from "lodash";

export function useShowTabs({ tabs, condition }: IUseTabs) {
  const [showTabs, setShowTabs] = useState(true);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const { pathname } = useLocation();

  const showTabsHandler = useCallback(() => {
    const pathArr = pathname.split("/");
    const endPath = (pathArr.length > 2 && pathArr.at(-1)) || "";

    // Verifying whether the path is root or nested if nested path then it exists on tabs or not
    if (endPath !== "") {
      const showTabs =
        tabs.filter(({ name }) => endPath.includes(kebabCase(name))).length > 0;
      setShowTabs(showTabs);
      setIsDetailsPage(!showTabs);
    } else {
      setShowTabs(true);
      setIsDetailsPage(false);
    }

    if (condition === false) {
      // custom conditions if required
      setShowTabs(condition);
      setIsDetailsPage(false);
    }
  }, [condition, pathname, tabs]);

  useEffect(() => {
    showTabsHandler();
  }, [showTabsHandler]);

  return { showTabs, isDetailsPage };
}
