import React, { Fragment } from "react";
import DateFormatterContainer from "./DateFormatterContainer";

import { isDataPresent } from "utils/object_util";
import UserHelper from "components/CustomInput/UserHelper";

const DataFormatterContainer = (props) => {
    let dataFormatterComponent = null;

    let userHelperContent = null;
    const { userHelper, stageIndex } = props;

    if (isDataPresent(userHelper)) {
        userHelperContent = <UserHelper userHelper={userHelper} stageIndex={stageIndex} />;
    }

    switch (props.dataFormatterType) {
        case "date.formatter":
            dataFormatterComponent = <DateFormatterContainer {...props} userHelperContent={userHelperContent} />;
            break;
        default:
            dataFormatterComponent = null;
    }
    return <Fragment>{dataFormatterComponent}</Fragment>;
};

export default DataFormatterContainer;
