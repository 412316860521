import { call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_REGISTRATION_SOURCE,
  ADD_PASSWORD,
  ADD_PASSWORD_SUCCESS,
} from "./AccountDetailsActionTypes";
import {
  changePasswordSuccess,
  changePasswordFailure,
  checkRegistrationSourceSuccess,
  checkRegistrationSourceFailure,
  addPasswordSuccess,
  addPasswordFailure,
} from "./AccountDetailsActions";
import { asyncPost } from "utils/async_util";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* changePasswordSaga({ payload }) {
  const { password, sideEffectFunctions } = payload;
  try {
    const [err, res] = yield call(
      asyncPost,
      "/account/password/changePassword",
      password
    );

    if (err) {
      return yield put(changePasswordFailure());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(changePasswordFailure());
    }

    showSuccessToast(res?.data?.message || "Password has been changed");
    return yield put(changePasswordSuccess(sideEffectFunctions));
  } catch (err) {
    return yield put(changePasswordFailure());
  }
}

function* checkRegistrationSourceSaga() {
  try {
    const [err, res] = yield call(
      asyncPost,
      "/customer/getRegistrationSource"
    );

    if (err) {
      return yield put(checkRegistrationSourceFailure());
    }

    if (!res.data.success) {
      return yield put(checkRegistrationSourceFailure());
    }

    return yield put(
      checkRegistrationSourceSuccess(res.data.registrationSources)
    );
  } catch (err) {
    return yield put(checkRegistrationSourceFailure());
  }
}

function changePasswordSuccessSaga({ payload }) {
  const { sideEffectFunctions } = payload;
  _.each(sideEffectFunctions, (eachSideEffectFunction) => {
    eachSideEffectFunction(payload);
  });
}

function* addPasswordSaga({ payload }) {
  const { password, sideEffectFunctions } = payload;
  try {
    const [err, res] = yield call(asyncPost, "account/password/addPassword", {
      password,
    });

    if (err) {
      return yield put(addPasswordFailure());
    }

    if (!res.data.success) {
      showErrorToast(res.data.message);
      return yield put(addPasswordFailure());
    }

    showSuccessToast(res?.data?.message);
    return yield put(addPasswordSuccess(sideEffectFunctions));
  } catch (err) {
    showErrorToast();
    return yield put(addPasswordFailure());
  }
}

function addPasswordSuccessSaga({ payload }) {
  const { sideEffectFunctions } = payload;
  _.each(sideEffectFunctions, (eachSideEffectFunction) => {
    eachSideEffectFunction(payload);
  });
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(CHECK_REGISTRATION_SOURCE, checkRegistrationSourceSaga);
  yield takeEvery(CHANGE_PASSWORD_SUCCESS, changePasswordSuccessSaga);
  yield takeEvery(ADD_PASSWORD, addPasswordSaga);
  yield takeEvery(ADD_PASSWORD_SUCCESS, addPasswordSuccessSaga);
}
