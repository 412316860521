import {
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILURE,
	CHECK_REGISTRATION_SOURCE,
	CHECK_REGISTRATION_SOURCE_SUCCESS,
	CHECK_REGISTRATION_SOURCE_FAILURE,
	ADD_PASSWORD,
	ADD_PASSWORD_SUCCESS,
	ADD_PASSWORD_FAILURE,
} from "./AccountDetailsActionTypes";

const INIT_STATE = {
	isChangingPassword: false,
	registrationSources: null,
	isAddingPassword: false,
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;

	switch (action.type) {
		case CHANGE_PASSWORD:
			return {
				...state,
				isChangingPassword: true,
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				isChangingPassword: false,
			};
		case CHANGE_PASSWORD_FAILURE:
			return {
				...state,
				isChangingPassword: false,
			};
		case CHECK_REGISTRATION_SOURCE:
			return {
				...state,
			};
		case CHECK_REGISTRATION_SOURCE_SUCCESS:
			return {
				...state,
				registrationSources: payload.registrationSources,
			};
		case CHECK_REGISTRATION_SOURCE_FAILURE:
			return {
				...state,
				registrationSources: null,
			};
		case ADD_PASSWORD:
			return {
				...state,
				isAddingPassword: true,
			};
		case ADD_PASSWORD_SUCCESS:
		case ADD_PASSWORD_FAILURE:
			return {
				...state,
				isAddingPassword: false,
			};
		default:
			return { ...state };
	}
};
