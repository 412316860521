import { startCase } from "lodash";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface flow {
  flowName: string;
  flowId: any;
}

const ConnectedFlow = ({ flowName, flowId }: flow) => {
  const navigate = useNavigate();
  return (
    <>
      {flowName && (
        <div>
          <h1 className="mx-4 text-xl font-semibold">Connected Flow</h1>
          <div className="m-4 flex">
            {startCase(flowName)}
            <BsBoxArrowUpRight
              size={18}
              className={"mx-1 mb-1 cursor-pointer text-orange-600"}
              onClick={() =>
                navigate(`/flow-builder?from=view&flowId=${flowId}`)
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConnectedFlow;
