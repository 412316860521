import React, { memo, useEffect, useState } from "react";
import { each, reduce, map, isEmpty } from "lodash";

import ElementTypesEnum from "enums/ElementTypesEnum";
import DataFormatterContainer from "./DataFormatterContainer";
import CustomInput from "components/CustomInput";

const ApiData = (props) => {
    const { apiData, commonData, stageIndex, dataFromPreviousStages, userHelper, reducerSelectedApiData } = props;
    const { dataLoadOptions, slug, displayText, isMandatory, elementType, inputType, dataFormattingOptions } = apiData;
    const { dataLoadUrlDetails, predefinedValues, shouldLoadDataFromPreviousStages } = dataLoadOptions;
    const currentStageCommonData = commonData[stageIndex];

    const [reload, setReload] = useState(false);

    // Updating the Body Params When Values Changes
    useEffect(() => {
        setReload(true);
    }, [currentStageCommonData]);

    let dataLoadUrlFilledDetails;
    if (!isEmpty(dataLoadUrlDetails)) {
        const { url, bodyParamsRequired } = dataLoadUrlDetails;
        const bodyParams = {};

        each(bodyParamsRequired, (eachBodyParamRequired) => {
            bodyParams[eachBodyParamRequired] = currentStageCommonData[eachBodyParamRequired];
        });

        dataLoadUrlFilledDetails = {
            url: `${window.wyzebulbApiBaseUrl}/${url}`,
            body: bodyParams,
        };
    }

    let formattedDataFromPreviousStages = [];
    if (shouldLoadDataFromPreviousStages) {
        formattedDataFromPreviousStages = reduce(
            dataFromPreviousStages,
            (accumulator, eachDataFromPreviousStage) => {
                const { stageIndex: previousStageIndex, stageData: previousStageData } = eachDataFromPreviousStage;

                const formattedPreviousStageData = map(previousStageData, (eachData) => ({
                    ...eachData,
                    displayText: `From Step ${previousStageIndex + 1} - ${eachData.displayText}`,
                    value: eachData.slug,
                    valueFrom: "previousStageDataSlug",
                }));

                return [...accumulator, ...formattedPreviousStageData];
            },
            [],
        );
    }

    const formattedPredefinedValues = map(predefinedValues, (eachPredefinedValue) => {
        return {
            ...eachPredefinedValue,
            valueFrom: "predefinedValues",
        };
    });

    const staticData = [...formattedDataFromPreviousStages, ...formattedPredefinedValues];

    return (
        reload && (
            <>
                {elementType === ElementTypesEnum.INPUT ? (
                    <form>
                        <CustomInput
                            name={slug}
                            dynamicDataLoadOptions={dataLoadUrlFilledDetails}
                            staticData={staticData}
                            userInputHandler={(selectedOption) => props.onSelectApiData(selectedOption)}
                            id={slug}
                            labelText={displayText}
                            showRequiredOrOptional={true}
                            isRequired={isMandatory}
                            userHelper={userHelper}
                            stageIndex={stageIndex}
                            selectedValueData={reducerSelectedApiData}
                            elementType={elementType}
                            inputType={inputType}
                        />
                    </form>
                ) : (
                    <>
                        <CustomInput
                            name={slug}
                            dynamicDataLoadOptions={dataLoadUrlFilledDetails}
                            staticData={staticData}
                            userInputHandler={(selectedOption, jsonDetailsOps) => props.onSelectApiData(selectedOption, jsonDetailsOps)}
                            id={slug}
                            labelText={displayText}
                            showRequiredOrOptional={true}
                            isRequired={isMandatory}
                            userHelper={userHelper}
                            stageIndex={stageIndex}
                            selectedValueData={reducerSelectedApiData}
                            elementType={elementType}
                            inputType={inputType}
                        />
                        {!isEmpty(dataFormattingOptions) ? (
                            <DataFormatterContainer
                                stageIndex={stageIndex}
                                dataFormattingOptions={dataFormattingOptions}
                                dataFormatterType={dataFormattingOptions.formatterSlug}
                                {...(!isEmpty(reducerSelectedApiData) && {
                                    selectedValueData: reducerSelectedApiData.selectedFormatterOptions,
                                })}
                                dataFormatHandler={(selectedFormatterOptionObj, selectedFormatterSlug) =>
                                    props.dataFormatHandler(selectedFormatterOptionObj, selectedFormatterSlug)
                                }
                            />
                        ) : null}
                    </>
                )}
            </>
        )
    );
};

export default memo(ApiData);
