import { Squares2X2Icon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useState } from "react";
import { get, startCase } from "lodash";

import callApi from "utils/callApi";
import { KpiCard } from "modules/Tasks/Tabs/common/ChartModules";
import WbLoader from "components/WbLoader";

export default function Summary(props: any) {
  /* Summary State variables */
  const [summary, setSummary] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response: any = await callApi({
        url: "/builtInTools/dataStudio/dataStudioSummary",
        method: "POST",
        body: {
          findQuery: {},
          projection: {
            flowFormattedName: 1,
            email: 1,
            createdAt: 1,
            updatedAt: 1,
          },
          sort: { updatedAt: -1 },
          limit: 5,
        },
      });

      setSummary(get(response, "crmKpiData", []));
      setIsLoading(false);
    } catch (error: any) {}
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const newData = summary?.map((summaryData: any) => {
    return {
      title: startCase(summaryData._id),
      metric: summaryData.count,
      icon: Squares2X2Icon,
      color: "gray",
    };
  });

  return (
    <>
      {isLoading ? (
        <WbLoader />
      ) : (
        <div className="mx-auto mb-2 grid w-full grid-cols-5 place-items-center">
          {newData.map((card: any, idx: number) => (
            <div className="w-[270px] p-2" key={idx}>
              <KpiCard card={card} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}
