import React, { Fragment, Component } from "react";
import { Editor, EditorState, ContentState, Modifier, convertToRaw, ContentBlock, convertFromRaw, SelectionState } from "draft-js";
import _ from "lodash";

import { isDataPresent } from "utils/object_util";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";

class DraftJsEditor extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        isToggleOpen: false,
    };

    async componentDidMount() {
        const { selectedValueData } = this.props;

        if (!isDataPresent(selectedValueData)) return;

        const selectedValue = selectedValueData.jsonDetails[0].valueDetails[0];

        const { extraDetails, mergeFields, value } = selectedValue;

        if (!isDataPresent(value)) return;

        if (!isDataPresent(mergeFields)) {
            const contentState = ContentState.createFromText(value);
            const newEditorState = EditorState.createWithContent(contentState);

            this.setState({
                editorState: newEditorState,
            });

            return;
        }

        const { contentBlocks, entityMap } = extraDetails;

        const contentState = convertFromRaw({
            blocks: _.map(contentBlocks, (eachContentBlock) => new ContentBlock(eachContentBlock)),
            entityMap,
        });

        const initEditorState = EditorState.createWithContent(contentState);
        await this.setState({
            editorState: initEditorState,
        });

        for (let i = 0; i < contentBlocks.length; i++) {
            const eachContentBlock = contentBlocks[i];
            const { entityRanges, key: blockKey } = eachContentBlock;

            for (let j = 0; j < entityRanges.length; j++) {
                const eachEntityRange = entityRanges[j];
                const { offset: entityOffset, length: entityLength, key: entityKey } = eachEntityRange;
                const selectionState = new SelectionState({
                    anchorKey: blockKey,
                    anchorOffset: entityOffset,
                    focusKey: blockKey,
                    focusOffset: entityOffset + entityLength,
                    isBackward: false,
                    hasFocus: true,
                });

                const oldEntityObj = entityMap[entityKey];
                const { data: oldEntityData } = oldEntityObj;
                const { editorState: oldEditorState } = this.state;
                const oldContentState = oldEditorState.getCurrentContent();

                const newEntityObj = oldContentState.createEntity("TOKEN", "IMMUTABLE", {
                    displayText: oldEntityData.displayText,
                    value: oldEntityData.value,
                });
                const newEntityKey = newEntityObj.getLastCreatedEntityKey();
                const optionSelected = Modifier.replaceText(
                    oldContentState,
                    selectionState,
                    oldEntityData.displayText,
                    ["CUSTOM_ENTITY"],
                    newEntityKey,
                );

                const newEditorState = EditorState.push(oldEditorState, optionSelected, "apply-entity");
                await this.setState({
                    editorState: newEditorState,
                });
            }
        }
    }

    editorStateHandler = (editorState) => {
        this.setState({
            editorState,
        });
    };

    changeToggleState = (event) => {
        event.preventDefault();

        this.props.toggleClickHandler();

        this.setState((prevState) => {
            return {
                isToggleOpen: !prevState.isToggleOpen,
            };
        });
    };

    focus = () => {
        const { editorState } = this.state;
        const initialInlineStyle = editorState.getCurrentInlineStyle();

        /* eslint-disable no-underscore-dangle */
        if (!isDataPresent(initialInlineStyle._tail)) return;
        /* eslint-enable */

        const contentState = editorState.getCurrentContent();
        const selectedTextOrCaretPosition = editorState.getSelection();
        const nextContentState = Modifier.replaceText(contentState, selectedTextOrCaretPosition, " ", null, null);

        const updated = EditorState.push(editorState, nextContentState, "apply-entity");
        this.setState({
            editorState: updated,
        });
    };

    async componentWillUpdate(nextProps) {
        const { selectedOptionUniqueId: currentSelectedOptionUniqueId } = this.props;
        const { selectedOptionUniqueId: nextSelectedOptionUniqueId, selectedOption } = nextProps;

        if (isDataPresent(currentSelectedOptionUniqueId) && currentSelectedOptionUniqueId === nextSelectedOptionUniqueId) return;

        if (isDataPresent(selectedOption)) {
            const { editorState } = this.state;

            const initialInlineStyle = editorState.getCurrentInlineStyle();
            const currentEditorContent = editorState.getCurrentContent();
            const selectedTextOrCaretPosition = editorState.getSelection();

            const newEntityObj = currentEditorContent.createEntity("TOKEN", "IMMUTABLE", {
                displayText: selectedOption.displayText,
                value: selectedOption.value,
            });
            const entityKey = newEntityObj.getLastCreatedEntityKey();

            const optionSelected = Modifier.replaceText(
                currentEditorContent,
                selectedTextOrCaretPosition,
                selectedOption.displayText,
                ["CUSTOM_ENTITY"],
                entityKey,
            );

            const addedOptionEditorState = EditorState.push(editorState, optionSelected, "apply-entity");

            await this.setState((prevState) => {
                return {
                    editorState: addedOptionEditorState,
                    isToggleOpen: !prevState.isToggleOpen,
                };
            });

            const removedOptionStyleEditorStyle = EditorState.setInlineStyleOverride(addedOptionEditorState, initialInlineStyle);
            await this.setState({
                editorState: removedOptionStyleEditorStyle,
            });

            const moveFocusToEndOfEditorState = EditorState.moveFocusToEnd(this.state.editorState);
            await this.setState({
                editorState: moveFocusToEndOfEditorState,
            });
            this.focus();
        }
    }

    editorBlurHandler = () => {
        const { editorState } = this.state;

        const currentContent = editorState.getCurrentContent();
        const rawCurrentContent = convertToRaw(currentContent);

        const { blocks, entityMap } = rawCurrentContent;

        let richInputTextFromEditor = "";
        const mergeFields = [];

        _.each(blocks, (eachBlock, blockIndex) => {
            let { text: currentBlockText } = eachBlock;
            const { entityRanges } = eachBlock;
            _.each(entityRanges, (eachEntity) => {
                const { key } = eachEntity;
                const { data } = entityMap[key];
                const { displayText, value } = data;
                if (isDataPresent(value)) {
                    mergeFields.push({
                        value,
                    });
                    currentBlockText = currentBlockText.replace(new RegExp(_.escapeRegExp(displayText), "g"), `%%${value}%%`);
                }
            });
            const isGreaterThanFirstBlock = blockIndex > 0;
            richInputTextFromEditor = `${richInputTextFromEditor}${isGreaterThanFirstBlock ? "\n" : ""}${currentBlockText}`;
        });

        this.props.userInputHandler({
            jsonPairIndex: 0,
            userInput: {
                value: richInputTextFromEditor,
                valueFrom: "userCustomValue",
                mergeFields,
                extraDetails: {
                    contentBlocks: blocks,
                    entityMap,
                },
            },
            jsonPairType: JsonPairTypesEnum.VALUE,
            jsonPairValueIndex: 0,
        });
    };

    render() {
        const isTextarea = !this.props.shouldStopReturn;
        return (
            <Fragment>
                <div className="border-2 p-2 flex flex-row justify-between dark:border-gray-600">
                    <div onClick={this.focus} className="w-full">
                        <Editor
                            customStyleMap={{
                                CUSTOM_ENTITY: {
                                    backgroundColor: "#757575",
                                    color: "white",
                                    padding: "3px 5px 3px 5px",
                                    fontWeight: "400",
                                    borderRadius: "5px",
                                    margin: "0px 5px 0px 5px",
                                },
                            }}
                            placeholder={<div className="text-gray-500 text-sm">{this.props.placeholder}</div>}
                            editorState={this.state.editorState}
                            spellCheck={true}
                            onChange={this.editorStateHandler}
                            onBlur={this.editorBlurHandler}
                            {...(!isTextarea && {
                                handleReturn: () => "handled",
                            })}
                        />
                    </div>
                    <div
                        onMouseDown={(event) => {
                            this.changeToggleState(event);
                            this.focus();
                        }}
                    >
                        {this.state.isToggleOpen ? <FaMinusSquare /> : <FaPlusSquare />}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default DraftJsEditor;
