import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { find, get } from "lodash";
import { FaHandPeace } from "react-icons/fa";

import TestActionModal from "./TestActionModal";
import { TEST_ACTION } from "enums/StageItemSlugsEnum";

export default function TestAction(props) {
    const { flowObj, selectedIntegrations } = useSelector((state) => state.FlowBuilder);
    const { unselectedDisplayText, stageIndex, stageType, orderIndex, userHelper, finishStageItem } = props;

    const [showModal, setShowModal] = useState(false);

    const actionStageItems = get(flowObj, `stages[${stageIndex}].stageItems`, {});
    const isStageSetupComplete = get(actionStageItems, "testAction.testStatus", "") === "done";

    const currentStageSelectedIntegration = selectedIntegrations[stageIndex];
    const { testActionOptions } = get(currentStageSelectedIntegration, "actionStageItems.testAction", {});

    const selectedActionSlug = get(actionStageItems, "selectAction.selectedActionSlug", "");
    const testActionOptionForSelectedAction = find(testActionOptions, { actionSlug: selectedActionSlug }) || {};
    const { pollUrlDetails = {} } = testActionOptionForSelectedAction;

    useEffect(() => {
        if (isStageSetupComplete) finishStageItem(TEST_ACTION);
    }, [finishStageItem, isStageSetupComplete]);

    return (
        <>
            <div className="mb-0 cursor-pointer max-w-fit" onClick={() => setShowModal(true)}>
                {!isStageSetupComplete ? (
                    <>
                        <span>
                            {orderIndex + 1}. Test action - {unselectedDisplayText}
                        </span>
                        <span className="text-red-600 mx-1">(required)</span>
                    </>
                ) : (
                    <>
                        <span>{orderIndex + 1}. Test action - Test completed</span>
                        <FaHandPeace size={16} className="inline-block text-green-600 mx-1" />
                    </>
                )}
            </div>
            {showModal && (
                <TestActionModal
                    testActionPollUrlDetails={pollUrlDetails}
                    integrationImageUrl={currentStageSelectedIntegration.imageUrl}
                    integrationDisplayText={currentStageSelectedIntegration.displayText}
                    userHelper={userHelper}
                    stageIndex={stageIndex}
                    stageType={stageType}
                    closeModal={() => setShowModal(false)}
                    slug={currentStageSelectedIntegration.slug}
                />
            )}
        </>
    );
}
