import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

const IntegrationDemoVideo = (props) => {
  const { stageType } = props;
  const { selectedIntegrations } = useSelector((state) => state.FlowBuilder);

  const triggerVideo = selectedIntegrations[0]?.youtubeHowToVideoUrl;
  const actionVideo = selectedIntegrations[selectedIntegrations.length - 1]?.youtubeHowToVideoUrl;

  return (
    <div className="absolute right-[-318px] top-9 ">
      {stageType === "trigger" ? (
        !isEmpty(triggerVideo) && (
          <div className="mt-5">
            <iframe src={triggerVideo} allowFullScreen className="w-full lg:h-[185px]" />
          </div>
        )
      ) : stageType === "action" ? (
        !isEmpty(actionVideo) && !(triggerVideo === actionVideo) ? (
          <div className={`${isEmpty(triggerVideo) ? "mt-2" : "mt-5"} h-full w-full`}>
            <iframe src={actionVideo} allowFullScreen className="w-full lg:h-[185px]" />
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </div>
  );
};
export default IntegrationDemoVideo;
