import React, {
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useState,
} from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

import { showErrorToast } from "components/WbToast";

// describes the props for a form component
type FormProps<T extends object> = {
  //UseFormReturn constins properties such as register, handleSubmit, and formState
  form: UseFormReturn<T>;
  handleSubmit: SubmitHandler<T>;
} & Omit<JSX.IntrinsicElements["form"], "onSubmit">;

const PlainForm = <T extends FieldValues>(
  props: FormProps<T>,
  ref: Ref<HTMLFormElement>
) => {
  const { form, handleSubmit, ...passThrough } = props;

  return (
    <FormProvider {...form}>
      <form
        ref={ref}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          form
            .handleSubmit(handleSubmit)(event)
            .catch((err) => {
              showErrorToast(err.message);
            });
        }}
        {...passThrough}
      >
        {
          /* @see https://react-hook-form.com/advanced-usage/#SmartFormComponent */
          React.Children.map(props.children, (child) => {
            return typeof child !== "string" &&
              typeof child !== "number" &&
              typeof child !== "boolean" &&
              child &&
              "props" in child &&
              child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    register: form.register,
                    key: child.props.name,
                  },
                })
              : child;
          })
        }
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(PlainForm) as <T extends FieldValues>(
  p: FormProps<T> & { ref?: Ref<HTMLFormElement> }
) => ReactElement;

// Input Field
//  the name prop is removed from the interface, since it will be added back in as a required prop.
type InputProps = Omit<JSX.IntrinsicElements["input"], "name"> & {
  name: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref
) {
  return (
    <input
      {...props}
      ref={ref}
      className={classNames(
        "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm",
        props.className
      )}
    />
  );
});

export function Label(props: JSX.IntrinsicElements["label"]) {
  return (
    <label
      {...props}
      className={classNames(
        "block text-sm font-medium text-gray-700 dark:text-white/60",
        props.className
      )}
    >
      {props.children}
    </label>
  );
}

type InputFieldProps = {
  label?: ReactNode;
  hint?: ReactNode;
  AddOnIcon?: React.ElementType;
} & React.ComponentProps<typeof Input> & {
    labelProps?: React.ComponentProps<typeof Label>;
  };

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const methods = useFormContext();
    const {
      label = props.name,
      labelProps,
      placeholder = props.placeholder ? props.placeholder : "",
      className,
      AddOnIcon,
      hint,
      ...passThrough
    } = props;
    return (
      <>
        {!!props.name && (
          <Label htmlFor={props.name} {...labelProps}>
            {label}
          </Label>
        )}
        {AddOnIcon ? (
          <div className="relative col-span-4 flex items-center md:w-[400px]">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <AddOnIcon className="text-gray-500" />
            </div>
            <Input
              id={props.name}
              placeholder={placeholder}
              className={classNames("p-2.5 pl-10 text-sm", className)}
              {...passThrough}
              ref={ref}
            />
          </div>
        ) : (
          <Input
            id={props.name}
            placeholder={placeholder}
            className={className}
            {...passThrough}
            ref={ref}
          />
        )}
        {hint}
        {methods?.formState?.errors[props.name]?.message && (
          <p className="mt-1 text-yellow-700">
            {<>{methods.formState.errors[props.name]!.message}</>}
          </p>
        )}
      </>
    );
  }
);

export const TextField = forwardRef<HTMLInputElement, InputFieldProps>(
  function TextField(props, ref) {
    return <InputField ref={ref} {...props} />;
  }
);

export const PasswordField = forwardRef<HTMLInputElement, InputFieldProps>(
  function PasswordField(props, ref) {
    return (
      <InputField
        data-testid="password"
        type="password"
        placeholder="•••••••••••••"
        ref={ref}
        {...props}
      />
    );
  }
);

export const EmailInput = forwardRef<HTMLInputElement, InputFieldProps>(
  function EmailInput(props, ref) {
    return (
      <Input
        ref={ref}
        type="email"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        inputMode="email"
        {...props}
      />
    );
  }
);

export const EmailField = forwardRef<HTMLInputElement, InputFieldProps>(
  function EmailField(props, ref) {
    return (
      <InputField
        ref={ref}
        type="email"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        inputMode="email"
        {...props}
      />
    );
  }
);

interface SelectFieldProps {
  name: string;
  label?: string;
  labelProps?: React.HTMLAttributes<HTMLLabelElement>;
  placeholder?: string;
  className?: string;
  options: { value: string; label: string }[];
  defaultValue?: string;
}

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  function SelectField(props, ref) {
    const methods = useFormContext();
    const {
      name,
      label = props.name,
      labelProps,
      placeholder = props.placeholder ? props.placeholder : "",
      className,
      options,
      defaultValue,
      ...passThrough
    } = props;
    return (
      <>
        {!!props.name && (
          <Label htmlFor={props.name} {...labelProps}>
            {label}
          </Label>
        )}
        <div className="customSelect">
          <select
            id={name}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            className={classNames(
              "block w-full cursor-pointer rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm",
              props.className
            )}
            ref={ref}
            {...passThrough}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {methods?.formState?.errors[props.name]?.message && (
          <p className="mt-1 text-yellow-700">
            {<>{methods.formState.errors[props.name]!.message}</>}
          </p>
        )}
      </>
    );
  }
);


export const ShowPassword = forwardRef<HTMLInputElement, InputFieldProps >(
  function ShowPassword(props, ref) {
    const [type, setType] = useState("password");
    const [Icon, setIcon] = useState<any>(EyeSlashIcon);

    const show = () => {
      type === "password" ? setType("text") : setType("password");
      Icon === EyeIcon ? setIcon(EyeSlashIcon) : setIcon(EyeIcon);
    };
    return (
      <div className="relative mt-1">
        <Input type={type} required={true} ref={ref} {...props} />
        <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
          <Icon
            className="h-5 w-5 cursor-pointer text-gray-400 "
            aria-hidden="true"
            onClick={show}
          />
        </div>
      </div>
    );
  }
);

export const ShowPasswordField = forwardRef<HTMLInputElement, InputFieldProps>(
  function ShowPasswordField(props, ref) {
    const methods = useFormContext();

    const {
      label = props.name,
      labelProps,
      placeholder = props.placeholder ? props.placeholder : "",
      className,
      AddOnIcon,
      hint,
      ...passThrough
    } = props;

    const [type, setType] = useState("password");
    const [Icon, setIcon] = useState<any>(EyeSlashIcon);

    const show = () => {
      type === "password" ? setType("text") : setType("password");
      Icon === EyeIcon ? setIcon(EyeSlashIcon) : setIcon(EyeIcon);
    };
    return (
      <>
        {!!props.name && (
          <Label htmlFor={props.name} {...labelProps}>
            {label}
          </Label>
        )}
        {AddOnIcon ? (
          <div className="relative md:w-[400px]">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <AddOnIcon className="text-gray-500" />
            </div>
            <div className=" mt-1">
              <Input
                type={type}
                required={true}
                ref={ref}
                id={props.name}
                placeholder={placeholder}
                className={classNames("p-2.5 pl-10 text-sm", className)}
                {...passThrough}
              />
              <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
                <Icon
                  className="h-5 w-5 cursor-pointer text-gray-400 "
                  aria-hidden="true"
                  onClick={show}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="relative mt-1">
            <Input
              type={type}
              required={true}
              ref={ref}
              placeholder={placeholder}
              {...passThrough}
            />
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
              <Icon
                className="h-5 w-5 cursor-pointer text-gray-400 "
                aria-hidden="true"
                onClick={show}
              />
            </div>
          </div>
        )}
        {hint}
        {methods?.formState?.errors[props.name]?.message && (
          <p className="mt-1 text-yellow-700">
            {<>{methods.formState.errors[props.name]!.message}</>}
          </p>
        )}
      </>
    );
  }
);
