import React, { useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";

import {
  contactUsForEnterprise,
  contactUsForSupport,
} from "./InputTypeDetails";
import WbButton from "components/WbButton";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbTextInput from "components/WbTextInput";
import WbLabel from "components/WbLabel";
import { sendMessageForContactUs } from "../SelectPlanActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";

export default function ContactUsModal({
  closeModal,
  shouldLoadEntepriseContactUs,
}: any) {
  const dispatch = useDispatch();
  const [contactUsInputDetails, setContactUsInputDetails] = useState({
    name: "",
    companyName: "",
    email: "",
  });

  const onChangeInput = (event: any) => {
    const { id, value } = event.target;

    setContactUsInputDetails({
      ...contactUsInputDetails,
      [id]: value,
    });
  };

  const onClickSubmit = () => {
    if (shouldLoadEntepriseContactUs) {
      const { name, companyName, email } = contactUsInputDetails;
      if (name === "" || companyName === "" || email === "")
        return showErrorToast("Please fill in all the details");
      showSuccessToast("Request as been recorded.");
      dispatch(sendMessageForContactUs(contactUsInputDetails));
      closeModal();
      return;
    }
    const { name, email } = contactUsInputDetails;
    if (name === "" || email === "")
      return showErrorToast("Please fill in all the details");
    showSuccessToast("Request as been recorded.");
    dispatch(sendMessageForContactUs(contactUsInputDetails));
    closeModal();
  };

  const inputDetailsToLoad = shouldLoadEntepriseContactUs
    ? contactUsForEnterprise
    : contactUsForSupport;
  const modalHeaderToShow = shouldLoadEntepriseContactUs
    ? "Request for ENTERPRISE plan"
    : "Contact Us";

  const contactUsDetails = _.map(
    inputDetailsToLoad,
    (eachInputDetails, index) => {
      if (eachInputDetails.elementType === "input") {
        return (
          <form key={index}>
            <div className="mb-4 block">
              <WbLabel>{eachInputDetails.labelText}</WbLabel>
              <WbTextInput
                type={eachInputDetails.inputType}
                id={eachInputDetails.name}
                placeholder={eachInputDetails.placeholder}
                name={eachInputDetails.name}
                required={eachInputDetails.isRequired}
                onChange={onChangeInput}
              />
            </div>
          </form>
        );
      } else {
        return (
          <div className="mb-4 block">
            <WbLabel>{eachInputDetails.labelText}</WbLabel>
            <WbTextInput
              key={index}
              type={eachInputDetails.inputType}
              id={eachInputDetails.name}
              placeholder={eachInputDetails.placeholder}
              name={eachInputDetails.name}
              required={eachInputDetails.isRequired}
              onChange={onChangeInput}
            />
          </div>
        );
      }
    }
  );

  return (
    <WbModal isOpen={true} size="md" toggle={closeModal}>
      <WbModalTitle toggle={closeModal} title={modalHeaderToShow} />
      <div className="p-4">{contactUsDetails}</div>
      <div className="float-right p-4">
        <WbButton onClick={onClickSubmit}>Continue</WbButton>
      </div>
    </WbModal>
  );
}
