import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import withOAuthContainer from "layouts/OAuth";
import { showReconnectAccountModal, hideReconnectAccountModal } from "modules/Apps/AccountsActions";
import ReconnectAccountContainer from "modules/Apps/Actions/ReconnectAccount";
import { isDataPresent } from "utils/object_util";

class UserHelper extends Component {
    initReconnectAccount = () => {
        const { flowObj, integrationId, stageIndex } = this.props;

        const { selectedAccountId } = flowObj.stages[stageIndex].stageItems.selectAccount;
        this.props.showReconnectAccountModal(integrationId, selectedAccountId.toString());
    };

    stopAccountReconnection = () => {
        this.props.hideReconnectAccountModal();
    };

    userHelperTextHtmlHyperLinkClickHandler = () => {
        this.initReconnectAccount();
    };

    getHelperTextHtmlJsxFromPartsForAccountReconnect = (helperTextHtmlParts, { reconnectIntegration, reconnectAccountId }) => {
        const { oAuthDetails } = reconnectIntegration;
        return _.map(helperTextHtmlParts, (eachPart, index) => {
            const { partValue, isHyperLink } = eachPart;

            if (!isHyperLink) {
                return <span key={index}>{partValue}</span>;
            }

            if (oAuthDetails) {
                const UserHelperTextHtml = () => {
                    return <span className={"hover:underline cursor-pointer text-sky-500"}>{partValue}</span>;
                };
                const UserHelperTextHtmlHoc = withOAuthContainer(UserHelperTextHtml);
                return (
                    <UserHelperTextHtmlHoc
                        key={index}
                        oAuthIntegration={reconnectIntegration}
                        oAuthType="reconnect"
                        reconnectAccountId={reconnectAccountId}
                    ></UserHelperTextHtmlHoc>
                );
            }

            return (
                <span key={index} onClick={this.userHelperTextHtmlHyperLinkClickHandler} className={"hover:underline cursor-pointer text-sky-500"}>
                    {partValue}
                </span>
            );
        });
    };

    getHelperTextHtmlJsxFromParts = (helperTextHtmlParts) => {
        return _.map(helperTextHtmlParts, (eachPart, index) => {
            const { partValue, isHyperLink, hyperLinkHref } = eachPart;

            if (!isHyperLink) {
                return <span key={index}>{partValue}</span>;
            }

            return (
                <span key={index}>
                    <a href={hyperLinkHref} target="_blank" rel="noopener noreferrer" className={"hover:underline cursor-pointer text-sky-500"}>
                        {partValue}
                    </a>
                </span>
            );
        });
    };

    render() {
        const { userHelper, stageIndex, flowObj, selectedIntegrations } = this.props;
        const { helperTextHtmlParts } = userHelper;

        let helperTextHtmlJsx = null;
        let reconnectAccountModal = null;

        const isUserHelperForFlowBuilder = typeof stageIndex === "number";
        if (isUserHelperForFlowBuilder && isDataPresent(flowObj.stages[stageIndex].stageItems.selectAccount)) {
            const { selectedAccountId: reconnectAccountId } = flowObj.stages[stageIndex].stageItems.selectAccount;
            const reconnectIntegration = selectedIntegrations[stageIndex];
            const { _id: reconnectIntegrationId } = reconnectIntegration;

            if (this.props.shouldShowReconnectAccountModal) {
                reconnectAccountModal = (
                    <ReconnectAccountContainer
                        reconnectIntegrationId={reconnectIntegrationId}
                        reconnectAccountId={reconnectAccountId}
                        closeModal={this.stopAccountReconnection}
                    />
                );
            }

            helperTextHtmlJsx = this.getHelperTextHtmlJsxFromPartsForAccountReconnect(helperTextHtmlParts, {
                reconnectIntegration,
                reconnectAccountId,
            });
        } else {
            helperTextHtmlJsx = this.getHelperTextHtmlJsxFromParts(helperTextHtmlParts);
        }

        return (
            <Fragment>
                <div>{helperTextHtmlJsx}</div>
                {reconnectAccountModal}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ FlowBuilder, Accounts }) => {
    const { flowObj, selectedIntegrations } = FlowBuilder;
    const { shouldShowReconnectAccountModal } = Accounts;

    return {
        flowObj,
        selectedIntegrations,
        shouldShowReconnectAccountModal,
    };
};

export default connect(mapStateToProps, {
    showReconnectAccountModal,
    hideReconnectAccountModal,
})(UserHelper);
