interface IHeaderSectionProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
}

interface IHeaderProps extends IHeaderSectionProps {}

export function HeaderSection({ title, description }: IHeaderSectionProps) {
  return (
    <header className="pb-4">
      <h4 className="text-xl font-medium text-gray-600 dark:text-gray-200">
        {title}
      </h4>
      <h6 className="pl-0.5 text-base text-gray-400">{description ?? ""}</h6>
    </header>
  );
}

export default function WbHeader({
  title,
  description,
  children,
}: IHeaderProps) {
  return (
    <div className="flex w-full items-center justify-between px-2 dark:text-gray-300">
      <HeaderSection title={title} description={description} />
      <section>{children}</section>
    </div>
  );
}
