import {
  ADD_NEW_ACCOUNT,
  ADD_NEW_ACCOUNT_SUCCESS,
  RECONNECT_ACCOUNT_SUCCESS,
  RECONNECT_ACCOUNT,
  ADD_NEW_ACCOUNT_FAIL,
  RECONNECT_ACCOUNT_FAIL,
  DISCONNECT_ACCOUNT,
  DISCONNECT_ACCOUNT_SUCCESS,
  DISCONNECT_ACCOUNT_FAIL,
  SHOW_ADD_ACCOUNT_MODAL,
  SHOW_RECONNECT_ACCOUNT_MODAL,
  SHOW_DISCONNECT_ACCOUNT_MODAL,
  HIDE_ADD_ACCOUNT_MODAL,
  HIDE_RECONNECT_ACCOUNT_MODAL,
  HIDE_DISCONNECT_ACCOUNT_MODAL,
  SHOW_ADD_ACCOUNT_FOR_INTEGRATION_MODAL,
  HIDE_ADD_ACCOUNT_FOR_INTEGRATION_MODAL,
  FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN,
  FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_SUCCESS,
  FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_FAIL,
  SHOW_CHANGE_ACCOUNT_NAME_MODAL,
  CHANGE_ACCOUNT_NAME,
  CHANGE_ACCOUNT_NAME_SUCCESS,
  CHANGE_ACCOUNT_NAME_FAIL,
  HIDE_CHANGE_ACCOUNT_NAME_MODAL,
} from "./AccountsActionTypes";

export const showAddAccountModal = () => {
  return {
    type: SHOW_ADD_ACCOUNT_MODAL,
  };
};

export const addNewAccount = (subscriptionId: any, accountDetails: any) => {
  return {
    type: ADD_NEW_ACCOUNT,
    payload: {
      subscriptionId,
      accountDetails,
    },
  };
};

export const addNewAccountSuccess = () => {
  return {
    type: ADD_NEW_ACCOUNT_SUCCESS,
  };
};

export const addNewAccountFail = () => {
  return {
    type: ADD_NEW_ACCOUNT_FAIL,
  };
};

export const hideAddAccountModal = () => {
  return {
    type: HIDE_ADD_ACCOUNT_MODAL,
  };
};

export const showReconnectAccountModal = (
  reconnectIntegrationId: any,
  reconnectAccountId: any
) => {
  return {
    type: SHOW_RECONNECT_ACCOUNT_MODAL,
    payload: {
      reconnectIntegrationId,
      reconnectAccountId,
    },
  };
};

export const reconnectAccount = (
  subscriptionId: any,
  accountId: any,
  apiKeys: any
) => {
  return {
    type: RECONNECT_ACCOUNT,
    payload: {
      subscriptionId,
      accountId,
      apiKeys,
    },
  };
};

export const reconnectAccountSuccess = () => {
  return {
    type: RECONNECT_ACCOUNT_SUCCESS,
  };
};

export const reconnectAccountFail = () => {
  return {
    type: RECONNECT_ACCOUNT_FAIL,
  };
};

export const hideReconnectAccountModal = () => {
  return {
    type: HIDE_RECONNECT_ACCOUNT_MODAL,
  };
};

export const showAddAccountForIntegrationModal = () => {
  return {
    type: SHOW_ADD_ACCOUNT_FOR_INTEGRATION_MODAL,
  };
};

export const hideAddAccountForIntegrationModal = () => {
  return {
    type: HIDE_ADD_ACCOUNT_FOR_INTEGRATION_MODAL,
  };
};

export const showDisconnectAccountModal = (disconnectAccountId: any) => {
  return {
    type: SHOW_DISCONNECT_ACCOUNT_MODAL,
    payload: {
      disconnectAccountId,
    },
  };
};

export const disconnectAccount = (
  subscriptionId: any,
  accountId: any,
  integrationId: string
) => {
  return {
    type: DISCONNECT_ACCOUNT,
    payload: {
      subscriptionId,
      accountId,
      integrationId,
    },
  };
};

export const disconnectAccountSuccess = () => {
  return {
    type: DISCONNECT_ACCOUNT_SUCCESS,
  };
};

export const disconnectAccountFail = () => {
  return {
    type: DISCONNECT_ACCOUNT_FAIL,
  };
};

export const hideDisconnectAccountModal = () => {
  return {
    type: HIDE_DISCONNECT_ACCOUNT_MODAL,
  };
};

export const showChangeAccountNameModal = (accountId: any) => {
  return {
    type: SHOW_CHANGE_ACCOUNT_NAME_MODAL,
    payload: {
      accountId,
    },
  };
};

export const changeAccountName = (
  subscriptionId: any,
  accountId: any,
  accountName: any
) => {
  return {
    type: CHANGE_ACCOUNT_NAME,
    payload: {
      subscriptionId,
      accountId,
      accountName,
    },
  };
};

export const changeAccountNameSuccess = () => {
  return {
    type: CHANGE_ACCOUNT_NAME_SUCCESS,
  };
};

export const changeAccountNameFail = () => {
  return {
    type: CHANGE_ACCOUNT_NAME_FAIL,
  };
};

export const hideChangeAccountNameModal = () => {
  return {
    type: HIDE_CHANGE_ACCOUNT_NAME_MODAL,
  };
};

export const fetchOAuthIntegrationsForAccountsShown = (accounts: any) => {
  return {
    type: FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN,
    payload: {
      accounts,
    },
  };
};

export const fetchOAuthIntegrationsForAccountsShownSuccess = (
  integrations: any
) => {
  return {
    type: FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_SUCCESS,
    payload: {
      integrations,
    },
  };
};

export const fetchOAuthIntegrationsForAccountsShownFail = () => {
  return {
    type: FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_FAIL,
  };
};
