import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaCheckDouble, FaPen } from "react-icons/fa";
import { get, find, isEmpty } from "lodash";

import { SELECT_ACTION } from "enums/StageItemSlugsEnum";

import SelectActionEventModal from "./SelectActionEventModal";

export default function SelectActionEvent(props) {
    const { stageType, userHelper, stageIndex, orderIndex, finishStageItem } = props;
    const { selectedIntegrations, flowObj } = useSelector((state) => state.FlowBuilder);
    const [showModal, setShowModal] = useState(false);

    const selectedAction = get(flowObj, `stages[${stageIndex}].stageItems.selectAction`, {});
    const isStageSetupComplete = !isEmpty(selectedAction);

    const currentStageSelectedIntegration = selectedIntegrations[stageIndex];
    const allActions = get(currentStageSelectedIntegration, "actionStageItems.selectAction.actions", []);

    let selectedActionFromIntegration = isStageSetupComplete
        ? find(allActions, (eachAction) => selectedAction.selectedActionSlug === eachAction.slug)
        : {};

    useEffect(() => {
        if (isStageSetupComplete) finishStageItem(SELECT_ACTION);
    }, [isStageSetupComplete, finishStageItem]);

    return (
        <>
            <div className="mb-0 cursor-pointer max-w-fit" onClick={() => setShowModal(true)}>
                <span>
                    {isStageSetupComplete
                        ? `${orderIndex + 1}. Action - ${selectedActionFromIntegration?.displayText}`
                        : `${orderIndex + 1}. Action - Click here to select action`}
                </span>
                {isStageSetupComplete ? (
                    <>
                        <FaPen size={12} className="inline-block text-blue-600 mx-1" />
                        <FaCheckDouble size={12} className="inline-block text-green-600" />
                    </>
                ) : (
                    <span className="text-red-600 mx-1">(required)</span>
                )}
            </div>

            {showModal && (
                <SelectActionEventModal
                    stageIndex={stageIndex}
                    stageType={stageType}
                    actions={allActions}
                    userHelper={userHelper}
                    integrationImageUrl={currentStageSelectedIntegration.imageUrl}
                    integrationDisplayText={currentStageSelectedIntegration.displayText}
                    reducerSelectedAction={selectedAction}
                    closeModal={() => setShowModal(false)}
                    slug={currentStageSelectedIntegration.slug}
                />
            )}
        </>
    );
}
