import React from "react";
import classNames from "classnames";
import WbHelperText from "components/WbHelperText";

const theme = {
	base: "block w-full rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
	colors: {
		gray: "border-gray-300 text-gray-900 focus:border-orange-500 focus:ring-orange-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-orange-500 dark:focus:ring-orange-500",
		info: "border-orange-500 bg-orange-50 text-orange-900 placeholder-orange-700 focus:border-orange-500 focus:ring-orange-500 dark:border-orange-400 dark:bg-orange-100 dark:focus:border-orange-500 dark:focus:ring-orange-500",
		failure:
			"border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
		warning:
			"border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
		success:
			"border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500",
	},
	withShadow: {
		on: "shadow-sm dark:shadow-sm-light",
		off: "",
	},
};

export default function WbTextarea(props) {
	const { shadow, helperText, color = "gray", className, ...restProps } = props;

	return (
		<>
			<textarea
				className={classNames(
					theme.base,
					theme.colors[color],
					theme.withShadow[shadow ? "on" : "off"],
					className
				)}
				{...restProps}
			/>
			{helperText && <WbHelperText color={color}>{helperText}</WbHelperText>}
		</>
	);
}
