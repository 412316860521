import _ from "lodash";

import {
	GET_SINGLE_INTEGRATION,
	GET_SINGLE_INTEGRATION_SUCCESS,
	GET_SINGLE_INTEGRATION_FAIL,
} from "./IntegrationsActionTypes";

const INIT_STATE = {
	isFetchingSingleIntegration: false,
	singleIntegration: null,
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;

	const subscriptionId =
		action.type === "FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER" &&
		_.get(payload, "loggedInUserSubscriptionId", "");
	if (subscriptionId)
		sessionStorage.setItem("subscriptionId", subscriptionId);

	switch (action.type) {
		case GET_SINGLE_INTEGRATION:
			return {
				...state,
				isFetchingSingleIntegration: true,
			};
		case GET_SINGLE_INTEGRATION_SUCCESS:
			return {
				...state,
				isFetchingSingleIntegration: false,
				singleIntegration: payload.integration,
			};
		case GET_SINGLE_INTEGRATION_FAIL:
			return {
				...state,
				isFetchingSingleIntegration: false,
				singleIntegration: null,
			};
		default:
			return {
				...state,
			};
	}
};
