import { HeaderSection } from "components/WbLayout/Header";
import CreateSender from "./CreateSender";
import SelectComponent from "../components/SelectComponent";
import React from 'react'

const options = [
  { value: "sendgrid", label: "Sendgrid" },
  { value: "gmail", label: "Gmail" },
  { value: "mailerlite", label: "Mailerlite" },
];

export default function SelectSender() {
  const [selectedComponent, setSelectedComponent] = React.useState(options[0].value);

  const handleSelectChange = (selectedValue:any) => {
    setSelectedComponent(selectedValue);
  };

  return (
    <div className="container mx-auto p-4">
      <HeaderSection
        title="Create Sender"
        description="Create sender from which you need to send the Email"
      />
      <div className="m-4 w-[30%]">
        <h1 className="mb-2">Select application:</h1>
        <SelectComponent
          options={options}
          defaultOption={options[0].value}
          onChange={handleSelectChange}
        />
      </div>
      <div className="mt-4">
        {selectedComponent === "sendgrid" && <CreateSender />}
        {selectedComponent === "gmail" && <CreateSender />}
        {selectedComponent === "mailerlite" && <CreateSender />}
      </div>
    </div>
  );
}
