/* eslint-disable import/no-anonymous-default-export */
import {
    GET_CURRENT_SUBSCRIBED_PLAN_DETAILS,
    GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_ERROR,
    GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_SUCCESS,
    CANCEL_STRIPE_SUBSCRIPTION,
    CANCEL_STRIPE_SUBSCRIPTION_ERROR,
    CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
    UPDATE_PLAN,
    UPDATE_PLAN_ERROR,
    UPDATE_PLAN_SUCCESS,
    GET_DEFAULT_CARD_DETAILS,
    GET_DEFAULT_CARD_DETAILS_ERROR,
    GET_DEFAULT_CARD_DETAILS_SUCCESS,
} from "./ManageSubscriptionActionTypes";

const INIT_STATE = {
    isLoadingStripeSubscriptionDetails: false,
    planDetails: null,
    isLoadingCancelSubscription: false,
    isLoadingUpdatePlan: false,
    defaultCardDetails: null,
    isLoadingGetDefaultCardDetails: false,
    isDefaultCardDetailsLoaded: false,
};

export default (state = INIT_STATE, action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_CURRENT_SUBSCRIBED_PLAN_DETAILS:
            return {
                ...state,
                isLoadingStripeSubscriptionDetails: true,
            };
        case GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_ERROR:
            return {
                ...state,
                isLoadingStripeSubscriptionDetails: false,
            };
        case GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_SUCCESS:
            return {
                ...state,
                planDetails: payload.planDetails,
                isLoadingStripeSubscriptionDetails: false,
            };
        case CANCEL_STRIPE_SUBSCRIPTION:
            return {
                ...state,
                isLoadingCancelSubscription: true,
            };
        case CANCEL_STRIPE_SUBSCRIPTION_ERROR:
        case CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isLoadingCancelSubscription: false,
                planDetails: null,
            };
        case UPDATE_PLAN:
            return {
                ...state,
                isLoadingUpdatePlan: true,
            };
        case UPDATE_PLAN_ERROR:
        case UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                isLoadingUpdatePlan: false,
            };
        case GET_DEFAULT_CARD_DETAILS:
            return {
                ...state,
                isLoadingGetDefaultCardDetails: true,
                isDefaultCardDetailsLoaded: true,
            };
        case GET_DEFAULT_CARD_DETAILS_ERROR:
            return {
                ...state,
                isLoadingGetDefaultCardDetails: false,
            };
        case GET_DEFAULT_CARD_DETAILS_SUCCESS:
            return {
                ...state,
                isLoadingGetDefaultCardDetails: false,
                defaultCardDetails: payload.cardDetails,
            };
        default:
            return {
                ...state,
            };
    }
};
