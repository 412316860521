import {
	FETCH_INTEGRATIONS,
	FETCH_INTEGRATIONS_SUCCESS,
	FETCH_INTEGRATIONS_FAIL,
	FILTER_INTEGRATIONS_BY_CATEGORY,
	FILTER_INTEGRATIONS_BY_TEXT,
	SHOW_REQUEST_INTEGRATION_MODAL,
	REQUEST_INTEGRATION,
	REQUEST_INTEGRATION_SUCCESS,
	REQUEST_INTEGRATION_FAIL,
	HIDE_REQUEST_INTEGRATION_MODAL,
} from "./SelectIntegrationActionTypes";

const INIT_STATE = {
	fetchingIntegrations: true,
	integrations: null,
	shouldShowRequestIntegrationModal: false,
	isRequestingIntegration: false,
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;

	switch (action.type) {
		case FETCH_INTEGRATIONS:
			return {
				...state,
				fetchingIntegrations: true,
			};
		case FETCH_INTEGRATIONS_SUCCESS:
			return {
				...state,
				fetchingIntegrations: false,
				integrations: payload.integrations,
			};
		case FETCH_INTEGRATIONS_FAIL:
			return {
				...state,
				fetchingIntegrations: false,
				integrations: null,
			};
		case FILTER_INTEGRATIONS_BY_CATEGORY:
		case FILTER_INTEGRATIONS_BY_TEXT:
			return {
				...state,
				fetchingIntegrations: true,
				integrations: payload.integrations,
			};
		case SHOW_REQUEST_INTEGRATION_MODAL:
			return {
				...state,
				shouldShowRequestIntegrationModal: true,
			};
		case REQUEST_INTEGRATION:
			return {
				...state,
				isRequestingIntegration: true,
			};
		case REQUEST_INTEGRATION_SUCCESS:
		case REQUEST_INTEGRATION_FAIL:
			return {
				...state,
				isRequestingIntegration: false,
				shouldShowRequestIntegrationModal: false,
			};
		case HIDE_REQUEST_INTEGRATION_MODAL:
			return {
				...state,
				shouldShowRequestIntegrationModal: false,
			};
		default:
			return {
				...state,
			};
	}
};
