import {
	ADD_NEW_ACCOUNT,
	ADD_NEW_ACCOUNT_SUCCESS,
	ADD_NEW_ACCOUNT_FAIL,
	RECONNECT_ACCOUNT,
	RECONNECT_ACCOUNT_SUCCESS,
	RECONNECT_ACCOUNT_FAIL,
	DISCONNECT_ACCOUNT,
	DISCONNECT_ACCOUNT_SUCCESS,
	DISCONNECT_ACCOUNT_FAIL,
	SHOW_ADD_ACCOUNT_MODAL,
	SHOW_RECONNECT_ACCOUNT_MODAL,
	SHOW_DISCONNECT_ACCOUNT_MODAL,
	HIDE_ADD_ACCOUNT_MODAL,
	HIDE_RECONNECT_ACCOUNT_MODAL,
	HIDE_DISCONNECT_ACCOUNT_MODAL,
	SHOW_ADD_ACCOUNT_FOR_INTEGRATION_MODAL,
	HIDE_ADD_ACCOUNT_FOR_INTEGRATION_MODAL,
	FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN,
	FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_SUCCESS,
	FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_FAIL,
	SHOW_CHANGE_ACCOUNT_NAME_MODAL,
	CHANGE_ACCOUNT_NAME,
	CHANGE_ACCOUNT_NAME_SUCCESS,
	CHANGE_ACCOUNT_NAME_FAIL,
	HIDE_CHANGE_ACCOUNT_NAME_MODAL,
} from "./AccountsActionTypes";

const INIT_STATE = {
	shouldShowAddAccountModal: false,
	isAddingAccount: false,
	shouldShowReconnectAccountModal: false,
	reconnectIntegrationId: "",
	reconnectAccountId: "",
	isReconnectingAccount: false,
	shouldShowAddAccountForIntegrationModal: false,
	shouldShowDisconnectAccountModal: false,
	disconnectAccountId: "",
	isDisconnectingAccount: false,
	shouldShowChangeAccountNameModal: false,
	changeAccountNameAccountId: "",
	isChangingAccountName: false,
	isFetchingOAuthIntegrationsForAccountsShown: false,
	oAuthIntegrationsForAccountsShown: null,
};

export default (state = INIT_STATE, action:any) => {
	const { payload } = action;
	switch (action.type) {
		case SHOW_ADD_ACCOUNT_MODAL:
			return {
				...state,
				shouldShowAddAccountModal: true,
			};
		case ADD_NEW_ACCOUNT:
			return {
				...state,
				isAddingAccount: true,
			};
		case ADD_NEW_ACCOUNT_SUCCESS:
			return {
				...state,
				isAddingAccount: false,
				shouldShowAddAccountModal: false,
				shouldShowAddAccountForIntegrationModal: false,
			};
		case ADD_NEW_ACCOUNT_FAIL:
			return {
				...state,
				isAddingAccount: false,
			};
		case HIDE_ADD_ACCOUNT_MODAL:
			return {
				...state,
				shouldShowAddAccountModal: false,
			};
		case SHOW_RECONNECT_ACCOUNT_MODAL:
			return {
				...state,
				shouldShowReconnectAccountModal: true,
				reconnectIntegrationId: payload.reconnectIntegrationId,
				reconnectAccountId: payload.reconnectAccountId,
			};
		case RECONNECT_ACCOUNT:
			return {
				...state,
				isReconnectingAccount: true,
			};
		case RECONNECT_ACCOUNT_SUCCESS:
			return {
				...state,
				isReconnectingAccount: false,
				shouldShowReconnectAccountModal: false,
			};
		case RECONNECT_ACCOUNT_FAIL:
			return {
				...state,
				isReconnectingAccount: false,
			};
		case HIDE_RECONNECT_ACCOUNT_MODAL:
			return {
				...state,
				shouldShowReconnectAccountModal: false,
			};
		case SHOW_ADD_ACCOUNT_FOR_INTEGRATION_MODAL:
			return {
				...state,
				shouldShowAddAccountForIntegrationModal: true,
			};
		case HIDE_ADD_ACCOUNT_FOR_INTEGRATION_MODAL:
			return {
				...state,
				shouldShowAddAccountForIntegrationModal: false,
			};
		case SHOW_DISCONNECT_ACCOUNT_MODAL:
			return {
				...state,
				shouldShowDisconnectAccountModal: true,
				disconnectAccountId: payload.disconnectAccountId,
			};
		case DISCONNECT_ACCOUNT:
			return {
				...state,
				isDisconnectingAccount: true,
			};
		case DISCONNECT_ACCOUNT_SUCCESS:
			return {
				...state,
				isDisconnectingAccount: false,
				shouldShowDisconnectAccountModal: false,
			};
		case DISCONNECT_ACCOUNT_FAIL:
			return {
				...state,
				isDisconnectingAccount: false,
			};
		case HIDE_DISCONNECT_ACCOUNT_MODAL:
			return {
				...state,
				shouldShowDisconnectAccountModal: false,
			};
		case SHOW_CHANGE_ACCOUNT_NAME_MODAL:
			return {
				...state,
				shouldShowChangeAccountNameModal: true,
				changeAccountNameAccountId: payload.accountId,
			};
		case CHANGE_ACCOUNT_NAME:
			return {
				...state,
				isChangingAccountName: true,
			};
		case CHANGE_ACCOUNT_NAME_SUCCESS:
			return {
				...state,
				isChangingAccountName: false,
				shouldShowChangeAccountNameModal: false,
			};
		case CHANGE_ACCOUNT_NAME_FAIL:
			return {
				...state,
				isChangingAccountName: false,
			};
		case HIDE_CHANGE_ACCOUNT_NAME_MODAL:
			return {
				...state,
				shouldShowChangeAccountNameModal: false,
			};
		case FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN:
			return {
				...state,
				isFetchingOAuthIntegrationsForAccountsShown: true,
			};
		case FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_SUCCESS:
			return {
				...state,
				isFetchingOAuthIntegrationsForAccountsShown: false,
				oAuthIntegrationsForAccountsShown: payload.integrations,
			};
		case FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_FAIL:
			return {
				...state,
				isFetchingOAuthIntegrationsForAccountsShown: false,
				oAuthIntegrationsForAccountsShown: null,
			};
		default:
			return {
				...state,
			};
	}
};
