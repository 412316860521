import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { get, isEmpty, each, find, isEqual } from "lodash";
import { Link } from "react-router-dom";
import { FaPen, FaTimesCircle } from "react-icons/fa";

import { ACTION, FILTER, TRIGGER } from "enums/StageTypesEnum";
import { loadPaymentFailureModal } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import { fetchIntegrations } from "components/SelectIntegration/SelectIntegrationActions";
import Stage from "./Stage";

import {
  addStage,
  deleteStage,
  fetchSingleFlow,
  getGeneratedWebhookUrl,
  getSingleIntegration,
  getTriggerAndActionIntegration,
  initStagesToBeShown,
  resetFlowBuilderReducer,
  saveFlow,
  seedFlowBuilderForView,
  setSelectedAction,
  setSelectedEvent,
  setSelectedIntegration,
  setSubscriptionId,
  fileUpload,
} from "./FlowBuilderActions";
import AddNameToFlowModal from "./AddNameToFlowModal";
import NonExistingIntegrationsModal from "./NonExistingIntegrationsModal";
import WbButton from "components/WbButton";
import { withRouter } from "withRouter";
import { XMarkIcon } from "@heroicons/react/24/solid";

class FlowBuilder extends Component {
  state = {
    shouldLoadAddNameToFlowModalContainer: false,
    openNonExistingIntegrationsModal: true,
  };

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    const queryObj = queryString.parse(search);
    let { stages: stagesFromQuery, from } = queryObj;
    /**Adding this at global level as the redux prop(this.props.isFlowSeeded) update is not happening properly.
         Setting it as false as re-rending of other comp will not happen */
    window.flowSeeded = false;
    /**Workaround for cases where searchParams coming as undefined, so that the JSON will not become invalid */
    //stagesFromQuery = stagesFromQuery?.replace('undefined', "");

    if (from !== "view") {
      let isRequestedFlowAvailable;
      if (!isEmpty(stagesFromQuery)) {
        const parsedQuery = JSON.parse(stagesFromQuery);
        isRequestedFlowAvailable = !isEmpty(parsedQuery[0].triggerStageItems)
          ? true
          : false;
      }

      const jsonParsedStagesFromQuery = isRequestedFlowAvailable
        ? JSON.parse(stagesFromQuery)
        : [];

      const stagesToBeShown = [];
      const stagesToBeShownData = [];
      if (!isEmpty(jsonParsedStagesFromQuery)) {
        each(jsonParsedStagesFromQuery, (eachStage, index) => {
          const isFirstStageItem = index === 0;
          const stageItemsForCurrentStage = isFirstStageItem
            ? eachStage.triggerStageItems
            : eachStage.actionStageItems;
          const { integrationId: currentStageIntegrationId } =
            stageItemsForCurrentStage;

          const stageType = isFirstStageItem ? TRIGGER : ACTION;
          stagesToBeShownData.push({
            integrationId: currentStageIntegrationId,
            stageType,
            ...(stageType === TRIGGER &&
              !isEmpty(stageItemsForCurrentStage.stageItems) && {
                selectedEventSlug:
                  stageItemsForCurrentStage.stageItems.selectEvent
                    .selectedEventSlug,
              }),
            ...(stageType === ACTION &&
              !isEmpty(stageItemsForCurrentStage.stageItems) && {
                selectedActionSlug:
                  stageItemsForCurrentStage.stageItems.selectAction
                    .selectedActionSlug,
              }),
          });
          stagesToBeShown.push(stageType);
        });
      }
      this.props.initStagesToBeShown(stagesToBeShown);

      each(stagesToBeShownData, (eachStageToBeShownData, stageIndex) => {
        const {
          integrationId,
          stageType,
          selectedEventSlug,
          selectedActionSlug,
        } = eachStageToBeShownData;

        if (isEmpty(integrationId)) return;
        this.props.getSingleIntegration(integrationId, [
          (sideEffectData) => {
            this.props.setSelectedIntegration(
              stageIndex,
              sideEffectData,
              stageType
            );

            const { integration } = sideEffectData;
            const isTriggerStage = stageType === TRIGGER;
            const isActionStage = stageType === ACTION;
            const eventsOrActions = isTriggerStage
              ? get(integration, "triggerStageItems.selectEvent.events", "")
              : get(integration, "actionStageItems.selectAction.actions", "");

            if (isTriggerStage && !isEmpty(selectedEventSlug)) {
              const currentStageEvent = find(eventsOrActions, {
                slug: selectedEventSlug,
              });
              this.props.setSelectedEvent(stageIndex, currentStageEvent);
            } else if (isActionStage && !isEmpty(selectedActionSlug)) {
              const currentStageAction = find(eventsOrActions, {
                slug: selectedActionSlug,
              });
              this.props.setSelectedAction(stageIndex, currentStageAction);
            }
          },
        ]);
      });
    }
    const { flowId } = queryObj;
    if (!isEmpty(flowId)) {
      this.props.fetchSingleFlow(flowId);
      return;
    }
    if (!isEmpty(stagesFromQuery)) {
      const parsedStageQuery = JSON.parse(stagesFromQuery);
      if (parsedStageQuery[0].triggerIntegrationId) {
        const triggerIntegrationId = parsedStageQuery[0].triggerIntegrationId;
        const actionIntegrationId = parsedStageQuery[0].actionIntegrationId;
        this.props.getTriggerAndActionIntegration(
          triggerIntegrationId,
          actionIntegrationId
        );
      }
    }

    this.props.setSubscriptionId(this.props.loggedInUserSubscriptionId);
  }

  closeNonExistingIntegrationsModal = () => {
    this.setState({
      openNonExistingIntegrationsModal: false,
    });
    const { history } = this.props;
    history.push({ pathname: "/flow-library" });
  };

  initAddNameToFlowModalContainer = () => {
    this.setState({ shouldLoadAddNameToFlowModalContainer: true });
  };

  stopAddNameToFlowModalContainer = () => {
    this.setState({ shouldLoadAddNameToFlowModalContainer: false });
  };

  initViewFlow = (flowObj) => {
    const { stages } = flowObj;

    const integrationIdsToFetch = [];
    each(stages, (eachStage) => {
      const { stageItems, stageType } = eachStage;

      if (stageType === FILTER) return;

      integrationIdsToFetch.push(
        get(stageItems, "selectIntegration.selectedIntegrationId", "")
      );
    });

    const findQuery = {
      _id: {
        $in: integrationIdsToFetch,
      },
    };
    const fetchIntegrationsSideEffect = (integrations) => {
      this.props.seedFlowBuilderForView(flowObj, integrations);
    };
    if (!isEmpty(integrationIdsToFetch)) {
      this.props.fetchIntegrations(findQuery, [fetchIntegrationsSideEffect]);
      /**Adding this at global level as the redux prop(this.props.isFlowSeeded) update is not happening properly */
      window.flowSeeded = true;
    }
  };

  componentDidUpdate(prevProps) {
    const {
      location,
      commonData,
      flowObj,
      isFlowSaved,
      subscription,
      selectedIntegrations,
    } = this.props;
    const { search } = location;
    const queryObj = queryString.parse(search);
    const { from, flowId: flowIdFromQuery } = queryObj;
    const flowId = get(flowObj, "_id", "");

    const isView = from === "view";
    const selectedTrgiggerSlug = get(selectedIntegrations, "0.slug", "");
    const wyzebulbUploadedFile = get(commonData, "0.wyzebulbUploadedFile", "");
    const wyzebulbWebhookTriggerUrl = get(
      commonData,
      "0.wyzebulbWebhookTriggerUrl",
      ""
    );

    if (
      !isEqual(commonData, prevProps.commonData) ||
      !isEqual(isFlowSaved, prevProps.isFlowSaved) ||
      !isEqual(flowObj, prevProps.flowObj) ||
      isEmpty(selectedTrgiggerSlug)
    ) {
      if (
        isView &&
        ["wyzebulb_webhook", "google_sheets_webhooks"].includes(
          selectedTrgiggerSlug
        ) &&
        isEmpty(wyzebulbWebhookTriggerUrl)
      ) {
        this.props.getGeneratedWebhookUrl(0, flowId, selectedTrgiggerSlug);
      } else if (
        isView &&
        ["wyzebulb_upload"].includes(selectedTrgiggerSlug) &&
        isEmpty(wyzebulbUploadedFile)
      ) {
        this.props.fileUpload({ stageIndex: 0, flowId, status: true });
      }

      if (isFlowSaved) {
        this.props.navigate("/flows", {
          state: {
            shouldLoadPricingModal: true,
            subscription: subscription,
          },
        });
      }

      if (flowIdFromQuery && !window.flowSeeded) {
        this.initViewFlow(flowObj);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetFlowBuilderReducer();
  }

  saveFlowHandler = (flowName) => {
    const { isPaymentPending, flowObj, commonData } = this.props;
    if (!flowObj.name && !flowName) {
      return this.initAddNameToFlowModalContainer();
    }

    if (!flowObj.name) {
      flowObj["name"] = flowName;
    }

    if (!isPaymentPending) {
      const extraDetailsToSaveFlow = {};
      if (!isEmpty(commonData[0].wyzebulbWebhookTriggerUrl)) {
        extraDetailsToSaveFlow.wyzebulbWebhookTriggerUrl =
          commonData[0].wyzebulbWebhookTriggerUrl;
      }
      if (!isEmpty(commonData[0].wyzebulbUploadedFile)) {
        extraDetailsToSaveFlow.wyzebulbUploadedFile =
          commonData[0].wyzebulbUploadedFile;
        extraDetailsToSaveFlow.subcriberEmail = get(
          this,
          "props.subscription.email",
          ""
        );
      }
      this.props.saveFlow(flowObj, extraDetailsToSaveFlow);

      return;
    }

    this.props.loadPaymentFailureModal();
  };

  render() {
    const {
      stagesToBeShown,
      subscription,
      flowObj,
      from,
      triggerAndActionIntegrationData,
    } = this.props;
    let nonExistingIntegrationsModal;

    if (triggerAndActionIntegrationData) {
      const isRequestedTriggerIntegrationPresent =
        triggerAndActionIntegrationData[0].isActive;
      const isRequestedActionIntegrationPresent =
        triggerAndActionIntegrationData[1].isActive;

      if (
        !isRequestedTriggerIntegrationPresent ||
        !isRequestedActionIntegrationPresent
      ) {
        if (this.state.openNonExistingIntegrationsModal) {
          nonExistingIntegrationsModal = (
            <NonExistingIntegrationsModal
              triggerAndActionIntegrationData={triggerAndActionIntegrationData}
              closeModal={this.closeNonExistingIntegrationsModal}
            />
          );
        }
      }
    }
    let shouldShowAddFilter = !isEmpty(subscription) ? true : false;
    return (
      <>
        {/* Flow Builder - Container Section */}
        <div className="h-screen w-full">
          {/* Flow Builder - Header Section */}
          <div className="w-full bg-gray-200 p-3 dark:bg-gray-700 dark:text-white ">
            <div className="flex flex-row items-center justify-between">
              <div
                onClick={this.initAddNameToFlowModalContainer}
                className="space-x-2"
              >
                <FaPen size={16} className="inline-block text-gray-600" />
                <span className=" font-medium text-gray-600 dark:text-gray-400">
                  {flowObj.name}
                </span>
              </div>
              <div className=" text-base font-medium">{`${
                window.location?.search.includes("flowId=") ? "Edit" : "Create"
              } Flow`}</div>
              <Link
                to={{
                  pathname: `/flow-library`,
                }}
                className="dark:text-white"
              >
                <XMarkIcon className="h-6 w-6" size={26} />
              </Link>
            </div>
          </div>
          {/* Flow Builder - Body Section */}
          <div className="mx-auto max-w-5xl p-4">
            <div className="flex flex-row items-start">
              {/* Flow Builder - Stage Section */}
              <div className=" flex-1">
                {stagesToBeShown.map((stageType, stageIndex) => (
                  <Stage
                    shouldShowAddFilter={shouldShowAddFilter}
                    addFilterStageHandler={this.addFilterStageHandler}
                    stageIndex={stageIndex}
                    deleteStage={() => this.props.deleteStage(stageIndex)}
                    stageType={stageType}
                    from={from}
                    isLastStage={stageIndex === stagesToBeShown.length - 1}
                    random={Math.random()}
                    addFilterStage={() =>
                      this.props.addStage(stageIndex + 1, FILTER)
                    }
                    key={stageIndex}
                  />
                ))}
              </div>
              {/* Flow Builder - Save Section */}
              <div className="w-[30%]">
                <WbButton onClick={() => this.saveFlowHandler()}>
                  Publish Flow
                </WbButton>
              </div>
            </div>
          </div>
        </div>
        {/* Flow Builder - Modal Section */}
        {this.state.shouldLoadAddNameToFlowModalContainer && (
          <AddNameToFlowModal
            closeModal={this.stopAddNameToFlowModalContainer}
            saveFlow={this.saveFlowHandler}
          />
        )}
        {nonExistingIntegrationsModal}
      </>
    );
  }
}

const mapStateToProps = ({ Auth, FlowBuilder, LoggedInLayout }) => {
  const { loggedInUserSubscriptionId } = Auth;
  const {
    isFlowSaved,
    stagesToBeShown,
    flowObj,
    selectedIntegrations,
    commonData,
    from,
    isSavingFlow,
    isFlowSeeded,
    triggerAndActionIntegrationData,
  } = FlowBuilder;
  const { subscription, isPaymentPending } = LoggedInLayout;
  return {
    loggedInUserSubscriptionId,
    isFlowSaved,
    stagesToBeShown,
    flowObj,
    selectedIntegrations,
    commonData,
    subscription,
    from,
    isSavingFlow,
    isPaymentPending,
    isFlowSeeded,
    triggerAndActionIntegrationData,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setSubscriptionId,
    addStage,
    deleteStage,
    initStagesToBeShown,
    saveFlow,
    getSingleIntegration,
    setSelectedIntegration,
    setSelectedEvent,
    setSelectedAction,
    loadPaymentFailureModal,
    getGeneratedWebhookUrl,
    fetchSingleFlow,
    fetchIntegrations,
    seedFlowBuilderForView,
    getTriggerAndActionIntegration,
    resetFlowBuilderReducer,
    fileUpload,
  })(FlowBuilder)
);
