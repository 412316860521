import React from "react";

import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";

export default function ComingSoon({ closeModal }) {
    return (
        <WbModal toggle={closeModal} isOpen={true} size="sm">
            <WbModalTitle toggle={closeModal} title="Integration - Coming Soon" />
            <div className="p-4">
                <h3 className="text-center">We have recorded your request for this app. Our team will get in touch with you shortly about it.</h3>
                <div className="text-center">
                    <WbButton onClick={closeModal}>Continue</WbButton>
                </div>
            </div>
        </WbModal>
    );
}
