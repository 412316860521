export const SHOW_ADD_ACCOUNT_MODAL = "SHOW_ADD_ACCOUNT_MODAL";
export const ADD_NEW_ACCOUNT = "ADD_NEW_ACCOUNT";
export const ADD_NEW_ACCOUNT_SUCCESS = "ADD_NEW_ACCOUNT_SUCCESS";
export const ADD_NEW_ACCOUNT_FAIL = "ADD_NEW_ACCOUNT_FAIL";
export const HIDE_ADD_ACCOUNT_MODAL = "HIDE_ADD_ACCOUNT_MODAL";
export const SHOW_RECONNECT_ACCOUNT_MODAL = "SHOW_RECONNECT_ACCOUNT_MODAL";
export const RECONNECT_ACCOUNT = "RECONNECT_ACCOUNT";
export const RECONNECT_ACCOUNT_SUCCESS = "RECONNECT_ACCOUNT_SUCCESS";
export const RECONNECT_ACCOUNT_FAIL = "RECONNECT_ACCOUNT_FAIL";
export const HIDE_RECONNECT_ACCOUNT_MODAL = "HIDE_RECONNECT_ACCOUNT_MODAL";
export const SHOW_ADD_ACCOUNT_FOR_INTEGRATION_MODAL = "SHOW_ADD_ACCOUNT_FOR_INTEGRATION_MODAL";
export const HIDE_ADD_ACCOUNT_FOR_INTEGRATION_MODAL = "HIDE_ADD_ACCOUNT_FOR_INTEGRATION_MODAL";
export const SHOW_DISCONNECT_ACCOUNT_MODAL = "SHOW_DISCONNECT_ACCOUNT_MODAL";
export const DISCONNECT_ACCOUNT = "DISCONNECT_ACCOUNT";
export const DISCONNECT_ACCOUNT_SUCCESS = "DISCONNECT_ACCOUNT_SUCCESS";
export const DISCONNECT_ACCOUNT_FAIL = "DISCONNECT_ACCOUNT_FAIL";
export const HIDE_DISCONNECT_ACCOUNT_MODAL = "HIDE_DISCONNECT_ACCOUNT_MODAL";
export const SHOW_CHANGE_ACCOUNT_NAME_MODAL = "SHOW_CHANGE_ACCOUNT_NAME_MODAL";
export const CHANGE_ACCOUNT_NAME = "CHANGE_ACCOUNT_NAME";
export const CHANGE_ACCOUNT_NAME_SUCCESS = "CHANGE_ACCOUNT_NAME_SUCCESS";
export const CHANGE_ACCOUNT_NAME_FAIL = "CHANGE_ACCOUNT_NAME_FAIL";
export const HIDE_CHANGE_ACCOUNT_NAME_MODAL = "HIDE_CHANGE_ACCOUNT_NAME_MODAL";
export const FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN = "FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN";
export const FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_SUCCESS =
    "FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_SUCCESS";
export const FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_FAIL =
    "FETCH_OAUTH_INTEGRATIONS_FOR_ACCOUNTS_SHOWN_FAIL";
