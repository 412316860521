import {
	FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER,
	LOGGED_IN_USER_SUBSCRIPTION_FETCH_SUCCESS,
	LOGGED_IN_USER_SUBSCRIPTION_FETCH_FAIL,
	REFRESH_SUBSCRIPTION,
	REFRESH_SUBSCRIPTION_SUCCESS,
	LOAD_DATA_FROM_URL,
	LOAD_DATA_FROM_URL_SUCCESS,
	LOAD_DATA_FROM_URL_FAIL,
	LOAD_PAYMENT_FAILURE_MODAL,
	STOP_PAYMENT_FAILURE_MODAL,
} from "./LoggedInLayoutActionTypes";

export const getSubscriptionForLoggedInUser = (loggedInUserSubscriptionId) => {
	return {
		type: FETCH_SUBSCRIPTION_FOR_LOGGED_IN_USER,
		payload: {
			loggedInUserSubscriptionId,
		},
	};
};

export const loggedInUserSubscriptionFetchSuccess = (fetchedSubscription) => {
	return {
		type: LOGGED_IN_USER_SUBSCRIPTION_FETCH_SUCCESS,
		payload: {
			fetchedSubscription,
		},
	};
};

export const loggedInUserSubscriptionFetchFail = () => {
	return {
		type: LOGGED_IN_USER_SUBSCRIPTION_FETCH_FAIL,
	};
};

export const refreshSubscription = (subscriptionId) => {
	return {
		type: REFRESH_SUBSCRIPTION,
		payload: {
			subscriptionId,
		},
	};
};

export const refreshSubscriptionSuccess = (fetchedSubscription) => {
	return {
		type: REFRESH_SUBSCRIPTION_SUCCESS,
		payload: {
			fetchedSubscription,
		},
	};
};

export const loadDataFromUrl = (url, body, sideEffectFns) => {
	return {
		type: LOAD_DATA_FROM_URL,
		payload: {
			url,
			body,
			sideEffectFns,
		},
	};
};

export const loadDataFromUrlSuccess = () => {
	return {
		type: LOAD_DATA_FROM_URL_SUCCESS,
	};
};

export const loadDataFromUrlFail = () => {
	return {
		type: LOAD_DATA_FROM_URL_FAIL,
	};
};

export const loadPaymentFailureModal = () => {
	return {
		type: LOAD_PAYMENT_FAILURE_MODAL,
	};
};

export const stopPaymentFailureModal = () => {
	return {
		type: STOP_PAYMENT_FAILURE_MODAL,
	};
};
