import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isDataPresent } from "utils/object_util";
import WbModal, { WbModalTitle } from "components/WbModal";
import SetupAccount from "./SetupAccount";
import { getSingleIntegration } from "components/Integrations/IntegrationsActions";
import { reconnectAccount } from "../AccountsActions";

export default function ReconnectAccount({ reconnectIntegrationId, reconnectAccountId, closeModal }:any) {
    const dispatch = useDispatch();
    const { subscription } = useSelector((state:any) => state.LoggedInLayout);
    const { singleIntegration: reconnectIntegration } = useSelector((state:any) => state.Integrations);
    const subscriptionId = subscription?._id?.toString();

    useEffect(() => {
        dispatch(getSingleIntegration(reconnectIntegrationId));
    }, []);

    const reconnectAccountHandler = (apiKeysFormData:any) => {
        dispatch(reconnectAccount(subscriptionId, reconnectAccountId, apiKeysFormData));
    };

    return (
        <WbModal toggle={closeModal} isOpen={true} size="lg">
            <WbModalTitle title="Reconnect Account" toggle={closeModal} />
            {isDataPresent(reconnectIntegration) && (
                <SetupAccount saveAccountHandler={reconnectAccountHandler} integration={reconnectIntegration} setupType="reconnect" />
            )}
        </WbModal>
    );
}
