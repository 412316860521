import { Component } from "react";
import { connect } from "react-redux";
import {
  isEmpty,
  filter,
  unionBy,
  cloneDeep,
  camelCase,
  some,
  get,
  isEqual,
  find,
} from "lodash";

import { getHelperTextHtmlJsxFromParts } from "utils/object_util";
import { loadDataFromUrl } from "layouts/LoggedInLayout/LoggedInLayoutActions";

import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbLoader from "components/WbLoader";
import WbIntegrationImage from "components/WbIntegrationImage";

import {
  selectApiData,
  initApiDataInReducer,
  setDataFormatterOption,
} from "../../FlowBuilderActions";

import ApiData from "./ApiData";

class SetUpActionTemplateModal extends Component {
  state = {
    apiData: null,
    apiDataToDisplay: null,
    isLoadingDynamicApiData: false,
    jsonDetails: [{}],
    mainFlowData: [{}],
  };

  apiDataSelectHandler = (stageIndex, apiData, jsonCustomInputDetails) => {
    this.props.selectApiData(stageIndex, apiData, jsonCustomInputDetails);
    this.setState((prevState) => {
      const { apiData: previousAllApiData } = prevState;
      const apiDataWithParentAsCurrentSelectedApiData = filter(
        previousAllApiData,
        { parentStaticDataSlug: apiData.slug }
      );

      return {
        apiDataToDisplay: unionBy(
          prevState.apiDataToDisplay,
          apiDataWithParentAsCurrentSelectedApiData,
          "slug"
        ),
      };
    });
    const { userInput } = jsonCustomInputDetails;

    const { commonData, flowObj } = this.props;

    const { dynamicDataLoadOptions } = apiData;
    if (isEmpty(dynamicDataLoadOptions)) return;

    const clonedCommonData = cloneDeep(commonData);
    const { slug: apiDataSlug } = apiData;
    const currentStageCommonData = clonedCommonData[stageIndex];
    currentStageCommonData[camelCase(apiDataSlug)] = userInput.value;

    const noOfDynamicDataLoadOptions = dynamicDataLoadOptions.length;
    const loadDynamicData = (index) => {
      if (index === noOfDynamicDataLoadOptions) return;
      const eachDynamicDataLoadOption = dynamicDataLoadOptions[0];
      const nextIndex = index + 1;

      const { bodyParamsRequired, url } = eachDynamicDataLoadOption;
      const bodyParams = {};
      const isAnyBodyParamNotPresent = some(
        bodyParamsRequired,
        (eachBodyParamRequired) => {
          if (isEmpty(currentStageCommonData[eachBodyParamRequired]))
            return true;
          bodyParams[eachBodyParamRequired] =
            currentStageCommonData[eachBodyParamRequired];
        }
      );

      if (isAnyBodyParamNotPresent) {
        this.setState({
          isLoadingDynamicApiData: false,
        });

        return loadDynamicData(nextIndex);
      }

      this.setState({
        isLoadingDynamicApiData: true,
      });

      const dynamicDataLoadOptionsSideEffect = (urlLoadedData) => {
        this.setState((prevState) => {
          let filteredApiDataToDisplay = [];

          prevState.apiDataToDisplay?.forEach((data) => {
            if (
              !isEmpty(get(data, "dataLoadOptions.dataLoadUrlDetails", {})) ||
              get(data, "dataLoadOptions.onDynamicDataLoadRetainField", false)
            ) {
              filteredApiDataToDisplay.push(data);
            }

            if (!isEmpty(data.dynamicDataLoadOptions)) {
              filteredApiDataToDisplay.push(data);
            }

            urlLoadedData?.forEach((prev) => {
              isEqual(data, prev) && filteredApiDataToDisplay.push(prev);
            });
          });

          const unionApiDatawithUrlData = unionBy(
            filteredApiDataToDisplay,
            urlLoadedData,
            "slug"
          );
          // this.setState({ mainFlowData: unionApiDatawithUrlData });
          this.apiDataHandler(unionApiDatawithUrlData);

          return {
            apiData: unionApiDatawithUrlData,
            apiDataToDisplay: unionApiDatawithUrlData,
            isLoadingDynamicApiData: false,
          };
        });
        const { apiData: reducerApiData } =
          flowObj.stages[stageIndex].stageItems.setupActionTemplate;
        this.props.initApiDataInReducer(
          stageIndex,
          urlLoadedData,
          reducerApiData
        );
        return loadDynamicData(nextIndex);
      };
      this.props.loadDataFromUrl(
        `${window.wyzebulbApiBaseUrl}/${url}`,
        bodyParams,
        [dynamicDataLoadOptionsSideEffect]
      );
    };
    loadDynamicData(0);
  };

  async componentDidMount() {
    const { selectedAction, actionApiRequiredData, stageIndex, flowObj } =
      this.props;

    const actionApiDataRequiredDataForSelectedAction = find(
      actionApiRequiredData,
      (eachActionApiRequiredData) =>
        selectedAction.selectedActionSlug ===
        eachActionApiRequiredData.actionSlug
    );

    const { apiStaticData, apiDynamicDataLoadOptions } =
      actionApiDataRequiredDataForSelectedAction;
    await this.setState({
      apiData: apiStaticData,
      apiDataToDisplay: filter(apiStaticData, (eachApiStaticData) =>
        isEmpty(eachApiStaticData.parentStaticDataSlug)
      ),
    });

    const { apiData } = this.state;
    const { apiData: reducerApiData } =
      flowObj.stages[stageIndex].stageItems.setupActionTemplate;
    this.props.initApiDataInReducer(stageIndex, apiData, reducerApiData);

    if (!isEmpty(apiDynamicDataLoadOptions)) {
      await this.setState({
        isLoadingDynamicApiData: true,
      });
      const { commonData } = this.props;
      const clonedCommonData = cloneDeep(commonData);
      const currentStageCommonData = clonedCommonData[stageIndex];
      const { bodyParamsRequired, url } = apiDynamicDataLoadOptions;
      const bodyParams = {};
      bodyParamsRequired?.forEach((eachBodyParamRequired) => {
        bodyParams[eachBodyParamRequired] =
          currentStageCommonData[eachBodyParamRequired];
      });
      const dynamicDataLoadOptionsSideEffect = (urlLoadedData) => {
        this.setState((prevState) => {
          const filteredApiDataToDisplay = [];

          prevState.apiDataToDisplay?.forEach((data) => {
            if (
              !isEmpty(get(data, "dataLoadOptions.dataLoadUrlDetails", {})) ||
              get(data, "dataLoadOptions.onDynamicDataLoadRetainField", false)
            ) {
              filteredApiDataToDisplay.push(data);
            }

            if (!isEmpty(data.dynamicDataLoadOptions)) {
              filteredApiDataToDisplay.push(data);
            }

            urlLoadedData?.forEach((prev) => {
              isEqual(data, prev) && filteredApiDataToDisplay.push(prev);
            });
          });

          const unionApiDatawithUrlData = unionBy(
            filteredApiDataToDisplay,
            urlLoadedData,
            "slug"
          );
          return {
            apiData: unionApiDatawithUrlData,
            apiDataToDisplay: filter(unionApiDatawithUrlData, (eachApiData) =>
              isEmpty(eachApiData.parentStaticDataSlug)
            ),
            isLoadingDynamicApiData: false,
          };
        });
      };
      this.props.loadDataFromUrl(
        `${window.wyzebulbApiBaseUrl}/${url}`,
        bodyParams,
        [dynamicDataLoadOptionsSideEffect]
      );
    }
  }

  apiDataHandler = (currentFlows) => {
    const { stageIndex, flowObj } = this.props;
    let requiredFlows = [];
    const filterFlows =
      flowObj.stages[stageIndex].stageItems.setupActionTemplate.apiData;
    // const currentFlows = this.state.mainFlowData;

    filterFlows?.forEach((flow) => {
      if (isEmpty(requiredFlows)) {
        requiredFlows.push(flow);
      }
      currentFlows?.forEach((current) => {
        if (flow.integrationSpecificSlug === current.integrationSpecificSlug) {
          requiredFlows.push(flow);
        }
      });
    });

    requiredFlows = [...new Set(requiredFlows)];
    flowObj.stages[stageIndex].stageItems.setupActionTemplate.apiData =
      requiredFlows;
  };

  dataFormatHandler = (dataFormatObj) => {
    const {
      stageIndex,
      selectedFormatterOptionObj,
      selectedFormatterSlug,
      eachApiDataSlug,
    } = dataFormatObj;
    this.props.setDataFormatterOption(
      stageIndex,
      selectedFormatterOptionObj,
      selectedFormatterSlug,
      eachApiDataSlug
    );
  };

  render() {
    const {
      stageIndex,
      commonData,
      flowObj,
      integrationDisplayText,
      userHelper,
      slug,
    } = this.props;
    const { helperTextHtmlParts } = userHelper;
    const helperTextHtmlJsx =
      getHelperTextHtmlJsxFromParts(helperTextHtmlParts);
    const { dataFromPreviousStages, apiData: reducerApiData } =
      flowObj.stages[stageIndex].stageItems.setupActionTemplate;

    return (
      <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
        <WbModalTitle
          toggle={this.props.closeModal}
          title="Setup Action Template"
        />
        <div className="flex flex-col items-center p-4">
          <WbIntegrationImage
            slug={slug}
            altText={integrationDisplayText}
            avtarSize="80"
          />
          <h1
            className="text-xl"
            dangerouslySetInnerHTML={{
              __html: helperTextHtmlJsx,
            }}
          ></h1>
          <div className="w-[80%]">
            {this.state.apiDataToDisplay &&
              this.state.apiDataToDisplay.map((eachApiData, index) => {
                const reducerSelectedApiData = find(reducerApiData, {
                  slug: eachApiData.slug,
                });

                return (
                  <div className="mb-4" key={index}>
                    <ApiData
                      onSelectApiData={(
                        selectedApiDataOption,
                        jsonDetailsOps
                      ) =>
                        this.apiDataSelectHandler(
                          stageIndex,
                          eachApiData,
                          selectedApiDataOption,
                          jsonDetailsOps
                        )
                      }
                      commonData={commonData}
                      apiData={eachApiData}
                      userHelper={eachApiData.userHelper}
                      dataFromPreviousStages={dataFromPreviousStages}
                      reducerSelectedApiData={reducerSelectedApiData}
                      stageIndex={stageIndex}
                      dataFormatHandler={(
                        selectedFormatterOptionObj,
                        selectedFormatterSlug
                      ) =>
                        this.dataFormatHandler({
                          stageIndex,
                          selectedFormatterOptionObj,
                          selectedFormatterSlug,
                          eachApiDataSlug: eachApiData.slug,
                        })
                      }
                    />
                  </div>
                );
              })}
            {this.state.isLoadingDynamicApiData && (
              <div className="flex flex-col items-center">
                <WbLoader />
                <h6 className="mt-2">
                  Please wait. We are fetching the required fields.
                </h6>
              </div>
            )}
          </div>
        </div>
        <div className="float-right p-4">
          <WbButton onClick={this.props.finishSetup}>Save + Continue</WbButton>
        </div>
      </WbModal>
    );
  }
}

const mapStateToProps = ({ FlowBuilder }) => {
  const { commonData, flowObj } = FlowBuilder;

  return {
    commonData,
    flowObj,
  };
};

export default connect(mapStateToProps, {
  initApiDataInReducer,
  selectApiData,
  loadDataFromUrl,
  setDataFormatterOption,
})(SetUpActionTemplateModal);
