import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as Tooltip from "@radix-ui/react-tooltip";

import "./axiosInterceptor";
import "./utils/firebase";
import AppContainer from "./components/App";
import { configureStore } from "./reduxStore";

const reduxStore = configureStore({});

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

if (process.env.REACT_APP_DEBUG_STORE === "yes") {
  reduxStore.subscribe(() => {
    const state: any = reduxStore.getState();
    const flowBuilder = state.FlowBuilder;
    console.log(flowBuilder);
  });
}

const ToastRoot = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t} position={t.position || "top-center"}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>X</button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default function MainApp() {
  return (
    <>
      <Provider store={reduxStore}>
        <Tooltip.Provider>
          <Elements stripe={stripePromise}>
            <BrowserRouter>
              <Routes>
                <Route path="*" element={<AppContainer />} />
              </Routes>
            </BrowserRouter>
          </Elements>
        </Tooltip.Provider>
      </Provider>
      <ToastRoot />
    </>
  );
}
