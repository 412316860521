import {
	REGISTER_VIA_WYZEBULB,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOGGED_IN,
	USER_NOT_LOGGED_IN,
	CHECK_AUTH_STATE,
	LOGIN_VIA_WYZEBULB,
	LOGIN_VIA_FACEBOOK,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
} from "./AuthActionTypes";

const INIT_STATE = {
	isRegisteringViaWyzebulb: false,
	isLoggingInViaWyzebulb: false,
	isLoggingInViaFacebook: false,
	isCheckingAuthState: true,
	isUserLoggedIn: false,
	loggedInUserSubscriptionId: "",
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case REGISTER_VIA_WYZEBULB:
			return {
				...state,
				isRegisteringViaWyzebulb: true,
			};
		case REGISTER_SUCCESS:
		case REGISTER_FAIL:
			return {
				...state,
				isRegisteringViaWyzebulb: false,
			};
		case LOGIN_VIA_WYZEBULB:
			return {
				...state,
				isLoggingInViaWyzebulb: true,
			};
		case LOGIN_VIA_FACEBOOK:
			return {
				...state,
				isLoggingInViaFacebook: true,
			};
		case LOGIN_SUCCESS:
		case LOGIN_FAIL:
			return {
				...state,
				isLoggingInViaWyzebulb: false,
				isLoggingInViaFacebook: false,
			};
		case CHECK_AUTH_STATE:
			return {
				...state,
				isCheckingAuthState: true,
			};
		case USER_LOGGED_IN:
			return {
				...state,
				isCheckingAuthState: false,
				isUserLoggedIn: true,
				loggedInUserSubscriptionId: payload.loggedInUserSubscriptionId,
			};
		case USER_NOT_LOGGED_IN:
			return {
				...state,
				isCheckingAuthState: false,
				isUserLoggedIn: false,
			};
		default:
			return { ...state };
	}
};
