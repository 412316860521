import { takeEvery, call, put } from "redux-saga/effects";
import { delay } from "redux-saga";

import { asyncPost, asyncPostWithoutErrHandler } from "utils/async_util";
import {
	RESET_PASSWORD,
	VERIFY_RESET_PASSWORD_TOKEN,
} from "./ResetPasswordActionTypes";
import {
	resetPasswordFail,
	resetPasswordSuccess,
	verifyResetPasswordTokenFail,
	verifyResetPasswordTokenSuccess,
} from "./ResetPasswordActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* resetPasswordSaga({ payload }) {
	const { newPassword, resetPasswordToken } = payload;
	try {
		const [err, res] = yield call(
			asyncPost,
			`/account/password/resetPassword`,
			{
				newPassword,
				resetPasswordToken,
			}
		);

		if (err) {
			return yield put(resetPasswordFail());
		}

		if (!res.data.success) {
			showErrorToast(res?.data?.message);
			return yield put(resetPasswordFail());
		}

		showSuccessToast(
			`Password changed successfully. You will be redirected to the login page in 2 seconds.`
		);
		yield delay(2000);
		yield put(resetPasswordSuccess());
	} catch (err) {
		showErrorToast();
		return yield put(resetPasswordFail());
	}
}

function* verifyResetPasswordTokenSaga({ payload }) {
	const { resetPasswordToken } = payload;
	try {
		const [err, res] = yield call(
			asyncPostWithoutErrHandler,
			`/account/password/verifyPasswordToken`,
			{
				resetPasswordToken,
			}
		);

		if (err) {
			return yield put(
				verifyResetPasswordTokenFail(err.response.data.message)
			);
		}

		if (!res.data.success) {
			return yield put(verifyResetPasswordTokenFail(res.data.message));
		}

		return yield put(verifyResetPasswordTokenSuccess());
	} catch (err) {
		showErrorToast();
		return yield put(verifyResetPasswordTokenFail());
	}
}

export function* watchResetPasswordContainer() {
	yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
	yield takeEvery(VERIFY_RESET_PASSWORD_TOKEN, verifyResetPasswordTokenSaga);
}
