import { WbCard } from "components/WbCard";
import { get } from "lodash";
import { useState, useCallback, useEffect } from "react";
import callApi from "utils/callApi";
import { MdArrowForwardIos } from "react-icons/md";
import SegmentNameAndDateSection from "../Segments/SegmentNameAndDateSection";
import { useNavigate } from "react-router-dom";
import WbLoader from "components/WbLoader";

export default function Senders(props: any) {
  const [senders, setSenders] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: any = await callApi({
        url: `/builtInTools/dataStudio/listSenders`,
        method: "POST",
        body: {
          findQuery: {},
          projection: {
            senderName: 1,
            email: 1,
            createdAt: 1,
            updatedAt: 1,
          },
          sort: { updatedAt: -1 },
          limit: 5,
        },
      });
      setSenders(get(response, "data.docs", []));
      setIsLoading(false);
    } catch (error: any) {}
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {isLoading ? (
        <WbLoader />
      ) : (
        <>
          {senders?.map((sender: any) => (
            <div key={sender._id}>
              <WbCard
                className="mx-auto mb-2 w-full hover:cursor-pointer hover:bg-orange-50 dark:bg-gray-600 dark:hover:bg-slate-500 md:w-[60%]"
                title={sender.senderName}
                onClick={() => navigate(`${sender._id}`)}
              >
                <div className="flex w-full items-center">
                  <div className="flex w-[98%] items-center justify-between">
                    <SegmentNameAndDateSection
                      name={sender.senderName}
                      date={sender.updatedAt}
                      segmentId={sender._id}
                    />
                    <div className="flex">
                      <div className="mr-4 text-gray-500">
                        {sender.senderEmail}
                      </div>
                      <MdArrowForwardIos
                        size={25}
                        className="mb-1 text-orange-600"
                      />
                    </div>
                  </div>
                </div>
              </WbCard>
            </div>
          ))}
        </>
      )}
    </>
  );
}
