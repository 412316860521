import { useState } from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";

import useDarkSide from "./useDarkSide";

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(!darkSide);
  };

  return (
    <div className="pr-2">
      <button
        onClick={() => toggleDarkMode()}
        className=" flex items-center space-x-2 rounded-full"
      >
        {darkSide ? (
          <>
            <MdLightMode className=" rounded-full h-4 w-4 text-gray-400 group-hover:text-gray-500" size={15} />
            <span>Light Mode</span>
          </>
        ) : (
          <>
            <MdDarkMode className="rounded-full h-4 w-4 text-gray-400 group-hover:text-gray-500" size={15} />
            <span>Dark Mode</span>
          </>
        )}
      </button>
    </div>
  );
}
