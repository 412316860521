import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

const ComparePlans = () => {
  return (
    <table className="w-[100%] border-collapse border border-slate-400 text-left">
      <thead>
        <tr>
          <th className="border p-2">Features</th>
          <th className="border p-2 underline">INDIVIDUAL</th>
          <th className="border p-2 underline">
            {/*<span className="p-1 border-orange-800 border text-orange-800 rounded-2xl text-xs">Best value</span>*/}
            <div className="">STARTUP</div>
          </th>
          <th className="border p-2 underline">BUSINESS</th>
          <th className="border p-2 underline">CUSTOM PLAN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border p-2">Tasks per month</td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            3,000
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;10,000
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;50,000
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;100 - 2000
          </td>
        </tr>
        <tr>
          <td className="border p-2">Price / month</td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;<b>$15</b> / month{" "}
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;<b>$39</b> / month
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;<b>$129</b> / month
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;<b>$0.01</b> / Task
          </td>
        </tr>
        <tr>
          <td className="border p-2">Prorata cost</td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-4 inline h-5 w-5 text-green-400" />
            &nbsp;$0.05 / Task
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;$0.04 / Task
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;$0.03 / Task
          </td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No prorata cost
          </td>
        </tr>
        <tr>
          <td className="border p-2">Auto-retry</td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No auto retry
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-4 inline h-5 w-5 text-green-400" />
            &nbsp;Auto retry
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-4 inline h-5 w-5 text-green-400" />
            &nbsp;Auto retry
          </td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No auto retry
          </td>
        </tr>
        <tr>
          <td className="border p-2">Web Conference Support</td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;Yes
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;Yes
          </td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No
          </td>
        </tr>
        <tr>
          <td className="border p-2">On demand integrations</td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No
          </td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No
          </td>
          <td className="border p-2">
            <CheckCircleIcon className="mr-2 inline h-5 w-5 text-green-400" />
            &nbsp;Yes
          </td>
          <td className="border p-2">
            <XMarkIcon className="mr-2 inline h-5 w-5 text-red-400" />
            &nbsp; No
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ComparePlans;
