import { useEffect, Suspense, lazy as lazyLoader } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import { datadogRum } from "@datadog/browser-rum";

import SubscriptionRolesEnum from "enums/SubscriptionRolesEnum";
import { checkAuthState } from "modules/Auth/AuthActions";
import { storeQueryDetails } from "./AppActions";
import { stopPaymentFailureModal } from "layouts/LoggedInLayout/LoggedInLayoutActions";

import PaymentFailureModal from "../SelectPlan/PaymentFailureModal";
import LoggedInLayout from "layouts/LoggedInLayout";
import NonLoggedInLayout from "layouts/NonLoggedInLayout";

import WbLoader from "components/WbLoader";

/** Flows Routes */
import FlowBuilder from "modules/FlowBuilder";
import FlowLibrary from "modules/FlowLibrary";

/** User Routes */
import Auth from "modules/Auth";
import ForgotPassword from "modules/Auth/ForgotPassword";
import ResetPassword from "modules/Auth/ResetPassword";
import DeleteSubscription from "modules/User/DeleteSubscription";
import AdminControls from "modules/User/AdminControls";
import StudioRouter from "modules/BuiltInTools/DataStudio";

const TasksRouter = lazyLoader(() => import("modules/Tasks"));
const BuiltInToolsRouter = lazyLoader(() => import("modules/BuiltInTools"));
const AppsRouter = lazyLoader(() => import("modules/Apps"));
const FlowsRouter = lazyLoader(() => import("modules/Flows"));
const AccountRouter = lazyLoader(() => import("modules/Account"));
const SupportRouter = lazyLoader(() => import("modules/Support"));

const GA_TRACKING_ID = "G-N6CC73QHQ6";
ReactGA.initialize(GA_TRACKING_ID);

const initialiseDataDogRUM = (subscription) => {
  datadogRum.init({
    applicationId: "65cef849-66c2-40f0-90b1-0ac22ba46621",
    clientToken: "pubf531129cf254242ed82f3087a35744a5",
    site: "datadoghq.com",
    service: "wb-main",
    env: process.env.REACT_APP_NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  /**Adding user attributes */
  datadogRum.setUser({
    name: subscription?.name,
    email: subscription?.email,
    subId: subscription?._id,
  });

  datadogRum.startSessionReplayRecording();
};

const setupZohoParams = (subscription) => {
  window.$zoho?.salesiq.visitor.name(subscription?.name);
  window.$zoho?.salesiq.visitor.email(subscription?.email);
  window.$zoho?.salesiq.visitor.info({ ...subscription });
};

export default function AppContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isCheckingAuthState, isUserLoggedIn } = useSelector(
    (state) => state.Auth
  );
  const { subscription, shouldShowPaymentFailureModal } = useSelector(
    (state) => state.LoggedInLayout
  );

  const shouldShowAdminControls = subscription?.roles?.includes(
    SubscriptionRolesEnum.ADMIN
  );

  useEffect(() => {
    /**Initialise Datadog rum only in prod and on starting only */
    if (process.env.REACT_APP_NODE_ENV === "production" && subscription) {
      initialiseDataDogRUM(subscription);
      setupZohoParams(subscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  useEffect(() => {
    dispatch(storeQueryDetails(location?.pathname + location?.search));
    dispatch(checkAuthState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    ReactGA.pageview(location?.pathname + location?.search);
  }, [location]);

  return (
    <>
      {/* User Not Logged In - Render Routes */}
      {isCheckingAuthState ||
        (!isUserLoggedIn && (
          <Routes>
            <Route element={<NonLoggedInLayout />}>
              <Route index path="/login" element={<Auth />} />
              <Route path="/register" element={<Auth />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="*"
                element={<Navigate to="/login" replace={true} />}
              />
            </Route>
          </Routes>
        ))}

      {/* User  Logged In - Render Routes */}
      {!isCheckingAuthState && isUserLoggedIn && (
        <Suspense fallback={<WbLoader />}>
          <Routes>
            <Route element={<LoggedInLayout />}>
              <Route path="/tasks/*" element={<TasksRouter />} />
              <Route
                path="/built-in-tools/*"
                exact
                element={<BuiltInToolsRouter />}
              />
              <Route
                path="built-in-tools/data-studio/*"
                exact
                element={<StudioRouter />}
              />
              <Route path="/apps/*" exact element={<AppsRouter />} />
              <Route path="/flows/*" exact element={<FlowsRouter />} />
              <Route path="/account/*" element={<AccountRouter />} />
              <Route path="/dashboard" element={<FlowLibrary />} />

              <Route path="/flow-library" element={<FlowLibrary />} />

              <Route path="/flow-builder" element={<FlowBuilder />} />
              <Route path="/delete/me" element={<DeleteSubscription />} />
              {shouldShowAdminControls && (
                <>
                  <Route path="/support/*" element={<SupportRouter />} />
                  <Route
                    path="/nirvahane-controls"
                    element={<AdminControls />}
                  />
                </>
              )}
              <Route
                path="*"
                element={<Navigate to="/flows" replace={true} />}
              />
            </Route>
          </Routes>
        </Suspense>
      )}
      {shouldShowPaymentFailureModal && (
        <PaymentFailureModal
          closeModal={() => dispatch(stopPaymentFailureModal())}
        />
      )}
    </>
  );
}
