import { get, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { HiChevronDown } from "react-icons/hi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";

import { WbPageHeader } from "components/WbPage";
import WbMenu from "components/WbMenu";
import WbBadge from "components/WbBadge";

import callApi from "utils/callApi";
import SendEmail from "./SendEmail";
import ConnectedFlow from "./ConnectedFlow";
import DetailTabs from "../DetailTabs";
import LeadFields from "./LeadFields";

export default function Details({ data: leadId }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const [responseData, setResponse] = useState("");
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  leadId = location?.pathname?.split("/")?.at(-1);
  const fetchData = useCallback(async () => {
    try {
      const response: any = await callApi({
        url: "/builtInTools/dataStudio/fetchSingleLead",
        method: "POST",
        body: {
          findQuery: { leadId },
          limit: 1,
        },
      });

      setResponse(get(response, "data", []));
    } catch (error: any) {}
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getModifiedArray = (responseData: any) => {
    let newData: any = [];
    const source = get(responseData, "source", "");
    const leadId = get(responseData, "leadId", "");
    const email = get(responseData, "email", "");
    const flowFormattedName = get(responseData, "flowFormattedName", "");
    const formattedData = get(responseData, "formattedData", {});
    newData = [
      {
        source,
        leadId,
        email,
        flowFormattedName,
      },
    ];

    map(formattedData, (value, key) => {
      newData[0][key] = value;
    });

    return newData;
  };

  const singleLead = getModifiedArray(responseData);
  const flowName = get(singleLead, "0.flowFormattedName", "");
  const flowId = get(responseData, "flowId", "");
  const rawData = get(responseData, "rawData", "");

  const toggleSendEmailModal = () => {
    setShowSendEmailModal(!showSendEmailModal);
  };

  const flowDetailsMenuItems = [
    {
      text: "Send Email",
      icon: <MdOutlineEmail size={20} />,
      onClick: () => toggleSendEmailModal(),
    },
  ];

  return (
    <>
      <div>
        <WbPageHeader className={"w-full"} path="/flows">
          <IoMdArrowRoundBack
            size={20}
            className={"mb-1 cursor-pointer"}
            onClick={() => navigate(`/built-in-tools/data-studio/leads`)}
          />
          <div className="flex w-full justify-between">
            <div className="flex h-11 flex-row justify-between border-gray-500 text-right  text-xl font-semibold">
              <div className="flex items-center">
                <span className="dark: flex items-center text-gray-700 dark:text-gray-400">
                  {leadId}
                  <WbBadge
                    className="ml-2 rounded-md capitalize"
                    color={"success" || "warning"}
                  >
                    {"Created"}
                  </WbBadge>
                </span>
              </div>
            </div>

            <div className="flex items-start">
              <WbMenu
                menuItems={flowDetailsMenuItems}
                color="gray"
                className="flex h-10 w-full cursor-pointer items-center justify-center rounded-md border-2 border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white"
              >
                <span className="mr-1">Actions</span>{" "}
                <HiChevronDown className="dark:text-white" size={28} />
              </WbMenu>
            </div>
          </div>
        </WbPageHeader>
      </div>

      <DetailTabs />
      <LeadFields leadData={singleLead[0]} heading={"Details"} />
      <ConnectedFlow flowName={flowName} flowId={flowId} />
      <LeadFields leadData={rawData} heading={"Additional Fields"} />

      {showSendEmailModal && (
        <SendEmail
          email={singleLead[0].leadEmail}
          toggleSendEmailModal={toggleSendEmailModal}
        />
      )}
    </>
  );
}
