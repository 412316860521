import {
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	VERIFY_RESET_PASSWORD_TOKEN,
	VERIFY_RESET_PASSWORD_TOKEN_SUCCESS,
	VERIFY_RESET_PASSWORD_TOKEN_FAIL,
} from "./ResetPasswordActionTypes";

export const resetPassword = (newPassword, resetPasswordToken) => {
	return {
		type: RESET_PASSWORD,
		payload: {
			newPassword,
			resetPasswordToken,
		},
	};
};

export const resetPasswordSuccess = () => {
	return {
		type: RESET_PASSWORD_SUCCESS,
	};
};

export const resetPasswordFail = () => {
	return {
		type: RESET_PASSWORD_FAIL,
	};
};

export const verifyResetPasswordToken = (resetPasswordToken) => {
	return {
		type: VERIFY_RESET_PASSWORD_TOKEN,
		payload: {
			resetPasswordToken,
		},
	};
};

export const verifyResetPasswordTokenSuccess = () => {
	return {
		type: VERIFY_RESET_PASSWORD_TOKEN_SUCCESS,
	};
};

export const verifyResetPasswordTokenFail = (errMessage) => {
	return {
		type: VERIFY_RESET_PASSWORD_TOKEN_FAIL,
		payload: {
			errMessage,
		},
	};
};
