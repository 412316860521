export const FETCH_FLOWS = "FETCH_FLOWS";
export const FETCH_FLOWS_SUCCESS = "FETCH_FLOWS_SUCCESS";
export const FETCH_FLOWS_FAIL = "FETCH_FLOWS_FAIL";
export const CHANGE_FLOW_NAME = "CHANGE_FLOW_NAME";
export const CHANGE_FLOW_NAME_SUCCESS = "CHANGE_FLOW_NAME_SUCCESS";
export const CHANGE_FLOW_NAME_FAIL = "CHANGE_FLOW_NAME_FAIL";
export const SHOW_CHANGE_FLOW_NAME_MODAL = "SHOW_CHANGE_FLOW_NAME_MODAL";
export const HIDE_CHANGE_FLOW_NAME_MODAL = "HIDE_CHANGE_FLOW_NAME_MODAL";
export const CHANGE_FLOWS_STATE = "CHANGE_FLOWS_STATE";
export const FETCH_FLOWS_COUNT = "FETCH_FLOWS_COUNT"; 
export const FETCH_FLOWS_COUNT_SUCCESS = "FETCH_FLOWS_COUNT_SUCCESS";
