import { call, takeEvery, put, all } from "redux-saga/effects";
import _ from "lodash";

import { asyncPost } from "utils/async_util";
import { showErrorToast } from "components/WbToast";
import {
	GET_SINGLE_INTEGRATION,
	GET_SINGLE_INTEGRATION_SUCCESS,
} from "./IntegrationsActionTypes";
import {
	getSingleIntegrationFail,
	getSingleIntegrationSuccess,
} from "./IntegrationsActions";

function* getSingleIntegrationSaga({ payload }) {
	const { integrationId } = payload;

	try {
		const [err, res] = yield call(
			asyncPost,
			`/apps/${integrationId}`
		);
		if (err) {
			return yield put(getSingleIntegrationFail());
		}

		if (!res.data.success) {
			showErrorToast(res?.data?.message);
			return yield all([put(getSingleIntegrationFail())]);
		}

		return yield put(
			getSingleIntegrationSuccess(
				res.data.integration,
				payload.sideEffectFns
			)
		);
	} catch (err) {
		showErrorToast();
		return yield all([put(getSingleIntegrationFail())]);
	}
}

function getSingleIntegrationSuccessSaga({ payload }) {
	const { sideEffectFns } = payload;
	_.each(sideEffectFns, (eachFn) => {
		eachFn(payload);
	});
}

export function* watchIntegrations() {
	yield takeEvery(GET_SINGLE_INTEGRATION, getSingleIntegrationSaga);
	yield takeEvery(
		GET_SINGLE_INTEGRATION_SUCCESS,
		getSingleIntegrationSuccessSaga
	);
}
