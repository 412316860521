import {
  FETCH_FLOWS,
  FETCH_FLOWS_SUCCESS,
  FETCH_FLOWS_FAIL,
  CHANGE_FLOW_NAME,
  CHANGE_FLOW_NAME_SUCCESS,
  CHANGE_FLOW_NAME_FAIL,
  SHOW_CHANGE_FLOW_NAME_MODAL,
  HIDE_CHANGE_FLOW_NAME_MODAL,
  FETCH_FLOWS_COUNT,
  FETCH_FLOWS_COUNT_SUCCESS,
} from "./FlowsActionTypes";

const INIT_STATE = {
  fetchingFlows: false,
  flows: null,
  changeFlowNameFlowId: "",
  isChangingFlowName: false,
  shouldShowChangeFlowNameModal: false,
  flowsCount: null,
};

const flowReducer = (state = INIT_STATE, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_FLOWS:
      return {
        ...state,
        fetchingFlows: true,
      };
    case FETCH_FLOWS_SUCCESS:
      return {
        ...state,
        fetchingFlows: false,
        flows: payload.flows,
      };
    case FETCH_FLOWS_FAIL:
      return {
        ...state,
        fetchingFlows: false,
        flows: null,
      };
    case CHANGE_FLOW_NAME:
      return {
        ...state,
        isChangingFlowName: true,
      };
    case CHANGE_FLOW_NAME_SUCCESS:
      return {
        ...state,
        isChangingFlowName: false,
        shouldShowChangeFlowNameModal: false,
      };
    case CHANGE_FLOW_NAME_FAIL:
      return {
        ...state,
        isChangingFlowName: false,
      };
    case SHOW_CHANGE_FLOW_NAME_MODAL:
      return {
        ...state,
        shouldShowChangeFlowNameModal: true,
        changeFlowNameFlowId: payload.flowId,
      };
    case HIDE_CHANGE_FLOW_NAME_MODAL:
      return {
        ...state,
        shouldShowChangeFlowNameModal: false,
      };
    case FETCH_FLOWS_COUNT:
      return {
        ...state,
      };
    case FETCH_FLOWS_COUNT_SUCCESS:
      return {
        ...state,
        flowsCount: payload.flowsCount,
      };

    default:
      return { ...state };
  }
};

export default flowReducer;
