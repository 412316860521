import { call, put, takeEvery } from "redux-saga/effects";

import { asyncPostWithoutErrHandler } from "../../utils/async_util";

import {
  FETCH_FLOW_TEMPLATES,
  FLOW_FETCH_INTEGRATIONS,
} from "./FlowLibraryActionTypes";
import {
  fetchFlowTemplatesFail,
  fetchFlowTemplatesSuccess,
  fetchIntegrationsFail,
  fetchIntegrationsSuccess,
} from "./FlowLibraryActions";

function* fetchFlowTemplatesSaga({ payload }) {
  try {
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/flowTemplate/listFlowTemplates",
      {
        populateQuery: payload.populateQuery,
        findQuery: {
          ...payload.findQuery,
          isActive: true,
        },
        limit: payload.limit,
        skip: payload.skip,
      }
    );
    if (err) {
      return yield put(fetchFlowTemplatesFail());
    }

    if (!res.data.success) {
      return yield put(fetchFlowTemplatesFail());
    }

    return yield put(fetchFlowTemplatesSuccess(res.data.flowTemplates));
  } catch (err) {
    return yield put(fetchFlowTemplatesFail());
  }
}

function* fetchIntegrationsSaga({ payload }) {
  try {
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/apps/listApps",
      {
        findQuery: payload.findQuery,
        limit: 5,
        sortQuery: {
          isActive: -1,
        },
        projectionQuery: {
          isActive: 1,
          displayText: 1,
          slug: 1,
          _id: 1,
          imageUrl: 1,
        },
      }
    );

    if (err) {
      return yield put(fetchIntegrationsFail());
    }
    if (!res.data.success) {
      return yield put(fetchIntegrationsFail());
    }
    return yield put(fetchIntegrationsSuccess(res.data.integrations));
  } catch (err) {
    return yield put(fetchIntegrationsFail());
  }
}

export function* watchFlowTemplates() {
  yield takeEvery(FETCH_FLOW_TEMPLATES, fetchFlowTemplatesSaga);
  yield takeEvery(FLOW_FETCH_INTEGRATIONS, fetchIntegrationsSaga);
}
