import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { BsPlusCircleFill } from "react-icons/bs";

import { connectAccNotApplicable } from "constants/integrations";
import { getHelperTextHtmlJsxFromParts } from "utils/object_util";
import withOAuthContainer from "layouts/OAuth";
import StateButton from "components/StateButton";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbIntegrationImage from "components/WbIntegrationImage";

import {
  showAddAccountForIntegrationModal,
  hideAddAccountForIntegrationModal,
} from "../../../Apps/AccountsActions";
import AddAccountForIntegration from "../../../Apps/Actions/AddAccountForIntegration";
import { setSelectedAccount } from "../../FlowBuilderActions";
import WebhookTrigger from "./WebhookTrigger";
import WyzebulbUpload from "./WyzebulbUpload";
class SelectAccountModal extends Component {
  state = {
    selectedAccount: null,
    reducerSelectedAccountId: "",
  };

  componentWillMount() {
    const { accounts, reducerSelectedAccount } = this.props;
    const [firstAccount] = accounts;
    const reducerSelectedAccountId = !isEmpty(reducerSelectedAccount)
      ? reducerSelectedAccount?.selectedAccountId
      : "";

    this.setState({
      selectedAccount: firstAccount,
      reducerSelectedAccountId,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { accounts: currentAccounts } = this.props;
    const { accounts: nextAccounts } = nextProps;

    const [firstCurrentAccount] = currentAccounts;
    const [firstNextAccount] = nextAccounts;

    if (isEmpty(firstCurrentAccount) && !isEmpty(firstNextAccount)) {
      this.setState({
        selectedAccount: firstNextAccount,
      });

      return;
    }
  }

  changeAccountHandler = (account) => {
    this.setState({
      selectedAccount: account,
      reducerSelectedAccountId: "",
    });
  };

  stopAccountAddition = () => {
    this.props.hideAddAccountForIntegrationModal();
  };

  finishAccountSelect = () => {
    this.props.setSelectedAccount(
      this.props.stageIndex,
      this.state.selectedAccount,
    );
    this.props.closeModal();
  };

  render() {
    const {
      accounts,
      integrationForAccountAddition,
      integrationDisplayText,
      userHelper,
      stageIndex,
      slug,
    } = this.props;
    const { helperTextHtmlParts } = userHelper;
    const helperTextHtmlJsx =
      getHelperTextHtmlJsxFromParts(helperTextHtmlParts);
    const areAccountsPresent = !isEmpty(accounts);
    const isWebhookIntegration = connectAccNotApplicable.includes(
      integrationForAccountAddition.slug,
    );
    let noAccountsFound = null;
    let showAccounts = null;
    if (!areAccountsPresent) {
      noAccountsFound = (
        <div className="flex flex-col text-center">
          <i className="iconsmind-Coconut text-xxlarge" />
          <h1 className="text-2xl">You have no accounts</h1>
          <p className="text-gray-500">
            Add accounts to create flows, store contacts and segment them by
            location, engagement, activity, and more.
          </p>
        </div>
      );
    } else {
      showAccounts = accounts?.map((account) => {
        const stringifiedAccountId = account._id.toString();
        const isCurrentAccountSelectedInReducer =
          stringifiedAccountId === this.state.reducerSelectedAccountId;
        const isCurrentAccountSelectedInSelf =
          stringifiedAccountId === this.state.selectedAccount._id.toString();
        const isCurrentAccountSelected =
          isCurrentAccountSelectedInReducer || isCurrentAccountSelectedInSelf;

        return (
          <div
            key={account._id}
            className="m-2 flex h-16 cursor-pointer flex-row items-center space-x-3 bg-gray-100 p-4 dark:bg-gray-700"
            onClick={() => this.changeAccountHandler(account)}
          >
            <input
              type="radio"
              id={stringifiedAccountId}
              name="select-account-radio"
              checked={isCurrentAccountSelected}
              onChange={() => this.changeAccountHandler(account)}
            />
            <h3 className="text-base">{account.name}</h3>
          </div>
        );
      });
    }

    let addAccountModal = null;
    if (this.props.shouldShowAddAccountForIntegrationModal) {
      addAccountModal = (
        <AddAccountForIntegration
          addIntegrationId={integrationForAccountAddition._id}
          closeModal={this.stopAccountAddition}
        />
      );
    }

    let addAccountBtn = null;
    if (integrationForAccountAddition.oAuthDetails) {
      const addBtn = (props) => {
        return (
          <StateButton
            color={areAccountsPresent ? "white" : "primary"}
            size={areAccountsPresent ? "sm" : "responsive"}
            outline={areAccountsPresent}
            showSpinner={props.isOAuthInProgress}
            spinnerColor={areAccountsPresent ? "black" : "white"}
          >
            Connect {integrationDisplayText} Account
          </StateButton>
        );
      };
      const AddBtnHoc = withOAuthContainer(addBtn);
      addAccountBtn = (
        <AddBtnHoc
          oAuthIntegration={integrationForAccountAddition}
          oAuthType={"connect"}
          subscriptionId={this.props.subscriptionId}
        ></AddBtnHoc>
      );
    } else {
      addAccountBtn = (
        <WbButton
          onClick={() => this.props.showAddAccountForIntegrationModal()}
        >
          Connect {integrationDisplayText} Account
        </WbButton>
      );
    }

    let addNewAccountBtn = null;
    if (integrationForAccountAddition.oAuthDetails) {
      const addBtn = (props) => {
        return (
          <div
            color={areAccountsPresent ? "white" : "primary"}
            size={areAccountsPresent ? "sm" : "responsive"}
            outline={areAccountsPresent}
            showSpinner={props.isOAuthInProgress}
            spinnerColor={areAccountsPresent ? "black" : "white"}
          >
            Connect a new account
          </div>
        );
      };
      const AddBtnHoc = withOAuthContainer(addBtn);
      addNewAccountBtn = (
        <AddBtnHoc
          oAuthIntegration={integrationForAccountAddition}
          oAuthType={"connect"}
          subscriptionId={this.props.subscriptionId}
        ></AddBtnHoc>
      );
    }

    return (
      <>
        <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
          <WbModalTitle toggle={this.props.closeModal} title="Select Account" />
          <div className="flex flex-col items-center  p-4">
            <WbIntegrationImage
              slug={slug}
              altText={integrationDisplayText}
              avtarSize="80"
            />
            <h1
              className="text-xl"
              dangerouslySetInnerHTML={{
                __html: helperTextHtmlJsx,
              }}
            ></h1>
            {/* Webhook Trigger & Upload Section */}
            {isWebhookIntegration &&
              (!integrationForAccountAddition.slug.includes("upload") ? (
                <WebhookTrigger
                  closeModal={this.props.closeModal}
                  stageIndex={stageIndex}
                  slug={integrationForAccountAddition.slug}
                />
              ) : (
                <WyzebulbUpload
                  closeModal={this.props.closeModal}
                  stageIndex={stageIndex}
                  slug={integrationForAccountAddition.slug}
                />
              ))}
            {/* Show Accounts Section */}
            {!isWebhookIntegration && areAccountsPresent && (
              <>
                <div className="w-[80%]">{showAccounts}</div>
                {process.env.REACT_APP_NODE_ENV !== "production" && (
                  <div className="mb-2 flex h-16 w-[78%] cursor-pointer flex-row items-center space-x-3 bg-gray-100 p-4 dark:bg-gray-700">
                    <BsPlusCircleFill className="mt-1" />
                    {addNewAccountBtn}
                  </div>
                )}
              </>
            )}
            {!isWebhookIntegration && !areAccountsPresent && (
              <>
                {noAccountsFound}
                {addAccountBtn}
              </>
            )}
          </div>
          <div className="float-right p-4">
            {areAccountsPresent ? (
              <WbButton onClick={this.finishAccountSelect}>
                Save + Continue
              </WbButton>
            ) : null}
          </div>
        </WbModal>
        {addAccountModal}
      </>
    );
  }
}

const mapStateToProps = ({ Accounts, LoggedInLayout }) => {
  const { shouldShowAddAccountForIntegrationModal } = Accounts;
  const { subscription } = LoggedInLayout;

  return {
    shouldShowAddAccountForIntegrationModal,
    subscriptionId: subscription._id.toString(),
  };
};

export default connect(mapStateToProps, {
  showAddAccountForIntegrationModal,
  hideAddAccountForIntegrationModal,
  setSelectedAccount,
})(SelectAccountModal);
