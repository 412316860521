import { call, put, takeEvery, all } from "redux-saga/effects";
import _ from "lodash";

import {
  GET_BILLING_PLANS,
  CREATE_SUBSCRIPTION_IN_STRIPE,
  SEND_MESSAGE_FOR_CONTACT_US,
  CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS,
  UPDATE_STRIPE_CARD_DETAILS,
  UPDATE_STRIPE_CARD_DETAILS_SUCCESS,
} from "./SelectPlanActionTypes";
import {
  getBillingPlansSuccess,
  getBillingPlansFail,
  createSubscriptionInStripeSuccess,
  createSubscriptionInStripeFail,
  updateCardSuccess,
  updateCardError,
} from "./SelectPlanActions";
import { refreshSubscription } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import { asyncPost } from "utils/async_util";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* getBillingPlansSaga() {
  try {
    const [err, res] = yield call(
      asyncPost,
      "/account/billing/plan/listPlans",
      {
        findQuery: {
          isActive: true,
        },
        sortQuery: {
          displayOrderIndex: 1,
        },
      }
    );
    if (err) {
      return yield put(getBillingPlansFail());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(getBillingPlansFail());
    }
    return yield put(getBillingPlansSuccess(res.data.billingPlans));
  } catch (err) {
    showErrorToast();
    return yield put(getBillingPlansFail());
  }
}

function* createSubscriptionInStripeSaga({ payload }) {
  try {
    const {
      stripeCustomerId,
      stripeCardToken,
      stripePlanId,
      subscriptionId,
      isDefaultCardExist,
      tasksCount,
      sideEffectFunctions,
    } = payload;
    const createSubscriptionBody = {
      stripeCustomerId,
      stripeCardToken,
      stripePlanId,
      isDefaultCardExist,
      tasksCount,
    };

    const [err, res] = yield call(
      asyncPost,
      "/account/billing/plan/addPlan",
      createSubscriptionBody
    );
    if (err) {
      return yield put(createSubscriptionInStripeFail());
    }
    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(createSubscriptionInStripeFail());
    }

    showSuccessToast(res?.data?.message);
    return yield all([
      put(createSubscriptionInStripeSuccess(sideEffectFunctions)),
      put(refreshSubscription(subscriptionId)),
    ]);
  } catch (err) {
    showErrorToast(err?.message);
    return yield put(createSubscriptionInStripeFail());
  }
}

function createSubscriptionInStripeSuccessSaga({ payload }) {
  const { sideEffectFunctions } = payload;
  _.each(sideEffectFunctions, (eachFn) => {
    eachFn(payload);
  });
}

function* sendMessageForContactUsSaga({ payload }) {
  const { userDetails } = payload;

  try {
    const [err, res] = yield call(asyncPost, "/support/contactUs", {
      ...userDetails,
    });
    if (err) {
      showErrorToast();
      return;
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return;
    }

    showSuccessToast(
      "Thank you for the details. Our team will contact you soon."
    );
  } catch (err) {
    showErrorToast();
  }
}

function* updateStripeCardDetailsSaga({ payload }) {
  const { stripeCardToken, stripeCustomerId, addNewCard, sideEffectFunctions } =
    payload;
  const url = addNewCard
    ? "/account/billing/payment/addPaymentCard"
    : "/account/billing/payment/updatePaymentCard";

  try {
    const [err, res] = yield call(asyncPost, url, {
      stripeCardToken,
      stripeCustomerId
    });
    if (err) {
      return yield put(updateCardError());
    }

    if (!res.data.success) {
      return yield put(updateCardError(sideEffectFunctions));
    }

    showSuccessToast(res?.data?.message || "Payment info updated");
    return yield put(updateCardSuccess(sideEffectFunctions));
  } catch (err) {
    return yield put(updateCardError());
  }
}

function updateStripeCardDetailsSideEffectFunctionsSaga({ payload }) {
  const { sideEffectFunctions } = payload;
  _.each(sideEffectFunctions, (eachFn) => {
    eachFn(payload);
  });
}

export function* watchSelectPlan() {
  yield takeEvery(GET_BILLING_PLANS, getBillingPlansSaga);
  yield takeEvery(
    CREATE_SUBSCRIPTION_IN_STRIPE,
    createSubscriptionInStripeSaga
  );
  yield takeEvery(SEND_MESSAGE_FOR_CONTACT_US, sendMessageForContactUsSaga);
  yield takeEvery(
    CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS,
    createSubscriptionInStripeSuccessSaga
  );
  yield takeEvery(UPDATE_STRIPE_CARD_DETAILS, updateStripeCardDetailsSaga);
  yield takeEvery(
    UPDATE_STRIPE_CARD_DETAILS_SUCCESS,
    updateStripeCardDetailsSideEffectFunctionsSaga
  );
}
