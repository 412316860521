import axios from "axios";
import { to } from "await-to-js";
import cookie from "react-cookies";

import { axiosWithoutErrHandler, axiosWithoutErrHandlerForFileUploads } from "../axiosInstance";

interface ServerResponse {
    data: Object;
}

export const getAccessToken = () => {
    return cookie.load("token");
};

export async function asyncPost(url: string, data = {}) {
    /*const accessToken = Object.keys(data).includes("accessToken")
    ? data.accessToken
    : getAccessToken();
    */
    const [err, res] = await to(
        axios.post<ServerResponse>(url, data, {
            headers: {
                "x-access-token": getAccessToken(),
            },
        }),
    );
    return [err, res];
}

export async function asyncGet(url: string) {
    const [err, res] = await to(
        axios.get(url, {
            headers: {
                "x-access-token": getAccessToken(),
            },
        }),
    );
    return [err, res];
}

export async function asyncPostWithoutErrHandler(url: string, data = {}) {
    const [err, res] = await to(
        axiosWithoutErrHandler.post<ServerResponse>(url, data, {
            headers: {
                "x-access-token": getAccessToken(),
            },
        }),
    );
    return [err, res];
}

export async function asyncPostFileSupport(url: string, data = {}) {
    const [err, res] = await to(
        axiosWithoutErrHandlerForFileUploads.post<ServerResponse>(url, data, {
            headers: {
                "x-access-token": getAccessToken(),
            },
        }),
    );
    return [err, res];
}
