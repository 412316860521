import {
	GET_SINGLE_INTEGRATION,
	GET_SINGLE_INTEGRATION_SUCCESS,
	GET_SINGLE_INTEGRATION_FAIL,
} from "./IntegrationsActionTypes";

export const getSingleIntegration = (integrationId, sideEffectFns) => {
	return {
		type: GET_SINGLE_INTEGRATION,
		payload: {
			integrationId,
			sideEffectFns,
		},
	};
};

export const getSingleIntegrationSuccess = (integration, sideEffectFns) => {
	return {
		type: GET_SINGLE_INTEGRATION_SUCCESS,
		payload: {
			integration,
			sideEffectFns,
		},
	};
};

export const getSingleIntegrationFail = () => {
	return {
		type: GET_SINGLE_INTEGRATION_FAIL,
	};
};
