export const GET_CURRENT_SUBSCRIBED_PLAN_DETAILS = "GET_CURRENT_SUBSCRIBED_PLAN_DETAILS";
export const GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_ERROR = "GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_ERROR";
export const GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_SUCCESS = "GET_CURRENT_SUBSCRIBED_PLAN_DETAILS_SUCCESS";
export const CANCEL_STRIPE_SUBSCRIPTION = "CANCEL_STRIPE_SUBSCRIPTION";
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS = "CANCEL_STRIPE_SUBSCRIPTION_SUCCESS";
export const CANCEL_STRIPE_SUBSCRIPTION_ERROR = "CANCEL_STRIPE_SUBSCRIPTION_ERROR";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const GET_DEFAULT_CARD_DETAILS = "GET_DEFAULT_CARD_DETAILS";
export const GET_DEFAULT_CARD_DETAILS_ERROR = "GET_DEFAULT_CARD_DETAILS_ERROR";
export const GET_DEFAULT_CARD_DETAILS_SUCCESS = "GET_DEFAULT_CARD_DETAILS_SUCCESS";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
