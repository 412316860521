import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmpty, get } from "lodash";

import { asyncPostWithoutErrHandler } from "utils/async_util";
import { impersonateUser } from "./ImpersonateUserActions";
import WbButton from "components/WbButton";
import { WbPage, WbPageBody, WbPageHeader } from "components/WbPage";
import WbSelect from "components/WbSelect";

export default function ImpersonateUser() {
  const dispatch = useDispatch();
  const [userSelected, setUserSelected] = useState([]);
  const [subs, setSubs] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          findQuery: { email: { $regex: searchEmail, $options: "i" } },
          projection: { email: 1 },
          limit: 10,
        };

        const [, res] = await asyncPostWithoutErrHandler(
          `/get-subscriptions/subs`,
          payload
        );
        if (!isEmpty(res.data)) {
          setSubs(
            get(res, "data.subscriptions", []).map((d) => {
              return { label: d?.email, value: d?._id };
            })
          );
        }
      } catch (error) {}
    })();
  }, [searchEmail]);

  const handleOnInputChange = (value) => {
    setSearchEmail(value);
  };

  const loginToUserAccount = () => {
    dispatch(impersonateUser(userSelected));
  };

  return (
    <WbPage>
      <WbPageHeader>
        <h1 className="text-xl">Impersonate User</h1>
      </WbPageHeader>
      <WbPageBody>
        <div className="mt-5 w-96 p-10">
          <WbSelect
            data={subs}
            label="Search for Email Id"
            onChange={setUserSelected}
            onInputChange={handleOnInputChange}
          />
          <div className="mt-5">
            <WbButton onClick={loginToUserAccount}>Impersonate User</WbButton>
          </div>
        </div>
      </WbPageBody>
    </WbPage>
  );
}
