import { get } from "lodash";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { FaCopy, FaMinusCircle, FaPlusCircle } from "react-icons/fa";

import { isNullOrUndefined } from "utils/object_util";
import WbButton from "components/WbButton";
import WbLoader from "components/WbLoader";

class WebhookPostData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: get(this, "props.url", ""),
            dynamicValues: [{ keyName: "name", keyValue: "Test User" }],
            loading: false,
            copied: false,
            response: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDynamicValues(i, e) {
        let dynamicValues = this.state.dynamicValues;
        dynamicValues[i][e.target.name] = e.target.value;
        this.setState({ dynamicValues });
    }

    handleUrl(e) {
        this.setState({ url: e.target.value });
    }

    addFormFields() {
        this.setState({
            dynamicValues: [...this.state.dynamicValues, { keyName: "", keyValue: "" }],
        });
    }

    removeFormFields(i) {
        let dynamicValues = this.state.dynamicValues;
        dynamicValues.splice(i, 1);
        this.setState({ dynamicValues });
    }

    postData = async (url, data) => {
        const reqBody = {};
        data.flat(1).map((item) => {
            Object.assign(reqBody, { [`${item.keyName}`]: item.keyValue });
        });

        return await axios.post(url, reqBody);
    };

    async handleSubmit(event) {
        this.setState({ loading: true });
        event.preventDefault();

        const url = get(this, "state.url", "");
        const dynamicValues = this.state.dynamicValues;

        const response = await this.postData(url, dynamicValues);
        this.setState({ loading: false, response: response.status });
    }

    render() {
        return (
            <div className="w-full dark:text-gray-700">
                <div className="space-y-3 w-[70%] mx-auto">
                    {this.state.url !== "" && (
                        <div className="space-x-3 flex flex-row items-center">
                            <input
                                className="border rounded-lg h-12 w-[80%] p-2 dark:bg-gray-700 dark:text-gray-100"
                                type="text"
                                name="url"
                                value={this.state.url || ""}
                                disabled
                            />
                            <CopyToClipboard text={this.state.url} onCopy={() => this.setState({ copied: true })}>
                                <WbButton>
                                    <FaCopy size={12} />
                                </WbButton>
                            </CopyToClipboard>
                        </div>
                    )}
                    <div className="text-gray-700 font-semibold dark:text-gray-100">Add the Request Parameters for Webhook Url</div>
                    {this.state.dynamicValues.map((item, index) => (
                        <div key={index} className="space-x-3 flex flex-row items-center">
                            <input
                                type="text"
                                name="keyName"
                                value={item.keyName || ""}
                                className="border rounded-lg h-12 w-[30%] p-2 dark:bg-gray-700 dark:text-gray-100"
                                required={true}
                                placeholder="Key"
                                onChange={(e) => this.handleDynamicValues(index, e)}
                            />
                            <input
                                type="text"
                                name="keyValue"
                                value={item.keyValue || ""}
                                className="border rounded-lg h-12 w-[30%] p-2 dark:bg-gray-700 dark:text-gray-100"
                                placeholder="Value"
                                onChange={(e) => this.handleDynamicValues(index, e)}
                            />

                            {index === this.state.dynamicValues.length - 1 ? (
                                <FaPlusCircle size={20} className="text-green-600" onClick={() => this.addFormFields()} />
                            ) : (
                                <FaMinusCircle size={20} className="text-red-600" onClick={() => this.removeFormFields(index)} />
                            )}
                        </div>
                    ))}
                    {this.state.loading && (
                        <div className="text-center mt-2 w-100">
                            <WbLoader />
                            <h6 className="mt-2">Please wait while we process your Request!</h6>
                        </div>
                    )}
                    <div className="mt-2 mb-2">
                        {this.state.copied ? <span className="mt-4 text-green-600">Copied successfully.</span> : isNullOrUndefined}
                    </div>
                </div>
                <div className="float-right space-x-4 flex flex-row">
                    <WbButton onClick={this.handleSubmit}>Trigger</WbButton>
                    <WbButton onClick={this.props.closeModal} disabled={this.state.response === 200 ? false : true}>
                        Save + Continue
                    </WbButton>
                </div>
            </div>
        );
    }
}
export default WebhookPostData;
