import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";

import WbMenuButton from "./WbMenuButton";
import WbMenuItem from "./WbMenuItem";

export default function WbMenu(props) {
  const { menuItems, color, position = "bottom", ...restProps } = props;
  const theme = {
    base: "absolute dark:divide-gray-500 origin-top-right divide-y divide-gray-100  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1 p-1 dark:bg-gray-800 dark:ring-gray-500",
    position: {
      top: " -top-[150px] left-[5px] border mt-2 w-52",
      bottom: "right-1 mt-2 w-64",
    },
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <WbMenuButton {...restProps} color={color} outline={true} />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100 z-30"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(theme.base, theme.position[position])}
        >
          {menuItems.map(({ text, icon, onClick, disabled }, idx) => (
            <WbMenuItem
              text={text}
              icon={icon}
              onClick={onClick}
              key={idx}
              disabled={disabled}
            />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
