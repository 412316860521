import React, { Fragment, Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import CustomInput from "..";

const todos = [
    {
        displayText: "Walk the walk",
        valueFrom: "previousStage",
        value: "123",
        slug: "slug1",
    },
    {
        displayText: "Talk the talk",
        valueFrom: "previousStage",
        value: "456",
        slug: "slug2",
    },
    {
        displayText: "Jump the jump",
        valueFrom: "previousStage",
        value: "789",
        slug: "slug3",
    },
];

class HtmlTextarea extends Component {
    state = {
        draggedPrevStageItem: null,
        selectedOption: null,
        target: null,
    };

    setTargetHandler = async (event) => {
        const { target } = event;
        this.setState({
            target,
        });
    };

    selectedOptionHandler = async (selectedOption) => {
        const { target } = this.state;
        target.selection.setContent(`%%${selectedOption.displayText}%%`);
    };

    render() {
        return (
            <Fragment>
                <div className="mb-2">
                    <CustomInput
                        staticData={todos}
                        optionSelectHandler={(selectedOption) => this.selectedOptionHandler(selectedOption)}
                        id={"1"}
                        labelText="Select value"
                        showRequiredOrOptional={true}
                        isRequired={false}
                        elementType="rich-select"
                        inputType={"text"}
                    />
                </div>
                <div>
                    <Editor
                        apiKey="ww1g5jc2dljaeklqituyiawuc5ign26g4g2w4i5ty5cpbokg"
                        initialValue="<p>Start adding content...</p>"
                        init={{
                            height: 500,
                            plugins: [
                                "link image code",
                                "autoresize advlist autolink lists link image charmap preview hr anchor",
                                "pagebreak emoticons spellchecker",
                                "searchreplace wordcount visualblocks visualchars code fullscreen",
                                "insertdatetime media nonbreaking save table directionality",
                                "template paste textpattern imagetools",
                            ],
                            toolbar1: `undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify`,
                            toolbar2: `bullist numlist outdent indent | link image media | forecolor backcolor | preview code | emoticons`,
                        }}
                        onChange={this.setTargetHandler}
                        onFocus={this.setTargetHandler}
                    />
                </div>
            </Fragment>
        );
    }
}

export default HtmlTextarea;
