import SampleCsv from "./SampleCsv";
import { useState } from "react";
import callApi from "utils/callApi";
import WbButton from "components/WbButton";
import { FaUpload } from "react-icons/fa";
import { isEmpty, get } from "lodash";
import WbLoader from "components/WbLoader";
import WbAlert from "components/WbAlert";

export default function CSVReaders({ toggleSendEmailModal }: any) {
  const [isLoading, setIsLoading] = useState(false);

  const [uploadedFile, setUploadedFile] = useState([]);
  const [responseMsg, setResponseMsg] = useState("");

  const handleOnChangeFileUpload = async (e: any) => {
    setUploadedFile(e.currentTarget.files);
  };

  const handleFileUpload = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (uploadedFile) {
      formData.append("file", uploadedFile[0]);
      setIsLoading(true);

      const res = await callApi({
        url: `/builtInTools/dataStudio/handleUpload`,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setResponseMsg(get(res, "message", ""));
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center p-4 ">
        <div className="mt-3 flex flex-row items-center justify-center">
          <input
            type="file"
            accept=".csv"
            onChange={handleOnChangeFileUpload}
            className={"rounded-md border-gray-500 p-1 text-gray-500"}
          />
          <WbButton
            className="ml-4"
            disabled={isEmpty(uploadedFile)}
            onClick={(e: any) => handleFileUpload(e)}
          >
            <FaUpload />{" "}
          </WbButton>
        </div>

        <div className="w-100 mt-4 pr-4 text-right">
          {!isEmpty(responseMsg) && (
            <WbAlert
              color={responseMsg.includes("Success") ? "success" : "failure"}
            >
              {responseMsg}
            </WbAlert>
          )}
        </div>
        {isLoading && (
          <WbLoader message="Please wait we process your request!" />
        )}
      </div>
      <div>
        {
          <div>
            <SampleCsv />
            <ol className="text-gray-600">
              <li className="font-semibold text-black ">Instructions: </li>
              <li>
                Please follow the exact instructions on this page. Incorrectly
                formatted files can not be imported.
              </li>
              <li>1. Download the template.</li>
              <li>
                2. Fill in the template in the same way that the sample data is
                filled in.
              </li>
              <li>
                3. Click on File, then Save as. Select CSV UTF-8 (comma
                delimited) (*.csv) and save the file.
              </li>
            </ol>
          </div>
        }
      </div>
    </>
  );
}
