import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdMailOutline } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { CgOrganisation } from "react-icons/cg";
import { RiLockPasswordLine } from "react-icons/ri";

import { showErrorToast } from "components/WbToast";
import {
  EmailField,
  Form,
  InputField,
  ShowPasswordField,
} from "components/Fields";
import WbButton from "components/WbButton";

import { registerViaWyzebulb } from "../AuthActions";

export default function Register(props: any) {
  const { initialPathname, initialSearchQuery } = useSelector(
    (state: any) => state.App
  );
  const dispatch = useDispatch();
  const formMethods = useForm();

  const isRedirectedFromAppsumo = initialSearchQuery
    ?.toLowerCase()
    .includes("appsumo");

  const onClickRegister = (formValues: any) => {
    const { userEmail, userName, userCompanyName, userPassword } = formValues;
    if (!isRedirectedFromAppsumo) {
      if (
        userEmail === "" ||
        userName === "" ||
        userPassword === "" ||
        userCompanyName === ""
      ) {
        return showErrorToast("Please fill in all the details");
      }
    }
    dispatch(
      registerViaWyzebulb(
        {
          ...formValues,
          accessUriString: window.location.href,
          initialPathname,
          initialSearchQuery,
        },
        initialPathname,
        initialSearchQuery
      )
    );
  };

  return (
    <>
      <div className="h-screen">
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="flex flex-row items-center justify-center">
                  <img
                    className="h-12 w-auto"
                    src="/assets/img/logos/wyzebulb-1x.png"
                    alt="Wyzebulb"
                  />
                </div>
                <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white/60">
                  Register your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                  And lets get started with your free trial.{" "}
                </p>
              </div>

              <div className="mt-4">
                <div>
                  <div>{props.getSocialLogin()}</div>

                  <div className="relative mt-6">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-white px-2 text-gray-500 dark:bg-gray-800 dark:text-white/60">
                        Or continue with
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <Form
                    form={formMethods}
                    handleSubmit={onClickRegister}
                    className="space-y-4"
                  >
                    {!isRedirectedFromAppsumo && (
                      <div className="space-y-1">
                        <EmailField
                          label={"Email address"}
                          type="email"
                          autoComplete="email"
                          AddOnIcon={MdMailOutline}
                          required
                          {...formMethods.register("userEmail")}
                        />
                      </div>
                    )}
                    <div className="space-y-1">
                      <InputField
                        label={"Name"}
                        type="text"
                        autoComplete="current-name"
                        AddOnIcon={FaRegUserCircle}
                        required
                        {...formMethods.register("userName")}
                      />
                    </div>
                    <div className="space-y-1">
                      <InputField
                        label={"Company"}
                        type="text"
                        autoComplete="current-company"
                        AddOnIcon={CgOrganisation}
                        required
                        {...formMethods.register("userCompanyName")}
                      />
                    </div>
                    <div className="space-y-1">
                      <ShowPasswordField
                        label={"Password"}
                        AddOnIcon={RiLockPasswordLine}
                        autoComplete="current-password"
                        required
                        {...formMethods.register("userPassword")}
                      />
                    </div>
                    <WbButton type="submit" className="w-full md:w-[400px]">
                      Register
                    </WbButton>
                  </Form>
                </div>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="mr-4 font-medium text-orange-600 hover:text-orange-500"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
