import ReactDOM from "react-dom/client";

import "./index.css";
import "@tremor/react/dist/esm/tremor.css";

declare global {
  interface Window {
    wyzebulbApiBaseUrl: any;
    env:any;
  }
}

window.wyzebulbApiBaseUrl = process.env.REACT_APP_API_URL;
window.env = process.env.NODE_ENV;

//Note: Dynamically import App after the envs are assigned to window global object
const App = require("./App").default;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
