import classNames from "classnames";

const theme = {
  base: "group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 focus:ring-0",
  fullSized: "w-full",
  color: {
    dark: "bg-gray-800 text-white border border-gray-200 hover:bg-gray-700",
    failure: "bg-red-500 text-white hover:bg-red-600",
    gray: "bg-gray-50 border border-gray-200 text-black hover:bg-gray-100 dark:bg-gray-300",
    info: "bg-blue-500 text-white hover:bg-blue-600",
    purple: "bg-purple-500 text-white hover:bg-purple-600",
    orange: "bg-orange-500 text-white hover:bg-orange-600",
    success: "bg-green-500 text-white hover:bg-green-600",
    warning: "bg-yellow-500 text-white hover:bg-yellow-600",
    light: "bg-gray-50 border border-gray-200 text-black hover:bg-gray-100",
  },
  disabled: "cursor-not-allowed opacity-50",
  inner: {
    base: "flex items-center",
    position: {
      none: "",
      start: "rounded-r-none",
      middle: "!rounded-none",
      end: "rounded-l-none",
    },
    outline: "border border-transparent",
  },
  label:
    "ml-2 inline-flex h-4 w-4 items-center justify-center rounded-full bg-blue-200 text-xs font-semibold text-blue-800",
  outline: {
    color: {
      gray: "border border-gray-900 dark:text-gray-600 focus:ring-0",
      default: "border-0",
      light: "",
    },
    off: "",
    on: "bg-white text-gray-900 transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit focus:ring-0",
    pill: {
      off: "rounded-md",
      on: "rounded-full",
    },
  },
  pill: {
    off: "rounded-md",
    on: "rounded-full",
  },
  size: {
    xs: "text-xs px-2 py-1",
    sm: "text-sm px-3 py-1.5",
    md: "text-sm px-4 py-2",
    lg: "text-base px-5 py-2.5",
    xl: "text-base px-6 py-3",
  },
};

export default function WbButton(props) {
  const {
    children,
    color = "orange",
    disabled = false,
    gradientDuoTone,
    gradientMonochrome,
    href,
    label,
    outline = false,
    pill = false,
    fullSized,
    positionInGroup = "none",
    size = "md",
    className,
    ...otherProps
  } = props;
  const isLink = typeof href !== "undefined";
  const Component = isLink ? "a" : "button";

  return (
    <Component
      className={classNames(
        disabled && theme.disabled,
        !gradientDuoTone && !gradientMonochrome && theme.color[color],
        gradientDuoTone &&
          !gradientMonochrome &&
          theme.gradientDuoTone[gradientDuoTone],
        !gradientDuoTone &&
          gradientMonochrome &&
          theme.gradient[gradientMonochrome],
        outline && (theme.outline.color[color] ?? theme.outline.color.default),
        theme.base,
        theme.pill[pill ? "on" : "off"],
        fullSized && theme.fullSized,
        className
      )}
      disabled={disabled}
      href={href}
      type={isLink ? undefined : "button"}
      {...otherProps}
    >
      <span
        className={classNames(
          theme.inner.base,
          theme.inner.position[positionInGroup],
          theme.outline[outline ? "on" : "off"],
          theme.outline.pill[outline && pill ? "on" : "off"],
          theme.size[size],
          outline && !theme.outline.color[color] && theme.inner.outline
        )}
      >
        <>
          {typeof children !== "undefined" && children}
          {typeof label !== "undefined" && (
            <span className={theme.label} data-testid="wb-button-label">
              {label}
            </span>
          )}
        </>
      </span>
    </Component>
  );
}
