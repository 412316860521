import { SHOW_OAUTH_BACKDROP, HIDE_OAUTH_BACKDROP } from "./OAuthActionTypes";

const INIT_STATE = {
	showOAuthBackdrop: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case SHOW_OAUTH_BACKDROP:
			return {
				...state,
				showOAuthBackdrop: true,
			};
		case HIDE_OAUTH_BACKDROP:
			return {
				...state,
				showOAuthBackdrop: false,
			};
		default:
			return { ...state };
	}
};
