import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { get, startCase } from 'lodash';

import Register from "./Register";
import Login from "./Login";
import { loginViaFacebook, loginViaGoogle } from "./AuthActions";
import AuthLayout from "./AuthLayout";
import { showErrorToast } from "components/WbToast";

export default function Auth() {
  const { initialPathname, initialSearchQuery } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const location = useLocation();
  const isRedirectedFromAppsumo =
    initialSearchQuery &&
    initialSearchQuery.toLowerCase() &&
    initialSearchQuery.toLowerCase().includes("appsumo");

  localStorage.setItem("initialPathname", initialPathname);
  localStorage.setItem("initialSearchQuery", initialSearchQuery);
  const googleClientIdForDomains = {
    "https://main.wyzebulb.com":
      "998395689598-ecd60srthuhlm2bq40fe9n7dag7a5qj1.apps.googleusercontent.com",
    "https://dev-main.wyzebulb.com":
      "998395689598-ecd60srthuhlm2bq40fe9n7dag7a5qj1.apps.googleusercontent.com",
    "http://localhost:3000":
      "658289057171-mbeag2dsj9h4uqo3rm2g9oejunmjqe53.apps.googleusercontent.com",
    "http://localhost:3001":
      "998395689598-ecd60srthuhlm2bq40fe9n7dag7a5qj1.apps.googleusercontent.com",
  };
  const googleClientId = googleClientIdForDomains[window.location.origin];
  const pathName = location.pathname;

  const onClickSignInWithFacebook = async () => {
    try {
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result?.user;
      if (user) {
        dispatch(
          loginViaFacebook(
            {
              accessToken: user.accessToken,
              profileObj: {
                ...user,
                imageUrl: user?.photoURL,
              },
            },
            initialPathname,
            initialSearchQuery
          )
        );
      }
    } catch (error) {
      const { code, customData } = error;
      if (code === 'auth/account-exists-with-different-credential') {
        const verifiedProvider = get(customData, '_tokenResponse.verifiedProvider', [])[0] || "";

        showErrorToast(`Account already exists with ${verifiedProvider}. Please login with ${startCase(verifiedProvider.split('.')[0])}`)
      }
    }

  };

  const onClickSignInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result?.user;
    if (user) {
      dispatch(
        loginViaGoogle(
          {
            accessToken: user.accessToken,
            profileObj: {
              ...user,
              imageUrl: user.photoURL,
            },
          },
          initialPathname,
          initialSearchQuery
        )
      );
    }
  };

  const getSocialLogin = () => {
    return !isRedirectedFromAppsumo ? (
      <div className="flex justify-center">
        <div className="mr-2">
          <div
            onClick={onClickSignInWithFacebook}
            className="inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
          >
            <FaFacebook size={20} />
            <span className="pl-2">Facebook</span>
          </div>
        </div>

        <div className="ml-2">
          <div
            onClick={() => onClickSignInWithGoogle()}
            href="/"
            className="inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
          >
            <FaGoogle size={20} />
            <span className="pl-2">Google</span>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center rounded-lg dark:bg-slate-800">
        <div className="w-[100%]">
          {pathName === "/login" && <Login getSocialLogin={getSocialLogin} />}
          {pathName === "/register" && (
            <Register getSocialLogin={getSocialLogin} />
          )}
        </div>
      </div>
    </AuthLayout>
  );
}
