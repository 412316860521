import { takeEvery, call, put } from "redux-saga/effects";
import { delay } from "redux-saga";

import { asyncPost } from "utils/async_util";
import { SEND_PASSWORD_RESET_EMAIL } from "./ForgotPasswordActionTypes";
import {
	sendPasswordResetEmailFail,
	sendPasswordResetEmailSuccess,
} from "./ForgotPasswordActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";


function* sendPasswordResetEmail({ payload }) {
	const { email } = payload;

	try {
		const [err, res] = yield call(
			asyncPost,
			`/account/password/forgotPassword`,
			{
				email,
			}
		);

		if (err) {
			return yield put(sendPasswordResetEmailFail());
		}

		if (!res.data.success) {
			showErrorToast(res?.data?.message);
			return yield put(sendPasswordResetEmailFail());
		}

		showSuccessToast("Reset instructions have been sent to your email.")
		yield delay(1000);
		yield put(sendPasswordResetEmailSuccess());
	} catch (err) {
		showErrorToast();
		return yield put(sendPasswordResetEmailFail());
	}
}

export function* watchForgotPasswordContainer() {
	yield takeEvery(SEND_PASSWORD_RESET_EMAIL, sendPasswordResetEmail);
}
