import { useState } from "react";
import type { RuleGroupType } from "react-querybuilder";
import { formatQuery, QueryBuilder } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import { showErrorToast, showSuccessToast } from "components/WbToast";
import callApi from "utils/callApi";
import { debounce, get } from "lodash";
import "./queryBuilder.css";
import { HeaderSection } from "components/WbLayout/Header";
import QueriedData from "./QueriedData";

function CreateSegment() {
  const initialQuery: RuleGroupType = {
    combinator: "and",
    rules: [
      { field: "rawData.firstName", operator: "beginsWith", value: "John" },
    ],
  };
  const navigate = useNavigate();
  const [query, setQuery] = useState(initialQuery);
  const [selectedName, setSelectedName] = useState<any>("");
  const [customData, setCustomData] = useState<any>("");
  const [queryResponse, setResponse] = useState<any>([]);
  const [count, setCount] = useState<any>("");
  const [fields, setFields] = useState([
    {
      name: "rawData.firstName",
      label: "First Name",
      placeholder: "Enter first name",
    },
    {
      name: "rawData.lastName",
      label: "Last Name",
      placeholder: "Enter last name",
    },
    {
      name: "rawData.email",
      label: "Email",
      placeholder: "Enter Email",
    },
    { name: 'createdAt', label: 'Date', inputType: 'datetime-local' },
  ]);

  const handleName = (e: any) => {
    const value = e.target.value;
    setSelectedName(value);
    return value;
  };

  const onSubmit = async () => {
    const queryData: any = {};
    queryData.selectedName = selectedName;
    queryData.query = formatQuery(query, "mongodb");

    const res = await callApi({
      url: `/builtInTools/dataStudio/saveSegment`,
      body: queryData,
    });
    if (res.success === true) {
      navigate(-1)
      showSuccessToast(`${res.message}`);
    } else {
      showErrorToast(`${res.message}`);
    }
  };

  const handleCustomHeader = debounce((value: string) => {
    const header: Array<string> = [];
    const newheader = value.split(",");
    newheader.forEach((word: string) => {
      const key = word.trim();
      header.push(key);
    });
    const newFields: any = header.map((key) => ({
      name: `rawData.${key}`,
      label: key,
      placeholder: "Enter the value",
    }));
    setFields([...fields, ...newFields]);
  }, 2000);

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setCustomData(value);
    handleCustomHeader(value);
  };

  const displayResult = async (q: any) => {
    setQuery(q);
    const queryData: any = {};
    queryData.query = formatQuery(q, "mongodb");

    const response: any = await callApi({
      url: `/builtInTools/dataStudio/listSegmentsData`,
      method: "POST",
      body: {
        findQuery: queryData,
        projection: {
          segmentName: 1,
          email: 1,
          createdAt: 1,
          updatedAt: 1,
        },
        sort: { updatedAt: -1 },
        limit: 10,
      },
    });
    setResponse(get(response, "data.progressData.docs", []));
    setCount(get(response, "data.countOfDocs", ""));
  };

  return (
    <>
      <HeaderSection
        title="Create Segment"
        description="Create segments to send emails to perticular group."
      />
      {/* Name of the Segment*/}
      <div className="w-[50%]">
        <div className="mx-4 mt-2">
          <h1 className="mb-2 py-1 text-sm font-semibold text-slate-700">
            Segment Name
          </h1>
          <input
            className="border-md h-[95%] w-full rounded-md border-slate-300"
            type="text"
            value={selectedName}
            onChange={handleName}
            required
          />
        </div>
        <div className="mx-4 mt-2">
          <h1 className="mb-1 py-1 text-sm font-semibold text-slate-700">
            Custom field keys (Optional)
          </h1>
          <p className="mb-2 text-sm text-gray-500">
            If you want to query based on custom data please enter the keys in
            comma separated way.
          </p>
          <input
            className="border-md h-[95%] w-full rounded-md border-slate-300"
            type="text"
            value={customData}
            onBlur={() => handleCustomHeader.flush()}
            onChange={handleInputChange}
          />
        </div>
        <div className="m-2">
          <div className="justify-center">
            <QueryBuilder
              fields={fields}
              query={query}
              onQueryChange={displayResult}
              controlClassnames={{
                queryBuilder: "rounded-md bg-gray-100",
                ruleGroup: "flex flex-col row-gap-2 p-2 rounded-lg bg-gray-100",
                combinators:
                  "text-gray-600 rounded-md p-2 w-20 border-slate-300",
                addRule:
                  "text-gray-600 border border-slate-300 rounded-md p-2 w-20 ",
                addGroup:
                  "text-gray-600 rounded-md border border-slate-300 p-2 w-20 ",
                fields:
                  "bg-white text-gray-600 rounded-md p-2 w-40 border border-slate-300",
                operators:
                  "bg-white text-gray-600 border border-slate-300 rounded-md p-2 w-40 ",
                value:
                  "bg-white text-gray-600 rounded-md p-2 w-40 border border-slate-300 justify-between w-32",
                removeGroup: "text-black p-1 ml-auto",
                removeRule: "text-black p-2 ml-auto",
                rule: "gap-4",
              }}
            />
          </div>

          <h4 className="m-2">Query</h4>
          <div className="break-words">
            <pre className="m-2">
              <p>{formatQuery(query, "mongodb")}</p>
            </pre>
          </div>
        </div>
      </div>
      <QueriedData queryResponse={queryResponse} count={count} />
      <div className="flex justify-end w-[50%]">
        <button
          className="mx-2 my-2 rounded bg-orange-500 p-2 px-4 text-white"
          type="submit"
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </>
  );
}

export default CreateSegment;
