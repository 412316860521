import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaClipboardCheck, FaPen } from "react-icons/fa";
import { isEmpty } from "lodash";

import { SELECT_INTEGRATION } from "enums/StageItemSlugsEnum";

import { setSelectedIntegration } from "../../FlowBuilderActions";

import SelectIntegrationModal from "./SelectIntegrationModal";

export default function SelectIntegration(props) {
    const dispatch = useDispatch();
    const { stageIndex, stageType, finishStageItem } = props;
    const { selectedIntegrations, flowObj } = useSelector((state) => state.FlowBuilder);
    const [showModal, setShowModal] = useState(false);
    const { stages } = flowObj || {};
    let incomingData = null;
    let isPreviousStageFilter = false;

    if (!isEmpty(stages[stageIndex - 1])) {
        const { stageItems, stageType: previousStageType } = stages[stageIndex - 1];
        if (!isEmpty(previousStageType)) {
            isPreviousStageFilter = previousStageType === "filter" ? true : false;
        }
        const { setupEventOptions } = stageItems;
        if (!isEmpty(setupEventOptions)) {
            incomingData = setupEventOptions.incomingData;
        }
    }
    const selectedIntegration = selectedIntegrations[stageIndex];
    const isStageSetupComplete = !isEmpty(selectedIntegration);

    useEffect(() => {
        if (isStageSetupComplete) finishStageItem(SELECT_INTEGRATION);
    }, [isStageSetupComplete, finishStageItem]);

    return (
        <>
            <div className="mb-0 cursor-pointer max-w-fit" onClick={() => setShowModal(true)}>
                {!isStageSetupComplete ? (
                    <>
                        <span>1. App - Click here to select App</span>
                        <span className="text-red-600 mx-1">(required)</span>
                        {!isEmpty(incomingData) || stageIndex === 0 || isPreviousStageFilter ? <span></span> : null}
                    </>
                ) : (
                    <>
                        <span className="items-baseline">
                            1. App - {selectedIntegration.displayText}
                            <FaPen size={12} className="inline-block text-blue-600 mx-1" />
                            <FaClipboardCheck size={16} className="inline-block text-green-600" />
                        </span>
                    </>
                )}
            </div>
            {showModal && (
                <SelectIntegrationModal
                    stageIndex={stageIndex}
                    stageType={stageType}
                    getSingleIntegrationSideEffect={(sideEffectData) => dispatch(setSelectedIntegration(stageIndex, sideEffectData, stageType))}
                    closeModal={() => setShowModal(false)}
                />
            )}
        </>
    );
}
