import { call, takeEvery } from "redux-saga/effects";

import { asyncPost } from "utils/async_util";
import { DELETE_ME } from "./DeleteSubscriptionActionTypes";
import { showErrorToast } from "components/WbToast";

function* deleteMeSaga({ payload }) {
	const { token } = payload;

	try {
		const [err, res] = yield call(asyncPost, "/subscriptions/delete/me", {
			token,
		});

		if (err) {
			showErrorToast();
		}

		if (!res.data.success) {
			showErrorToast();
		}

		window.location.reload();
		return;
	} catch (err) {
		showErrorToast();
		return;
	}
}

export function* watchDeleteSubscription() {
	yield takeEvery(DELETE_ME, deleteMeSaga);
}
