import classNames from "classnames";

const theme = {
    base: "mt-2 text-sm",
    colors: {
        gray: "text-gray-500 dark:text-gray-400",
        info: "text-blue-700 dark:text-blue-800",
        success: "text-green-600 dark:text-green-500",
        failure: "text-red-600 dark:text-red-500",
        warning: "text-yellow-500 dark:text-yellow-600",
    },
};

export default function WbHelperText({ value, children, color = "default", className, ...props }) {
    return (
        <p className={classNames(theme.base, theme.colors[color], className)} {...props}>
            {value ?? children ?? ""}
        </p>
    );
}
