import React, { Component } from "react";
import { connect } from "react-redux";
import { FaCheckDouble, FaPen } from "react-icons/fa";

import SetupEventOptionsModal from "./SetupEventOptionsModal";
import { SETUP_EVENT_OPTIONS } from "enums/StageItemSlugsEnum";
import { isDataPresent } from "utils/object_util";

class SetupEventOptions extends Component {
    state = {
        shouldShowSetUpEventOptionsStageItemModal: false,
        isStageSetupComplete: false,
    };

    initEventOptionsSelection = () => {
        this.setState({
            shouldShowSetUpEventOptionsStageItemModal: true,
        });
    };

    stopEventOptionsSelection = () => {
        this.setState({
            shouldShowSetUpEventOptionsStageItemModal: false,
        });
    };

    componentDidUpdate() {
        this.finishStageItem();
    }

    componentWillMount() {
        this.finishStageItem();
        const { stageIndex, flowObj } = this.props;
        const setupEventOptions = flowObj.stages[stageIndex].stageItems.setupEventOptions;

        const isStageSetupComplete = isDataPresent(setupEventOptions.selectedDynamicIncomingDataPrerequisites);

        if (isStageSetupComplete) {
            this.setState({
                isStageSetupComplete,
            });
        }
    }

    finishStageItem = () => {
        const { stageIndex, flowObj } = this.props;
        const setupEventOptions = flowObj.stages[stageIndex].stageItems.setupEventOptions;

        const isStageSetupComplete = isDataPresent(setupEventOptions.selectedDynamicIncomingDataPrerequisites);

        if (isStageSetupComplete) this.props.finishStageItem(SETUP_EVENT_OPTIONS);
    };

    finishSetup = () => {
        const { stageIndex, flowObj } = this.props;
        const setupEventOptions = flowObj.stages[stageIndex].stageItems.setupEventOptions;

        const isStageSetupComplete = isDataPresent(setupEventOptions.selectedDynamicIncomingDataPrerequisites);

        if (isStageSetupComplete) {
            this.setState({
                isStageSetupComplete: true,
            });
        }

        this.stopEventOptionsSelection();
    };

    render() {
        const { stageIndex, stageType, selectedIntegrations, flowObj, userHelper, orderIndex } = this.props;
        const currentStageSelectedIntegration = selectedIntegrations[stageIndex];
        const selectedEvent = flowObj.stages[stageIndex].stageItems.selectEvent;

        return (
            <>
                <div>
                    {!this.state.isStageSetupComplete ? (
                        <h6 className="mb-0 cursor-pointer max-w-fit" onClick={this.initEventOptionsSelection}>
                            <span>{orderIndex + 1}. Setup event options - Click here to setup event options</span>
                            <span className="text-red-600 mx-1">(required)</span>
                        </h6>
                    ) : (
                        <h6 className="mb-0 flex flex-row cursor-pointer max-w-fit" onClick={this.initEventOptionsSelection}>
                            <span>
                                {orderIndex + 1}. Setup Event options - Click here to view setup event options
                                <span>
                                    <FaPen size={12} className="inline-block text-blue-600 mx-1" />
                                    <FaCheckDouble size={12} className="inline-block text-green-600" />
                                </span>
                            </span>
                        </h6>
                    )}
                </div>
                {this.state.shouldShowSetUpEventOptionsStageItemModal && (
                    <SetupEventOptionsModal
                        stageIndex={stageIndex}
                        stageType={stageType}
                        selectedEvent={selectedEvent}
                        eventOptions={selectedIntegrations[stageIndex].triggerStageItems.setupEventOptions.eventOptions}
                        userHelper={userHelper}
                        integrationImageUrl={currentStageSelectedIntegration.imageUrl}
                        integrationDisplayText={currentStageSelectedIntegration.displayText}
                        finishSetup={this.finishSetup}
                        closeModal={this.stopEventOptionsSelection}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ FlowBuilder }) => {
    const { selectedIntegrations, flowObj, commonData } = FlowBuilder;
    return {
        selectedIntegrations,
        flowObj,
        commonData,
    };
};

export default connect(mapStateToProps, null)(SetupEventOptions);
