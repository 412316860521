import { DELETE_ME } from "./DeleteSubscriptionActionTypes";

export const deleteMe = (token) => {
	return {
		type: DELETE_ME,
		payload: {
			token,
		},
	};
};
