import {
	FETCH_INTEGRATION_CATEGORIES,
	INTEGRATION_CATEGORIES_FETCH_SUCCESS,
	INTEGRATION_CATEGORIES_FETCH_FAIL,
} from "./IntegrationCategoriesActionTypes";

const INIT_STATE = {
	fetchingIntegrationCategories: true,
	integrationCategories: null,
};

export default (state = INIT_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case FETCH_INTEGRATION_CATEGORIES:
			return {
				...state,
				fetchingIntegrationCategories: true,
			};
		case INTEGRATION_CATEGORIES_FETCH_SUCCESS:
			return {
				...state,
				fetchingIntegrationCategories: false,
				integrationCategories: payload.integrationCategories,
			};
		case INTEGRATION_CATEGORIES_FETCH_FAIL:
			return {
				...state,
				fetchingIntegrationCategories: false,
				integrationCategories: null,
			};
		default:
			return {
				...state,
			};
	}
};
