import axios from "axios";
import cookie from "react-cookies";

export const getAccessToken = () => {
  return cookie.load("token");
};

export default async function callApi({
  url,
  body = {},
  method = "POST",
  headers,
  isNucleusApi = true,
}: ICallApiV2Args) {
  const finalUrl = isNucleusApi
    ? `${process.env.REACT_APP_NUCLEUS_API_URL}${url}`
    : url;

  if (headers) {
    headers["x-access-token"] = getAccessToken();
  }

  try {
    const response = await axios({
      url: finalUrl,
      method,
      headers: headers || {
        Accept: "application/json, text/plain, */*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "x-access-token": getAccessToken(),
      },
      data: body || {},
    });
    return response?.data;
  } catch (error) {
    return error;
  }
}
