import { useState } from "react";
import _ from "lodash";

import { isDataPresent } from "utils/object_util";
import WbButton from "components/WbButton";
import WbLabel from "components/WbLabel";
import WbTextInput from "components/WbTextInput";
import WbIntegrationImage from "components/WbIntegrationImage";

export default function SetupAccount({
	integration,
	setupType,
	saveAccountHandler,
}: any) {
	const [apiKeysFormData, setApiKeysFormData] = useState<any>({});
	if (setupType === "reconnect") {
		_.pullAllBy(
			integration?.apiKeys,
			[{ slug: `wyzebulb_account_name` }],
			"slug"
		);
	}

	const onChangeInput = (event: any) => {
		const { id, value } = event.target;

		setApiKeysFormData((prevState: any) => ({
			...prevState,
			[id]: value,
		}));
	};

	if (!isDataPresent(integration)) {
		return null;
	}

	return (
		<>
			<div className="flex flex-col items-center p-4">
				<WbIntegrationImage
					slug={integration.slug}
					altText={integration.displayText}
					classNames="w-52 h-52 object-contain"
				/>
				<h2>{integration.displayText}</h2>
				<form
					className="w-[60%]"
					onSubmit={() =>
						saveAccountHandler(
							apiKeysFormData,
							apiKeysFormData[`wyzebulb_account_name`]
						)
					}
				>
					{integration.apiKeys.map((eachApiKey: any, index: any) => {
						const { inputDetails, userHelper } = eachApiKey;
						return (
							<div className="mb-5" key={eachApiKey._id || index}>
								<WbLabel>
									<span>{eachApiKey.displayText}</span>
									<span className="ml-1 text-red-600">
										(required)
									</span>
								</WbLabel>
								<div className="flex items-center">
									{inputDetails.preInputText && (
										<div className="mr-1">
											{inputDetails.preInputText}
										</div>
									)}
									<div className="mt-1 w-full">
										<WbTextInput
											type={inputDetails.inputType}
											id={eachApiKey.slug}
											placeholder={
												inputDetails.inputPlaceholder
											}
											name={eachApiKey.slug}
											required={eachApiKey.isMandatory}
											userHelper={userHelper}
											preInputText={
												inputDetails.preInputText
											}
											postInputText={
												inputDetails.postInputText
											}
											onChange={onChangeInput}
											disabled={false}
										/>
									</div>
									{inputDetails.postInputText && (
										<div className="ml-1">
											{inputDetails.postInputText}
										</div>
									)}
								</div>
							</div>
						);
					})}
				</form>
			</div>
			<div className="float-right p-10">
				{setupType === "reconnect" ? (
					<WbButton
						id={"add-account"}
						onClick={() =>
							saveAccountHandler(
								apiKeysFormData,
								apiKeysFormData[`wyzebulb_account_name`]
							)
						}
					>
						Reconnect Account
					</WbButton>
				) : (
					<WbButton
						id={"reconnect-account"}
						onClick={() =>
							saveAccountHandler(
								apiKeysFormData,
								apiKeysFormData[`wyzebulb_account_name`]
							)
						}
					>
						Add Account
					</WbButton>
				)}
			</div>
		</>
	);
}
