// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const devConfig = {
	apiKey: "AIzaSyBBCb5jC2S5Jbdbro3K-MydQhUOMf9Shlw",
	authDomain: "wyzebulb-main.firebaseapp.com",
	projectId: "wyzebulb-main",
	storageBucket: "wyzebulb-main.appspot.com",
	messagingSenderId: "817095404656",
	appId: "1:817095404656:web:372314e788c17e2a85d8b5",
};

const prodConfig = {
	apiKey: "AIzaSyBI8lokVe8ACcpJh81de8i3CNir2v23ZkM",
	authDomain: "wyzebulb-app-prod.firebaseapp.com",
	projectId: "wyzebulb-app-prod",
	storageBucket: "wyzebulb-app-prod.appspot.com",
	messagingSenderId: "533327268669",
	appId: "1:533327268669:web:18e2ac2445003cdd441354",
	measurementId: "G-NW37C578FX",
};

// Initialize Firebase
const app = initializeApp(
	process.env.REACT_APP_NODE_ENV === "production" ? prodConfig : devConfig
);

export default app;
