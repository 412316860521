import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Avatar from "react-avatar";
import { FaCaretRight } from "react-icons/fa";

import { Separator } from "components/CustomBootstrap";
import CustomInput from "components/CustomInput";
import { fetchActiveIntegrationsWithSimilarCategories } from "../FlowBuilderActions";
import { requestIntegration } from "components/SelectIntegration/SelectIntegrationActions";
import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import { showErrorToast } from "components/WbToast";

class NonExistingIntegrationsModal extends Component {
    state = {
        useCase: "",
    };

    componentDidMount = () => {
        const { triggerAndActionIntegrationData } = this.props;
        const triggerIntegration = triggerAndActionIntegrationData[0];
        const actionIntegration = triggerAndActionIntegrationData[1];
        if (!triggerIntegration.isActive && actionIntegration.isActive) {
            this.props.fetchActiveIntegrationsWithSimilarCategories(triggerIntegration.categories);
        }
        if (triggerIntegration.isActive && !actionIntegration.isActive) {
            this.props.fetchActiveIntegrationsWithSimilarCategories(actionIntegration.categories);
        }
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    onContinueToExplore = () => {
        const { triggerAndActionIntegrationData } = this.props;
        const triggerIntegration = triggerAndActionIntegrationData[0];
        const actionIntegration = triggerAndActionIntegrationData[1];
        if (this.state.useCase === "") {
            showErrorToast("Please fill in the usecase!");
        } else {
            const { subscription } = this.props;
            const flowClickedOnLandingPage = `${triggerIntegration.displayText} + ${actionIntegration.displayText}`;
            this.props.requestIntegration(subscription.name, subscription.email, this.state.useCase, flowClickedOnLandingPage);
            this.props.closeModal();
        }
    };

    render() {
        const { integrationsWithSimilarCategories, triggerAndActionIntegrationData } = this.props;
        const triggerIntegration = triggerAndActionIntegrationData[0];
        const actionIntegration = triggerAndActionIntegrationData[1];
        const arrayOfPopularIntegrations = [
            { imgUrl: "facebook_lead_ads", displayText: "Facebook Lead Ads" },
            { imgUrl: "google_sheets", displayText: "Google Sheets" },
            { imgUrl: "waboxapp", displayText: "WaboxApp SMS" },
            { imgUrl: "mailchimp", displayText: "Mailchimp" },
            { imgUrl: "facebook_messenger", displayText: "Facebook Messenger" },
        ];
        const popularOptionImg = _.map(arrayOfPopularIntegrations, (eachPopularIntegration, index) => {
            return (
                <div className="ml-2 mr-2 center" key={index}>
                    <img src={`/assets/img/integrations/hex_${eachPopularIntegration.imgUrl}.png`} alt={eachPopularIntegration.displayText} className="object-contain" />
                    <p>{eachPopularIntegration.displayText}</p>
                </div>
            );
        });
        const alternateOptionImg = _.map(integrationsWithSimilarCategories, (eachAlternateIntegration, index) => {
            return (
                <div className="ml-2 mr-2 center" key={index}>
                    <img src={`/assets/${eachAlternateIntegration.imageUrl}`} alt={eachAlternateIntegration.displayText} className="object-contain" />
                    <p>{eachAlternateIntegration.displayText}</p>
                </div>
            );
        });

        let triggerIntegrationImg = null;
        let actionIntegrationImg = null;
        let captionToShow = null;
        let alternateOptions = null;
        let popularOptions = null;

        const isNoneOfTheIntegrationsPresent = !triggerIntegration.isActive && !actionIntegration.isActive;
        if (isNoneOfTheIntegrationsPresent) {
            triggerIntegrationImg = (
                <div className="align-self-center ml-3 mr-3">
                    <Avatar name={triggerIntegration.displayText} maxInitials={2} size="80" round={true} />
                </div>
            );
            actionIntegrationImg = (
                <div className="align-self-center ml-3">
                    <Avatar name={actionIntegration.displayText} maxInitials={2} size="80" round={true} />
                </div>
            );

            captionToShow = (
                <h2>
                    Sorry!&nbsp;
                    <span>
                        {triggerIntegration.displayText} and {actionIntegration.displayText}
                    </span>
                    &nbsp; Integrations are temporarily unavailable.
                </h2>
            );
            popularOptions = (
                <Fragment>
                    <h2>Quick Check Popular Integrations, what others Just chose on Wyzebulb</h2>
                    <div className="d-flex flex-wrap justify-content-around">{popularOptionImg}</div>
                </Fragment>
            );
        }

        const isTriggerIntegrationPresent = triggerIntegration.isActive && !actionIntegration.isActive;
        if (isTriggerIntegrationPresent) {
            triggerIntegrationImg = <img src={`/assets/${triggerIntegration.imageUrl}`} alt={triggerIntegration.displayText} className="object-contain" />;

            actionIntegrationImg = (
                <div className="align-self-center ml-3">
                    <Avatar name={actionIntegration.displayText} maxInitials={2} size="80" round={true} />
                </div>
            );

            captionToShow = (
                <h2>
                    Sorry!&nbsp;
                    <span>{actionIntegration.displayText}</span>
                    &nbsp; Integration is temporarily unavailable.
                </h2>
            );
            if (integrationsWithSimilarCategories) {
                alternateOptions = (
                    <Fragment>
                        <h2>Quick Check Alternate Integrations, what others Just chose on Wyzebulb</h2>
                        <div className="d-flex flex-wrap justify-content-around">{alternateOptionImg}</div>
                    </Fragment>
                );
            } else {
                popularOptions = (
                    <Fragment>
                        <h2>Quick Check Popular Integrations, what others Just chose on Wyzebulb</h2>
                        <div className="d-flex flex-wrap justify-content-around">{popularOptionImg}</div>
                    </Fragment>
                );
            }
        }

        const isActionIntegrationPresent = !triggerIntegration.isActive && actionIntegration.isActive;
        if (isActionIntegrationPresent) {
            triggerIntegrationImg = (
                <div className="align-self-center ml-3 mr-3">
                    <Avatar name={triggerIntegration.displayText} maxInitials={2} size="80" round={true} />
                </div>
            );
            actionIntegrationImg = <img src={`/assets/${actionIntegration.imageUrl}`} alt={actionIntegration.displayText} className="object-contain" />;

            captionToShow = (
                <h2>
                    Sorry!&nbsp;
                    <span>{triggerIntegration.displayText}</span>
                    &nbsp; Integration is temporarily unavailable.
                </h2>
            );
            if (integrationsWithSimilarCategories) {
                alternateOptions = (
                    <Fragment>
                        <h2>Quick Check Alternate Integrations, what others Just chose on Wyzebulb</h2>
                        <div className="d-flex flex-wrap justify-content-around">{alternateOptionImg}</div>
                    </Fragment>
                );
            } else {
                popularOptions = (
                    <Fragment>
                        <h2>Quick Check Popular Integrations, what others Just chose on Wyzebulb</h2>
                        <div className="d-flex flex-wrap justify-content-around">{popularOptionImg}</div>
                    </Fragment>
                );
            }
        }

        return (
            <Fragment>
                <WbModal isOpen={true} toggle={this.props.closeModal}>
                    <WbModalTitle toggle={this.props.closeModal} title="Integration - Not Exist" />
                    <div className="p-4">
                        <div className="mt-3">
                            <div className="d-flex mt-2 mb-2" style={{ justifyContent: "center" }}>
                                {triggerIntegrationImg}
                                <div className="align-self-center">
                                    <FaCaretRight size={16} />
                                </div>
                                {actionIntegrationImg}
                            </div>
                            <div className="center">{captionToShow}</div>
                            <div>
                                <div>
                                    <CustomInput
                                        elementType="textarea"
                                        id="useCase"
                                        labelText="Let us know your usecase:"
                                        placeholder="Please explain your use-case..."
                                        name="useCase"
                                        isRequired={true}
                                        showRequiredOrOptional={true}
                                        userInputHandler={this.onInputChange}
                                    />
                                </div>
                                <Separator className="mt-5 mb-5" />
                                {popularOptions}
                                {alternateOptions}
                                <div className="float-right mt-3">
                                    <WbButton onClick={this.onContinueToExplore}>Continue To Explore</WbButton>
                                    {"  "}
                                </div>
                            </div>
                        </div>
                    </div>
                </WbModal>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ FlowBuilder, LoggedInLayout }) => {
    const { integrationsWithSimilarCategories } = FlowBuilder;
    const { subscription } = LoggedInLayout;
    return {
        integrationsWithSimilarCategories,
        subscription,
    };
};

export default connect(mapStateToProps, {
    fetchActiveIntegrationsWithSimilarCategories,
    requestIntegration,
})(NonExistingIntegrationsModal);
