import { HeaderSection } from "components/WbLayout/Header";
import { SubmitHandler } from "react-hook-form";
import Form from "../components/Form";
import { showErrorToast, showSuccessToast } from "components/WbToast";
import callApi from "utils/callApi";
import { useNavigate } from "react-router-dom";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  rawData: string;
};

const fields = [
  { label: "First Name", name: "firstName", type: "text" },
  { label: "Last Name", name: "lastName", type: "text" },
  { label: "Email", name: "email", type: "email" },
  { label: "Data", name: "rawData", type: "textarea" },
];

function CreateLead() {
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const res = await callApi({
      url: `/builtInTools/dataStudio/savelead`,
      body: data,
    });
    if (res.success === true) {
      navigate(-1);
      showSuccessToast(`${res.message}`);
    } else {
      showErrorToast(`${res.message}`);
    }
  };

  return (
    <>
      <HeaderSection
        title="Create Lead"
        description="Create lead to send emails to perticular lead."
      />
      <Form fields={fields} className="w-[90%] md:w-[50%] xl:w-[30%]" onSubmit={onSubmit} />
    </>
  );
}

export default CreateLead;
