/* eslint-disable import/no-anonymous-default-export */
import {
	GET_BILLING_PLANS,
	GET_BILLING_PLANS_SUCCESS,
	CREATE_SUBSCRIPTION_IN_STRIPE,
	CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS,
	CREATE_SUBSCRIPTION_IN_STRIPE_FAIL,
	GET_BILLING_PLANS_FAIL,
	UPDATE_STRIPE_CARD_DETAILS,
	UPDATE_STRIPE_CARD_DETAILS_ERROR,
	UPDATE_STRIPE_CARD_DETAILS_SUCCESS,
	LOAD_MODAL_IF_MOUSE_POSITION_IS_OUT,
	STOP_MODAL_IF_MOUSE_POSITION_IS_IN,
} from "./SelectPlanActionTypes";

const INIT_STATE = {
	isLoadingBillingPlans: false,
	isLoadingSubscriptionCreationInStripe: false,
	billingPlans: null,
	isLoadingStripeCardDetailsUpdate: false,
	shouldLoadModalIfMousePositionIsOut: false,
	isUserExitIntentModalShown: false,
};

export default (state = INIT_STATE, action) => {
	const { payload, type } = action;

	switch (type) {
		case GET_BILLING_PLANS:
			return {
				...state,
				isLoadingBillingPlans: true,
			};
		case GET_BILLING_PLANS_SUCCESS:
			return {
				...state,
				isLoadingBillingPlans: false,
				billingPlans: payload.billingPlans,
			};
		case GET_BILLING_PLANS_FAIL:
			return {
				...state,
				isLoadingBillingPlans: false,
				billingPlans: null,
			};
		case CREATE_SUBSCRIPTION_IN_STRIPE:
			return {
				...state,
				isLoadingSubscriptionCreationInStripe: true,
			};
		case CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS:
			return {
				...state,
				isLoadingSubscriptionCreationInStripe: false,
			};
		case CREATE_SUBSCRIPTION_IN_STRIPE_FAIL:
			return {
				...state,
				isLoadingSubscriptionCreationInStripe: false,
			};
		case UPDATE_STRIPE_CARD_DETAILS:
			return {
				...state,
				isLoadingStripeCardDetailsUpdate: true,
			};
		case UPDATE_STRIPE_CARD_DETAILS_ERROR:
		case UPDATE_STRIPE_CARD_DETAILS_SUCCESS:
			return {
				...state,
				isLoadingStripeCardDetailsUpdate: false,
			};
		case LOAD_MODAL_IF_MOUSE_POSITION_IS_OUT:
			return {
				...state,
				shouldLoadModalIfMousePositionIsOut: true,
				isUserExitIntentModalShown: true,
			};
		case STOP_MODAL_IF_MOUSE_POSITION_IS_IN:
			return {
				...state,
				shouldLoadModalIfMousePositionIsOut: false,
				isUserExitIntentModalShown: true,
			};
		default:
			return {
				...state,
			};
	}
};
