import { Fragment } from "react";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import WbIntegrationImage from "components/WbIntegrationImage";
import withOAuthContainer from "layouts/OAuth";

import { requestIntegration, updateApplicationsSortSeq } from "../../SelectIntegrationActions";

function BetaBadge() {
    return <span className="absolute top-2 left-2 border-2 px-1 rounded-full bg-orange-50 text-orange-600 font-semibold text-xs">BETA</span>;
}

export default function SingleIntegration(props) {
    const dispatch = useDispatch();
    const { subscription } = useSelector((state) => state.LoggedInLayout);
    const { integration, integrationClicked, initComingSoonNotification, onSelectIntegration, shouldLoadOAuthWindow } = props;

    const isBeta = integration?.stage === "beta";

    const handleClickOnComingSoonIntegration = () => {
        const integrationRequested = integrationClicked.displayText;
        initComingSoonNotification();
        dispatch(requestIntegration(subscription.name, subscription.email, integrationRequested, null));
    };

    const handleUpdateApplicationsSortSeq = (integration) => {
        const slugName = get(integration, "slug", "");
        const subscriptionId = get(subscription, "_id", "");
        const userEmail = get(subscription, "email", "");

        dispatch(updateApplicationsSortSeq(slugName, subscriptionId, userEmail));
    };

    const handleClickOnIntegration = (integration) => {
        onSelectIntegration(integration);
        handleUpdateApplicationsSortSeq(integration);
    };

    let singleIntegration = null;

    if (!isEmpty(integration.oAuthDetails) && shouldLoadOAuthWindow) {
        const singleIntegrationCard = () => {
            return (
                <div className="relative bg-white p-4 w-[150px] h-[200px] m-1 rounded-xl cursor-pointer shadow-lg border-2 dark:border-gray-700 dark:bg-gray-800 ">
                    {isBeta && <BetaBadge />}
                    <div className="flex flex-col items-center space-y-2">
                        <WbIntegrationImage slug={integration.slug} altText={integration.displayText} classNames="w-24 h-24 mt-3 object-contain" />
                        <p className="text-center dark:text-white flex justify-center flex-col">{integration.displayText}</p>
                    </div>
                </div>
            );
        };
        const SingleIntegrationCardHoc = withOAuthContainer(singleIntegrationCard);
        singleIntegration = <SingleIntegrationCardHoc oAuthIntegration={integration} oAuthType="connect"></SingleIntegrationCardHoc>;
    } else {
        singleIntegration = (
            <div
                className="relative bg-white p-4 w-[150px] h-[200px] m-1 rounded-xl cursor-pointer shadow-lg border-2 space-y-2 dark:border-gray-700  dark:bg-gray-800 dark:text-white"
                {...(integration.isActive
                    ? { onClick: () => handleClickOnIntegration(integration) }
                    : { onClick: () => handleClickOnComingSoonIntegration() })}
            >
                {isBeta && <BetaBadge />}
                {!integration.isActive && <div className="bg-sky-600 text-sm text-center px-1 py-0.5 text-sky-100 rounded-xl">Coming Soon</div>}
                <div className="flex flex-col items-center space-y-2">
                    <WbIntegrationImage slug={integration.slug} altText={integration.displayText} classNames="w-24 h-24 mt-3 object-contain" />
                    <p className="text-center dark:text-white flex justify-center flex-col">{integration.displayText}</p>
                </div>
            </div>
        );
    }

    return <Fragment>{singleIntegration}</Fragment>;
}
