import WbTabs from "components/WbTabs";
import Details from "./Details"

const DetailTabs = () => {
  return (
    <WbTabs
      tabs={[
        {
          name: "Overview",
          pathName: "overview",
          // content: (
          //   <Details />
          // ),
        },
        {
          name: `Activity`,
          pathName: "activity",
          // content: <Activity flow={flow} />,
        },
      ]}
    />
  );
};

export default DetailTabs;
