import React, { PureComponent } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import { isDataPresent } from "utils/object_util";
import WebhookPostData from "./WebookPostData";
import { getGeneratedWebhookUrl } from "../../../FlowBuilderActions";

class WebhookTrigger extends PureComponent {
    state = {
        copied: false,
    };

    componentDidMount() {
        const { commonData, stageIndex, slug } = this.props;
        const wyzebulbWebhookTriggerUrl = commonData[stageIndex].wyzebulbWebhookTriggerUrl;
        if (!isDataPresent(wyzebulbWebhookTriggerUrl)) {
            this.props.getGeneratedWebhookUrl(stageIndex, undefined, slug);
        }
    }

    render() {
        const { commonData, stageIndex } = this.props;
        const generatedWbURL = commonData[stageIndex].wyzebulbWebhookTriggerUrl;

        return !isEmpty(generatedWbURL) ? <WebhookPostData url={generatedWbURL} closeModal={this.props.closeModal} /> : <div />;
    }
}

const mapStateToProps = ({ FlowBuilder }) => {
    const { commonData, isLoadingWebhookUrl, flowObj } = FlowBuilder;

    return {
        flowObj,
        commonData,
        isLoadingWebhookUrl,
    };
};

export default connect(mapStateToProps, {
    getGeneratedWebhookUrl,
})(WebhookTrigger);
