export const faqs = [
  {
    question: "What is a flow?",
    answer: `A flow is a connection between two or more apps, and defines how to automate your task. You'll choose a trigger (eg.,when a user comments on my Facebook Post) and corresponding actions (eg., add an entry in a google sheet), and Wyzebulb makes the connection.`,
  },
  {
    question: "What is a filter/segment?",
    answer: `A filter is the criteria you define to decide whether to run a specific task (eg., if city is equal to Denver).`,
  },
  {
    question: "What is a task?",
    answer: `A successful flow action is called a task. One flow can automate many tasks.`,
  },
  {
    question: "What is a contact?",
    answer: `A contact is simply the set of data identifying/defining an user (eg., email, mobile number) upon whom your tasks ran.`,
  },
  {
    question: "How do you calculate the tasks executed in a month?",
    answer: `It's simply the number of times an action is successfully executed in a
                            billing cycle.`,
  },
  {
    question: "How do you calculate the contacts used in a month?",
    answer: `It's simply the number of contacts upon which actions are executed in a
                            billing cycle.`,
  },
  {
    question: "What is auto-retry?",
    answer: `If a task failed for any reason (eg., configuration errors, API
                            throttling), Wyzebulb tries to re-execute the same
                            action after some delay. Please note that this is available only starting from Growth plan.`,
  },
  {
    question: "I don't see an app, what should I do?",
    answer: `If you don't see an app, we're likely working on it. But please go
                            ahead and mail us at support@wyzebulb.com if
                            you'd like the app to be available within 24 hours.`,
  },
  {
    question: "Can I change my plan later?",
    answer: `You can always upgrade or downgrade your plan based on your requirement,
                            subject to our usage policies.`,
  },
  {
    question: "How do I cancel my subscription?",
    answer: `You can always cancel your subscription from your account.`,
  },
  {
    question: "What happens when I exceed the number of tasks in a cycle?",
    answer: `You'll be notified by mail when you're close to exhausting your monthly quota. And you'll be
                            notified again on the day
                            you exceed the quota. We'll give a 2-day grace period during which you can upgrade to an
                            appropriate pricing plan. If no
                            action is take, within the 2 days, all your flows will be paused which can be resumed as
                            soon as you upgrade. If no
                            action is taken in the next 7 days, we'll stop all the flows and clear all queued data.`,
  },
  {
    question: "When does the billing cycle start?",
    answer: `The billing cycle starts the day your trial ends, and you're charged every
                            month unless you cancel the subscription or
                            delete your account.`,
  },
  {
    question: "What do I do if I have more questions?",
    answer: `Please do refer to our knowledge-base (<a href="https://help.wyzebulb.com"
                                target="_blank">https://help.wyzebulb.com</a>), or you
                            can always mail us at support@wyzebulb.com.`,
  },
];
