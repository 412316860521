import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BulletList } from "react-content-loader";

import { fetchIntegrationCategories } from "./IntegrationCategoriesActions";

export default function IntegrationCategories({ searchIntegrationsByCategory }) {
    const dispatch = useDispatch();
    const { integrationCategories } = useSelector((state) => state.IntegrationCategories);
    const [selectedSlug, setSelectedSlug] = useState("all");

    useEffect(() => {
        dispatch(fetchIntegrationCategories());
    }, []);

    const onClickCategory = (categorySlug) => {
        setSelectedSlug(categorySlug);
        searchIntegrationsByCategory(categorySlug);
    };

    return (
        <Fragment>
            <h2 className="font-bold text-[16px] my-2">Filter by Category</h2>
            {!integrationCategories && <BulletList />}
            {integrationCategories && (
                <div className="flex flex-col space-y-2">
                    <div>
                        <span onClick={() => onClickCategory("all")}>All</span>
                    </div>
                    {integrationCategories.map((category) => {
                        return (
                            <div key={category.slug}>
                                <span onClick={() => onClickCategory(category.slug)}>{category.displayText}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </Fragment>
    );
}
