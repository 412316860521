export const GET_BILLING_PLANS = "GET_BILLING_PLANS";
export const GET_BILLING_PLANS_SUCCESS = "GET_BILLING_PLANS_SUCCESS";
export const GET_BILLING_PLANS_FAIL = "GET_BILLING_PLANS_FAIL";
export const CREATE_SUBSCRIPTION_IN_STRIPE = "CREATE_SUBSCRIPTION_IN_STRIPE";
export const CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS = "CREATE_SUBSCRIPTION_IN_STRIPE_SUCCESS";
export const CREATE_SUBSCRIPTION_IN_STRIPE_FAIL = "CREATE_SUBSCRIPTION_IN_STRIPE_FAIL";
export const SEND_MESSAGE_FOR_CONTACT_US = "SEND_MESSAGE_FOR_CONTACT_US";
export const UPDATE_STRIPE_CARD_DETAILS = "UPDATE_STRIPE_CARD_DETAILS";
export const UPDATE_STRIPE_CARD_DETAILS_ERROR = "UPDATE_STRIPE_CARD_DETAILS_ERROR";
export const UPDATE_STRIPE_CARD_DETAILS_SUCCESS = "UPDATE_STRIPE_CARD_DETAILS_SUCCESS";
export const LOAD_MODAL_IF_MOUSE_POSITION_IS_OUT = "LOAD_MODAL_IF_MOUSE_POSITION_IS_OUT";
export const STOP_MODAL_IF_MOUSE_POSITION_IS_IN = "STOP_MODAL_IF_MOUSE_POSITION_IS_IN";
