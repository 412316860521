import React, { Component, Fragment } from "react";
import _ from "lodash";

import { Colxx } from "components/CustomBootstrap";
import CustomInput from "components/CustomInput";
import { isDataPresent } from "utils/object_util";

class DateFormatterContainer extends Component {
    state = {
        shouldShowDateFormatter: false,
    };

    componentDidMount() {
        const { selectedValueData } = this.props;
        if (isDataPresent(selectedValueData)) {
            this.setState({
                shouldShowDateFormatter: true,
            });
        }
    }

    showDateFormatterOptions = () => {
        this.setState((prevState) => {
            return {
                ...prevState.shouldShowDateFormatter,
                shouldShowDateFormatter: !prevState.shouldShowDateFormatter,
            };
        });
    };

    onSelectApiData = (selectedOption, dateFormatterSlug) => {
        const { userInput } = selectedOption;
        const valueDetails = {
            value: userInput.value,
            displayText: userInput.displayText,
            from: userInput.valueFrom,
        };
        const selectedFormatterOption = {
            slug: dateFormatterSlug,
            valueDetails: valueDetails,
        };
        const selectedFormatterSlug = "date.formatter";
        this.props.dataFormatHandler(selectedFormatterOption, selectedFormatterSlug);
    };

    render() {
        const { dataFormattingOptions, selectedValueData } = this.props;
        const { formatterRequiredOptions } = dataFormattingOptions;

        const formatterRequiredOptionsToShow = _.map(formatterRequiredOptions, (eachFormatterRequiredOption, index) => {
            const { dataLoadOptions, slug } = eachFormatterRequiredOption;
            const { predefinedValues, dataLoadUrlDetails } = dataLoadOptions;
            let selectedValueDataToSend = null;
            if (isDataPresent(selectedValueData)) {
                const selectedValueDataTime = _.find(selectedValueData, (eachSelectedValueData) => slug === eachSelectedValueData.slug);
                if (isDataPresent(selectedValueDataTime)) {
                    const jsonDetails = [
                        {
                            slug: selectedValueDataTime.slug,
                            valueDetails: [selectedValueDataTime.valueDetails],
                        },
                    ];
                    selectedValueDataToSend = {
                        jsonDetails,
                    };
                }
            }

            const staticData = _.map(predefinedValues, (eachPredefinedValue) => ({
                ...eachPredefinedValue,
                valueFrom: "predefinedValues",
            }));
            return (
                <Colxx lg="6" key={index}>
                    <CustomInput
                        elementType="rich-select"
                        name={slug}
                        dynamicDataLoadOptions={dataLoadUrlDetails}
                        staticData={staticData}
                        userInputHandler={(selectedOption) => this.onSelectApiData(selectedOption, slug)}
                        id={eachFormatterRequiredOption.slug}
                        labelText={eachFormatterRequiredOption.displayText}
                        showRequiredOrOptional={true}
                        isRequired={true}
                        selectedValueData={selectedValueDataToSend}
                        userHelper={eachFormatterRequiredOption.userHelper}
                        stageIndex={this.props.stageIndex}
                    />
                </Colxx>
            );
        });

        return (
            <div>
                <div className="space-x-2 mt-2">
                    <input type="checkbox" checked={this.state.shouldShowDateFormatter} onChange={this.showDateFormatterOptions} />
                    <span>{dataFormattingOptions.formatterDisplayText}</span>
                </div>
                {this.state.shouldShowDateFormatter ? (
                    <div className="">
                        {this.props.userHelperContent}
                        <div className="grid grid-cols-3 gap-4 mt-5">{formatterRequiredOptionsToShow}</div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default DateFormatterContainer;
