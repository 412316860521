import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  FETCH_FLOWS,
  CHANGE_FLOW_NAME,
  CHANGE_FLOWS_STATE,
  FETCH_FLOWS_COUNT,
} from "./FlowsActionTypes";
import {
  fetchFlowsFail,
  fetchFlowsSuccess,
  changeFlowNameFail,
  changeFlowNameSuccess,
  fetchFlows,
  fetchFlowsCountSuccess,
  fetchFlowsCount,
} from "./FlowsActions";
import { asyncPostWithoutErrHandler, asyncPost } from "utils/async_util";
import { isDataPresent } from "utils/object_util";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* fetchFlowsSaga({ payload }: any): any {
  const { sortQuery, searchQuery, skip, limit, page } = payload;
  try {
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/flows/listFlows",
      {
        findQuery: {
          ...(isDataPresent(searchQuery) && {
            name: {
              $regex: `.*${searchQuery}.*`,
              $options: "i",
            },
          }),
          state: {
            $in: ["running", "paused"],
          },
        },
        populateQuery: {
          path: "flowAnalytics",
        },
        ...(isDataPresent(sortQuery) && { sortQuery }),
        ...(skip && { skip }),
        ...(limit && { limit }),
        ...(page && { page }),
      }
    );

    if (err) {
      return yield put(fetchFlowsFail());
    }

    if (!res.data.success) {
      return yield put(fetchFlowsFail());
    }
    return yield put(fetchFlowsSuccess(res.data.flows));
  } catch (err) {
    return yield put(fetchFlowsFail());
  }
}

function* changeFlowNameSaga({ payload }: any): any {
  const { flowId, flowName, sortQuery, searchQuery, skip, limit } = payload;
  try {
    const [err, res] = yield call(asyncPost, `/flows/updateFlow`, {
      updateQuery: {
        name: flowName,
      },
      flowId,
    });

    if (err) {
      return yield put(changeFlowNameFail());
    }
    if (!res.data.success) {
      return yield put(changeFlowNameFail());
    }
    return yield all([
      put(
        fetchFlows({
          sortQuery,
          searchQuery,
          skip,
          limit,
        })
      ),
      put(changeFlowNameSuccess()),
    ]);
  } catch (err) {
    return yield put(changeFlowNameFail());
  }
}

function* changeFlowsStateSaga({ payload }: any): any {
  const { selectedFlowIds, newState, sortQuery, skip, limit, searchQuery } =
    payload;

  try {
    const [err, res] = yield call(asyncPost, `/flows/updateFlow`, {
      updateQuery: {
        state: newState,
      },
      flowId: selectedFlowIds,
    });

    if (err) {
      return;
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return;
    }

    showSuccessToast("Flows updated successfully.");
    return yield all([
      put(
        fetchFlows({
          sortQuery,
          searchQuery,
          skip,
          limit,
        })
      ),
      put(fetchFlowsCount()),
    ]);
  } catch (err) {
    showErrorToast();
    return;
  }
}

function* fetchFlowsCountSaga(): any {
  try {
    const [err, res] = yield call(asyncPost, "/flows/getFlowsCount");
    if (err) {
      return;
    }
    if (!res.data.success) {
      return;
    }

    return yield put(fetchFlowsCountSuccess(res.data.flowsCount));
  } catch (err) {
    return;
  }
}

export function* watchFlows() {
  yield takeEvery(FETCH_FLOWS, fetchFlowsSaga);
  yield takeEvery(CHANGE_FLOW_NAME, changeFlowNameSaga);
  yield takeEvery(CHANGE_FLOWS_STATE, changeFlowsStateSaga);
  yield takeEvery(FETCH_FLOWS_COUNT, fetchFlowsCountSaga);
}
