import { useState, useEffect } from "react";
import { HiCheck, HiChevronDown, HiMagnifyingGlass } from "react-icons/hi2";
import { BiLoader } from "react-icons/bi";
import { GoTasklist } from "react-icons/go";
import { Combobox } from "@headlessui/react";
import { startCase } from "lodash";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props: any) {
    const { options, onChange, isLoading, placeholder } = props;
    const [query, setQuery] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [showLoader, setShowLoader] = useState(isLoading || false);

    useEffect(() => {
        setSelectedOption("");
    }, [options]);

    useEffect(() => {
        setShowLoader(isLoading);
    }, [isLoading]);

    const filteredOptions =
        query === ""
            ? options
            : options.filter((opt: any) => {
                  return opt?.displayText?.includes(query?.toLowerCase());
              });

    const handleOnChange = (value: any) => {
        setSelectedOption(value);
        onChange && onChange(value);
    };

    return (
        <Combobox as="div" value={selectedOption} onChange={handleOnChange} disabled={props?.disabled}>
            <div className="relative mt-1">
                <Combobox.Input
                    className="sm:text-md w-full rounded-md border-gray-300 py-2 pl-8 pr-10
         shadow-sm outline-none focus:border-orange-500 focus:bg-white text-gray-900 dark:bg-gray-200
               focus:ring-orange-500"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(opt: any) => {
                        return startCase(opt);
                    }}
                    placeholder={placeholder || "Search"}
                />
                <Combobox.Button className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 text-gray-600 focus:text-gray-300 focus:outline-none">
                    {showLoader ? <BiLoader className="spinner-border mr-5 h-5 w-5 animate-spin" aria-hidden="true" /> : <GoTasklist className="" />}
                </Combobox.Button>
                {/* <Combobox.Button className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 text-gray-600 focus:text-gray-300 focus:outline-none">
                    <HiMagnifyingGlass className="mr-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                </Combobox.Button> */}
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <HiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredOptions?.length > 0 && (
                    <Combobox.Options
                        className="absolute z-10 mt-1 max-h-56 w-full overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black
 ring-opacity-5 scrollbar-thin scrollbar-track-orange-50 scrollbar-thumb-orange-400 scrollbar-track-rounded-full scrollbar-thumb-rounded-md scrollbar-w-1
 focus:outline-none sm:text-sm"
                    >
                        {filteredOptions.map((opt: any) => (
                            <Combobox.Option
                                key={opt._id}
                                value={opt.slug}
                                className={({ active }) =>
                                    classNames(
                                        "relative cursor-default select-none py-2 pl-8 pr-4",
                                        active ? "bg-gray-200 text-gray-900" : "text-gray-900",
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames("block truncate", selected && "font-semibold")}>{opt.displayText}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                                    active ? "text-gray-900" : "text-orange-600",
                                                )}
                                            >
                                                <HiCheck className="h-5 w-5 text-gray-700" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
