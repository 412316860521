import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { cloneDeep, get, isEmpty } from "lodash";
import ContentLoader from "react-content-loader";
import { FaAnchor, FaFilter, FaPaperPlane, FaTrash } from "react-icons/fa";

import { connectAccNotApplicable } from "constants/integrations";
import SelectIntegration from "../StageItems/SelectIntegration";
import SelectTriggerEvent from "../StageItems/SelectTriggerEvent";
import SelectAccount from "../StageItems/SelectAccount";
import SetupEventOptions from "../StageItems/SetupEventOptions";
import TestEvent from "../StageItems/TestEvent";
import SelectActionEvent from "../StageItems/SelectActionEvent";
import SetupActionTemplate from "../StageItems/SetupActionTemplate";
import TestAction from "../StageItems/TestAction";
import SetupFilter from "../StageItems/SetupFilter";
import SetupDelay from "../StageItems/SetupDelay";
import IntegrationDemoVideo from "../IntegrationDemoVideo";

import {
  SELECT_INTEGRATION,
  SELECT_EVENT,
  SELECT_ACTION,
  SELECT_ACCOUNT,
  SETUP_EVENT_OPTIONS,
  SETUP_ACTION_TEMPLATE,
  SETUP_FILTER,
  TEST_EVENT,
  TEST_ACTION,
} from "enums/StageItemSlugsEnum";
import { TRIGGER, ACTION, FILTER, DELAY } from "enums/StageTypesEnum";
import { TrashIcon } from "@heroicons/react/24/solid";

class StageContainer extends Component {
  stageItemsStatuses = {
    HIDDEN: "hidden",
    SHOWING: "showing",
    LOADING: "loading",
    COMPLETE: "complete",
  };

  stageItemSlugToComponentMapping = {
    [SELECT_INTEGRATION]: SelectIntegration,
    [SELECT_EVENT]: SelectTriggerEvent,
    [SELECT_ACTION]: SelectActionEvent,
    [SELECT_ACCOUNT]: SelectAccount,
    [SETUP_EVENT_OPTIONS]: SetupEventOptions,
    [SETUP_ACTION_TEMPLATE]: SetupActionTemplate,
    [SETUP_FILTER]: SetupFilter,
    [TEST_EVENT]: TestEvent,
    [TEST_ACTION]: TestAction,
  };

  state = {
    stageItemsToDisplay: [
      {
        stageItemSlug: SELECT_INTEGRATION,
        status: this.stageItemsStatuses.SHOWING,
      },
    ],
    isIntegrationChangedInViewFlow: false,
    random: 0,
  };

  initStageItems = async (nextProps, isComponentMounted) => {
    const setNextStageItemsToDisplay = (nextSelectedIntegration) => {
      let stageItems;
      if (stageType === TRIGGER) {
        stageItems = nextSelectedIntegration.triggerStageItems;
      } else if (stageType === ACTION) {
        stageItems = nextSelectedIntegration.actionStageItems;
      }

      delete stageItems?._id;

      this.setState((prevState) => {
        const { stageItemsToDisplay } = prevState;
        let clonedstageItemsToDisplay = cloneDeep(stageItemsToDisplay);
        clonedstageItemsToDisplay = clonedstageItemsToDisplay.slice(0, 1);
        const stageItemsSlugs = Object.keys(stageItems);
        stageItemsSlugs.forEach((eachStageItemSlug) => {
          const { orderIndex } = stageItems[eachStageItemSlug];

          if (isEmpty(clonedstageItemsToDisplay[orderIndex])) {
            clonedstageItemsToDisplay[orderIndex] = {
              stageItemSlug: eachStageItemSlug,
              status: this.stageItemsStatuses.HIDDEN,
            };
          }
        });

        clonedstageItemsToDisplay[1].status = this.stageItemsStatuses.SHOWING;

        return {
          stageItemsToDisplay: clonedstageItemsToDisplay,
        };
      });
    };

    const {
      selectedIntegrations: prevSelectedIntegrations,
      stageIndex: prevStageIndex,
    } = this.props;
    const prevSelectedIntegration = prevSelectedIntegrations[prevStageIndex];

    const {
      selectedIntegrations: nextSelectedIntegrations,
      stageIndex: nextStageIndex,
      stageType,
      flowObj,
      from,
    } = nextProps;
    const nextSelectedIntegration = nextSelectedIntegrations[nextStageIndex];

    if (from === "view" && !this.state.isIntegrationChangedInViewFlow) {
      const { stages } = flowObj;
      const currentStage = stages[nextStageIndex];
      const { stageItems: currentStageItems } = currentStage;
      let stageItemsToDisplay = [];

      if (stageType === TRIGGER || stageType === ACTION) {
        if (
          !isEmpty(prevSelectedIntegration) &&
          !isEmpty(nextSelectedIntegration) &&
          prevSelectedIntegration._id.toString() !==
            nextSelectedIntegration._id.toString()
        ) {
          this.setState({
            isIntegrationChangedInViewFlow: true,
          });
          setNextStageItemsToDisplay(nextSelectedIntegration);
          return;
        }

        if (
          !isEmpty(currentStageItems.selectIntegration.selectedIntegrationId)
        ) {
          stageItemsToDisplay.push({
            stageItemSlug: SELECT_INTEGRATION,
            status: this.stageItemsStatuses.COMPLETE,
          });
        }

        if (!isEmpty(currentStageItems.selectEvent)) {
          stageItemsToDisplay.push({
            stageItemSlug: SELECT_EVENT,
            status: this.stageItemsStatuses.COMPLETE,
          });
        }

        if (!isEmpty(currentStageItems.selectAction)) {
          stageItemsToDisplay.push({
            stageItemSlug: SELECT_ACTION,
            status: this.stageItemsStatuses.COMPLETE,
          });
        }

        if (
          (!isEmpty(currentStageItems.selectAccount) &&
            !isEmpty(currentStageItems.selectAccount.selectedAccountId)) ||
          (connectAccNotApplicable.includes(
            currentStageItems.selectIntegration.selectedIntegrationSlug
          ) &&
            nextStageIndex === 0)
        ) {
          stageItemsToDisplay.push({
            stageItemSlug: SELECT_ACCOUNT,
            status: this.stageItemsStatuses.COMPLETE,
          });
        }

        if (
          !isEmpty(currentStageItems.setupEventOptions) &&
          !isEmpty(
            currentStageItems.setupEventOptions
              .selectedDynamicIncomingDataPrerequisites
          )
        ) {
          stageItemsToDisplay.push({
            stageItemSlug: SETUP_EVENT_OPTIONS,
            status: this.stageItemsStatuses.COMPLETE,
          });
        }

        if (
          !isEmpty(currentStageItems.setupActionTemplate) &&
          !isEmpty(currentStageItems.setupActionTemplate.apiData)
        ) {
          stageItemsToDisplay.push({
            stageItemSlug: SETUP_ACTION_TEMPLATE,
            status: this.stageItemsStatuses.COMPLETE,
          });
        }

        if (!isEmpty(currentStageItems.testEvent)) {
          stageItemsToDisplay.push({
            stageItemSlug: TEST_EVENT,
            status:
              currentStageItems.testEvent.testStatus === "done"
                ? this.stageItemsStatuses.COMPLETE
                : this.stageItemsStatuses.SHOWING,
          });
        }

        if (!isEmpty(currentStageItems.testAction)) {
          stageItemsToDisplay.push({
            stageItemSlug: TEST_ACTION,
            status:
              currentStageItems.testAction.testStatus === "done"
                ? this.stageItemsStatuses.COMPLETE
                : this.stageItemsStatuses.SHOWING,
          });
        }
      } else if (stageType === FILTER) {
        if (!isEmpty(currentStageItems.setupFilter)) {
          stageItemsToDisplay = [
            {
              stageItemSlug: SETUP_FILTER,
              status: this.stageItemsStatuses.COMPLETE,
            },
          ];
        }
      }

      this.setState({
        stageItemsToDisplay,
      });
    } else {
      if (stageType === FILTER) {
        this.setState({
          stageItemsToDisplay: [
            {
              stageItemSlug: SETUP_FILTER,
              status: this.stageItemsStatuses.SHOWING,
            },
          ],
        });
        return;
      }
      if (!isComponentMounted) {
        if (
          !isEmpty(prevSelectedIntegration) &&
          !isEmpty(nextSelectedIntegration) &&
          prevSelectedIntegration._id.toString() ===
            nextSelectedIntegration._id.toString()
        ) {
          return;
        }

        const { stageItemsToDisplay } = this.state;
        if (
          !isEmpty(nextSelectedIntegration) &&
          stageItemsToDisplay[0].stageItemSlug === SETUP_FILTER
        ) {
          stageItemsToDisplay[0] = {
            stageItemSlug: SELECT_INTEGRATION,
            status: this.stageItemsStatuses.COMPLETE,
          };
          await this.setState({
            stageItemsToDisplay,
          });
        }
      }

      const isCurrentStageIntegrationSelected = !isEmpty(
        nextSelectedIntegration
      );

      if (!isCurrentStageIntegrationSelected) {
        this.setState({
          stageItemsToDisplay: [
            {
              stageItemSlug: SELECT_INTEGRATION,
              status: this.stageItemsStatuses.SHOWING,
            },
          ],
        });
        return;
      }

      setNextStageItemsToDisplay(nextSelectedIntegration);
    }
  };

  componentDidMount() {
    this.initStageItems(this.props, true);
  }

  setStageItemCompletionStatus = (stageItem) => {
    this.setState((prevState) => {
      const { stageItemsToDisplay } = prevState;

      const indexOfCurrentStageItem = stageItemsToDisplay.findIndex(
        ({ stageItemSlug }) => stageItemSlug === stageItem
      );

      const clonedstageItemsToDisplay = cloneDeep(stageItemsToDisplay);
      const nextStageItemIndex = indexOfCurrentStageItem + 1;
      if (!isEmpty(clonedstageItemsToDisplay[indexOfCurrentStageItem])) {
        clonedstageItemsToDisplay[indexOfCurrentStageItem].status =
          this.stageItemsStatuses.COMPLETE;
      }

      if (!isEmpty(clonedstageItemsToDisplay[nextStageItemIndex])) {
        clonedstageItemsToDisplay[nextStageItemIndex].status =
          this.stageItemsStatuses.SHOWING;
      }

      return {
        stageItemsToDisplay: clonedstageItemsToDisplay,
      };
    });
  };

  componentWillReceiveProps(nextProps) {
    this.initStageItems(nextProps);
  }

  render() {
    const {
      stageIndex,
      stageType,
      selectedIntegrations,
      isLastStage,
      shouldShowAddFilter,
      flowObj,
    } = this.props;
    const currentStageSelectedIntegration = selectedIntegrations[stageIndex];

    let currentStageIntegrationStageItems = {};
    if (!isEmpty(currentStageSelectedIntegration)) {
      currentStageIntegrationStageItems =
        stageType === TRIGGER
          ? currentStageSelectedIntegration.triggerStageItems
          : currentStageSelectedIntegration.actionStageItems;
    }

    const { stageItemsToDisplay } = this.state;
    const stageItemsComponents = [];

    stageItemsToDisplay?.forEach((eachStageItem, eachStageItemIndex) => {
      const { status, stageItemSlug } = eachStageItem;
      const StageItemComponent =
        this.stageItemSlugToComponentMapping[stageItemSlug];
      const stageItemsStatuses = this.stageItemsStatuses;
      const currentStageItem = currentStageIntegrationStageItems[stageItemSlug];

      if (
        status === stageItemsStatuses.SHOWING ||
        status === stageItemsStatuses.COMPLETE
      ) {
        stageItemsComponents.push(
          <Fragment>
            <StageItemComponent
              stageIndex={stageIndex}
              stageType={stageType}
              {...(!isEmpty(currentStageItem)
                ? {
                    isMandatory: currentStageItem.isMandatory,
                    titleDisplayText: currentStageItem.titleDisplayText,
                    unselectedDisplayText:
                      currentStageItem.unselectedDisplayText,
                    userHelper: currentStageItem.userHelper,
                    orderIndex: currentStageItem.orderIndex,
                  }
                : {})}
              finishStageItem={this.setStageItemCompletionStatus}
              isStageSetupComplete={status === stageItemsStatuses.COMPLETE}
              isLastStageItem={
                eachStageItemIndex === stageItemsToDisplay.length - 1
              }
            />
          </Fragment>
        );
      } else if (status === stageItemsStatuses.LOADING) {
        stageItemsComponents.push(
          <ContentLoader height={4} width={50} speed={2}>
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="4" />
          </ContentLoader>
        );
      }
    });

    let stageToLoad = null;
    if (stageType === TRIGGER || stageType === ACTION) {
      stageToLoad = (
        <div>
          {stageItemsComponents.map((eachStageItemToDisplay, index) => {
            return (
              <div className="mt-3" key={index}>
                {eachStageItemToDisplay}
              </div>
            );
          })}
        </div>
      );
    } else if (stageType === FILTER) {
      stageToLoad = (
        <div>
          <SetupFilter
            stageType={this.props.stageType}
            stageIndex={stageIndex}
            finishStageItem={this.setStageItemCompletionStatus}
          />
        </div>
      );
    } else if (stageType === DELAY) {
      stageToLoad = (
        <div>
          <SetupDelay
            stageType={this.props.stageType}
            stageIndex={stageIndex}
            finishStageItem={this.setStageItemCompletionStatus}
          />
        </div>
      );
    }

    let stageHeader = <span>{stageType}</span>;

    const flowStages = flowObj?.stages;
    const actionIndex = flowStages?.findIndex(
      ({ stageType }) => stageType === "action"
    );

    const isTriggerStageCompleted = get(
      flowStages,
      "0.stageItems.testEvent.testStatus",
      ""
    );
    const isActionStageCompleted = get(
      flowStages,
      `${actionIndex}.stageItems.testAction.testStatus`,
      ""
    );

    return (
      <>
        <div className=" flex w-[100%] flex-row">
          {/* Left - Stage Indicator Section */}
          <div className=" flex w-[10%] flex-col items-center">
            {/*Number Circle*/}
            <div
              className={`h-12 w-12 ${
                (stageIndex === 0 && isTriggerStageCompleted === "done") ||
                (stageIndex === actionIndex &&
                  isActionStageCompleted === "done")
                  ? "bg-green-100"
                  : "bg-gray-100"
              } flex flex-row items-center justify-center rounded-[50%] text-gray-600`}
            >
              {stageIndex + 1}
            </div>
            {/* Filter and Top Bottom Line Wrapper */}
            {shouldShowAddFilter && !isLastStage && (
              <>
                {/*Filter Top Line */}
                <div className="ml-4 h-[100px] w-[18px] border-l-[2px] border-gray-300"></div>
                {/*Filter Icon */}
                <div
                  onClick={this.props.addFilterStage}
                  className="flex h-12 w-12 flex-row items-center justify-center rounded-[50%] bg-gray-200 text-gray-600"
                >
                  <FaFilter size={12} />
                </div>
                {/*Filter Bottom Line */}
                <div className="ml-4 h-[80px] w-[18px] border-l-[2px] border-gray-300"></div>
              </>
            )}
          </div>
          {/* Right - Stage Wrapper Section */}
          <div className="relative mx-4 h-[250px] w-full border-2 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-700 dark:text-white">
            {/* Stage - Header Section */}
            <div className="te-flex-row flex items-center justify-between space-x-3 bg-gray-100 p-4 capitalize dark:bg-gray-600">
              <div className="space-x-3">
                {stageType === TRIGGER && (
                  <FaPaperPlane size={16} className="inline" />
                )}
                {stageType === ACTION && (
                  <FaAnchor size={16} className="inline" />
                )}
                {stageType === FILTER && (
                  <FaFilter size={16} className="inline" />
                )}
                <span className="text-base font-semibold">{stageHeader}</span>
              </div>
              {stageType === FILTER && (
                <TrashIcon
                  className="h-5 w-5 cursor-pointer text-red-500"
                  onClick={() => this.props.deleteStage(stageIndex, stageType)}
                />
              )}
            </div>
            {/* Stage - Body Section */}
            <div className="px-4 pt-2 pb-10 text-[14px]">{stageToLoad}</div>
            <IntegrationDemoVideo stageType={stageType} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ FlowBuilder }) => {
  const { selectedIntegrations, flowObj } = FlowBuilder;

  return {
    selectedIntegrations,
    flowObj,
  };
};

export default connect(mapStateToProps, null)(StageContainer);
