import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Avatar from "react-avatar";

interface IWbIntegrationImage {
	slug: string | any;
	altText?: string;
	classNames?: string;
	avtarSize?: string;
}

export default function WbIntegrationImage(props: IWbIntegrationImage) {
	const { slug, altText, classNames, avtarSize } = props;
	const [isDefaultImage, setIsDefaultImage] = useState(false);
	const location = useLocation();
	const pathname = location.pathname;

	useEffect(() => {
		setIsDefaultImage(false);
	}, [pathname]);

	const handleDefaultImage = (e: any) => {
		setIsDefaultImage(true);
	};

	return (
		<>
			{!isDefaultImage ? (
				<img
					src={`/assets/img/integrations/hex_${slug}.png`}
					className={
						classNames ? classNames : "h-24 w-24 object-contain"
					}
					alt={altText ? altText : slug}
					onError={handleDefaultImage}
				/>
			) : (
				<>
					{classNames?.includes("bg-") ? (
						<div className=" rounded-full border bg-gray-50 p-2">
							<Avatar
								name={slug}
								maxInitials={2}
								size={avtarSize ? avtarSize : "80"}
								round={true}
							/>
						</div>
					) : (
						<Avatar
							color="orange"
							className="bg-orange-500"
							name={slug}
							maxInitials={2}
							size={avtarSize ? avtarSize : "80"}
							round={true}
						/>
					)}
				</>
			)}
		</>
	);
}
