export const FETCH_INTEGRATIONS = "FETCH_INTEGRATIONS";
export const FETCH_INTEGRATIONS_SUCCESS = "FETCH_INTEGRATIONS_SUCCESS";
export const FETCH_INTEGRATIONS_FAIL = "FETCH_INTEGRATIONS_FAIL";
export const FILTER_INTEGRATIONS_BY_TEXT = "FILTER_INTEGRATIONS_BY_TEXT";
export const FILTER_INTEGRATIONS_BY_CATEGORY = "FILTER_INTEGRATIONS_BY_CATEGORY";
export const SHOW_REQUEST_INTEGRATION_MODAL = "SHOW_REQUEST_INTEGRATION_MODAL";
export const REQUEST_INTEGRATION = "REQUEST_INTEGRATION";
export const REQUEST_INTEGRATION_SUCCESS = "REQUEST_INTEGRATION_SUCCESS";
export const REQUEST_INTEGRATION_FAIL = "REQUEST_INTEGRATION_FAIL";
export const HIDE_REQUEST_INTEGRATION_MODAL = "HIDE_REQUEST_INTEGRATION_MODAL";
export const UPDATE_APPLICATION_SORT_SEQ = "UPDATE_APPLICATION_SORT_SEQ"; 