import { isEmpty, map } from "lodash";

const LeadFields = ({ leadData, heading }: any) => {
  return (
    <>
      <div className="text-xl mx-4 font-semibold">
        <h1>{heading}</h1>
      </div>
      <div className="p-4">
        {!isEmpty(leadData) ? (
          map(leadData, (value, key) => {
            if (
              typeof value !== "object" &&
              ![null, undefined, ""].includes(value)
            ) {
              return (
                <div key={key} className="my-2 grid grid-cols-4">
                  <div className="flex items-center rounded-md border break-words p-2 dark:border-gray-600 dark:text-gray-100">
                    {key}
                  </div>
                  <div className="col-span-3 ml-2 flex items-center break-words rounded-md border p-2 font-semibold dark:border-gray-600 dark:text-gray-100">
                    {typeof value === "string" && value?.includes("http") ? (
                      <a
                        href={value}
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {value}
                      </a>
                    ) : (
                      value
                    )}
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div className="my-2 grid grid-cols-4">
            <div className="flex items-center rounded-md border p-2 dark:border-gray-600 dark:text-gray-100">
              No Data
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LeadFields;
