import { call, put, takeEvery } from "redux-saga/effects";
import { each } from "lodash";

import { asyncPost, asyncPostWithoutErrHandler } from "utils/async_util";

import {
  GET_CURRENT_SUBSCRIBED_PLAN_DETAILS,
  CANCEL_STRIPE_SUBSCRIPTION,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  GET_DEFAULT_CARD_DETAILS,
  SEND_FEEDBACK,
  CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
} from "./ManageSubscriptionActionTypes";

import {
  getPlanDetailsError,
  getPlanDetailsSuccess,
  cancelSubscriptionError,
  cancelSubscriptionSuccess,
  updatePlanError,
  updatePlanSuccess,
  getDefaultCardDetailsError,
  getDefaultCardDetailsSuccess,
} from "./ManageSubscriptionActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* getPlanDetailsSaga({ payload }) {
  try {
    const { stripeSubscriptionId, subscriptionId } = payload;

    const [err, res] = yield call(asyncPost, "/account/billing/plan/getPlanDetails", {
      stripeSubscriptionId: stripeSubscriptionId,
      subscriptionId: subscriptionId,
    });

    if (err) {
      return yield put(getPlanDetailsError());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.errMessage || res?.data?.message);
      return yield put(getPlanDetailsError());
    }

    return yield put(getPlanDetailsSuccess(res.data.subscriptionDetails));
  } catch (err) {
    showErrorToast(err.message);
    return yield put(getPlanDetailsError());
  }
}

function* cancelSubscriptionSaga({ payload }) {
  try {
    const {
      shouldCancelSubscriptionImmediately,
      stripeSubscriptionId,
      subscriptionId,
      reasonForCancel,
      description,
      sideEffectFunctions,
    } = payload;

    const [err, res] = yield call(asyncPost, "/account/billing/plan/cancelPlan", {
      shouldCancelSubscriptionImmediately,
      stripeSubscriptionId: stripeSubscriptionId,
      subscriptionId,
      reasonForCancel,
      description,
    });

    if (err) {
      return yield put(cancelSubscriptionError());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(cancelSubscriptionError());
    }

    showSuccessToast("Successfully unsubscribed to plan.");

    return yield put(cancelSubscriptionSuccess(sideEffectFunctions));
  } catch (err) {
    showErrorToast();
    return yield put(cancelSubscriptionError());
  }
}

function cancelSubscriptionSideEffectFunctionsSaga({ payload }) {
  const { sideEffectFunctions } = payload;
  each(sideEffectFunctions, (eachFn) => {
    eachFn(payload);
  });
}

function* updatePlanSaga({ payload }) {
  const { stripeSubscriptionId, planId, tasksCount, sideEffectFunctions } =
    payload;

  try {
    const [err, res] = yield call(asyncPost, "/account/billing/plan/updatePlan", {
      stripeSubscriptionId: stripeSubscriptionId,
      planIdToUpdate: planId,
      tasksCount,
    });

    if (err) {
      return yield put(updatePlanError());
    }

    if (!res.data.success) {
      showErrorToast(res?.data?.message);
      return yield put(updatePlanError());
    }

    showSuccessToast("Plan changed successfully.");

    return yield put(updatePlanSuccess(sideEffectFunctions));
  } catch (err) {
    showErrorToast();
    return yield put(updatePlanError());
  }
}

function updatePlanSuccessSaga({ payload }) {
  const { sideEffectFunctions } = payload;
  each(sideEffectFunctions, (eachFn) => {
    eachFn(payload);
  });
}

function* getDefaultCardDetailsSaga({ payload }) {
  const { stripeCustomerId } = payload;

  try {
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/account/billing/payment/getPaymentCard",
      { stripeCustomerId }
    );
    if (err) {
      return yield put(getDefaultCardDetailsError());
    }

    if (!res.data.success) {
      return yield put(getDefaultCardDetailsError());
    }
    return yield put(getDefaultCardDetailsSuccess(res.data.defaultCard));
  } catch (err) {
    return yield put(getDefaultCardDetailsError());
  }
}

function* sendFeedBackSaga({ payload }) {
  const { reason, description } = payload;

  try {
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/support/feedback",
      { reason, description }
    );
    if (err) {
      return;
    }

    if (!res.data.success) {
      return;
    }
    return;
  } catch (err) {
    return;
  }
}

export function* watchManageSubscription() {
  yield takeEvery(GET_CURRENT_SUBSCRIBED_PLAN_DETAILS, getPlanDetailsSaga);
  yield takeEvery(CANCEL_STRIPE_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(UPDATE_PLAN, updatePlanSaga);
  yield takeEvery(UPDATE_PLAN_SUCCESS, updatePlanSuccessSaga);
  yield takeEvery(GET_DEFAULT_CARD_DETAILS, getDefaultCardDetailsSaga);
  yield takeEvery(SEND_FEEDBACK, sendFeedBackSaga);
  yield takeEvery(
    CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
    cancelSubscriptionSideEffectFunctionsSaga
  );
}
