import WbButton from "components/WbButton";
import WbModal, { WbModalTitle } from "components/WbModal";

import CSVReaders from "./CSVReader";

interface importFile {
  toggleModal: Function;
}

export default function ImportLeads({ toggleModal }: importFile) {
  const handleContinue = async () => {
    toggleModal();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <WbModal toggle={toggleModal} isOpen={true} size="md">
      <WbModalTitle title="Import Leads" toggle={toggleModal} />
      <div className="p-4">
        <CSVReaders />
      </div>
      <div className="float-right flex items-center space-x-3 p-4">
        <button
          onClick={() => toggleModal()}
          className="flex h-full items-center rounded border p-2 px-5 text-gray-700 hover:border-gray-500"
        >
          Cancel
        </button>
        <WbButton onClick={handleContinue}>Continue</WbButton>
      </div>
    </WbModal>
  );
}
