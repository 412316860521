// import { StripeProvider } from "react-stripe-elements";

import WbModal, { WbModalTitle } from "components/WbModal";
import StripeElement from "../StripeCard/StripeElement";
import Individual from "../../../assets/img/billing/bootstrap.png";
import Startup from "../../../assets/img/billing/startup.png";
import Business from "../../../assets/img/billing/business.png";

export default function AddCardModal(props) {
  const { planSelected, tasksCount, closeModal } = props;
  const { displayText, vendorProductId, vendorPlanId } = planSelected;
  let { features, price } = planSelected;

  if (tasksCount > 0) {
    price = tasksCount * 0.01;
    features = [`${tasksCount} tasks / month`];
  }
  const planIcon = {
    Startup,
    Individual,
    Business,
  };

  return (
    <WbModal isOpen={true} size="md" toggle={closeModal}>
      <WbModalTitle toggle={closeModal} title="Add Card Details" />
      <div className="flex flex-row items-center space-x-2 p-4">
        <div className="h-80 flex-1 space-y-4 bg-gray-200">
          <div className="flex justify-center rounded-br-[40px] bg-gray-300 p-5 text-center">
            <img
              src={planIcon[displayText]}
              alt="bootstarp"
              width={40}
              height={40}
            />
          </div>
          <div className="flex justify-center text-2xl">
            ${price}
            <div className="">/ month</div>
          </div>
          <h2 className="text-center text-xl font-semibold uppercase text-orange-600">
            {displayText}
          </h2>
          <div className="text-l px-20 text-gray-800">
            <ul>
              {features &&
                features.map((eachFeature, index) => (
                  <li key={index}>
                    <h6>
                      <i className="simple-icon-check text-green-700" />
                      &nbsp;
                      {eachFeature}
                    </h6>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="flex-1 space-y-4 p-4">
          <h6 className="text-lg">SUBSCRIBE TO A PLAN</h6>
          <div className="flex flex-row items-center space-x-4">
            <div className="text-gray-600">Payment Period</div>
            <div className="rounded-lg bg-sky-700 p-1 text-white">Monthly</div>
          </div>
          <div>
            {/* <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}> */}
              <StripeElement
                tasksCount={tasksCount}
                closeModal={closeModal}
                vendorProductId={vendorProductId}
                vendorPlanId={vendorPlanId}
              />
            {/* </StripeProvider> */}
          </div>
          <div className="text-[14px] text-gray-600">
            No commitments for 7 days - cancel anytime
          </div>
        </div>
      </div>
    </WbModal>
  );
}
