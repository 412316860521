import WbMenu from "components/WbMenu";
import { isEmpty } from "lodash";
import { HiChevronDown } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { DocumentPlusIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function ActionsHelper({ toggleModal }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname.split("/").at(-1);

  const menuItems = {
    leads: [
      {
        text: "Create",
        icon: <DocumentPlusIcon className="w-4" />,
        onClick: () => navigate("leads/new"),
      },
      {
        text: "Import",
        icon: <UserPlusIcon className="w-4" />,
        onClick: () => toggleModal(),
      }
    ],
    segments: [
      {
        text: "Create Segment",
        icon: <DocumentPlusIcon className="w-4" />,
        onClick: () => navigate("segments/new"),
      },
    ],
    senders: [
      {
        text: "Create Sender",
        icon: <DocumentPlusIcon className="w-4" />,
        onClick: () => navigate("senders/new"),
      },
    ],
  };
  
  const getMenuItems = (pathName) => {
    return menuItems[pathName];
  };
  const path = getMenuItems(pathName);

  return (
    <>
      {!isEmpty(path) && (
        <section>
          <WbMenu
            menuItems={getMenuItems(pathName)}
            color="gray"
            className="flex h-10 w-full cursor-pointer items-center justify-center rounded-md border-2 border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white"
          >
            <span className="mr-1">Actions</span>{" "}
            <HiChevronDown className="dark:text-white" size={28} />
          </WbMenu>
        </section>
      )}
    </>
  );
}
