const SearchBar = (props: any) => {
  return (
    <div className="w-[100%]">
      <input
        type="text"
        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
        id={props.id}
        placeholder={props.placeholder}
        onKeyUp={(e) => props.handleSearch(e)}
        name={props.name}
        value={props.value}
      />
    </div>
  );
};

export default SearchBar;
