import { useCSVDownloader } from "react-papaparse";

export default function SampleCsv() {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <>
      <div className="text-center">
        <CSVDownloader
          type={Type.Button}
          filename={"data_studio_import_sample"}
          bom={true}
          config={{
            delimiter: ",",
          }}
          data={[
            {
              Email: "john@example.com",
              "First Name": "John",
              "Last Name": "Doe",
            },
            {
              Email: "john@example.com",
              "First Name": "John",
              "Last Name": "Doe",
            },
          ]}
        >
          <div className="text-center w-full text-orange-600 underline">
            Download Sample Template
          </div>
        </CSVDownloader>
      </div>
    </>
  );
}
