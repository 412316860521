import { useState } from "react";
import _ from "lodash";
import DOMPurify from "dompurify";

import { faqs } from "./FAQsData";

export default function FAQSContainer() {
  const [shouldExpandQuestion, setShouldExpandQuestion] = useState<any>({});

  const toggleQuestions = (indexOfQuestion: any) => {
    setShouldExpandQuestion({
      ...shouldExpandQuestion,
      [indexOfQuestion]: shouldExpandQuestion[indexOfQuestion],
    });
  };

  const faqsToShow = _.map(faqs, (eachFaq, index) => {
    return (
      <div key={index}>
        <div
          onClick={() => toggleQuestions(index)}
          aria-expanded={shouldExpandQuestion[index]}
        >
          <h6>{eachFaq.question}</h6>
        </div>

        {shouldExpandQuestion[index] && (
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(eachFaq.answer),
            }}
          ></p>
        )}
      </div>
    );
  });

  return (
    <div className="mt-4 pt-4">
      <h1>
        Frequently Asked <span>Questions</span>
      </h1>
      <div>{faqsToShow}</div>
    </div>
  );
}
