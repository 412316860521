import { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { isDataPresent } from "utils/object_util";
import { loadDataFromUrl } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";
import OptionalRequiredLabel from "../OptionalRequiredLabel";
import WbTextInput from "components/WbTextInput";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

class RichSelect extends Component {
    state = {
        shouldLoadDropDownMenuItems: false,
        optionsData: [],
        searchText: null,
        isSelectBarOpened: false,
    };

    toggleOptionsDisplay = () => {
        this.setState((prevState) => ({
            shouldLoadDropDownMenuItems: !prevState.shouldLoadDropDownMenuItems,
        }));
    };

    async componentDidMount() {
        const { dynamicDataLoadOptions, staticData, selectedValueData } = this.props;

        let newOptionsData = [];
        if (isDataPresent(staticData)) {
            newOptionsData = [...newOptionsData, ...staticData];
            await this.setState({
                optionsData: newOptionsData,
            });
            if (isDataPresent(selectedValueData)) {
                const { jsonDetails: selectedJsonDetails } = selectedValueData;
                const selectedValueDetails = selectedJsonDetails[0].valueDetails[0];
                this.props.userInputHandler({
                    jsonPairIndex: 0,
                    userInput: {
                        value: selectedValueDetails.value,
                        displayText: selectedValueDetails.displayText,
                        valueFrom: selectedValueDetails.valueFrom || selectedValueDetails.from,
                    },
                    jsonPairType: JsonPairTypesEnum.VALUE,
                    jsonPairValueIndex: 0,
                });
            }
        }

        if (isDataPresent(dynamicDataLoadOptions)) {
            const { url, body } = dynamicDataLoadOptions;

            const setUrlLoadedData = async (urlLoadedData) => {
                const urlLoadedDataWithValueFrom = _.map(urlLoadedData, (eachUrlLoadedData) => {
                    return {
                        ...eachUrlLoadedData,
                        valueFrom: "dataLoadUrl",
                    };
                });
                newOptionsData = [...newOptionsData, ...urlLoadedDataWithValueFrom];
                await this.setState({ optionsData: newOptionsData });

                if (isDataPresent(selectedValueData)) {
                    const { jsonDetails: selectedJsonDetails } = selectedValueData;
                    const selectedValueDetails = selectedJsonDetails[0].valueDetails[0];
                    const selectedOption = _.find(newOptionsData, { value: selectedValueDetails.value });

                    if (isDataPresent(selectedOption)) {
                        this.props.userInputHandler({
                            jsonPairIndex: 0,
                            userInput: {
                                value: selectedValueDetails.value,
                                displayText: selectedValueDetails.displayText,
                                valueFrom: selectedValueDetails.valueFrom || selectedValueDetails.from,
                            },
                            jsonPairType: JsonPairTypesEnum.VALUE,
                            jsonPairValueIndex: 0,
                        });
                    }
                }
            };
            this.props.loadDataFromUrl(url, body, [setUrlLoadedData]);
        }
    }

    handleInputClick = () => {
        const { shouldLoadDropDownMenuItems: currentShouldLoadDropDownMenuItems } = this.state;

        this.toggleOptionsDisplay();

        const nextShouldLoadDropDownMenuItems = !currentShouldLoadDropDownMenuItems;

        if (!nextShouldLoadDropDownMenuItems) return;
    };

    optionSelectHandler = (jsonCustomInputDetails) => {
        this.toggleOptionsDisplay();
        this.setState({ searchText: null });
        this.props.userInputHandler(jsonCustomInputDetails);
    };

    searchOptionsToShowHandler = (event) => {
        const { target } = event;
        const { value } = target;

        this.setState((prevState) => {
            return {
                ...prevState.searchText,
                searchText: value,
            };
        });
    };

    render() {
        const { shouldLoadDropDownMenuItems, optionsData } = this.state;
        const { isLoadingDataFromUrl, userHelperContent, selectedValueData, labelText } = this.props;

        let displayTextOfDropDown = `Select ${labelText}`;
        let selectedOption;
        if (isDataPresent(selectedValueData)) {
            const { jsonDetails: selectedJsonDetails } = selectedValueData;
            const selectedValue = selectedJsonDetails[0].valueDetails[0].value;
            selectedOption = _.find(optionsData, { value: selectedValue });
            if (isDataPresent(selectedOption)) {
                displayTextOfDropDown = selectedOption.displayText;
            }
        }

        let optionsToShow = null;
        if (isLoadingDataFromUrl) {
            optionsToShow = <div>Loading choices...</div>;
        } else if (!isDataPresent(optionsData)) {
            optionsToShow = (
                <div className=" text-red-600 text-sm">
                    No choices found; either the choices are empty or there was an error. Please reach out to us at support@wyzebulb.com or our
                    support chat if there's an error.
                </div>
            );
        } else {
            let optionsDataFromState = this.state.optionsData;

            if (isDataPresent(this.state.searchText)) {
                optionsDataFromState = optionsData.filter((eachOption) => {
                    return eachOption.displayText.toLowerCase().includes(this.state.searchText.toLowerCase());
                });
            }

            optionsToShow = _.map(optionsDataFromState, (eachOption, index) => {
                return (
                    <div
                        className="p-2 hover:bg-gray-500 hover:text-white text-sm cursor-pointer"
                        key={index}
                        onClick={() =>
                            this.optionSelectHandler({
                                jsonPairIndex: 0,
                                userInput: {
                                    value: eachOption.value,
                                    displayText: eachOption.displayText,
                                    valueFrom: eachOption.valueFrom,
                                },
                                jsonPairType: JsonPairTypesEnum.VALUE,
                                jsonPairValueIndex: 0,
                            })
                        }
                        value={eachOption?.value}
                    >
                        {eachOption.displayText}
                    </div>
                );
            });
        }

        return (
            <Fragment>
                <div>
                    <div>
                        <OptionalRequiredLabel {...this.props} />
                    </div>
                </div>
                <div className=" text-gray-600 text-sm m-2">{userHelperContent}</div>
                <div className="flex flex-row items-center justify-between border-2 p-2 mt-4 dark:border-gray-600" onClick={this.handleInputClick}>
                    <div className="w-full">{displayTextOfDropDown}</div>
                    <div>{shouldLoadDropDownMenuItems ? <FaChevronUp /> : <FaChevronDown />}</div>
                </div>
                {shouldLoadDropDownMenuItems ? (
                    <div className="border-2">
                        {!isLoadingDataFromUrl && isDataPresent(this.state.optionsData) ? (
                            <div>
                                <WbTextInput
                                    sizing="sm"
                                    type="text"
                                    name="searchOptions"
                                    placeholder={`Search for ${this.props.labelText}`}
                                    onChange={this.searchOptionsToShowHandler}
                                />
                            </div>
                        ) : null}
                        <div className="p-2 max-h-[250px] overflow-x-auto bg-white  dark:bg-gray-700 dark:text-white">{optionsToShow}</div>
                    </div>
                ) : null}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ LoggedInLayout }) => {
    const { isLoadingDataFromUrl } = LoggedInLayout;

    return {
        isLoadingDataFromUrl,
    };
};

export default connect(mapStateToProps, {
    loadDataFromUrl,
})(RichSelect);
