import React, { Fragment } from "react";

import { isDataPresent } from "utils/object_util";
import DefaultInput from "./DefaultInput";
import DefaultTextarea from "./DefaultTextarea";
import RichInput from "./RichInput";
import RichSelect from "./RichSelect";
import HtmlTextarea from "./HtmlTextarea";
import RichTextarea from "./RichTextarea";
import UserHelper from "./UserHelper";
import JsonInput from "./JsonInput";

// TODO: Handle helperImageUrl and helperVideoUrl - One of the ideas is to open nested modal on click of the URL.
const CustomInput = (props) => {
    let inputElement = null;

    let userHelperContent = null;
    const { userHelper, stageIndex } = props;

    if (isDataPresent(userHelper)) {
        userHelperContent = <UserHelper userHelper={userHelper} stageIndex={stageIndex} />;
    }

    switch (props.elementType) {
        case "input":
            inputElement = <DefaultInput {...props} userHelperContent={userHelperContent} />;
            break;
        case "textarea":
            inputElement = <DefaultTextarea {...props} userHelperContent={userHelperContent} />;
            break;
        case "rich-input":
            inputElement = <RichInput {...props} userHelperContent={userHelperContent} />;
            break;
        case "rich-select":
            inputElement = <RichSelect {...props} userHelperContent={userHelperContent} />;
            break;
        case "rich-textarea":
            inputElement = <RichTextarea {...props} userHelperContent={userHelperContent} />;
            break;
        case "html-textarea":
            inputElement = <HtmlTextarea {...props} userHelperContent={userHelperContent} />;
            break;
        case "json-input":
            inputElement = <JsonInput {...props} userHelperContent={userHelperContent} />;
            break;
        default:
            inputElement = <DefaultInput {...props} userHelperContent={userHelperContent} />;
    }
    return <Fragment>{inputElement}</Fragment>;
};

export default CustomInput;
