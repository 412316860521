import {
	FETCH_INTEGRATIONS,
	FETCH_INTEGRATIONS_SUCCESS,
	FETCH_INTEGRATIONS_FAIL,
	FILTER_INTEGRATIONS_BY_CATEGORY,
	FILTER_INTEGRATIONS_BY_TEXT,
	SHOW_REQUEST_INTEGRATION_MODAL,
	REQUEST_INTEGRATION,
	REQUEST_INTEGRATION_SUCCESS,
	REQUEST_INTEGRATION_FAIL,
	HIDE_REQUEST_INTEGRATION_MODAL,
	UPDATE_APPLICATION_SORT_SEQ,
} from "./SelectIntegrationActionTypes";

export const fetchIntegrations = (findQuery, sideEffectFns, subscriptionId) => {
	return {
		type: FETCH_INTEGRATIONS,
		payload: {
			findQuery,
			sideEffectFns,
			subscriptionId,
		},
	};
};

export const fetchIntegrationsSuccess = (integrations) => {
	return {
		type: FETCH_INTEGRATIONS_SUCCESS,
		payload: {
			integrations,
		},
	};
};

export const fetchIntegrationsFail = () => {
	return {
		type: FETCH_INTEGRATIONS_FAIL,
	};
};

export const filterIntegrationsByCategory = (
	category,
	integrations,
	findQuery
) => {
	return {
		type: FILTER_INTEGRATIONS_BY_CATEGORY,
		payload: {
			category,
			integrations,
			findQuery,
		},
	};
};

export const filterIntegrationsByText = (text, integrations, findQuery) => {
	return {
		type: FILTER_INTEGRATIONS_BY_TEXT,
		payload: {
			text,
			integrations,
			findQuery,
		},
	};
};

export const showRequestIntegrationModal = () => {
	return {
		type: SHOW_REQUEST_INTEGRATION_MODAL,
	};
};

export const requestIntegration = (
	userName,
	userEmail,
	useCase,
	flowClickedOnLandingPage
) => {
	return {
		type: REQUEST_INTEGRATION,
		payload: {
			useCase,
			userName,
			userEmail,
			flowClickedOnLandingPage,
		},
	};
};

export const requestIntegrationSuccess = () => {
	return {
		type: REQUEST_INTEGRATION_SUCCESS,
	};
};

export const requestIntegrationFail = () => {
	return {
		type: REQUEST_INTEGRATION_FAIL,
	};
};

export const hideRequestIntegrationModal = () => {
	return {
		type: HIDE_REQUEST_INTEGRATION_MODAL,
	};
};

export const updateApplicationsSortSeq = (
	slugName,
	subscriptionId,
	userEmail
) => {
	return {
		type: UPDATE_APPLICATION_SORT_SEQ,
		payload: {
			slugName,
			subscriptionId,
			userEmail,
		},
	};
};
