import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  getHelperTextHtmlJsxFromParts,
  isDataPresent,
} from "utils/object_util";

import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbIntegrationImage from "components/WbIntegrationImage";

import { setSelectedEvent as triggerSetSelectedEvent } from "../../FlowBuilderActions";

export default function SelectTriggerEventModal(props) {
  const dispatch = useDispatch();
  const { events, stageIndex, reducerSelectedEvent } = props;
  const { integrationDisplayText, userHelper, slug } = props;
  const [firstEvent] = events;
  const eventSlug = isDataPresent(reducerSelectedEvent)
    ? reducerSelectedEvent.selectedEventSlug
    : "";
  const [selectedEvent, setSelectedEvent] = useState(firstEvent);
  const [reducerSelectedEventSlug, setReducerSelectedEventSlug] =
    useState(eventSlug);
  const { helperTextHtmlParts } = userHelper;
  const helperTextHtmlJsx = getHelperTextHtmlJsxFromParts(helperTextHtmlParts);

  const changeEventHandler = (event) => {
    setSelectedEvent(event);
    setReducerSelectedEventSlug("");
  };

  const finishEventSelect = () => {
    dispatch(triggerSetSelectedEvent(stageIndex, selectedEvent));
    props.closeModal();
  };

  return (
    <WbModal isOpen={true} size="md" toggle={props.closeModal}>
      <WbModalTitle toggle={props.closeModal} title="Select Trigger Event" />
      <div className="flex flex-col items-center p-4">
        <WbIntegrationImage
          slug={slug}
          altText={integrationDisplayText}
          avtarSize="80"
        />
        <h1
          className="text-xl"
          dangerouslySetInnerHTML={{
            __html: helperTextHtmlJsx,
          }}
        ></h1>
        <div className="w-[80%]">
          {events.map((event) => {
            const stringifiedEventId = event._id.toString();
            const eventSlug = event.slug;
            const isCurrentEventSelectedInReducer =
              eventSlug === reducerSelectedEventSlug;
            const isCurrentEventSelectedInSelf =
              stringifiedEventId === selectedEvent._id.toString();
            const isCurrentEventSelected =
              isCurrentEventSelectedInReducer || isCurrentEventSelectedInSelf;

            return (
              <div
                key={event._id}
                className="m-2 flex h-16 flex-row items-center space-x-3 rounded bg-gray-100 p-4 dark:bg-gray-700 cursor-pointer"
                onClick={() => changeEventHandler(event)}
              >
                <input
                  type="radio"
                  id={event.slug}
                  name="select-event-radio"
                  checked={isCurrentEventSelected}
                  onChange={() => changeEventHandler(event)}
                />
                <h3 className="text-lg">{event.displayText}</h3>
              </div>
            );
          })}
        </div>
      </div>
      <div className="float-right p-4">
        <WbButton onClick={finishEventSelect}>Save + Continue</WbButton>
      </div>
    </WbModal>
  );
}
