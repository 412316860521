import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

const SelectComponent = ({ options, defaultOption, onChange }: any) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const { control } = useForm();

  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(selectedValue);
  };

  return (
    <Controller
      name="component"
      control={control}
      render={({ field }) => (
        <select
          {...field}
          className="focus:shadow-outline block w-full appearance-none rounded border border-slate-300 bg-white px-4 py-2 pr-8 leading-tight focus:outline-none"
          value={selectedOption}
          onChange={handleSelectChange}
        >
          {options.map((option:any) => (
            <option
              key={option.value}
              value={option.value}
              className="text-gray-800 hover:bg-gray-100 hover:text-gray-900"
            >
              {option.label}
            </option>
          ))}
        </select>
      )}
    />
  );
};

export default SelectComponent;
