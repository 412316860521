import { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import {
  isDataPresent,
  getHelperTextHtmlJsxFromParts,
} from "utils/object_util";

import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbAlert from "components/WbAlert";
import WbLoader from "components/WbLoader";
import WbIntegrationImage from "components/WbIntegrationImage";

import { sendTestActionData } from "../../FlowBuilderActions";

class TestActionModal extends PureComponent {
  testAction = () => {
    const { stageIndex, testActionPollUrlDetails, commonData, flowObj } =
      this.props;

    const { url, bodyParamsRequired } = testActionPollUrlDetails;
    const bodyParams = {};

    if (isDataPresent(bodyParamsRequired)) {
      _.each(bodyParamsRequired, (eachBodyParamRequired) => {
        bodyParams[eachBodyParamRequired] =
          commonData[stageIndex][eachBodyParamRequired];
      });
    }
    const { apiData, dataFromPreviousStages } =
      flowObj.stages[stageIndex].stageItems.setupActionTemplate;
    this.props.sendTestActionData(
      stageIndex,
      url,
      bodyParams,
      apiData,
      dataFromPreviousStages
    );
  };

  componentDidMount() {
    this.testAction();
  }

  saveClickHandler = () => {
    this.props.closeModal();
  };

  render() {
    const {
      integrationDisplayText,
      commonData,
      stageIndex,
      isLoadingTestActionData,
      userHelper,
      slug,
    } = this.props;

    const { testActionSuccessMessage, testActionErrorMessage } =
      commonData[stageIndex];
    let testActionDataUi = null;
    if (!isLoadingTestActionData) {
      testActionDataUi = (
        <Fragment>
          {isDataPresent(testActionErrorMessage) && (
            <WbAlert color="failure" className="mt-5 rounded">
              {testActionErrorMessage}
            </WbAlert>
          )}
          {isDataPresent(testActionSuccessMessage) && (
            <WbAlert color="success" className="mt-5 rounded">
              {testActionSuccessMessage}
            </WbAlert>
          )}
        </Fragment>
      );
    }

    const { helperTextHtmlParts } = userHelper;
    const helperTextHtmlJsx =
      getHelperTextHtmlJsxFromParts(helperTextHtmlParts);

    return (
      <Fragment>
        <WbModal isOpen={true} size="md" toggle={this.props.closeModal}>
          <WbModalTitle toggle={this.props.closeModal} title="Test Action" />
          <div className="flex flex-col items-center p-4">
            <WbIntegrationImage
              slug={slug}
              altText={integrationDisplayText}
              avtarSize="80"
            />
            <h1
              className="text-2xl"
              dangerouslySetInnerHTML={{
                __html: helperTextHtmlJsx,
              }}
            ></h1>
            {testActionDataUi}
            {isLoadingTestActionData ? (
              <div className="mt-2 text-center">
                <WbLoader />
                <h6 className="mt-2">Please wait. We are testing this step.</h6>
              </div>
            ) : null}
          </div>
          <div className="mx-10 flex flex-row justify-between space-x-3 p-10">
            <WbButton onClick={this.testAction}>
              Test again with sample data
            </WbButton>
            <WbButton onClick={this.saveClickHandler}>Continue</WbButton>
          </div>
        </WbModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ FlowBuilder }) => {
  const { selectedIntegrations, flowObj, commonData, isLoadingTestActionData } =
    FlowBuilder;

  return {
    selectedIntegrations,
    flowObj,
    commonData,
    isLoadingTestActionData,
  };
};

export default connect(mapStateToProps, {
  sendTestActionData,
})(TestActionModal);
