import {
	SEND_PASSWORD_RESET_EMAIL,
	SEND_PASSWORD_RESET_EMAIL_SUCCESS,
	SEND_PASSWORD_RESET_EMAIL_FAIL,
	RESET_REDUCER,
} from "./ForgotPasswordActionTypes";

export const sendPasswordResetEmail = (email) => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL,
		payload: {
			email,
		},
	};
};

export const sendPasswordResetEmailSuccess = () => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
	};
};

export const sendPasswordResetEmailFail = () => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL_FAIL,
	};
};

export const resetReducer = () => {
	return {
		type: RESET_REDUCER,
	};
};
