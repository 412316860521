import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { find, get } from "lodash";
import { FaCheckDouble } from "react-icons/fa";

import { TEST_EVENT } from "enums/StageItemSlugsEnum";

import TestEventModal from "./TestEventModal";

export default function TestEvent(props) {
    const { selectedIntegrations, flowObj } = useSelector((state) => state.FlowBuilder);
    const { titleDisplayText, stageIndex, stageType, orderIndex, userHelper, finishStageItem } = props;

    const [showModal, setShowModal] = useState(false);

    const eventStageItems = get(flowObj, `stages[${stageIndex}].stageItems`, {});
    const selectedEventSlug = get(eventStageItems, "selectEvent.selectedEventSlug", "");
    const isStageSetupComplete = get(eventStageItems, "testEvent.testStatus", "") === "done";

    const currentStageSelectedIntegration = selectedIntegrations[stageIndex];
    const { testEventOptions } = get(currentStageSelectedIntegration, "triggerStageItems.testEvent", {});
    const testEventOptionForSelectedEvent = find(testEventOptions, { eventSlug: selectedEventSlug });
    const { pollUrlDetails = {} } = testEventOptionForSelectedEvent || {};

    useEffect(() => {
        if (isStageSetupComplete) finishStageItem(TEST_EVENT);
    }, [isStageSetupComplete, finishStageItem]);

    const skipTestEventStageItemHandler = () => {
        finishStageItem(TEST_EVENT);
    };

    return (
        <>
            <div className="mb-0 cursor-pointer max-w-fit" onClick={() => setShowModal(true)}>
                <span>
                    {orderIndex + 1}. Test event - {titleDisplayText}
                </span>
                {!isStageSetupComplete ? (
                    <span className="text-red-600 mx-1">(required)</span>
                ) : (
                    <FaCheckDouble size={12} className="inline-block text-green-600 mx-1" />
                )}
            </div>
            {showModal && (
                <TestEventModal
                    testEventPollUrlDetails={pollUrlDetails}
                    integrationImageUrl={currentStageSelectedIntegration.imageUrl}
                    integrationDisplayText={currentStageSelectedIntegration.displayText}
                    userHelper={userHelper}
                    stageIndex={stageIndex}
                    stageType={stageType}
                    closeModal={() => setShowModal(false)}
                    skipStageItem={skipTestEventStageItemHandler}
                    slug={currentStageSelectedIntegration.slug}
                />
            )}
        </>
    );
}
