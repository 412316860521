/* eslint-disable import/no-anonymous-default-export */
import { get, cloneDeep, map, union } from "lodash";
import {
    SET_SELECTED_INTEGRATION,
    SET_SUBSCRIPTION_ID,
    SET_SELECTED_EVENT,
    SET_SELECTED_ACTION,
    SET_SELECTED_ACCOUNT,
    SELECT_DYNAMIC_INCOMING_DATA_PREREQUISITE,
    SELECT_API_DATA,
    SELECT_DYNAMIC_INCOMING_DATA,
    SETUP_FILTER,
    SAVE_FLOW_SUCCESS,
    ADD_STAGE,
    DELETE_STAGE,
    INIT_STAGES_TO_BE_SHOWN,
    SETUP_DATA_FROM_PREVIOUS_STAGES,
    SEED_FLOW_BUILDER_FOR_VIEW,
    SET_FLOW_NAME,
    SAVE_FLOW,
    SAVE_FLOW_FAIL,
    GET_TEST_EVENT_SAMPLE_DATA,
    GET_TEST_EVENT_SAMPLE_DATA_ERROR,
    GET_TEST_EVENT_SAMPLE_DATA_SUCCESS,
    SET_SELECTED_TEST_EVENT_SAMPLE_DATA,
    SEND_TEST_ACTION_DATA,
    SEND_TEST_ACTION_DATA_SUCCESS,
    SEND_TEST_ACTION_DATA_ERROR,
    GET_GENERATED_WEBHOOK_URL,
    GET_GENERATED_WEBHOOK_URL_SUCCESS,
    GET_GENERATED_WEBHOOK_URL_ERROR,
    FETCH_SINGLE_FLOW,
    FETCH_SINGLE_FLOW_SUCCESS,
    FETCH_SINGLE_FLOW_FAIL,
    RESET_FLOW_BUILDER_REDUCER,
    SET_DATA_FORMATTER_OPTION,
    SET_DEFAULT_DYNAMIC_INCOMING_DATA,
    GET_TRIGGER_AND_ACTION_INTEGRATION_SUCCESS,
    FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES_SUCCESS,
    FILE_UPLOAD,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_SUCCESS,
} from "./FlowBuilderActionTypes";
import {
    initStagesToBeShownReducerHandler,
    addStageReducerHandler,
    selectApiDataReducerHandler,
    deleteStageReducerHandler,
    selectDynamicIncomingDataPrerequisiteReducerHandler,
    selectDynamicIncomingDataReducerHandler,
    setupDataFromPreviousStagesReducerHandler,
    setupFilterReducerHandler,
    seedFlowBuilderForViewReducerHandler,
    setSelectedTestEventSampleDataReducerHandler,
    setSelectedDataFormatterReducerHandler,
    setDefaultDynamicIncomingData,
} from "./FlowBuilderReducerHandler";
import { TRIGGER, ACTION } from "../../enums/StageTypesEnum";
import { isDataPresent } from "../../utils/object_util";

const INIT_STATE = {
    flowObj: {
        name: "",
        state: "running",
        stages: [],
    },
    isLoadingFetchSingleFlow: false,
    selectedIntegrations: [],
    commonData: [],
    isSavingFlow: false,
    isFlowSaved: false,
    stagesToBeShown: [TRIGGER, ACTION],
    from: "",
    isLoadingTestEventSampleData: false,
    testEventSampleData: [],
    isLoadingTestActionData: false,
    isLoadingWebhookUrl: false,
    isFlowSeeded: false,
    triggerAndActionIntegrationData: null,
    integrationsWithSimilarCategories: null,
};

export default (state = INIT_STATE, action) => {
    const { payload, type } = action;

    const clonedFlowObj = cloneDeep(state.flowObj);
    const clonedCommonData = cloneDeep(state.commonData);

    switch (type) {
        case INIT_STAGES_TO_BE_SHOWN:
            const stagesToBeShown = isDataPresent(payload.stagesToBeShown) ? payload.stagesToBeShown : [TRIGGER, ACTION];
            const initStagesHandler = initStagesToBeShownReducerHandler(INIT_STATE, stagesToBeShown);

            const { clonedFlowObj: clonedFlowObjToInitStages } = initStagesHandler;

            return {
                ...INIT_STATE,
                stagesToBeShown: stagesToBeShown,
                commonData: map(stagesToBeShown, () => ({})),
                selectedIntegrations: map(stagesToBeShown, () => ({})),
                flowObj: clonedFlowObjToInitStages,
            };
        case SET_SUBSCRIPTION_ID:
            clonedFlowObj.subscriptionId = payload.subscriptionId.toString();

            return {
                ...state,
                flowObj: clonedFlowObj,
            };
        case ADD_STAGE:
            const addStageHandler = addStageReducerHandler(state, payload);

            return {
                ...state,
                ...addStageHandler,
            };
        case DELETE_STAGE:
            const deleteStageHandler = deleteStageReducerHandler(state, payload);
            const {
                clonedFlowObj: clonedFlowObjToUpdateFlowObjOnDeleteStage,
                clonedSelectedIntegrations: clonedFlowObjToUpdateSelectedIntegrationsOnDeleteStage,
                clonedStagesToBeShown: clonedStagesToBeShownToDeleteStage,
                clonedCommonData: clonedCommonDataToDeleteStage,
            } = deleteStageHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToUpdateFlowObjOnDeleteStage,
                selectedIntegrations: clonedFlowObjToUpdateSelectedIntegrationsOnDeleteStage,
                stagesToBeShown: clonedStagesToBeShownToDeleteStage,
                commonData: clonedCommonDataToDeleteStage,
            };
        case SET_SELECTED_INTEGRATION:
            const { sideEffectData, stageIndex, stageType } = payload;
            const updateIntegrationStageItem = {
                stageType: stageType,
                stageItems: {
                    selectIntegration: {
                        selectedIntegrationId: sideEffectData.integration._id.toString(),
                        selectedIntegrationSlug: sideEffectData.integration.slug,
                    },
                },
            };
            clonedFlowObj.stages[stageIndex] = updateIntegrationStageItem;
            const clonedSelectedIntegrations = cloneDeep(state.selectedIntegrations);
            clonedSelectedIntegrations[stageIndex] = sideEffectData.integration;

            return {
                ...state,
                flowObj: clonedFlowObj,
                selectedIntegrations: clonedSelectedIntegrations,
            };
        case SET_SELECTED_EVENT:
            clonedFlowObj.stages[payload.stageIndex].stageItems.selectEvent = {
                selectedEventSlug: payload.event.slug,
            };

            const { staticIncomingData } = payload.event;
            const staticIncomingDataToStore = map(staticIncomingData, (eachStaticIncomingData) => {
                return {
                    displayText: eachStaticIncomingData.displayText,
                    slug: eachStaticIncomingData.slug,
                    integrationSpecificSlug: eachStaticIncomingData.integrationSpecificSlug,
                    testValue: eachStaticIncomingData.testValue,
                    isFilterable: eachStaticIncomingData.isFilterable,
                };
            });
            clonedFlowObj.stages[payload.stageIndex].stageItems.setupEventOptions = {
                selectedDynamicIncomingDataPrerequisites: [],
                incomingData: staticIncomingDataToStore,
            };

            clonedFlowObj.stages[payload.stageIndex].stageItems.testEvent = {
                testStatus: "skipped",
            };

            if (clonedCommonData[payload.stageIndex]) {
                clonedCommonData[payload.stageIndex].staticIncomingData = staticIncomingDataToStore;
            }
            return {
                ...state,
                flowObj: clonedFlowObj,
                commonData: clonedCommonData,
            };
        case SET_SELECTED_ACTION:
            clonedFlowObj.stages[payload.stageIndex].stageItems.selectAction = {
                selectedActionSlug: payload.action.slug,
            };

            clonedFlowObj.stages[payload.stageIndex].stageItems.setupActionTemplate = {
                dataFromPreviousStages: [],
                apiData: [],
            };

            return {
                ...state,
                flowObj: clonedFlowObj,
            };
        case SET_SELECTED_ACCOUNT:
            clonedFlowObj.stages[payload.stageIndex].stageItems.selectAccount = {
                selectedAccountId: payload.account._id,
            };

            if (clonedCommonData[payload.stageIndex]) {
                clonedCommonData[payload.stageIndex].accountId = payload.account._id.toString();
            }
            return {
                ...state,
                flowObj: clonedFlowObj,
                commonData: clonedCommonData,
            };
        case SELECT_DYNAMIC_INCOMING_DATA_PREREQUISITE:
            const selectDynamicIncomingDataPrerequisiteHandler = selectDynamicIncomingDataPrerequisiteReducerHandler(state, payload);

            const {
                clonedFlowObj: clonedFlowObjToSelectDynamicIncomingDataPrerequisite,
                clonedCommonData: clonedCommonDataToSelectDynamicIncomingDataPrerequisite,
            } = selectDynamicIncomingDataPrerequisiteHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToSelectDynamicIncomingDataPrerequisite,
                commonData: clonedCommonDataToSelectDynamicIncomingDataPrerequisite,
                testEventSampleData: [],
            };

        case SET_DEFAULT_DYNAMIC_INCOMING_DATA:
            const { clonedCommonData: clonedCommonDataToSetDefaultDynamicIncomingData, clonedFlowObj: clonedFlowObjToSetDefaultDynamicIncomingData } =
                setDefaultDynamicIncomingData(state, payload);
            return {
                ...state,
                commonData: clonedCommonDataToSetDefaultDynamicIncomingData,
                flowObj: clonedFlowObjToSetDefaultDynamicIncomingData,
                testEventSampleData: [],
            };

        case SELECT_DYNAMIC_INCOMING_DATA:
            const selectDynamicIncomingDataHandler = selectDynamicIncomingDataReducerHandler(state, payload);

            const { clonedFlowObj: clonedFlowObjToSelectDynamicIncomingData, clonedCommonData: clonedCommonDataToSelectDynamicIncomingData } =
                selectDynamicIncomingDataHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToSelectDynamicIncomingData,
                commonData: clonedCommonDataToSelectDynamicIncomingData,
            };
        case SETUP_DATA_FROM_PREVIOUS_STAGES:
            const setupDataFromPreviousStagesHandler = setupDataFromPreviousStagesReducerHandler(state, payload);

            const { clonedFlowObj: clonedFlowObjToSetupDataFromPreviousStages } = setupDataFromPreviousStagesHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToSetupDataFromPreviousStages,
            };
        case SELECT_API_DATA:
            const selectApiDataHandler = selectApiDataReducerHandler(state, payload);

            const { clonedFlowObj: clonedFlowObjToSelectApiData, clonedCommonData: clonedCommonDataToSelectApiData } = selectApiDataHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToSelectApiData,
                commonData: clonedCommonDataToSelectApiData,
            };
        case SETUP_FILTER:
            const setupFilterHandler = setupFilterReducerHandler(state, payload);

            const { clonedFlowObj: clonedFlowObjToSetupFilter } = setupFilterHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToSetupFilter,
            };
        case SET_FLOW_NAME:
            return {
                ...state,
                flowObj: {
                    ...state.flowObj,
                    name: payload.flowName,
                },
            };
        case SAVE_FLOW:
            return {
                ...state,
                isSavingFlow: true,
            };
        case SAVE_FLOW_SUCCESS:
            return {
                ...state,
                isSavingFlow: false,
                isFlowSaved: true,
            };
        case SAVE_FLOW_FAIL:
            return {
                ...state,
                isSavingFlow: false,
            };
        case SEED_FLOW_BUILDER_FOR_VIEW:
            const seedFlowBuilderForViewHandler = seedFlowBuilderForViewReducerHandler(payload);

            return {
                ...state,
                ...seedFlowBuilderForViewHandler,
                from: "view",
                isFlowSaved: false,
                isFlowSeeded: true,
            };
        case GET_GENERATED_WEBHOOK_URL:
            return {
                ...state,
                isLoadingWebhookUrl: true,
            };
        case GET_GENERATED_WEBHOOK_URL_SUCCESS:
            clonedCommonData[payload.stageIndex].wyzebulbWebhookTriggerUrl = payload.webhookUrl;
            return {
                ...state,
                commonData: clonedCommonData,
                isLoadingWebhookUrl: false,
            };
        case GET_GENERATED_WEBHOOK_URL_ERROR:
            return {
                ...state,
                isLoadingWebhookUrl: false,
            };
        case GET_TEST_EVENT_SAMPLE_DATA:
            return {
                ...state,
                isLoadingTestEventSampleData: true,
            };
        case GET_TEST_EVENT_SAMPLE_DATA_ERROR:
            return {
                ...state,
                isLoadingTestEventSampleData: false,
            };
        case GET_TEST_EVENT_SAMPLE_DATA_SUCCESS:
            const currentTestEventSampleData = clonedCommonData[payload.stageIndex].testEventSampleData;
            const newTestEventSampleData = union(currentTestEventSampleData, [payload.testEventSampleData]);
            clonedCommonData[payload.stageIndex].testEventSampleData = newTestEventSampleData;

            clonedFlowObj.stages[payload.stageIndex].stageItems.testEvent = {
                testStatus: "done",
            };

            return {
                ...state,
                commonData: clonedCommonData,
                flowObj: clonedFlowObj,
                isLoadingTestEventSampleData: false,
            };
        case SET_SELECTED_TEST_EVENT_SAMPLE_DATA:
            const setSelectedTestEventSampleDataHandler = setSelectedTestEventSampleDataReducerHandler(state, payload);

            const {
                clonedFlowObj: clonedFlowObjToSetSelectedTestEventSampleData,
                clonedCommonData: clonedCommonDataToSetSelectedTestEventSampleData,
            } = setSelectedTestEventSampleDataHandler;

            return {
                ...state,
                flowObj: clonedFlowObjToSetSelectedTestEventSampleData,
                commonData: clonedCommonDataToSetSelectedTestEventSampleData,
            };
        case SEND_TEST_ACTION_DATA:
            clonedCommonData[payload.stageIndex].testActionSuccessMessage = "";
            clonedCommonData[payload.stageIndex].testActionErrorMessage = "";

            return {
                ...state,
                commonData: clonedCommonData,
                isLoadingTestActionData: true,
            };
        case SEND_TEST_ACTION_DATA_SUCCESS:
            clonedCommonData[payload.stageIndex].testActionSuccessMessage = payload.message;
            clonedFlowObj.stages[payload.stageIndex].stageItems.testAction = {
                testStatus: "done",
            };

            return {
                ...state,
                commonData: clonedCommonData,
                flowObj: clonedFlowObj,
                isLoadingTestActionData: false,
            };
        case SEND_TEST_ACTION_DATA_ERROR:
            clonedCommonData[payload.stageIndex].testActionErrorMessage = payload.message;

            return {
                ...state,
                commonData: clonedCommonData,
                isLoadingTestActionData: false,
            };
        case FETCH_SINGLE_FLOW:
            return {
                ...state,
                isLoadingFetchSingleFlow: true,
            };
        case FETCH_SINGLE_FLOW_SUCCESS:
            return {
                ...state,
                flowObj: payload.flowObj,
                isLoadingFetchSingleFlow: false,
            };
        case FETCH_SINGLE_FLOW_FAIL:
            return {
                ...state,
                isLoadingFetchSingleFlow: false,
            };
        case RESET_FLOW_BUILDER_REDUCER:
            return {
                ...state,
                isFlowSaved: false,
                isFlowSeeded: false,
                commonData: [],
                selectedIntegrations: [],
                stagesToBeShown: [TRIGGER, ACTION],
                from: "",
                isLoadingTestEventSampleData: false,
                testEventSampleData: [],
                isLoadingTestActionData: false,
                isLoadingWebhookUrl: false,
                flowObj: INIT_STATE.flowObj, //Resetting prev flowObj to initial value so that each flow will be newly updated
            };
        case GET_TRIGGER_AND_ACTION_INTEGRATION_SUCCESS:
            return {
                ...state,
                triggerAndActionIntegrationData: payload.triggerAndActionIntegrationData,
            };
        case FETCH_ACTIVE_INTEGRATIONS_WITH_SIMILAR_CATEGORIES_SUCCESS:
            return {
                ...state,
                integrationsWithSimilarCategories: payload.integrationsWithSimilarCategories,
            };
        case SET_DATA_FORMATTER_OPTION:
            const setSelectedDataFormatterHandler = setSelectedDataFormatterReducerHandler(state, payload);
            return {
                ...state,
                flowObj: setSelectedDataFormatterHandler.clonedFlowObj,
            };
        case FILE_UPLOAD:
            return {
                ...state,
                isUploadingFile: true,
            };
        case FILE_UPLOAD_SUCCESS:
            clonedCommonData[payload.stageIndex].wyzebulbUploadedFile = payload.wyzebulbUploadedFile;
            return {
                ...state,
                commonData: clonedCommonData,
                isUploadingFile: false,
                wbFileInfo: get(payload, "wbFileInfo", {}),
            };
        case FILE_UPLOAD_ERROR:
            return {
                ...state,
                isUploadingFile: false,
            };
        default:
            return {
                ...state,
            };
    }
};
