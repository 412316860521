import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdMailOutline } from "react-icons/md";

import { sendPasswordResetEmail, resetReducer } from "./ForgotPasswordActions";
import WbButton from "components/WbButton";
import AuthLayout from "../AuthLayout";
import { Form, EmailField } from "components/Fields";

export default function ForgotPassword() {
  const { isSendPasswordResetEmailSuccess } = useSelector(
    (state) => state.ForgotPassword
  );
  const dispatch = useDispatch();
  const formMethods = useForm();

  useEffect(() => {
    return () => {
      dispatch(resetReducer());
    };
  }, []);

  const onClickSendEmail = (formValues) => {
    const { userEmail } = formValues;

    dispatch(sendPasswordResetEmail(userEmail));
  };

  if (isSendPasswordResetEmailSuccess) {
    return <Navigate to="/login" />;
  }

  return (
    <AuthLayout>
      <div className="h-screen">
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center px-4 dark:bg-slate-900 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="flex flex-row items-center justify-center">
                  <img
                    className="h-12 w-auto"
                    src="/assets/img/logos/wyzebulb-1x.png"
                    alt="Wyzebulb"
                  />
                </div>
                <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white/60">
                  Forgot Password
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                  Enter your login email. We'll send you a link to reset your
                  password.
                </p>
              </div>
              <Form
                form={formMethods}
                handleSubmit={onClickSendEmail}
                className="mt-8"
              >
                <div className="space-y-4 py-4">
                  <EmailField
                    label={"Email address"}
                    type="email"
                    autoComplete="email"
                    AddOnIcon={MdMailOutline}
                    required
                    {...formMethods.register("userEmail")}
                  />
                  <div className="md:w-[400px]">
                    <WbButton type="submit" className="w-full">
                      Send Reset Instructions
                    </WbButton>
                  </div>

                  <div className="text-center text-sm dark:text-white">
                    Back to
                    <NavLink
                      to={`/login`}
                      className="ml-1 font-medium text-orange-600 hover:text-orange-500 "
                    >
                      Sign in
                    </NavLink>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
