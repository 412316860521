import {
	SEND_PASSWORD_RESET_EMAIL,
	SEND_PASSWORD_RESET_EMAIL_SUCCESS,
	SEND_PASSWORD_RESET_EMAIL_FAIL,
	RESET_REDUCER,
} from "./ForgotPasswordActionTypes";

const INIT_STATE = {
	isSendingPasswordResetEmail: false,
	isSendPasswordResetEmailSuccess: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case SEND_PASSWORD_RESET_EMAIL:
			return {
				...state,
				isSendingPasswordResetEmail: true,
			};
		case SEND_PASSWORD_RESET_EMAIL_SUCCESS:
			return {
				...state,
				isSendingPasswordResetEmail: false,
				isSendPasswordResetEmailSuccess: true,
			};
		case SEND_PASSWORD_RESET_EMAIL_FAIL:
			return {
				...state,
				isSendingPasswordResetEmail: false,
			};
		case RESET_REDUCER:
			return {
				...state,
				isSendPasswordResetEmailSuccess: false,
				isSendingPasswordResetEmail: false,
			};
		default:
			return { ...state };
	}
};
