import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, startCase } from "lodash";
import moment from "moment";

import WbModal, { WbModalTitle } from "components/WbModal";
import WbButton from "components/WbButton";
import WbTextInput from "components/WbTextInput";
import WbLabel from "components/WbLabel";

import { setFlowName as saveFlowName } from "../FlowBuilderActions";
import { showErrorToast } from "components/WbToast";

export default function AddNameToFlowModal({ closeModal, saveFlow }: any) {
	const dispatch = useDispatch();
	const { flowObj } = useSelector((state: any) => state.FlowBuilder);
	const triggerApp = get(
		flowObj,
		"stages.0.stageItems.selectIntegration.selectedIntegrationSlug",
		"Trigger App"
	);
	const actionApp = get(
		flowObj,
		"stages.1.stageItems.selectIntegration.selectedIntegrationSlug",
		"Trigger App"
	);
	const [flowName, setFlowName] = useState(
		flowObj?.name ||
			`${startCase(triggerApp)} To ${startCase(
				actionApp
			)}-${moment().format(`DD-MMM_YYYY`)}`
	);

	const onClickContinue = () => {
		if (flowName === "My Flow") {
			return showErrorToast("Please enter the new flow name");
		} else if (flowName === "") {
			return showErrorToast("Flow Name cannot be blank");
		}

		dispatch(saveFlowName(flowName));
		saveFlow(flowName);
		closeModal();
	};

	return (
		<WbModal isOpen={true} toggle={closeModal} size="sm">
			<WbModalTitle toggle={closeModal} title="Add Flow Name" />
			<div className="space-y-4 p-4">
				<WbLabel>Please Enter The Flow Name</WbLabel>
				<WbTextInput
					value={flowName}
					onChange={(event: any) => setFlowName(event.target.value)}
					type="text"
					name="flowName"
					id="flowName"
					placeholder="Enter the name..."
				/>
			</div>
			<div className="float-right flex flex-row space-x-4 p-4">
				<WbButton outline={true} onClick={closeModal}>
					Cancel
				</WbButton>
				<WbButton onClick={onClickContinue}>Continue</WbButton>
			</div>
		</WbModal>
	);
}
