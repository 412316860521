import { Routes, Route } from "react-router-dom";

interface IRoute {
  path: string;
  element: JSX.Element;
}

interface IWbRouterProps {
  routes: IRoute[];
}

export default function WbRouter({ routes = [] }: IWbRouterProps) {
  return (
    <Routes>
      {routes.map(({ path, element }: IRoute, idx: number) => (
        <Route key={idx}>
          {idx === 0 && <Route key={idx} index element={element} />}
          {path !== "" && <Route key={idx} path={path} element={element} />}
        </Route>
      ))}
    </Routes>
  );
}
