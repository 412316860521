import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { find, get, isEmpty } from "lodash";
import { FaCheckDouble, FaPen } from "react-icons/fa";

import { SELECT_EVENT } from "enums/StageItemSlugsEnum";
import SelectTriggerEventModal from "./SelectTriggerEventModal";

export default function SelectTriggerEvent(props) {
    const { selectedIntegrations, flowObj } = useSelector((state) => state.FlowBuilder);

    const { stageIndex, stageType, userHelper, orderIndex, finishStageItem } = props;

    const [showModal, setShowModal] = useState(false);

    const selectedEvent = flowObj?.stages[stageIndex]?.stageItems?.selectEvent;
    const isStageSetupComplete = !isEmpty(selectedEvent);
    const currentStageSelectedIntegration = selectedIntegrations[stageIndex];

    const allEvents = get(currentStageSelectedIntegration, "triggerStageItems.selectEvent.events", []);

    let selectedEventFromIntegration = isStageSetupComplete ? find(allEvents, (eachEvent) => selectedEvent.selectedEventSlug === eachEvent.slug) : {};

    useEffect(() => {
        if (isStageSetupComplete) finishStageItem(SELECT_EVENT);
    }, [isStageSetupComplete, finishStageItem]);

    return (
        <>
            <div className="mb-0 cursor-pointer max-w-fit" onClick={() => setShowModal(true)}>
                <span>
                    {isStageSetupComplete
                        ? `${orderIndex + 1}. Trigger - ${selectedEventFromIntegration?.displayText}`
                        : `${orderIndex + 1}. Trigger - Click here to select trigger`}
                </span>
                {isStageSetupComplete ? (
                    <>
                        <FaPen size={12} className="inline-block text-blue-600 mx-1" />
                        <FaCheckDouble size={12} className="inline-block text-green-600" />
                    </>
                ) : (
                    <span className="text-red-600 mx-1">(required)</span>
                )}
            </div>
            {showModal && (
                <SelectTriggerEventModal
                    stageIndex={stageIndex}
                    stageType={stageType}
                    events={allEvents}
                    userHelper={userHelper}
                    integrationImageUrl={currentStageSelectedIntegration.imageUrl}
                    integrationDisplayText={currentStageSelectedIntegration.displayText}
                    reducerSelectedEvent={selectedEvent}
                    closeModal={() => setShowModal(false)}
                    slug={currentStageSelectedIntegration.slug}
                />
            )}
        </>
    );
}
