import { call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { asyncPostWithoutErrHandler } from "utils/async_util";

import { asyncPost } from "utils/async_util";
import {
    FETCH_INTEGRATIONS,
    FILTER_INTEGRATIONS_BY_CATEGORY,
    FILTER_INTEGRATIONS_BY_TEXT,
    REQUEST_INTEGRATION,
    UPDATE_APPLICATION_SORT_SEQ,
} from "./SelectIntegrationActionTypes";
import { fetchIntegrationsSuccess, fetchIntegrationsFail, requestIntegrationFail, requestIntegrationSuccess } from "./SelectIntegrationActions";
import { showErrorToast, showSuccessToast } from "components/WbToast";

function* getIntegrations({ payload }) {
  try {
    const [err, res] = yield call(
      asyncPostWithoutErrHandler,
      "/apps/listApps",
      {
        findQuery: payload.findQuery,
        sortQuery: {
          isActive: -1,
        },
        skip: 0,
        limit: 250,
        subscriptionId: payload.subscriptionId,
      }
    );
    if (err) {
      return yield put(fetchIntegrationsFail());
    }

    if (!res.data.success) {
      return yield put(fetchIntegrationsFail());
    }

    const { sideEffectFns } = payload;
    _.each(sideEffectFns, (eachFn) => {
      eachFn(res.data.integrations);
    });

    return yield put(fetchIntegrationsSuccess(res.data.integrations));
  } catch (err) {
    return yield put(fetchIntegrationsFail());
  }
}

function* filterIntegrationsByCategoriesSaga({ payload }) {
    try {
        const { category, findQuery } = payload;
        const [err, res] = yield call(asyncPostWithoutErrHandler, "/apps/listApps", {
            findQuery: {
                ...(category !== "all" && { categories: category }),
                ...findQuery,
            },
            projectionQuery: {
                slug: 1,
                displayText: 1,
                categories: 1,
                imageUrl: 1,
                apiKeys: 1,
                oAuthDetails: 1,
                isActive: 1,
                stage: 1,
            },
            sortQuery: {
                isActive: -1,
            },
            skip: 0,
            limit: 50,
        });
        if (err) {
            return yield put(fetchIntegrationsFail());
        }

        if (!res.data.success) {
            return yield put(fetchIntegrationsFail());
        }

        return yield put(fetchIntegrationsSuccess(res.data.integrations));
    } catch (err) {
        return yield put(fetchIntegrationsFail());
    }
}

function* filterIntegrationsByTextSaga({ payload }) {
    try {
        const { text, findQuery } = payload;

        const [err, res] = yield call(asyncPostWithoutErrHandler, "/apps/listApps", {
            findQuery: {
                displayText: {
                    $regex: `.*${text}.*`,
                    $options: "i",
                },
                ...findQuery,
            },
            projectionQuery: {
                slug: 1,
                displayText: 1,
                categories: 1,
                imageUrl: 1,
                apiKeys: 1,
                oAuthDetails: 1,
                isActive: 1,
                stage: 1,
            },
            sortQuery: {
                isActive: -1,
            },
            skip: 0,
            limit: 50,
        });
        if (err) {
            return yield put(fetchIntegrationsFail());
        }

        if (!res.data.success) {
            return yield put(fetchIntegrationsFail());
        }

        return yield put(fetchIntegrationsSuccess(res.data.integrations));
    } catch (err) {
        return yield put(fetchIntegrationsFail());
    }
}

function* requestIntegrationSaga({ payload }) {
    const { userName, userEmail, useCase, flowClickedOnLandingPage } = payload;
    let integrationName;
    let useCaseToSend;
    if (flowClickedOnLandingPage) {
        integrationName = flowClickedOnLandingPage;
        useCaseToSend = useCase;
    } else {
        integrationName = useCase;
        useCaseToSend = "";
    }

    try {
        const [slackNotificationError, slackNotificationSuccess] = yield call(asyncPost, `/support/requestApp`, {
            useCase,
        });

        yield call(asyncPost, `https://livestream.wyzebulb.com/wyzebulb_webhook/vgIhChqjP`, {
            userName,
            userEmail,
            integrationName,
            useCase: useCaseToSend,
        });

        if (slackNotificationError) {
            return yield put(requestIntegrationFail());
        }

        if (!slackNotificationSuccess.data.success) {
            showErrorToast(slackNotificationSuccess?.data?.message)
            return yield put(requestIntegrationFail());
        }

        showSuccessToast("Thank you for the details. Our team will get in touch with you.");
        return yield put(requestIntegrationSuccess());
    } catch (err) {
        showErrorToast();
        return yield put(requestIntegrationFail());
    }
}

function* updateApplicationSortSeqSaga({ payload }) {
    const { slugName, subscriptionId, userEmail } = payload;
    try {
        yield call(asyncPost, `/customer/updateAppSortSeq`, {
            slugName,
            subscriptionId,
            userEmail,
        });
        return yield put(requestIntegrationSuccess());
    } catch (err) {
        showErrorToast();
    }
}

export function* watchSelectIntegrations() {
    yield takeEvery(FETCH_INTEGRATIONS, getIntegrations);
    yield takeEvery(FILTER_INTEGRATIONS_BY_CATEGORY, filterIntegrationsByCategoriesSaga);
    yield takeEvery(FILTER_INTEGRATIONS_BY_TEXT, filterIntegrationsByTextSaga);
    yield takeEvery(REQUEST_INTEGRATION, requestIntegrationSaga);
    yield takeEvery(UPDATE_APPLICATION_SORT_SEQ, updateApplicationSortSeqSaga);
}
