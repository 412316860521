import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdMailOutline } from "react-icons/md";

import { EmailField, Form, Label, ShowPasswordField } from "components/Fields";
import WbButton from "components/WbButton";

import { loginViaWyzebulb } from "../AuthActions";

export default function Login(props: any) {
  const { initialPathname, initialSearchQuery } = useSelector(
    (state: any) => state.App
  );
  const dispatch = useDispatch();
  const formMethods = useForm();

  const onClickLogin = (formValues: any) => {
    dispatch(loginViaWyzebulb(formValues, initialPathname, initialSearchQuery));
  };

  return (
    <>
      <div className="h-screen">
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center px-4 dark:bg-slate-900 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="flex flex-row items-center justify-center">
                  <img
                    className="h-12 w-auto"
                    src="/assets/img/logos/wyzebulb-1x.png"
                    alt="Wyzebulb"
                  />
                </div>
                <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white/60">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                  Automate your workflows with Wyzebulb.{" "}
                </p>
              </div>

              <div className="mt-4">
                <div>
                  <div>{props.getSocialLogin()}</div>

                  <div className="relative mt-6">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm dark:text-white/60">
                      <span className="bg-white px-2 text-gray-500 dark:bg-gray-800 dark:text-white/60">
                        Or continue with
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <Form
                    form={formMethods}
                    handleSubmit={onClickLogin}
                    className="space-y-6"
                  >
                    <div className="space-y-1">
                      <EmailField
                        label={"Email address"}
                        type="email"
                        autoComplete="email"
                        AddOnIcon={MdMailOutline}
                        required
                        {...formMethods.register("userEmail")}
                      />
                    </div>

                    <div className="space-y-1">
                      <ShowPasswordField
                        label={"Password"}
                        AddOnIcon={RiLockPasswordLine}
                        autoComplete="current-password"
                        required
                        {...formMethods.register("userPassword")}
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <Label className="ml-2">Remember me</Label>
                      </div>

                      <div className="text-sm">
                        <Link
                          to="/forgot-password"
                          className="font-medium text-orange-600 hover:text-orange-500"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </div>

                    <WbButton type="submit" className="w-full md:w-[400px]">
                      Sign in
                    </WbButton>
                  </Form>
                </div>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-white/60">
                  Don't have an account yet?{" "}
                  <Link
                    to="/register"
                    className="mr-4 font-medium text-orange-600 hover:text-orange-500"
                  >
                    Register
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
