import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import WbLoader from "components/WbLoader";

export default function WbTabs({ tabs, isLoading = false }: any) {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const tabKeys = Object.keys(tabs);
  const tabValues = Object.values(tabs);

  useEffect(() => {
    if (location.search === "") {
      setActiveTab(0);
    } else {
      const tabIndex = tabValues.findIndex(({ pathName }: any) =>
        location.search.includes(pathName)
      );
      setActiveTab(tabIndex);
    }
  }, [activeTab, location.search, tabValues]);

  return (
    <div className="flex h-full w-full flex-wrap rounded-lg border-2 border-gray-100 bg-white dark:border-gray-700 dark:bg-gray-700 dark:text-white">
      <div className="h-fit w-full">
        <ul className="mb-auto flex flex-wrap items-center px-4 text-sm font-medium text-gray-500 dark:text-gray-400">
          {tabKeys.map((tabKey: string, idx: number) => {
            const tabName = tabs[tabKey].name;
            const tabPath = tabs[tabKey]?.pathName || "pathName-missing";

            return (
              <li key={tabKey} className="mr-2 uppercase" role="presentation">
                <Link
                  className={`active group inline-flex cursor-pointer rounded-t-lg border-b-2 border-transparent p-4 hover:border-orange-600 hover:text-orange-600 dark:hover:text-gray-300 ${
                    activeTab === idx
                      ? "border-b-2 border-orange-600 text-orange-600"
                      : "none"
                  }`}
                  onClick={() => setActiveTab(idx)}
                  data-toggle="tab"
                  role="tablist"
                  to={`?${tabPath}`}
                >
                  {tabName}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="relative flex max-h-min w-full min-w-0 flex-col break-words rounded bg-white dark:bg-gray-700">
          <div className="flex-auto p-4 ">
            <div className="tab-content tab-space">
              {tabKeys.map((tabKey: string, idx: number) => {
                const tabContent = tabs[tabKey].content;

                return (
                  <div
                    key={idx}
                    className={activeTab === idx ? "block" : "hidden"}
                  >
                    {tabContent}
                    {isLoading && <WbLoader message={"Please Wait Loading!"} />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
