import React, { useState } from "react";
import {
  HiOutlineArrowsRightLeft,
  HiOutlineArrowsUpDown,
} from "react-icons/hi2";
import IntegrationDropdown from "../IntegrationDropdown";

import Dropdown from "../Dropdown";
import { Link } from "react-router-dom";
import { asyncGet } from "utils/async_util";

function FlowBuilder() {
  const [triggerEvents, setTriggerEvents] = useState([]);
  const [actionEvents, setActionEvents] = useState([]);
  const [triggerApp, setTriggerApp] = useState("");
  const [actionApp, setActionApp] = useState("");
  const [triggerEvent, setTriggerEvent] = useState("");
  const [actionEvent, setActionEvent] = useState("");
  const [triggerId, setTriggerId] = useState("");
  const [actionId, setActionId] = useState("");
  const [tEventLoading, setTEventLoading] = useState(false);
  const [aEventLoading, setAEventLoading] = useState(false);

  const onSelectTriggerApp = async (value: any) => {
    setTriggerEvents([]);
    setTEventLoading(true);
    const [err, res]: any = await asyncGet(
      `${process.env.REACT_APP_API_URL}/integrations/get-integration-events?slug=${value}`
    );
    //const jsonData = await response.json();

    setTriggerApp(value);
    setTriggerEvents(res?.data?.triggerStageItems?.selectEvent?.events || []);
    setTriggerId(res?.data?._id);
    setTEventLoading(false);
  };

  const onSelectActionApp = async (value: any) => {
    setActionEvents([]);
    setAEventLoading(true);
    const [err, res]: any = await asyncGet(
      `${process.env.REACT_APP_API_URL}/integrations/get-integration-events?slug=${value}`
    );
    //const jsonData = await response.json();

    setActionApp(value);
    setActionEvents(res?.data?.actionStageItems?.selectAction?.actions || []);
    setActionId(res?.data?._id);
    setAEventLoading(false);
  };

  const onSelectTriggerEvent = (value: any) => {
    setTriggerEvent(value);
  };

  const onSelectActionEvent = (value: any) => {
    setActionEvent(value);
  };

  const stageItems = [
    {
      triggerStageItems: {
        stageItems: { selectEvent: { selectedEventSlug: triggerEvent } },
        integrationId: triggerId,
      },
    },
    {
      actionStageItems: {
        stageItems: { selectAction: { selectedActionSlug: actionEvent } },
        integrationId: actionId,
      },
    },
  ];

  const stringifiedStageItems = JSON.stringify(stageItems);

  return (
    <div className="flex flex-col p-4">
      <div className="py-2 px-4">
        <div className="pb-2 text-2xl leading-7 text-gray-700 dark:text-gray-100">
          Create your own Automation in few steps
        </div>
        <div className="text-md text-gray-400">
          Select App and its specific event
        </div>
      </div>
      <div className="w-full justify-around bg-gray-50 p-[2%] px-[5%] dark:bg-gray-800">
        <div className="flex  flex-col lg:flex-row ">
          {/**Trigger Section */}
          <div className="mx-auto mt-1 w-full rounded-3xl bg-white p-8 dark:bg-gray-600   lg:w-1/2">
            <div className="text-md text-gray-500 dark:text-white">
              Connect this App...
            </div>
            <IntegrationDropdown
              handleSelect={onSelectTriggerApp}
              shouldRedirect={false}
              showTrigger={true}
            />
            <div className="text-md pt-4 text-gray-500 dark:text-white">
              When this Happens...
            </div>
            <Dropdown
              options={triggerEvents}
              onChange={onSelectTriggerEvent}
              isLoading={setTEventLoading}
              placeholder="Search for Trigger Event"
            />
          </div>
          {/**Exchange Icon */}
          <div className="relative ">
            <HiOutlineArrowsRightLeft className="relative top-[12vh] hidden text-4xl text-orange-500 lg:block" />
            <HiOutlineArrowsUpDown className="relative left-[18vh] text-4xl text-orange-500 sm:block lg:hidden" />
          </div>
          {/**Action Section */}
          <div className="mx-auto  w-full rounded-3xl bg-white p-8 dark:bg-gray-600 lg:w-1/2 ">
            <div className="text-md text-gray-500 dark:text-white">
              With this one!
            </div>
            <IntegrationDropdown
              handleSelect={onSelectActionApp}
              shouldRedirect={false}
              showAction={true}
            />
            <div className="text-md pt-4 text-gray-500 dark:text-white">
              Do this!
            </div>
            <Dropdown
              options={actionEvents}
              onChange={onSelectActionEvent}
              isLoading={setAEventLoading}
              placeholder="Search for Action Event"
            />
          </div>
        </div>
        <Link
          to={`/flow-builder?stages=${encodeURIComponent(
            stringifiedStageItems
          )}`}
          className="float-right m-4 w-fit content-end rounded bg-orange-500 p-2 text-center text-white"
          color="orange"
        >
          Try Now
        </Link>
      </div>
    </div>
  );
}

export default FlowBuilder;
