import React from "react";

const StateButton = (props) => {
    return (
        <button id={props.id} className="bg-orange-600 px-6 py-3 text-white" onClick={props.onClick} disabled={props.showSpinner || props.disabled}>
            <span className="label">{props.children}</span>
        </button>
    );
};

export default StateButton;
