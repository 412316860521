import { call, put, takeEvery } from "redux-saga/effects";

import { asyncPost } from "utils/async_util";
import { IMPERSONATE_USER, SET_TOKEN_COOKIE_FOR_SELECTED_USER } from "./ImpersonateUserActionTypes";
import { setTokenCookieForSelectedUser } from "./ImpersonateUserActions";
import { showErrorToast } from "components/WbToast";

function* impersonateUserSaga({ payload }) {
    const { subscriptionId } = payload;
    try {
        const [err, res] = yield call(asyncPost, "/admin/impersonateUser", {
            subscriptionId,
        });
        if (err) {
            return;
        }

        if (!res.data.success) {
            return;
        }
        return yield put(setTokenCookieForSelectedUser(res.data.tokenGenerated));
    } catch (err) {
        showErrorToast();
    }
}

function setTokenCookieForSelectedUserSaga({ payload }) {
    const { accessToken } = payload;
    try {
        const noOfSecondsIn1Day = 24 * 60 * 60;
        const noOfSecondsIn7Days = 7 * noOfSecondsIn1Day;

        const { hostname } = window.location;

        const isLocal = hostname === "localhost";
        const domain = isLocal ? "localhost" : "wyzebulb.com";
        const secure = isLocal ? "" : "secure";
        const cookieStr = `token=${accessToken}; domain=${domain}; max-age=${noOfSecondsIn7Days}; path=/; ${secure}`;
        document.cookie = cookieStr;
        window.location = window.location.origin;
    } catch (err) {
        showErrorToast();
    }
}

export function* watchImpersonateUser() {
    yield takeEvery(IMPERSONATE_USER, impersonateUserSaga);
    yield takeEvery(SET_TOKEN_COOKIE_FOR_SELECTED_USER, setTokenCookieForSelectedUserSaga);
}
