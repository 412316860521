import React from "react";

import OptionalRequiredLabel from "../OptionalRequiredLabel";
import { isDataPresent } from "utils/object_util";
import JsonPairTypesEnum from "enums/JsonPairTypesEnum";

const defaultTextarea = (props) => {
    const { selectedValueData } = props;
    let selectedValue = "";
    if (isDataPresent(selectedValueData)) {
        const { jsonDetails: selectedJsonDetails } = selectedValueData;
        selectedValue = selectedJsonDetails[0].valueDetails[0].value;
    }
    return (
        <form>
            <OptionalRequiredLabel {...props} />
            {props.userHelperContent}
            <div>
                <textarea
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder || `Enter ${props.labelText}`}
                    required={props.isRequired}
                    {...(props.shouldHandleChange
                        ? {
                              onChange: (event) =>
                                  props.userInputHandler({
                                      jsonPairIndex: 0,
                                      userInput: {
                                          value: event.target.value,
                                          valueFrom: "userCustomValue",
                                          id: props.id,
                                      },
                                      jsonPairType: JsonPairTypesEnum.VALUE,
                                      jsonPairValueIndex: 0,
                                  }),
                          }
                        : {
                              onBlur: (event) =>
                                  props.userInputHandler({
                                      jsonPairIndex: 0,
                                      userInput: {
                                          value: event.target.value,
                                          valueFrom: "userCustomValue",
                                          id: props.id,
                                      },
                                      jsonPairType: JsonPairTypesEnum.VALUE,
                                      jsonPairValueIndex: 0,
                                  }),
                          })}
                    value={selectedValue}
                />
                <span>Please enter {props.labelText}</span>
            </div>
        </form>
    );
};

export default defaultTextarea;
