import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaCaretRight, FaInfoCircle } from "react-icons/fa";

import WbIntegrationImage from "components/WbIntegrationImage";
import WbButton from "components/WbButton";
import FlowTemplateDetailsModal from "../FlowTemplateDetailsModal";
import { initStagesToBeShown } from "../../FlowBuilder/FlowBuilderActions";

export default function SingleFlowTemplate({ singleFlowTemplate }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const triggerIntegration = singleFlowTemplate.triggerStageItems.integrationId;
  const actionIntegration =
    singleFlowTemplate.actionStageItems[0].integrationId;

  if (!actionIntegration || !triggerIntegration) {
    return null;
  }

  const stageItems = [
    {
      triggerStageItems: {
        ...singleFlowTemplate.triggerStageItems,
        integrationId: triggerIntegration._id,
      },
    },
    {
      actionStageItems: {
        ...singleFlowTemplate.actionStageItems[0],
        integrationId: actionIntegration._id,
      },
    },
  ];
  const stringifiedStageItems = JSON.stringify(stageItems);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="flex h-full flex-col items-center rounded border bg-gray-50 p-6 shadow-md dark:border-gray-700 dark:bg-gray-800 dark:text-white">
        <div className="flex flex-row items-center justify-center">
          <WbIntegrationImage
            slug={triggerIntegration.slug}
            altText={triggerIntegration.displayText}
            classNames="object-contain w-16 h-16"
          />
          <FaCaretRight size={12} />
          <WbIntegrationImage
            slug={actionIntegration.slug}
            altText={actionIntegration.displayText}
            classNames="object-contain w-16 h-16"
            avtarSize="50"
          />
        </div>
        <div className="mt-3 space-x-2 text-center text-base leading-5 tracking-normal text-gray-800 dark:text-gray-100">
          <FaInfoCircle size={12} onClick={toggleModal} className="inline" />
          <span>{singleFlowTemplate.name}</span>
        </div>
        <div className="mt-8 flex flex-col md:flex-row items-center space-x-3 ">
          <Link
            to={{
              pathname: `/flow-builder`,
              search: `?stages=${encodeURIComponent(stringifiedStageItems)}`,
            }}
            onClick={() => dispatch(initStagesToBeShown([]))}
            className="my-2 block rounded-md bg-gray-500 px-5 py-2 text-gray-100 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white"
          >
            Use this Flow
          </Link>
          <WbButton
            onClick={toggleModal}
            outline={true}
            color="gray"
            className="border-gray-200 text-base font-medium leading-6 transition duration-150 ease-in-out hover:text-gray-900"
          >
            <span>Explore Flow</span>
          </WbButton>
        </div>
      </div>
      {showModal && (
        <FlowTemplateDetailsModal
          flowTemplate={singleFlowTemplate}
          shouldShowModal={showModal}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
}
