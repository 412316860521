import axios from "axios";

import { showErrorToast } from "components/WbToast";

axios.defaults.baseURL = window.wyzebulbApiBaseUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    const { response: errResponse, message: errMessage } = err;

    if (errResponse) {
      const { error: errResponseMessage, message } = errResponse?.data;

      /* 
                Error Sample Message
                "400 - {"success":false,"message":"Error Message Goes Here."}"
                Axios Error Message is coming in the above format. below code will make it JSON Parsable.
            */
      if (errResponseMessage) {
        const formattedErr = errResponseMessage
          ?.replace(/\//g, "")
          ?.split("-")
          ?.splice(-1)[0];
        const errorInJson = JSON.parse(formattedErr);

        showErrorToast(
          errorInJson?.message ||
            "Something went wrong! Please reload and try again"
        );
      } else {
        showErrorToast(message);
      }
    } else {
      showErrorToast(errMessage);
    }
    return Promise.reject(err);
  }
);
