const integrations = [
    {
        _id: 'ObjectId("5c7f9a93fa15e3387f090f6b")',
        isActive: true,
        categories: ["google", "spreadsheet", "crm"],
        displayText: "Google Sheets",
        slug: "google-sheets-integration",
        isTrigger: false,
        isAction: true,
    },
    // {
    //     _id: 'ObjectId("5c7f9cb07755353fe1121500")',
    //     isActive: false,
    //     categories: ["facebook", "social-network", "lead-generation", "marketing", "sales"],
    //     displayText: "Facebook Lead Ads",
    //     slug: "facebook-lead-ads-integration",
    //     isTrigger: true,
    //     isAction: false,
    // },
    // {
    //     _id: 'ObjectId("5cac73c20dae590a9e913ecb")',
    //     isActive: true,
    //     categories: ["facebook", "social-network", "marketing"],
    //     displayText: "Facebook Pages",
    //     slug: "facebook-pages-integration",
    //     isTrigger: true,
    //     isAction: false,
    // },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe36e")',
        isActive: true,
        categories: ["crm"],
        displayText: "Instagram for Business",
        slug: "instagram-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f61e620506d56e445e6c7b")',
        isActive: true,
        categories: ["social-network", "messaging", "marketing", "sales"],
        displayText: "LinkedIn",
        slug: "linkedin-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcd5a")',
        isActive: true,
        categories: ["webhook", "trigger"],
        displayText: "Webhooks by Wyzebulb",
        slug: "wyzebulb-webhook-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("63b6c16c614ab22b32062d7c")',
        isActive: true,
        categories: ["webhook", "crm"],
        displayText: "Wyzebulb Upload",
        slug: "wyzebulb-upload-integration",
        isTrigger: true,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd366")',
        isActive: true,
        categories: ["crm", "sales", "analytics-platform", "support", "email", "sms", "lead-generation", "marketing", "sales", "messaging"],
        displayText: "Leadsquared",
        slug: "leadsquared-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63104e878e14396966a48033")',
        isActive: true,
        categories: ["project", "management", "tasks", "crm"],
        displayText: "Zoho CRM",
        slug: "zoho-crm-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbfa4")',
        isActive: true,
        categories: ["crm", "sales", "marketing"],
        displayText: "Salesforce",
        slug: "salesforce-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8d3b50867bf08cadda881")',
        isActive: true,
        categories: ["project", "management", "tasks", "crm"],
        displayText: "Hubspot",
        slug: "hubspot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd15c")',
        isActive: true,
        categories: ["google"],
        displayText: "Youtube",
        slug: "youtube-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("63075d0d0bd3c79d6f9181e8")',
        isActive: true,
        categories: ["google", "email"],
        displayText: "Gmail",
        slug: "gmail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("630774940bd3c79d6f91820f")',
        isActive: true,
        categories: ["docs", "google"],
        displayText: "Google Docs",
        slug: "google-docs-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63c93348158459b45fb4440a")',
        isActive: true,
        categories: ["ats"],
        displayText: "Lever",
        slug: "lever-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63e0933c7ae0899b155b2994")',
        isActive: true,
        categories: ["ats"],
        displayText: "Recruitee",
        slug: "recruitee-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63c67f6f690affaac95ff26d")',
        isActive: true,
        categories: ["ats"],
        displayText: "Greenhouse",
        slug: "greenhouse-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("6101f3e2af9a5371b417b31f")',
        isActive: false,
        categories: ["lms"],
        displayText: "Acadle",
        slug: "acadle-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5d5a6a35dff79b1e7fdebd2f")',
        isActive: true,
        categories: ["sms", "messaging"],
        displayText: "Twitch",
        slug: "twitch-integration",
        isTrigger: true,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe608")',
        isActive: false,
        categories: ["support"],
        displayText: "FreshService",
        slug: "freshservice-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd92a")',
        isActive: true,
        categories: ["sms", "messaging"],
        displayText: "Discord",
        slug: "discord-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe90e")',
        isActive: true,
        categories: ["crm", "email", "marketing", "sales", "automation-platform"],
        displayText: "ActiveCampaign",
        slug: "activecampaign-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd81c")',
        isActive: true,
        categories: ["marketing", "lead-generation", "website-builders", "sms", "automation-platform"],
        displayText: "E-goi",
        slug: "e-goi-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe5f6")',
        isActive: true,
        categories: ["crm", "marketing"],
        displayText: "Freshsales",
        slug: "freshsales-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcd12")',
        isActive: true,
        categories: ["sales", "lead-generation", "support", "marketing", "messaging"],
        displayText: "ManyChat",
        slug: "manychat-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb908")',
        isActive: true,
        categories: ["support"],
        displayText: "Front",
        slug: "front-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc50e")',
        isActive: false,
        categories: ["support"],
        displayText: "Freshdesk",
        slug: "freshdesk-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e7933d281ce32f930a1176")',
        isActive: true,
        categories: ["crm", "e-commerce", "sales", "marketing"],
        displayText: "Shopify",
        slug: "shopify-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eccadb1d0f9a5269b5c185")',
        isActive: true,
        categories: ["comments"],
        displayText: "Disqus",
        slug: "disqus-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba5c36f3c94fc93ca0d30")',
        isActive: false,
        categories: ["crm"],
        displayText: "SurveyMonkey",
        slug: "surveymonkey-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb24")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Gitlab",
        slug: "gitlab-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbab8")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Formsonfire",
        slug: "formsonfire-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbba94")',
        isActive: false,
        categories: ["email"],
        displayText: "Thankster",
        slug: "thankster-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbba2")',
        isActive: false,
        categories: ["email"],
        displayText: "Sendlane",
        slug: "sendlane-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbbb4")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Ciscospark",
        slug: "ciscospark-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbaa6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Evernotebusiness",
        slug: "evernotebusiness-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb842")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Desk",
        slug: "desk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc20")',
        isActive: false,
        categories: ["crm"],
        displayText: "Infusionsoft",
        slug: "infusionsoft-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbadc")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Selz",
        slug: "selz-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb986")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Kayako",
        slug: "kayako-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb93e")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Tickettailor",
        slug: "tickettailor-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb7fa")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Reddit",
        slug: "reddit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbbea")',
        isActive: false,
        categories: ["events"],
        displayText: "Picatic",
        slug: "picatic-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbbc6")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Leadinformation",
        slug: "leadinformation-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb12")',
        isActive: false,
        categories: ["email"],
        displayText: "Mailgun",
        slug: "mailgun-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb8c0")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Accredible",
        slug: "accredible-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc0e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Toky",
        slug: "toky-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb5a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Connectwisemanage",
        slug: "connectwisemanage-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb9aa")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Amazonsqs",
        slug: "amazonsqs-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb88a")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Awslambda",
        slug: "awslambda-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb7e")',
        isActive: false,
        categories: ["finance"],
        displayText: "Nibo",
        slug: "nibo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb9e0")',
        isActive: false,
        categories: ["survey-tools"],
        displayText: "Goformz",
        slug: "goformz-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb91a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "10000ft",
        slug: "10000ft-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb80c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Zohoprojects",
        slug: "zohoprojects-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbbfc")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Hourstack",
        slug: "hourstack-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb48")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Citrixsharefile",
        slug: "citrixsharefile-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbaee")',
        isActive: false,
        categories: ["finance"],
        displayText: "Moonclerk",
        slug: "moonclerk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb950")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Teamwork Projects",
        slug: "teamwork-projects-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbba3a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Teamworkdesk",
        slug: "teamworkdesk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbba82")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Autotask",
        slug: "autotask-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb8d2")',
        isActive: false,
        categories: ["cms"],
        displayText: "Magento",
        slug: "magento-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb36")',
        isActive: false,
        categories: ["support", "messaging"],
        displayText: "Livechat",
        slug: "livechat-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbb6c")',
        isActive: false,
        categories: ["email"],
        displayText: "Emailoctopus",
        slug: "emailoctopus-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbaca")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Postgresql",
        slug: "postgresql-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbba4c")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Ryver",
        slug: "ryver-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb854")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Clickfunnels",
        slug: "clickfunnels-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb9f2")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Bitly",
        slug: "bitly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb998")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Twist",
        slug: "twist-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb878")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Happyfox",
        slug: "happyfox-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb9bc")',
        isActive: false,
        categories: ["crm"],
        displayText: "Greenrope",
        slug: "greenrope-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb8e4")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Freshbooks",
        slug: "freshbooks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbba5e")',
        isActive: false,
        categories: ["email"],
        displayText: "Lockbin",
        slug: "lockbin-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb8ae")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Sifter",
        slug: "sifter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb89c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Teem",
        slug: "teem-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbd40")',
        isActive: false,
        categories: ["email"],
        displayText: "Smtp",
        slug: "smtp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbcb0")',
        isActive: false,
        categories: ["crm"],
        displayText: "Vtiger",
        slug: "vtiger-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbd1c")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Bambu",
        slug: "bambu-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbd0a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Getswift",
        slug: "getswift-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbcd4")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Newrelicinsights",
        slug: "newrelicinsights-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbd52")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Devicemagic",
        slug: "devicemagic-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc8c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Newyorktimes",
        slug: "newyorktimes-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc32")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Sqlserver",
        slug: "sqlserver-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbcc2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Projectmanager",
        slug: "projectmanager-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc68")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Supersaas",
        slug: "supersaas-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc44")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "jotform",
        slug: "jotform-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbc7a")',
        isActive: true,
        categories: ["e-commerce", "email", "marketing"],
        displayText: "Madmimi",
        slug: "madmimi-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe06")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Kunversion",
        slug: "kunversion-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbd88")',
        isActive: false,
        categories: ["survey-tools"],
        displayText: "Surveypal",
        slug: "surveypal-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbde2")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Kajabi",
        slug: "kajabi-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbdac")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Spotio",
        slug: "spotio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe4e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Flic",
        slug: "flic-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbd9a")',
        isActive: false,
        categories: ["email"],
        displayText: "Mailparser",
        slug: "mailparser-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbd76")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Asknicely",
        slug: "asknicely-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbd64")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Breezyhr",
        slug: "breezyhr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe2a")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Calltrackingmetrics",
        slug: "calltrackingmetrics-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe3c")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Swarm",
        slug: "swarm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe18")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Maximizer",
        slug: "maximizer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbdbe")',
        isActive: false,
        categories: ["crm"],
        displayText: "Nutshellcrm",
        slug: "nutshellcrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("6348f0519cdf45f37a55a2c3")',
        isActive: true,
        categories: ["payment", "event"],
        displayText: "Stripe",
        slug: "stripe-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("628377d06eb620f5c8d63e74")',
        isActive: false,
        categories: ["marketing"],
        displayText: "MoskIt CRM",
        slug: "moskit-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc62e")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Klipfolio",
        slug: "klipfolio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc532")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Trackvia",
        slug: "trackvia-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc316")',
        isActive: false,
        categories: ["crm"],
        displayText: "Onepagecrm",
        slug: "onepagecrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc1e4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Wintouch",
        slug: "wintouch-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc022")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Cognitoforms",
        slug: "cognitoforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc058")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Signupto",
        slug: "signupto-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf4a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Eposnow",
        slug: "eposnow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf26")',
        isActive: false,
        categories: ["crm"],
        displayText: "Convergehub",
        slug: "convergehub-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc3ca")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Cobot",
        slug: "cobot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc304")',
        isActive: true,
        categories: ["email"],
        displayText: "Zoho Mail",
        slug: "zoho-mail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc07c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Liquidplanner",
        slug: "liquidplanner-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbfec")',
        isActive: false,
        categories: ["email"],
        displayText: "Streamsend",
        slug: "streamsend-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf92")',
        isActive: false,
        categories: ["email"],
        displayText: "Quickmail",
        slug: "quickmail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe84")',
        isActive: false,
        categories: ["email"],
        displayText: "Followupboss",
        slug: "followupboss-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc60a")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Couponcarrier",
        slug: "couponcarrier-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc0d6")',
        isActive: false,
        categories: ["finance"],
        displayText: "Practiceignition",
        slug: "practiceignition-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc4a2")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Mattermost",
        slug: "mattermost-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc448")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Things",
        slug: "things-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc4b4")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Jasmin",
        slug: "jasmin-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc33a")',
        isActive: false,
        categories: ["sms"],
        displayText: "SMS Gateway Hub",
        slug: "smsgatewayhub-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc2ce")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Getfivestars",
        slug: "getfivestars-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc1c0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Targetprocess",
        slug: "targetprocess-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc568")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Youcanbookme",
        slug: "youcanbookme-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc4d8")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Calldrip",
        slug: "calldrip-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc520")',
        isActive: false,
        categories: ["cms"],
        displayText: "Magentov2",
        slug: "magentov2-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc490")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Avaza",
        slug: "avaza-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc382")',
        isActive: false,
        categories: ["crm"],
        displayText: "Repsly",
        slug: "repsly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc262")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googleforms",
        slug: "googleforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc11e")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Crisp",
        slug: "crisp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf6e")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Pushwoosh",
        slug: "pushwoosh-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbea8")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Serwersms",
        slug: "serwersms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe60")',
        isActive: false,
        categories: ["sms"],
        displayText: "Netcore-Smartech",
        slug: "smartech-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc5e6")',
        isActive: false,
        categories: ["video-audio-images"],
        displayText: "Widencollective",
        slug: "widencollective-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc394")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Zohocreator",
        slug: "zohocreator-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc328")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Wimi",
        slug: "wimi-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf80")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Formatter",
        slug: "formatter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf02")',
        isActive: false,
        categories: ["email"],
        displayText: "Createsend",
        slug: "createsend-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc4fc")',
        isActive: false,
        categories: ["internet-of-things-io-t", "development-tools"],
        displayText: "Daskeyboard5q",
        slug: "daskeyboard5q-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc5f8")',
        isActive: false,
        categories: ["crm"],
        displayText: "Zohocrm",
        slug: "zohocrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc412")',
        isActive: false,
        categories: ["email"],
        displayText: "Webmerge",
        slug: "webmerge-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc130")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Zohoforms",
        slug: "zohoforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc154")',
        isActive: false,
        categories: ["email"],
        displayText: "Mailup",
        slug: "mailup-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc046")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Ringcentral",
        slug: "ringcentral-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbffe")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Wistia",
        slug: "wistia-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbfb6")',
        isActive: false,
        categories: ["payment"],
        displayText: "Recurly",
        slug: "recurly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc4ea")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Transloadit",
        slug: "transloadit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc35e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Fieldbook",
        slug: "fieldbook-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc2f2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Trainerize",
        slug: "trainerize-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc1f6")',
        isActive: false,
        categories: ["survey-tools"],
        displayText: "Smartsurvey",
        slug: "smartsurvey-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc10c")',
        isActive: false,
        categories: ["finance"],
        displayText: "Invoiceninja",
        slug: "invoiceninja-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbfda")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Zohosubscriptions",
        slug: "zohosubscriptions-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf14")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Appointy",
        slug: "appointy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc46c")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Smsapi",
        slug: "smsapi-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc3dc")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googlehangoutschat",
        slug: "googlehangoutschat-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc286")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Burner",
        slug: "burner-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc166")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Codereadr",
        slug: "codereadr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc0b2")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Formforall",
        slug: "formforall-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc3ee")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Smoove",
        slug: "smoove-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc34c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Quizmaker",
        slug: "quizmaker-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc21a")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Botatbot",
        slug: "botatbot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe96")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Flickr",
        slug: "flickr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbe72")',
        isActive: false,
        categories: ["crm"],
        displayText: "Excel",
        slug: "excel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc640")',
        isActive: false,
        categories: ["finance"],
        displayText: "Lessaccounting",
        slug: "lessaccounting-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc370")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Easyprojects",
        slug: "easyprojects-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc23e")',
        isActive: false,
        categories: ["crm"],
        displayText: "Easircrm",
        slug: "easircrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc274")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Zillow",
        slug: "zillow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc22c")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Patreon",
        slug: "patreon-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc1d2")',
        isActive: false,
        categories: ["sales"],
        displayText: "Insidesalesbox",
        slug: "insidesalesbox-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbef0")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Edgar",
        slug: "edgar-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc5c2")',
        isActive: false,
        categories: ["crm"],
        displayText: "Zohorecruit",
        slug: "zohorecruit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc58c")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Prospectio",
        slug: "prospectio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc544")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Glip",
        slug: "glip-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc400")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Filestack",
        slug: "filestack-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc3a6")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Visuallease",
        slug: "visuallease-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc436")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Chatfuelforfacebook",
        slug: "chatfuelforfacebook-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc0e8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Sapjam",
        slug: "sapjam-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc06a")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Idxleads",
        slug: "idxleads-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbede")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Acton",
        slug: "acton-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc556")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Picreel",
        slug: "picreel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc5d4")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Sharpspring",
        slug: "sharpspring-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc298")',
        isActive: false,
        categories: ["cms"],
        displayText: "Happygrasshopper",
        slug: "happygrasshopper-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc208")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Etsy",
        slug: "etsy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc18a")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Tumblr",
        slug: "tumblr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc19c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Aha",
        slug: "aha-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc0a0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Pocket",
        slug: "pocket-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc08e")',
        isActive: false,
        categories: ["payment"],
        displayText: "Paypal",
        slug: "paypal-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc010")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Leadpages",
        slug: "leadpages-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc61c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Planyoonlinebooking",
        slug: "planyoonlinebooking-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc652")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Hotspotsystem",
        slug: "hotspotsystem-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc47e")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Realgeeks",
        slug: "realgeeks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc4c6")',
        isActive: false,
        categories: ["sms"],
        displayText: "Mobytsms",
        slug: "mobytsms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc3b8")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Repairshopr",
        slug: "repairshopr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf38")',
        isActive: false,
        categories: ["finance"],
        displayText: "Reckonone",
        slug: "reckonone-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc57a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Zapevent",
        slug: "zapevent-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc45a")',
        isActive: false,
        categories: ["crm"],
        displayText: "Nimble",
        slug: "nimble-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc2bc")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Visitortrack",
        slug: "visitortrack-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc0fa")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Vision6",
        slug: "vision6-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbfc8")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googlecloudprint",
        slug: "googlecloudprint-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbf5c")',
        isActive: false,
        categories: ["finance"],
        displayText: "Nusiiproposals",
        slug: "nusiiproposals-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc2aa")',
        isActive: false,
        categories: ["email"],
        displayText: "Referralrock",
        slug: "referralrock-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc250")',
        isActive: false,
        categories: ["events"],
        displayText: "Songkick",
        slug: "songkick-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc676")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Microsoftdynamics",
        slug: "microsoftdynamics-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc6d0")',
        isActive: false,
        categories: ["payment"],
        displayText: "Memberful",
        slug: "memberful-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc6be")',
        isActive: false,
        categories: ["cms"],
        displayText: "Wpallexportpro",
        slug: "wpallexportpro-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc69a")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Pipelinedeals",
        slug: "pipelinedeals-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("628329886eb620f5c8d63e72")',
        isActive: false,
        categories: ["crm"],
        displayText: "Harvest",
        slug: "harvest-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("628f60c9ce13757ee9f1b5cf")',
        isActive: false,
        categories: ["crm"],
        displayText: "Spotify",
        slug: "spotify-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce0e")',
        isActive: false,
        categories: ["finance"],
        displayText: "Saasu",
        slug: "saasu-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcbf2")',
        isActive: false,
        categories: ["email"],
        displayText: "Cyberimpact",
        slug: "cyberimpact-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca0c")',
        isActive: false,
        categories: ["user-management"],
        displayText: "Googlecontacts",
        slug: "googlecontacts-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcae4")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Coschedule",
        slug: "coschedule-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcd6c")',
        isActive: false,
        categories: ["crm"],
        displayText: "Sugarcrm",
        slug: "sugarcrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcec2")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Accelo",
        slug: "accelo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc94")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Callrail",
        slug: "callrail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbceb0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Hootboard",
        slug: "hootboard-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcbbc")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Storenvy",
        slug: "storenvy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb98")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Hipchat",
        slug: "hipchat-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca8a")',
        isActive: false,
        categories: ["email"],
        displayText: "Verticalresponse",
        slug: "verticalresponse-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc9b2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Moxtra",
        slug: "moxtra-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcd7e")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Olark",
        slug: "olark-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb3e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Ghost",
        slug: "ghost-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca42")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Meistertask",
        slug: "meistertask-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc9fa")',
        isActive: false,
        categories: ["sms"],
        displayText: "Textmagic",
        slug: "textmagic-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce44")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Basecamp3",
        slug: "basecamp3-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcd90")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Dialmycalls",
        slug: "dialmycalls-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca66")',
        isActive: false,
        categories: ["fundraising"],
        displayText: "Donationmanagerredcloud",
        slug: "donationmanagerredcloud-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcdc6")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Cincopa",
        slug: "cincopa-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcaae")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Amazonec2",
        slug: "amazonec2-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce9e")',
        isActive: false,
        categories: ["crm"],
        displayText: "Prosperworks",
        slug: "prosperworks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce56")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Square",
        slug: "square-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcdd8")',
        isActive: false,
        categories: ["finance"],
        displayText: "Zohobooks",
        slug: "zohobooks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcdb4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Speechtrans",
        slug: "speechtrans-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcca6")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Ruleranalytics",
        slug: "ruleranalytics-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbced4")',
        isActive: false,
        categories: ["sms"],
        displayText: "Text Marketer",
        slug: "text-marketer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc16")',
        isActive: false,
        categories: ["crm"],
        displayText: "Tribuscrm",
        slug: "tribuscrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca54")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Restyaboard",
        slug: "restyaboard-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc9c4")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Clearbit",
        slug: "clearbit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb74")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Bucketio",
        slug: "bucketio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc5e")',
        isActive: false,
        categories: ["email"],
        displayText: "Woodpecker",
        slug: "woodpecker-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcbaa")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Mixpanel",
        slug: "mixpanel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb62")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Meetup",
        slug: "meetup-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcdea")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Lighthouse",
        slug: "lighthouse-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbccdc")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Proposify",
        slug: "proposify-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbccca")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Surefire",
        slug: "surefire-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca1e")',
        isActive: false,
        categories: ["cms"],
        displayText: "Mautic",
        slug: "mautic-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc958")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Streettext",
        slug: "streettext-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc9e8")',
        isActive: false,
        categories: ["finance"],
        displayText: "Clio",
        slug: "clio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca30")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Upviral",
        slug: "upviral-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf0a")',
        isActive: false,
        categories: ["finance"],
        displayText: "Creditrepaircloud",
        slug: "creditrepaircloud-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce7a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Peoplehr",
        slug: "peoplehr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcd36")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Instapaper",
        slug: "instapaper-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce32")',
        isActive: false,
        categories: ["crm"],
        displayText: "Workbooks",
        slug: "workbooks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcd24")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Instantcontact",
        slug: "instantcontact-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcbce")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Limoanywhere",
        slug: "limoanywhere-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcad2")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Imagerelay",
        slug: "imagerelay-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcdfc")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Pipefy",
        slug: "pipefy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcee6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Redmine",
        slug: "redmine-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc4c")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Hatchbuck",
        slug: "hatchbuck-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb1a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Wodify",
        slug: "wodify-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbca78")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Loopyloyalty",
        slug: "loopyloyalty-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc8fe")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Vimeo",
        slug: "vimeo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcef8")',
        isActive: false,
        categories: ["finance"],
        displayText: "Runmyaccounts",
        slug: "runmyaccounts-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce68")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Gocanvas",
        slug: "gocanvas-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc70")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Weebly",
        slug: "weebly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcda2")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Inwise",
        slug: "inwise-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce8c")',
        isActive: false,
        categories: ["email"],
        displayText: "Cleverreach",
        slug: "cleverreach-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc3a")',
        isActive: false,
        categories: ["finance"],
        displayText: "Gustforaccelerators",
        slug: "gustforaccelerators-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbccb8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Papyrs",
        slug: "papyrs-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb08")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Next",
        slug: "next-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbce20")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Ringbyname",
        slug: "ringbyname-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcd48")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Clicktime",
        slug: "clicktime-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc82")',
        isActive: false,
        categories: ["finance"],
        displayText: "Expensify",
        slug: "expensify-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbccee")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Pusher",
        slug: "pusher-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc28")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Assembla",
        slug: "assembla-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcc04")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Dispatch",
        slug: "dispatch-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcaf6")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Whatconverts",
        slug: "whatconverts-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc838")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Onedesk",
        slug: "onedesk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc7de")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Universe",
        slug: "universe-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc96a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Office365",
        slug: "office365-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc892")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Joinme",
        slug: "joinme-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc784")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Burstsms",
        slug: "burstsms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc946")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Visualvisitor",
        slug: "visualvisitor-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc934")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Aivalabs",
        slug: "aivalabs-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc910")',
        isActive: false,
        categories: ["marketing"],
        displayText: "123formbuilder",
        slug: "123formbuilder-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc84a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Wrike",
        slug: "wrike-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc85c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Hive",
        slug: "hive-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc826")',
        isActive: false,
        categories: ["crm"],
        displayText: "Freeagent",
        slug: "freeagent-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc922")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Closeio",
        slug: "closeio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc73c")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Kanbanery",
        slug: "kanbanery-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc772")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Pomodoneapp",
        slug: "pomodoneapp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc8a4")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Ambassador",
        slug: "ambassador-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc796")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Intercom",
        slug: "intercom-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc7a8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Activecollab",
        slug: "activecollab-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc98e")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Code",
        slug: "code-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc9a0")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Supportbee",
        slug: "supportbee-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc86e")',
        isActive: false,
        categories: ["crm"],
        displayText: "Openerp",
        slug: "openerp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc7ba")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Windowsazurewebsites",
        slug: "windowsazurewebsites-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc880")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Pushbullet",
        slug: "pushbullet-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc814")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Rememberthemilk",
        slug: "rememberthemilk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc760")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Xingevents",
        slug: "xingevents-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc706")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Autopilot",
        slug: "autopilot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc718")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Bitbucket",
        slug: "bitbucket-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc9d6")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Uservoice",
        slug: "uservoice-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc7cc")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Barcloud",
        slug: "barcloud-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc97c")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Digitalocean",
        slug: "digitalocean-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc8ec")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Cyfe",
        slug: "cyfe-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc8b6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Lyft",
        slug: "lyft-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc802")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Amazons3",
        slug: "amazons3-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc74e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Lander",
        slug: "lander-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc6e2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Lusha",
        slug: "lusha-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf76")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Simplero",
        slug: "simplero-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf40")',
        isActive: false,
        categories: ["email"],
        displayText: "Imap",
        slug: "imap-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf52")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Angellist",
        slug: "angellist-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf64")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Delay",
        slug: "delay-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf2e")',
        isActive: false,
        categories: ["sms"],
        displayText: "InforUMobile",
        slug: "inforumobile-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf1c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Flock",
        slug: "flock-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd0a8")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Knack",
        slug: "knack-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd114")',
        isActive: false,
        categories: ["video-audio-images"],
        displayText: "Soundcloud",
        slug: "soundcloud-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd0ba")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Tapfiliate",
        slug: "tapfiliate-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd0cc")',
        isActive: false,
        categories: ["scheduling", "webinar"],
        displayText: "Demio",
        slug: "demio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd084")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Clickdesk",
        slug: "clickdesk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd04e")',
        isActive: false,
        categories: ["cms"],
        displayText: "Wpremote",
        slug: "wpremote-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd0f0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Leadquizzes",
        slug: "leadquizzes-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf9a")',
        isActive: false,
        categories: ["finance"],
        displayText: "Wealthbox",
        slug: "wealthbox-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcfac")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Nozbe",
        slug: "nozbe-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd060")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Google Adwords",
        slug: "google-adwords-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd006")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Surveygizmo",
        slug: "surveygizmo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcfe2")',
        isActive: false,
        categories: ["crm"],
        displayText: "Highrise",
        slug: "highrise-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd096")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Verifiedreviews",
        slug: "verifiedreviews-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd03c")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Nintex",
        slug: "nintex-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcfbe")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Amazoncloudwatch",
        slug: "amazoncloudwatch-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd0de")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Keepintouch",
        slug: "keepintouch-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcf88")',
        isActive: false,
        categories: ["email"],
        displayText: "Emailparser",
        slug: "emailparser-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd126")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Agentlegend",
        slug: "agentlegend-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd02a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Sidekick",
        slug: "sidekick-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcff4")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googleglass",
        slug: "googleglass-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd018")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Rewardsciences",
        slug: "rewardsciences-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd072")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Quip",
        slug: "quip-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd78c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Realvolve",
        slug: "realvolve-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd888")',
        isActive: false,
        categories: ["task-management"],
        displayText: "10to8",
        slug: "10to8-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd7d4")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Yammer",
        slug: "yammer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd7f8")',
        isActive: false,
        categories: ["management-tools"],
        displayText: "Highline",
        slug: "highline-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd82e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Campfire",
        slug: "campfire-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd840")',
        isActive: false,
        categories: ["email"],
        displayText: "Cheetah Digital",
        slug: "cheetahdigital-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd732")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Freshbooksnew",
        slug: "freshbooksnew-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd756")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Pingdom",
        slug: "pingdom-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd7e6")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Visualstudioonline",
        slug: "visualstudioonline-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd80a")',
        isActive: false,
        categories: ["sms"],
        displayText: "Smsfactor",
        slug: "smsfactor-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd79e")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Shortcm",
        slug: "shortcm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd744")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Brivity",
        slug: "brivity-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd864")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Learnercommunity",
        slug: "learnercommunity-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd7c2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Omnifocus",
        slug: "omnifocus-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd77a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Talentlms",
        slug: "talentlms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd876")',
        isActive: false,
        categories: ["cms"],
        displayText: "Egnyte",
        slug: "egnyte-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd6fc")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Contactually",
        slug: "contactually-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd852")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Gravityforms",
        slug: "gravityforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd4e0")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Clockworksms",
        slug: "clockworksms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd462")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Strava",
        slug: "strava-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd2fa")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googleslides",
        slug: "googleslides-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd2c4")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "OptinMonster",
        slug: "optinmonster-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd5a6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Beamer",
        slug: "beamer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd43e")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Giftup",
        slug: "giftup-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd1da")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Buysellads",
        slug: "buysellads-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd1c8")',
        isActive: false,
        categories: ["crm"],
        displayText: "Salesforceiq",
        slug: "salesforceiq-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd636")',
        isActive: false,
        categories: ["finance"],
        displayText: "Rightsignature",
        slug: "rightsignature-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd3d2")',
        isActive: false,
        categories: ["email"],
        displayText: "Websand",
        slug: "websand-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd378")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Hootsuite",
        slug: "hootsuite-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd354")',
        isActive: false,
        categories: ["sms"],
        displayText: "Voodoosms",
        slug: "voodoosms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd27c")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Nationbuildernexus",
        slug: "nationbuildernexus-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd67e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Basecamp2",
        slug: "basecamp2-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd582")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Fomo",
        slug: "fomo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd516")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Chatfuelfortelegram",
        slug: "chatfuelfortelegram-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd528")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Weekplan",
        slug: "weekplan-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd4ce")',
        isActive: false,
        categories: ["email"],
        displayText: "Mailmunch",
        slug: "mailmunch-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd41a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Resourceguru",
        slug: "resourceguru-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd210")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Prodpad",
        slug: "prodpad-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd16e")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Digest",
        slug: "digest-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd70e")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Meltwater",
        slug: "meltwater-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd55e")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Rss",
        slug: "rss-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd2a0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Onstrategy",
        slug: "onstrategy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd3ae")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Getprospect",
        slug: "getprospect-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd222")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Jenkins",
        slug: "jenkins-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd234")',
        isActive: false,
        categories: ["task-management"],
        displayText: "JIRA",
        slug: "jira-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd14a")',
        isActive: false,
        categories: ["e-commerce", "email"],
        displayText: "Mailchimpecommerce",
        slug: "mailchimpecommerce-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd5ee")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Unbounce",
        slug: "unbounce-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd5ca")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Gumroad",
        slug: "gumroad-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd408")',
        isActive: false,
        categories: ["finance"],
        displayText: "Xero",
        slug: "xero-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd2e8")',
        isActive: false,
        categories: ["finance"],
        displayText: "Quoteroller",
        slug: "quoteroller-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd192")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Amazonpolly",
        slug: "amazonpolly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd180")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Servemanager",
        slug: "servemanager-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd624")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Trustpilot",
        slug: "trustpilot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd4bc")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googleurlshortener",
        slug: "googleurlshortener-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd3c0")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Leadformly",
        slug: "leadformly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd1fe")',
        isActive: false,
        categories: ["crm"],
        displayText: "Sugarsync",
        slug: "sugarsync-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd4aa")',
        isActive: false,
        categories: ["finance"],
        displayText: "Zohoinvoice",
        slug: "zohoinvoice-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd2b2")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Fleep",
        slug: "fleep-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd6d8")',
        isActive: false,
        categories: ["email"],
        displayText: "Mailshake",
        slug: "mailshake-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd5b8")',
        isActive: false,
        categories: ["email"],
        displayText: "Klicktipp",
        slug: "klicktipp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd474")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Tubular",
        slug: "tubular-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd30c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Seg",
        slug: "seg-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd258")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Formsite",
        slug: "formsite-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd768")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Streamtime",
        slug: "streamtime-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd6ea")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Symphony",
        slug: "symphony-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd66c")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Procore",
        slug: "procore-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd65a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Livestorm",
        slug: "livestorm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd486")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Orbtr",
        slug: "orbtr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd54c")',
        isActive: false,
        categories: ["crm"],
        displayText: "Sunoray",
        slug: "sunoray-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd4f2")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Rssfeed",
        slug: "rssfeed-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd342")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Gotomeeting",
        slug: "gotomeeting-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd1ec")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Riddlequizmaker",
        slug: "riddlequizmaker-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd138")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Glueexcel",
        slug: "glueexcel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd612")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Beanstalk",
        slug: "beanstalk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd600")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Kissmetrics",
        slug: "kissmetrics-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd594")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Yodel",
        slug: "yodel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd5dc")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Callfire",
        slug: "callfire-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd31e")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Servicenow",
        slug: "servicenow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd38a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Seva",
        slug: "seva-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd570")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Brickftp",
        slug: "brickftp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd42c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Timetracker",
        slug: "timetracker-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd330")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Giphy",
        slug: "giphy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd1a4")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Woocommerce",
        slug: "woocommerce-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd26a")',
        isActive: false,
        categories: ["payment"],
        displayText: "Paymo",
        slug: "paymo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbd1b6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Braintree",
        slug: "braintree-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd6c6")',
        isActive: false,
        categories: ["finance"],
        displayText: "Quickbooks",
        slug: "quickbooks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd53a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Mapmyfitness",
        slug: "mapmyfitness-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd504")',
        isActive: false,
        categories: ["crm"],
        displayText: "Actpremium",
        slug: "actpremium-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd450")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Poptin",
        slug: "poptin-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd3e4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Basecampclassic",
        slug: "basecampclassic-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475a7905404035fbd39c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Mavenlink",
        slug: "mavenlink-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdbb2")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Sendowl",
        slug: "sendowl-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb22")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Plecto",
        slug: "plecto-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb58")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Samanage",
        slug: "samanage-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdac8")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Mention",
        slug: "mention-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda14")',
        isActive: false,
        categories: ["sms"],
        displayText: "Clickatell",
        slug: "clickatell-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd9de")',
        isActive: false,
        categories: ["email"],
        displayText: "Netcore-Falconide",
        slug: "falconide-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd996")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Webinato",
        slug: "webinato-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb34")',
        isActive: false,
        categories: ["human-resource"],
        displayText: "Vivahr",
        slug: "vivahr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb46")',
        isActive: false,
        categories: ["sms"],
        displayText: "Tatango",
        slug: "tatango-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdaa4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Actionnetwork",
        slug: "actionnetwork-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdbfa")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Seranking",
        slug: "seranking-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda02")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Rezdy",
        slug: "rezdy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdbd6")',
        isActive: false,
        categories: ["email"],
        displayText: "Privy",
        slug: "privy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda6e")',
        isActive: false,
        categories: ["crm"],
        displayText: "Liondeskcrm",
        slug: "liondeskcrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd8f4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Taskpigeon",
        slug: "taskpigeon-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb8e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Weather",
        slug: "weather-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda4a")',
        isActive: false,
        categories: ["crm"],
        displayText: "Sellf",
        slug: "sellf-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd9cc")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Autoremote",
        slug: "autoremote-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd906")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Liveagent",
        slug: "liveagent-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdbc4")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Gtxsms",
        slug: "gtxsms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd8be")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Zohoreports",
        slug: "zohoreports-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda80")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Hunter",
        slug: "hunter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd93c")',
        isActive: false,
        categories: ["scheduling", "webinar"],
        displayText: "Gotowebinar",
        slug: "gotowebinar-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb6a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Deputy",
        slug: "deputy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdb7c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Lucidmeetings",
        slug: "lucidmeetings-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdada")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Boingnet",
        slug: "boingnet-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd972")',
        isActive: false,
        categories: ["email"],
        displayText: "Sendloop",
        slug: "sendloop-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd9f0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Lob",
        slug: "lob-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda26")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Onesignal",
        slug: "onesignal-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd984")',
        isActive: false,
        categories: ["email"],
        displayText: "Dotmailer",
        slug: "dotmailer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdaec")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Wpforms",
        slug: "wpforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd94e")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Remarkety",
        slug: "remarkety-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd960")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Scoopit",
        slug: "scoopit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd918")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Icontactpro",
        slug: "icontactpro-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdab6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Rdstation",
        slug: "rdstation-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdbe8")',
        isActive: false,
        categories: ["email"],
        displayText: "Benchmarkemail",
        slug: "benchmarkemail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd9a8")',
        isActive: false,
        categories: ["email"],
        displayText: "Directmail",
        slug: "directmail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd8e2")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Skedda",
        slug: "skedda-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdba0")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Linkedinleadgenforms",
        slug: "linkedinleadgenforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbda92")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Raventools",
        slug: "raventools-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd9ba")',
        isActive: false,
        categories: ["crm"],
        displayText: "Spiro",
        slug: "spiro-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd8d0")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Pinboard",
        slug: "pinboard-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbd89a")',
        isActive: false,
        categories: ["email"],
        displayText: "Amazon SES",
        slug: "amazon-ses-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe12e")',
        isActive: false,
        categories: ["human-resource"],
        displayText: "Sparkhire",
        slug: "sparkhire-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde94")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Cloudconvert",
        slug: "cloudconvert-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd74")',
        isActive: false,
        categories: ["crm"],
        displayText: "Wave",
        slug: "wave-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd2c")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Proagentsolutions",
        slug: "proagentsolutions-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe10a")',
        isActive: false,
        categories: ["crm"],
        displayText: "Capsulecrm",
        slug: "capsulecrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdeca")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Cronofycalendarapi",
        slug: "cronofycalendarapi-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde28")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Processstreet",
        slug: "processstreet-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe044")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Submittable",
        slug: "submittable-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe0e6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Assessmentgenerator",
        slug: "assessmentgenerator-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe09e")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Myprintcloud",
        slug: "myprintcloud-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe0c2")',
        isActive: false,
        categories: ["email"],
        displayText: "Unisender",
        slug: "unisender-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdfea")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Itmplatform",
        slug: "itmplatform-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdcd2")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Analyticcalltracking",
        slug: "analyticcalltracking-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe164")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Taprightchatbot",
        slug: "taprightchatbot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf6c")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Caspioclouddatabase",
        slug: "caspioclouddatabase-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf24")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Quickbase",
        slug: "quickbase-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe07a")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Marketo",
        slug: "marketo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc54")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Moverbase",
        slug: "moverbase-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe032")',
        isActive: false,
        categories: ["crm"],
        displayText: "Adroll",
        slug: "adroll-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc0c")',
        isActive: false,
        categories: ["internet-of-things-io-t"],
        displayText: "Blink",
        slug: "blink-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdde0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Runkeeper",
        slug: "runkeeper-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd62")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Moco",
        slug: "moco-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc42")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Wix",
        slug: "wix-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe00e")',
        isActive: false,
        categories: ["email"],
        displayText: "Followup",
        slug: "followup-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd50")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Samcart",
        slug: "samcart-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdcc0")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Bigcartel",
        slug: "bigcartel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc30")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Scheduleonce",
        slug: "scheduleonce-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe19a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Route",
        slug: "route-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe020")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Iterate",
        slug: "iterate-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf00")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Dynamodb",
        slug: "dynamodb-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbddaa")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Yodiz",
        slug: "yodiz-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdea6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Teachable",
        slug: "teachable-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe068")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Mongodb",
        slug: "mongodb-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd1a")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Pdffiller",
        slug: "pdffiller-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf48")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Monday",
        slug: "monday-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf12")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Brandgaming",
        slug: "brandgaming-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbddbc")',
        isActive: false,
        categories: ["sms"],
        displayText: "Sendhub",
        slug: "sendhub-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe188")',
        isActive: false,
        categories: ["sales"],
        displayText: "Limego",
        slug: "limego-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdcf6")',
        isActive: false,
        categories: ["sms"],
        displayText: "seven67",
        slug: "seven67-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc9c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Approvaldonkey",
        slug: "approvaldonkey-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd98")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Tendersupport",
        slug: "tendersupport-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc78")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Podia",
        slug: "podia-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdfb4")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Slybroadcast",
        slug: "slybroadcast-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc66")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Jitbithelpdesk",
        slug: "jitbithelpdesk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf7e")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Databox",
        slug: "databox-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdeee")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Attach",
        slug: "attach-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde04")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Callexafeedback",
        slug: "callexafeedback-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe152")',
        isActive: false,
        categories: ["marketing", "analytics-platform"],
        displayText: "Promoterio",
        slug: "promoterio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde70")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Signupanywhere",
        slug: "signupanywhere-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc8a")',
        isActive: false,
        categories: ["payment"],
        displayText: "Fortepayments",
        slug: "fortepayments-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe0b0")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Whaller",
        slug: "whaller-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdfa2")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Stocktwits",
        slug: "stocktwits-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbddce")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Callhub",
        slug: "callhub-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe0f8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Optix",
        slug: "optix-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf90")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Sumo",
        slug: "sumo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdffc")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Apemobile",
        slug: "apemobile-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde5e")',
        isActive: false,
        categories: ["sales"],
        displayText: "Persistiq",
        slug: "persistiq-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe0d4")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Activedemand",
        slug: "activedemand-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdedc")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Docparser",
        slug: "docparser-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde4c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Northstar",
        slug: "northstar-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdce4")',
        isActive: false,
        categories: ["email"],
        displayText: "Email",
        slug: "email-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdcae")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Viewneo",
        slug: "viewneo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdc1e")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Leadsimple",
        slug: "leadsimple-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde82")',
        isActive: false,
        categories: ["finance"],
        displayText: "Invoiced",
        slug: "invoiced-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde3a")',
        isActive: false,
        categories: ["email"],
        displayText: "Sendpulse",
        slug: "sendpulse-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe056")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Mojo",
        slug: "mojo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdfd8")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Kissflow",
        slug: "kissflow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe176")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Zohoconnect",
        slug: "zohoconnect-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd08")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Arcgisonline",
        slug: "arcgisonline-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe35c")',
        isActive: false,
        categories: ["e-signing-softwares"],
        displayText: "Signnow",
        slug: "signnow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe314")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Marketvolt",
        slug: "marketvolt-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe284")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Actionsprout",
        slug: "actionsprout-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe24e")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Kanbanflow",
        slug: "kanbanflow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe46a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Proworkflow",
        slug: "proworkflow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe3b6")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Maintenancecare",
        slug: "maintenancecare-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe2cc")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Chatra",
        slug: "chatra-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe326")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Volusion",
        slug: "volusion-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe2ba")',
        isActive: false,
        categories: ["survey-tools"],
        displayText: "Zipformplus",
        slug: "zipformplus-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe260")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Andco",
        slug: "andco-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe410")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Tick",
        slug: "tick-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe272")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Fogbugz",
        slug: "fogbugz-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe542")',
        isActive: false,
        categories: ["finance"],
        displayText: "Zohonotebook",
        slug: "zohonotebook-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe48e")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Squarespace",
        slug: "squarespace-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe50c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Podio",
        slug: "podio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe23c")',
        isActive: false,
        categories: ["sms"],
        displayText: "SiegSMS",
        slug: "sieg-sms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe22a")',
        isActive: false,
        categories: ["payment"],
        displayText: "Chargify",
        slug: "chargify-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe446")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Yalla",
        slug: "yalla-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe458")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Webinarninja",
        slug: "webinarninja-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe3da")',
        isActive: false,
        categories: ["scheduling", "meeting"],
        displayText: "Anymeeting",
        slug: "anymeeting-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe3fe")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Linkedinmatchedaudiences",
        slug: "linkedinmatchedaudiences-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe4e8")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Honeybook",
        slug: "honeybook-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe3c8")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Nearbynow",
        slug: "nearbynow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe1d0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Notifymyandroid",
        slug: "notifymyandroid-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe4c4")',
        isActive: false,
        categories: ["news"],
        displayText: "Mercury",
        slug: "mercury-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe566")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Zapier",
        slug: "zapier-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe3a4")',
        isActive: false,
        categories: ["email"],
        displayText: "Sendy",
        slug: "sendy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe2f0")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Push",
        slug: "push-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe1f4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Rev",
        slug: "rev-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe2a8")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Neto",
        slug: "neto-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe4b2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Icontact",
        slug: "icontact-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe4a0")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Powrformbuilder",
        slug: "powrformbuilder-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe380")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Teamworkprojects",
        slug: "teamworkprojects-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe554")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Bizzabo",
        slug: "bizzabo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe51e")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Pardot",
        slug: "pardot-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe34a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Addressfinderaustralia",
        slug: "addressfinderaustralia-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe302")',
        isActive: false,
        categories: ["task-management"],
        displayText: "4demit",
        slug: "4demit-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe4d6")',
        isActive: false,
        categories: ["scheduling", "events"],
        displayText: "Ticketco",
        slug: "ticketco-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe434")',
        isActive: false,
        categories: ["finance"],
        displayText: "Practicepantherlegalsoftware",
        slug: "practicepantherlegalsoftware-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe3ec")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Buffer",
        slug: "buffer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe392")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Yumpu",
        slug: "yumpu-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe1e2")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Prefinery",
        slug: "prefinery-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe422")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Kickofflabs",
        slug: "kickofflabs-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe296")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Weekdone",
        slug: "weekdone-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe218")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Jvzoo",
        slug: "jvzoo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe1be")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Toodledo",
        slug: "toodledo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe920")',
        isActive: false,
        categories: ["website-builders"],
        displayText: "Whmcs",
        slug: "whmcs-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe8fc")',
        isActive: false,
        categories: ["crm"],
        displayText: "Wiseagentcrm",
        slug: "wiseagentcrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe73a")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Viralsweep",
        slug: "viralsweep-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe890")',
        isActive: false,
        categories: ["email"],
        displayText: "Bombbombcom",
        slug: "bombbombcom-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe6ce")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Onenote",
        slug: "onenote-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe5d2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Formidable",
        slug: "formidable-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea52")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Filter",
        slug: "filter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea76")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Safetyculture",
        slug: "safetyculture-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe8a2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Schedule",
        slug: "schedule-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea0a")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Appdotnet",
        slug: "appdotnet-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe74c")',
        isActive: false,
        categories: ["real-estate"],
        displayText: "Cloudattract",
        slug: "cloudattract-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe6f2")',
        isActive: false,
        categories: ["crm"],
        displayText: "Cloze",
        slug: "cloze-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe704")',
        isActive: false,
        categories: ["human-resource"],
        displayText: "Hubplanner",
        slug: "hubplanner-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea40")',
        isActive: false,
        categories: ["crm"],
        displayText: "Hubspotcrm",
        slug: "hubspotcrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe9f8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Solve360",
        slug: "solve360-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe7b8")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Memberspace",
        slug: "memberspace-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe7ca")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Runrunit",
        slug: "runrunit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe578")',
        isActive: false,
        categories: ["finance"],
        displayText: "Hiveage",
        slug: "hiveage-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe932")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Emma",
        slug: "emma-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe824")',
        isActive: false,
        categories: ["crm"],
        displayText: "Wootric",
        slug: "wootric-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe6bc")',
        isActive: false,
        categories: ["human-resource"],
        displayText: "Bamboohr",
        slug: "bamboohr-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbeaac")',
        isActive: false,
        categories: ["crm"],
        displayText: "Karmacrm",
        slug: "karmacrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea64")',
        isActive: false,
        categories: ["crm"],
        displayText: "Basecrm",
        slug: "basecrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe97a")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Facebookofflineconversions",
        slug: "facebookofflineconversions-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe8d8")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Mojohelpdesk",
        slug: "mojohelpdesk-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe7a6")',
        isActive: false,
        categories: ["e-commerce", "logistics"],
        displayText: "Dpd",
        slug: "dpd-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe5e4")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Precisefp",
        slug: "precisefp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe5ae")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Evernote",
        slug: "evernote-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea1c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Projectbubble",
        slug: "projectbubble-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe9c2")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Acuityscheduling",
        slug: "acuityscheduling-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe9e6")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Storage",
        slug: "storage-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe956")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Displetretsidx",
        slug: "displetretsidx-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe728")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Envoy",
        slug: "envoy-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe800")',
        isActive: false,
        categories: ["crm"],
        displayText: "Googleanalytics",
        slug: "googleanalytics-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe794")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Mindmanagerenterprise",
        slug: "mindmanagerenterprise-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe674")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Signrequest",
        slug: "signrequest-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe61a")',
        isActive: false,
        categories: ["telephony-software"],
        displayText: "Zipwhip",
        slug: "zipwhip-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe8ea")',
        isActive: false,
        categories: ["human-resource"],
        displayText: "Zenegyowner",
        slug: "zenegyowner-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe650")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Ninjaforms",
        slug: "ninjaforms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea88")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Webceo",
        slug: "webceo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe968")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Webflow",
        slug: "webflow-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe836")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Freedcamp",
        slug: "freedcamp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe662")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Float",
        slug: "float-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe63e")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Feedly",
        slug: "feedly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea2e")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Callloop",
        slug: "callloop-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe944")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Yocoboard",
        slug: "yocoboard-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe782")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Rocketchat",
        slug: "rocketchat-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe6e0")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Purechat",
        slug: "purechat-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe6aa")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Instantcustomer",
        slug: "instantcustomer-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe5c0")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Triggerapp",
        slug: "triggerapp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe9b0")',
        isActive: false,
        categories: ["sms"],
        displayText: "Sms",
        slug: "sms-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe86c")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Nocrmio",
        slug: "nocrmio-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe770")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Chatter",
        slug: "chatter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbea9a")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Scoro",
        slug: "scoro-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe8b4")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Dasheroo",
        slug: "dasheroo-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe99e")',
        isActive: false,
        categories: ["sms"],
        displayText: "mGage",
        slug: "mgage-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe85a")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Woobox",
        slug: "woobox-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe812")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Amazonsellercentral",
        slug: "amazonsellercentral-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe8c6")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Chatwork",
        slug: "chatwork-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe686")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Firebase",
        slug: "firebase-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe7dc")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Surveyfunnel",
        slug: "surveyfunnel-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe698")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Zohowriter",
        slug: "zohowriter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe58a")',
        isActive: false,
        categories: ["crm"],
        displayText: "Amocrm",
        slug: "amocrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeb96")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Teamworkpm",
        slug: "teamworkpm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbecc8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Ruum",
        slug: "ruum-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbecda")',
        isActive: false,
        categories: ["email"],
        displayText: "Bettervoicemail",
        slug: "bettervoicemail-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbed10")',
        isActive: false,
        categories: ["crm"],
        displayText: "Translate",
        slug: "translate-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbebba")',
        isActive: false,
        categories: ["crm"],
        displayText: "Bullhorncrm",
        slug: "bullhorncrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbed22")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Pinterest",
        slug: "pinterest-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeb72")',
        isActive: false,
        categories: ["email"],
        displayText: "Click2mail",
        slug: "click2mail-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec92")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Googletasks",
        slug: "googletasks-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbebcc")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Facebookgroups",
        slug: "facebookgroups-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeabe")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Happyfoxchat",
        slug: "happyfoxchat-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeb2a")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Ecwid",
        slug: "ecwid-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeba8")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Setmoreappointment",
        slug: "setmoreappointment-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec38")',
        isActive: false,
        categories: ["email"],
        displayText: "Reply",
        slug: "reply-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbead0")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Signupgenius",
        slug: "signupgenius-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeca4")',
        isActive: false,
        categories: ["automation-platform"],
        displayText: "Mercurywebparser",
        slug: "mercurywebparser-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbecb6")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "Ticketbud",
        slug: "ticketbud-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeaf4")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Amazonsns",
        slug: "amazonsns-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec02")',
        isActive: false,
        categories: ["crm"],
        displayText: "Redtailcrm",
        slug: "redtailcrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec14")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Megaventory",
        slug: "megaventory-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbecfe")',
        isActive: false,
        categories: ["crm"],
        displayText: "Dubsado",
        slug: "dubsado-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeb84")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Weemss",
        slug: "weemss-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec6e")',
        isActive: true,
        categories: ["website-builders", "marketing", "email", "analytics-platform", "sales"],
        displayText: "Mailerlite",
        slug: "mailerlite-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec4a")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Wisepops",
        slug: "wisepops-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec5c")',
        isActive: false,
        categories: ["crm"],
        displayText: "Appstatus",
        slug: "appstatus-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec26")',
        isActive: false,
        categories: ["finance"],
        displayText: "Abacus",
        slug: "abacus-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeb3c")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Tangocard",
        slug: "tangocard-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbec80")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Onedrive",
        slug: "onedrive-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbeb18")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Planviewleankit",
        slug: "planviewleankit-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbde16")',
        isActive: true,
        categories: ["real-estate", "telephony-software", "sms"],
        displayText: "Twilio",
        slug: "twilio-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7526e5364004acfa785ac")',
        isActive: true,
        categories: ["marketing", "email", "analytics-platform", "survey-tools", "website-builders"],
        displayText: "Mailchimp",
        slug: "mailchimp-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbbeba")',
        isActive: true,
        categories: ["messaging"],
        displayText: "Slack",
        slug: "slack-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc034")',
        isActive: true,
        categories: ["messaging", "sms"],
        displayText: "Waboxapp",
        slug: "waboxapp-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbc6f4")',
        isActive: true,
        categories: ["sms", "telephony-software", "messaging"],
        displayText: "Plivo",
        slug: "plivo-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb7d6")',
        isActive: true,
        categories: ["sms", "telephony-software"],
        displayText: "Exotel",
        slug: "exotel-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475e7905404035fbed34")',
        isActive: true,
        categories: ["crm", "support", "automation-platform", "survey-tools"],
        displayText: "Zendesk",
        slug: "zendesk-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747577905404035fbc424")',
        isActive: true,
        categories: ["crm", "marketing", "website-builders", "telephony-software", "management-tools", "sales"],
        displayText: "Agile CRM",
        slug: "agile-crm-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbbd2e")',
        isActive: false,
        categories: ["crm"],
        displayText: "Pipedrive",
        slug: "pipedrive-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdf5a")',
        isActive: true,
        categories: ["scheduling", "sms", "telephony-software", "management-tools"],
        displayText: "Contacts+",
        slug: "fullcontact-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475d7905404035fbe59c")',
        isActive: true,
        categories: ["email", "marketing"],
        displayText: "SendGrid",
        slug: "sendgrid-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb8f6")',
        isActive: true,
        categories: ["email"],
        displayText: "Mandrill",
        slug: "mandrill-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747587905404035fbcb2c")',
        isActive: true,
        categories: ["messaging", "sms"],
        displayText: "MSG91",
        slug: "msg91-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("6352819ea24360647ba013a8")',
        isActive: true,
        categories: ["task-management", "management-tools", "collaboration"],
        displayText: "Trello",
        slug: "trello-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5d2c4494725d983bc5465952")',
        isActive: true,
        categories: ["telephony-software", "support"],
        displayText: "Knowlarity",
        slug: "knowlarity-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5d5e2c9720a3b94770dd244f")',
        isActive: true,
        categories: ["marketing", "crm", "sales", "email", "analytics-platform", "sms", "automation-platform", "lead-generation"],
        displayText: "Metroleads",
        slug: "metroleads-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbdd86")',
        isActive: true,
        categories: ["website-builders", "cms"],
        displayText: "Wordpress",
        slug: "wordpress-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("60f8368baf9a5371b417b319")',
        isActive: true,
        categories: ["email", "marketing", "analytics-platform"],
        displayText: "SendFox",
        slug: "sendfox-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6100a6b6af9a5371b417b31c")',
        isActive: false,
        categories: ["email", "campaign"],
        displayText: "Encharge",
        slug: "encharge-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("610477095935c544b09ca2fe")',
        isActive: false,
        categories: ["email", "campaign"],
        displayText: "Qwary",
        slug: "qwary-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6105aa205935c544b09ca300")',
        isActive: true,
        categories: ["crm", "sales", "analytics-platform", "email", "finance", "lead-generation", "task-management", "messaging", "collaboration"],
        displayText: "Flowlu",
        slug: "flowlu-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb7475b7905404035fbe08c")',
        isActive: false,
        categories: ["scheduling", "meeting"],
        displayText: "Calendly",
        slug: "calendly-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("610dd65485fbd93898a5ef3a")',
        isActive: false,
        categories: ["crm"],
        displayText: "Kartra",
        slug: "kartra-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6125042468eae466f4d35d23")',
        isActive: false,
        categories: ["sms"],
        displayText: "TextLocal.in",
        slug: "textlocal-in-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("61147be7cb39914cdcb43440")',
        isActive: false,
        categories: ["sms"],
        displayText: "TextLocal",
        slug: "textlocal-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6115bb02cb39914cdcb43442")',
        isActive: false,
        categories: ["email"],
        displayText: "Sendinblue",
        slug: "sendinblue-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("611c57c3cb39914cdcb43447")',
        isActive: false,
        categories: ["crm"],
        displayText: "Notion",
        slug: "notion-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("615e64252d04954194c8772b")',
        isActive: true,
        categories: ["task-management", "scheduling", "management-tools"],
        displayText: "simPRO",
        slug: "simpro-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("61708a2383a0ef2944c4c814")',
        isActive: false,
        categories: ["notes"],
        displayText: "Readwise",
        slug: "readwise-integration",
        isTrigger: true,
        isAction: false,
    },
    {
        _id: 'ObjectId("61fc244723d6ee09e88f4b64")',
        isActive: false,
        categories: ["scheduling"],
        displayText: "CalendarHero",
        slug: "calendarhero-integration",
        isTrigger: true,
        isAction: false,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbba28")',
        isActive: false,
        categories: ["crm"],
        displayText: "Daylite CRM",
        slug: "daylitecrm-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("628cad98769af5281744f37d")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Sifterapp",
        slug: "sifterapp-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62c3cad02f1e2bd5eac015ff")',
        isActive: false,
        categories: ["crm", "task-management"],
        displayText: "Airtable",
        slug: "airtable-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62d131e8166a9f7809dc22ed")',
        isActive: false,
        categories: ["task-management"],
        displayText: "GetResponse",
        slug: "getresponse-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e0d6b7d0ee4df2f9f0ca08")',
        isActive: false,
        categories: ["social-network"],
        displayText: "Easypromos",
        slug: "easypromos-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e0e1b3d0ee4df2f9f0ca69")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Copper",
        slug: "copper-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f0dd36fda38d27655ef3e3")',
        isActive: true,
        categories: ["messaging", "sms"],
        displayText: "Textit",
        slug: "textit-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e104476094ec0aab4658a4")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Pipeline CRM",
        slug: "pipeline-crm-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e22dd761b17632bab6417b")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Pipeliner CRM",
        slug: "pipeliner-crm-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e2577461b17632bab64228")',
        isActive: false,
        categories: ["crm"],
        displayText: "Todoist",
        slug: "todoist-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e261d061b17632bab64263")',
        isActive: false,
        categories: ["marketing"],
        displayText: "ActiveTrail",
        slug: "activetrail-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e3a7b264e305c66e4b0481")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Chatbot",
        slug: "chatbot-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e3a8fd64e305c66e4b04a6")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "EngageBay",
        slug: "engagebay-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e3aa2164e305c66e4b04e4")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Campaign Monitor",
        slug: "campaignmonitor-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e3abde64e305c66e4b051d")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "ServiceM8",
        slug: "servicem8-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("62e3ad1564e305c66e4b053a")',
        isActive: false,
        categories: ["crm"],
        displayText: "Nethuntcrm",
        slug: "nethuntcrm-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e774013696d7c7bf8b04f2")',
        isActive: false,
        categories: ["crm"],
        displayText: "Bexio",
        slug: "bexio-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e796163696d7c7bf8b050c")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "clockify",
        slug: "clockify-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e79c053696d7c7bf8b0643")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Bugherd",
        slug: "bugherd-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e79eb13696d7c7bf8b0684")',
        isActive: false,
        categories: ["payment"],
        displayText: "RedBooth",
        slug: "redbooth-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e7a1843696d7c7bf8b06fd")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Elopage",
        slug: "elopage-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e7a2753696d7c7bf8b071c")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Groove",
        slug: "groove-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63072a67216ab0529f36278d")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "FormStack",
        slug: "formstack-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e7a9b33696d7c7bf8b072d")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "MessageBird",
        slug: "messagebird-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e7b4e63696d7c7bf8b0787")',
        isActive: false,
        categories: [""],
        displayText: "Aeroworkflow",
        slug: "aeroworkflow-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8cf260867bf08cadda842")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Sms Alert",
        slug: "smsalert-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8d0650867bf08cadda85f")',
        isActive: false,
        categories: ["Communication"],
        displayText: "Infobip",
        slug: "infobip-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f203852f83ecc89ca950d8")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Apify",
        slug: "apify-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8d5390867bf08cadda8ce")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Mailjet",
        slug: "mailjet-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("6305c5ddb539ac55acbecd83")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Aircall",
        slug: "aircall-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8d6760867bf08cadda8ff")',
        isActive: false,
        categories: ["analytics-platform"],
        displayText: "Monkeylearn",
        slug: "monkeylearn-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8d7970867bf08cadda95d")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "New Relic",
        slug: "newrelic-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8e5730867bf08cadda976")',
        isActive: false,
        categories: ["Service"],
        displayText: "Pagerduty",
        slug: "pagerduty-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6307620f7040c027f0a75181")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "EventBrite",
        slug: "eventbrite-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8f0b60867bf08cadda99e")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "PivotalTracker",
        slug: "pivotaltracker-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8f9e90867bf08cadda9b8")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Kanbanize",
        slug: "kanbanize-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8fa300867bf08cadda9f5")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Close",
        slug: "close-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8fc540867bf08caddaa19")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Jobnimbus",
        slug: "jobnimbus-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8fce10867bf08caddaa32")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "BulkSms",
        slug: "bulksms-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62ea1834e5896d60da415971")',
        isActive: false,
        categories: ["sms"],
        displayText: "ClickSend",
        slug: "clicksend-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62ea1d88e5896d60da4159c1")',
        isActive: false,
        categories: ["task-management"],
        displayText: "Favro",
        slug: "favro-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62ea2104e5896d60da4159e4")',
        isActive: false,
        categories: ["messaging"],
        displayText: "Pushover",
        slug: "pushover-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62ea21dee5896d60da415a18")',
        isActive: false,
        categories: ["email"],
        displayText: "Elastic Email",
        slug: "elasticemail-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62ea58bde5896d60da415a30")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Sumo logic",
        slug: "sumologic-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba23e81634d8c3c3f6e2f")',
        isActive: false,
        categories: ["crm"],
        displayText: "Aweber",
        slug: "aweber-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba2d581634d8c3c3f6e57")',
        isActive: false,
        categories: ["crm"],
        displayText: "Constant Contact",
        slug: "constantcontact-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba34981634d8c3c3f6e81")',
        isActive: false,
        categories: ["crm"],
        displayText: "Google Drive",
        slug: "googledrive-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba41881634d8c3c3f6efc")',
        isActive: false,
        categories: ["crm"],
        displayText: "Helpscout",
        slug: "helpscout-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba62b6f3c94fc93ca0d60")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Workable",
        slug: "workable-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eceeec898c6f563c825f4a")',
        isActive: false,
        categories: ["crm"],
        displayText: "SmartSheet",
        slug: "smartsheet-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("62ecf116898c6f563c825f79")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Aftership",
        slug: "aftership-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f1fda98dd4ea07e431da91")',
        isActive: false,
        categories: ["finance"],
        displayText: "Hellosign",
        slug: "hellosign-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f209ef8dd4ea07e431dac5")',
        isActive: false,
        categories: ["Marketing"],
        displayText: "Postmark",
        slug: "postmark-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f226628dd4ea07e431db0d")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Drip",
        slug: "drip-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f35083a796e2c5292b216b")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Toggl",
        slug: "toggl-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f4a1068dd4ea07e431db7c")',
        isActive: false,
        categories: ["subscription-management-tool"],
        displayText: "Chargebee",
        slug: "chargebee-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f4a75b8dd4ea07e431dbcb")',
        isActive: false,
        categories: ["crm"],
        displayText: "Zoom",
        slug: "zoom-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f5fde60506d56e445e6c33")',
        isActive: false,
        categories: ["crm"],
        displayText: "Typeform",
        slug: "typeform-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62fdd76604c8fec2a23b02c4")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Clickup",
        slug: "clickup-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62fb527795fa7835f40ff4f4")',
        isActive: false,
        categories: ["crm"],
        displayText: "Dropbox",
        slug: "dropbox-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6304960f918baed43929e6da")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "ontraport",
        slug: "ontraport-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6304a3db918baed43929e70b")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Paperform",
        slug: "paperform-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62eba4836f3c94fc93ca0d2e")',
        isActive: false,
        categories: ["lead-generation"],
        displayText: "Jump Cloud",
        slug: "jump-cloud-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6304c44f918baed43929e71b")',
        isActive: false,
        categories: ["meeting", "events", "Webinar"],
        displayText: "ClickMeeting",
        slug: "clickmeeting-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6305f18a9376df838fe45b2f")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Datadog",
        slug: "datadog-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6305fa5bb539ac55acbece2c")',
        isActive: false,
        categories: ["comments"],
        displayText: "Drift",
        slug: "drift-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630705c16886d8384d0d1282")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Streak",
        slug: "streak-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630709af6886d8384d0d12ae")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "TimeCamp",
        slug: "timecamp-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63070b636886d8384d0d12fc")',
        isActive: false,
        categories: ["e-commerce"],
        displayText: "Bigcommerce",
        slug: "bigcommerce-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63071ae06886d8384d0d132b")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Nexmo",
        slug: "nexmo-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630722b26886d8384d0d1399")',
        isActive: false,
        categories: ["payment,social network,e-commerce"],
        displayText: "Razor Pay",
        slug: "razorpay-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630724ba6886d8384d0d13c9")',
        isActive: false,
        categories: ["forms", "survey"],
        displayText: "Wufoo",
        slug: "wufoo-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63074b5e0bd3c79d6f9181b1")',
        isActive: false,
        categories: ["marketing"],
        displayText: "WATI",
        slug: "wati-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308b1e63d89cbd9a60ad307")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Okta",
        slug: "okta-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63086b29ba1a7059445ddc0b")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Pandadoc",
        slug: "pandadoc-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63086bd2ba1a7059445ddc29")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Delighted",
        slug: "delighted-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62e8faef0867bf08caddaa17")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Klaviyo",
        slug: "klaviyo-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630873a4ba1a7059445ddc7d")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Chargeover",
        slug: "chargeover-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308ca2a23f9369dae80a648")',
        isActive: false,
        categories: ["project", "management", "tasks", "todo"],
        displayText: "Asana",
        slug: "asana-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308835d7040c027f0a751bc")',
        isActive: false,
        categories: ["service platform"],
        displayText: "Box",
        slug: "box-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630890a07040c027f0a751ff")',
        isActive: false,
        categories: ["CRM functionality"],
        displayText: "Insightly",
        slug: "insightly-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308a1513d89cbd9a60ad2ba")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Shipstation",
        slug: "shipstation-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308a9993d89cbd9a60ad2f9")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Thinkific",
        slug: "thinkific-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6305b3d8f63711e43178fa40")',
        isActive: false,
        categories: ["Management Tools, Development Tools, Task management"],
        displayText: "CloudAMQP",
        slug: "cloudamqp-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308b56b3d89cbd9a60ad323")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Customerio",
        slug: "customerio-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308b5ac3d89cbd9a60ad33c")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Donately",
        slug: "donately-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6308b7193d89cbd9a60ad377")',
        isActive: false,
        categories: ["Ecommerce"],
        displayText: "Kanban Tool",
        slug: "kanbantool-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("6309aff7ab49feb2386f9c45")',
        isActive: false,
        categories: ["crm"],
        displayText: "Github",
        slug: "github-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("62f33f76a796e2c5292b215d")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Vbout",
        slug: "vbout-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630def12841404ba6fd523b7")',
        isActive: false,
        categories: ["sms"],
        displayText: "Abyssale",
        slug: "abyssale-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("630df623841404ba6fd523c6")',
        isActive: false,
        categories: ["crm"],
        displayText: "Miro",
        slug: "miro-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("631062d88e14396966a48066")',
        isActive: false,
        categories: ["development-tools"],
        displayText: "Mysql",
        slug: "mysql-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("63240d489d037abfd239c230")',
        isActive: false,
        categories: ["email"],
        displayText: "Convertkit",
        slug: "convertkit-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747567905404035fbb830")',
        isActive: false,
        categories: ["messaging", "facebook"],
        displayText: "Facebook Messenger",
        slug: "facebook-messenger-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("632c18ce63a9329630c1548a")',
        isActive: true,
        categories: ["sms", "messaging"],
        displayText: "WhatsApp Cloud API",
        slug: "whatsapp-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("632c3c0191c4c4c3c7d027fb")',
        isActive: false,
        categories: ["crm"],
        displayText: "Capsule",
        slug: "capsule-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("633707c4103d80a168bc89ef")',
        isActive: true,
        categories: ["webhook", "google"],
        displayText: "Google Sheets Webhook",
        slug: "google-sheets-webhooks-integration",
        isTrigger: true,
        isAction: false,
    },
    {
        _id: 'ObjectId("633ea1e1f9bbf8e9cff44d7c")',
        isActive: true,
        categories: ["crm", "sales", "analytics-platform", "support", "email", "sms", "lead-generation", "marketing", "sales", "messaging"],
        displayText: "Leadsquared V2",
        slug: "leadsquared-v2-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63049defa70c3ad36a5e20df")',
        isActive: true,
        categories: ["comments", "facebook", "social-network", "marketing"],
        displayText: "Facebook Page Comments",
        slug: "facebook-page-comments-integration",
        isTrigger: true,
        isAction: false,
    },
    {
        _id: 'ObjectId("62fc8d2d0a72316f602ef01d")',
        isActive: true,
        categories: ["facebook", "social-network", "lead-generation", "marketing", "sales"],
        displayText: "Facebook Lead Ads",
        slug: "facebook-lead-ads-v2-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("638db7f62e09f28fcb89bbcb")',
        isActive: true,
        categories: ["email", "event", "analytics-platform", "management-tools"],
        displayText: "EasySendy",
        slug: "easysendy-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("632c0e438dfde609410a0d19")',
        isActive: true,
        categories: ["messaging", "facebook"],
        displayText: "Facebook Page Messenger",
        slug: "facebook-page-messenger-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("638db7f92e09f28fcb89bc0d")',
        isActive: true,
        categories: ["crm", "time-management"],
        displayText: "Firmao",
        slug: "firmao-integration",
        isTrigger: false,
        isAction: true,
    },
    {
        _id: 'ObjectId("638db8002e09f28fcb89bc70")',
        isActive: true,
        categories: ["google", "time-management", "events", "management-tools"],
        displayText: "Google Calendar",
        slug: "google-calendar-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("5cb747597905404035fbcfd0")',
        isActive: true,
        categories: ["messaging", "social-network"],
        displayText: "Twitter",
        slug: "twitter-integration",
        isTrigger: true,
        isAction: true,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad88")',
        isActive: false,
        categories: ["finance"],
        displayText: "Sage Intact",
        slug: "sage-intact-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad87")',
        isActive: false,
        categories: ["billing", "marketing"],
        displayText: "Bill Com",
        slug: "bill-com-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad86")',
        isActive: false,
        categories: ["finance"],
        displayText: "Workday",
        slug: "workday-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad85")',
        isActive: false,
        categories: ["data"],
        displayText: "Amazon Cloud Watch",
        slug: "amazon-cloud-watch-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad84")',
        isActive: false,
        categories: ["crm", "sales"],
        displayText: "Thrivecart",
        slug: "thrivecart-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad83")',
        isActive: false,
        categories: ["email", "marketing"],
        displayText: "Campaign Monitor",
        slug: "campaign-monitor-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad82")',
        isActive: false,
        categories: ["task management", "crm", "meetings", "events", "timemanagement"],
        displayText: "Ebay",
        slug: "ebay-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad81")',
        isActive: false,
        categories: ["marketing", "sms", "messaging"],
        displayText: "Rdstation Onesignal",
        slug: "rdstation-onesignal-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad80")',
        isActive: false,
        categories: ["task management", "crm", "meetings", "events", "timemanagement"],
        displayText: "Smarttask",
        slug: "smarttask-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad7f")',
        isActive: false,
        categories: ["scheduling", "calendar", "event"],
        displayText: "Youcarbook Me",
        slug: "youcarbook-me-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad7e")',
        isActive: false,
        categories: ["sales", "marketing", "development tools"],
        displayText: "Netlify",
        slug: "netlify-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad7d")',
        isActive: false,
        categories: ["scheduling", "meeting", "messaging"],
        displayText: "Oncehub",
        slug: "oncehub-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad7c")',
        isActive: false,
        categories: ["sms", "marketing", "messaging"],
        displayText: "Salesmessage",
        slug: "salesmessage-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad7b")',
        isActive: false,
        categories: ["crm", "sales", "marketing"],
        displayText: "Baremetrics",
        slug: "baremetrics-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad7a")',
        isActive: false,
        categories: ["email", "marketing"],
        displayText: "Mailerliteclass",
        slug: "mailerliteclass-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad79")',
        isActive: false,
        categories: ["marketing"],
        displayText: "Pushcut",
        slug: "pushcut-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad78")',
        isActive: false,
        categories: ["crm"],
        displayText: "Zillow Techconnect",
        slug: "zillow-techconnect-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2c92a85afab2f9ad77")',
        isActive: false,
        categories: ["crm", "marketing"],
        displayText: "Flash",
        slug: "flash-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2b92a85afab2f9ad76")',
        isActive: false,
        categories: ["sales", "marketing", "crm", "finance"],
        displayText: "Splash",
        slug: "splash-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2b92a85afab2f9ad75")',
        isActive: false,
        categories: ["crm", "marketing"],
        displayText: "Cvent",
        slug: "cvent-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2b92a85afab2f9ad74")',
        isActive: false,
        categories: ["sales", "marketing"],
        displayText: "Drupal",
        slug: "drupal-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2b92a85afab2f9ad73")',
        isActive: false,
        categories: ["scheduling", "marketing"],
        displayText: "Teamup",
        slug: "teamup-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2b92a85afab2f9ad72")',
        isActive: false,
        categories: ["sales", "marketing", "spreadsheet"],
        displayText: "Stackby",
        slug: "stackby-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a2b92a85afab2f9ad71")',
        isActive: false,
        categories: ["crm", "marketing"],
        displayText: "Squareup",
        slug: "squareup-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a0f92a85afab2f9ad70")',
        isActive: false,
        categories: ["crm", "marketing"],
        displayText: "Bubble",
        slug: "bubble-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a0f92a85afab2f9ad6f")',
        isActive: false,
        categories: ["event", "webinar", "marketing", ""],
        displayText: "Instapage",
        slug: "instapage-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a0e92a85afab2f9ad6e")',
        isActive: false,
        categories: ["event", "webinar", "marketing", ""],
        displayText: "On 24",
        slug: "on-24-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a0d92a85afab2f9ad6d")',
        isActive: false,
        categories: ["webinar", "meeting", "messaging"],
        displayText: "Webinar Jam",
        slug: "webinar-jam-integration",
        isTrigger: false,
        isAction: false,
    },
    {
        _id: 'ObjectId("63ac0a0c92a85afab2f9ad6c")',
        isActive: false,
        categories: ["finance"],
        displayText: "Plaid",
        slug: "plaid-integration",
        isTrigger: false,
        isAction: false,
    },
];

module.exports = integrations;
