import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { filter, find, get, isEmpty } from "lodash";
import { FaUserCheck } from "react-icons/fa";

import { SELECT_ACCOUNT } from "enums/StageItemSlugsEnum";
import { connectAccNotApplicable } from "constants/integrations";
import SelectAccountModal from "./SelectAccountModal";

const SelectAccount = (props) => {
    const {
        stageIndex,
        stageType,
        subscription,
        selectedIntegrations,
        flowObj,
        commonData,
        userHelper,
        orderIndex,
    } = props;
    const { accounts = [] } = subscription || {};

    const [showSelectAccStageItemModal, setShowSelectAccStageItemModal] = useState(false);
    const selectedIntegration = selectedIntegrations[stageIndex];
    let accountsForSelectedInt = [];

    // filerting Accounts Based on the Selected Integration.
    if (!isEmpty(accounts)) {
        accountsForSelectedInt = filter(accounts, ({ integration: { id } }) => id.toString() === selectedIntegration?._id.toString());
    }
    const selectedAccount = get(flowObj, `stages[${stageIndex}].stageItems.selectAccount`, "");

    const wyzebulbWebhookTriggerUrl = get(commonData[stageIndex], `wyzebulbWebhookTriggerUrl`, "");
    const wyzebulbUploadedFile = get(commonData[stageIndex], `wyzebulbUploadedFile`, "");

    let selectedAccountDisplay = get(props, "unselectedDisplayText", "");

    // Verifiying Account Selection Stage is Completed to Continue or Not
    const isStageSetupComplete = !isEmpty(selectedAccount) ? true : [wyzebulbWebhookTriggerUrl, wyzebulbUploadedFile].some((s) => !isEmpty(s));

    if (isStageSetupComplete) {
        if (!isEmpty(selectedAccount)) {
            const selectedAccFromSub = find(accountsForSelectedInt, (acc) => selectedAccount.selectedAccountId.toString() === acc._id.toString());

            selectedAccountDisplay = get(selectedAccFromSub, "name", "");
        }
    }
    const isWyzebulbIntegration = connectAccNotApplicable.includes(selectedIntegration?.slug);

    useEffect(() => {
        const { stageIndex, flowObj, commonData } = props;
        const selectedIntegration = get(props, `selectedIntegrations.${stageIndex}.slug`, "");
        const selectedAccount = get(flowObj, `stages[${stageIndex}].stageItems.selectAccount`, "");

        const wyzebulbWebhookTriggerUrl = get(commonData[stageIndex], `wyzebulbWebhookTriggerUrl`, "");
        const wyzebulbUploadedFile = get(commonData[stageIndex], `wyzebulbUploadedFile`, "");

        // Verifying and Marking the Acc Selection Stage Completed Based on Some Conditions
        if (!isEmpty(selectedAccount)) {
            props.finishStageItem(SELECT_ACCOUNT);
        } else if (
            connectAccNotApplicable.includes(selectedIntegration) &&
            [wyzebulbWebhookTriggerUrl, wyzebulbUploadedFile].some((s) => !isEmpty(s))
        ) {
            props.finishStageItem(SELECT_ACCOUNT);
        }
    }, [props, wyzebulbWebhookTriggerUrl]);

    const initAccountSelection = () => {
        setShowSelectAccStageItemModal(true);
    };

    const stopAccountSelection = () => {
        setShowSelectAccStageItemModal(false);
    };

    return (
        <>
            <div onClick={initAccountSelection}>
                {!isStageSetupComplete ? (
                    <div className="mb-0 cursor-pointer max-w-fit" onClick={initAccountSelection}>
                        <span>
                            {!isWyzebulbIntegration ? (
                                <span>{orderIndex + 1}. Account - Click here to select account</span>
                            ) : selectedIntegration?.slug.includes("_upload") ? (
                                <span>{orderIndex + 1}. Upload File - Click here to Upload File</span>
                            ) : (
                                <span>{orderIndex + 1}. Webhook URL - Click here to select webhook URL</span>
                            )}
                        </span>
                        <span className="text-red-600 mx-1">(required)</span>
                    </div>
                ) : (
                    <div className="mb-0 cursor-pointer max-w-fit" onClick={initAccountSelection}>
                        <span>
                            {!isWyzebulbIntegration ? (
                                <span>
                                    {orderIndex + 1}. Account - {selectedAccountDisplay}
                                </span>
                            ) : selectedIntegration?.slug.includes("_upload") ? (
                                <span>{orderIndex + 1}. Upload File - Click here to Upload File</span>
                            ) : (
                                <span>{orderIndex + 1}. Webhook URL - Click here to select webhook URL</span>
                            )}
                            <span>
                                <FaUserCheck size={16} className="inline-block text-green-600 mx-1" />
                            </span>
                        </span>
                    </div>
                )}
            </div>
            {showSelectAccStageItemModal && (
                <SelectAccountModal
                    stageIndex={stageIndex}
                    stageType={stageType}
                    integrationForAccountAddition={selectedIntegration}
                    accounts={accountsForSelectedInt}
                    userHelper={userHelper}
                    integrationImageUrl={selectedIntegration?.imageUrl}
                    integrationDisplayText={selectedIntegration?.displayText}
                    reducerSelectedAccount={selectedAccount}
                    closeModal={stopAccountSelection}
                    slug={selectedIntegration?.slug}
                />
            )}
        </>
    );
};

const mapStateToProps = ({ LoggedInLayout, FlowBuilder }) => {
    const { subscription } = LoggedInLayout;
    const { selectedIntegrations, flowObj, commonData } = FlowBuilder;

    return {
        subscription,
        selectedIntegrations,
        flowObj,
        commonData,
    };
};

export default connect(mapStateToProps, null)(SelectAccount);
